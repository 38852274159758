import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { connect } from "react-redux";
import { MySelectField } from '../../../components';
import CommonModal from './CommonModal'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    modalTitle: {
        width: 500,
        backgroundColor: "#977c4f",
        color: 'white'
    },
    mainTitle: {
        display: 'flex',
        border: '2px solid #dedee7',
        borderRadius: 10,
        padding: 14,
        margin: '20px 20px 0px 20px',
        background: '#f8f8fa',
        alignItems: 'center',
    },
    mainTitleAbove: {
        display: 'flex',
        border: '2px solid #dedee7',
        borderRadius: 10,
        padding: 14,
        margin: '0px 20px 20px 20px',
        background: '#f8f8fa',
        alignItems: 'center',
    },
    avatar: {
        backgroundColor: "#977c4f",
        marginRight: 15,
    },
    dottedLine: {
        background: 'transparent',
        borderColor: '#96959e',
        borderStyle: 'dashed',
        borderLeftWidth: 2,
        width: 2,
        height: 48,
        marginLeft: 53,
        marginTop: 'auto',
    },
    dottedLineAbove: {
        background: 'transparent',
        borderColor: '#96959e',
        borderStyle: 'dashed',
        borderLeftWidth: 2,
        width: 2,
        height: 48,
        marginLeft: 53,
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    userMargin: {
        margin: 20
    },
    userMarginAbove: {
        margin: '20px 20px 0px 20px'
    }
}));

function AddLineModal(props) {
    const { addLineModal, handleAddLineClose, usersData, userInputValue, handleChanges, isAbove } = props;
    const classes = useStyles();

    return (
        <div>
            {/* <BootstrapDialog
                onClose={handleAddLineClose}
                aria-labelledby="customized-dialog-title"
                open={addLineModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleAddLineClose}
                    className={classes.modalTitle}>
                    {`Add Dotted Line ${isAbove === "isAbove" ? "Above" : "Below"}`}

                </BootstrapDialogTitle>
                <DialogContent dividers >
                    {isAbove === "isAbove" ?
                        <>
                            <div className={classes.userMarginAbove}>
                                <MySelectField
                                    label="User name"
                                    name="user_name"
                                    type="text"
                                    onChange={handleChanges}
                                    value={userInputValue}
                                    error={false}
                                    helperText=""
                                    placeholder="Select a person"
                                >
                                    {usersData && usersData.map((item,index) => {
                                        return (
                                            <option key={index} value={item._id}
                                                onChange={(e) => handleChanges(e, item._id)}>
                                                {item.user_name}
                                            </option>
                                        );
                                    })}
                                </MySelectField>
                            </div>
                            <hr className={classes.dottedLineAbove} />
                            <div className={classes.mainTitleAbove}>
                                <Avatar className={classes.avatar}>
                                    <AccountCircleIcon />
                                </Avatar>
                                <div>
                                    Manager
                                </div>
                            </div>
                        </> :
                        <>
                            <div className={classes.mainTitle}>
                                <Avatar className={classes.avatar}>
                                    <AccountCircleIcon />
                                </Avatar>
                                <div>
                                    Manager
                                </div>
                            </div>
                            <hr className={classes.dottedLine} />

                            <div className={classes.userMargin}>
                                <MySelectField
                                    label="User name"
                                    name="user_name"
                                    type="text"
                                    onChange={handleChanges}
                                    value={userInputValue}
                                    error={false}
                                    helperText=""
                                    placeholder="Select a person"
                                >
                                    {usersData && usersData.map((item, index) => {
                                        return (
                                            <option key={index} value={item._id}
                                                onChange={(e) => handleChanges(e, item._id)}>
                                                {item.user_name}
                                            </option>
                                        );
                                    })}
                                </MySelectField>
                            </div>
                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleAddLineClose}>
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    usersData: state.HierarachyChart.usersData,
});

const ConnectWith = connect(mapStateToProps, null)(AddLineModal);
export default (ConnectWith);