//ExportCsv
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import { Button } from '@material-ui/core';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  Download: {
    marginLeft: '3px',
    padding: '7px 30px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  }
});

class ExportCsv extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { classes } = this.props;
    var allData = []
    var headData = ["First Name","Last Name","EmployeeID", "TimeCode", "TotalHours", "HourRate", "Total", "WorkDate", "Authorized"]
    allData.push(headData)

    this.props.rowData.map(item => {
      var rowData = []
      rowData.push(item.username)
      rowData.push(item.lastname)
      rowData.push(item.EmployeeID)
      rowData.push(item.TimeCode.replace('"', ''))
      rowData.push(item.TotalHours)
      rowData.push(item.HourRate)
      rowData.push(item.Total)
      rowData.push(item.WorkDate ? (item.WorkDate).replace('"', '') : "")
      rowData.push(item.Authorized.replace(/"/g, ""))
      allData.push(rowData)
    });

    allData = allData.join('\r\n');
    
    return (
      <CSVLink
        filename={"cash.csv"}
        data={allData}
        style={{textDecoration: "none"}}
      >
        <Button
          className={classes.Download}
        >
          {/* PE */}
          CASH
        </Button>
      </CSVLink>
    );
  }
}

export default withStyles(styles)(ExportCsv);
