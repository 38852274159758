import {
    SET_SETTING_LOADING,
  SET_SETTING_DATA,
  SET_SETTING
} from "./constants";

const initialState = {
  isLoading: true,
  SettingData: [],
  settingPayload: [],
}

const Reducer = (state = initialState, { type, payload }) => {
  console.log("abc payload",payload)
  switch (type) {
    case SET_SETTING_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SETTING_DATA:
      return {
        ...state,
        isLoading: false,
        settingPayload: payload.settingPayload
      };
    //   case SET_SETTING:
    //     return {
    //       ...state,
    //       isLoading: false,
    //       SettingData: payload
    //     };
    default:
      return state;
  }
};

export default Reducer;