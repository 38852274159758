import {
  SET_TIMEOFF_LOADING,
  SET_TIMEOFF,
  ADD_TIMEOFF,
  EDIT_TIMEOFF,
  DELETE_TIMEOFF,
  EDIT_STATUS_TIMEOFF
} from "./constants";

const initialState = {
  isLoading: true,
  mytimeOffData: [],
  usersData: [],
  timeOffCategory: [],
  teamTimeOffData:[]
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMEOFF_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMEOFF:
      return {
        ...state,
        isLoading: false,
        usersData: payload.usersOnThisLocation,
        mytimeOffData: payload.myTimeOff,
        teamTimeOffData:payload.teamTimeOff,
        timeOffCategory: payload.timeOffCategory
      };
    case ADD_TIMEOFF:
      return {
        ...state,
        isLoading: false,
        timeOffData: [...state.teamTimeOffData, payload]
      }
    // case EDIT_TIMEOFF:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     timeOffData: state.timeOffData.map((item) => item._id === payload._id ? { ...payload } : item)
    //   }
    case EDIT_STATUS_TIMEOFF:
      var newTimeoff = state.teamTimeOffData.map((item) => {
        var cloneItem = item
        if (item._id === payload._id) {
          cloneItem["status"] = payload.status
          cloneItem["approved_by"] = payload.approved_by
          cloneItem["approved_date"] = payload.approved_date
        }
        return cloneItem
      })
      return {
        ...state,
        teamTimeOffData: newTimeoff
      }
    case DELETE_TIMEOFF:
      return {
        ...state,
        isLoading: false,
        teamTimeOffData: state.teamTimeOffData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;