import React from "react";
import './Hierarachy.css'
import { MyLeftModal, MyTextfield } from '../../../components';
import { ovHrColor } from "../../../config";
import DeleteModal from "./DeleteModal";
import _ from "lodash";
import './Hierarachy.css';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { InputBase } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    inputBase: {
        width: 44,
        height: 44,
    },
    flex: {
        display: 'flex'
    },
    colorTitle: {
        marginLeft: 29
    },
}));


const EditDepartmentModal = (props) => {
    const { onHandleCloseEditDepartModal, editDepartmentModal, onHandleCloseDeleteModal,
        deleteRoleModal, oneItem, onHandleDeleteClick, isName,
        onChangeInput, inputsValue, onHandleEditClick } = props;

    const classes = useStyles();
    return (
        <>
            <MyLeftModal
                bgColor={ovHrColor.main}
                title={isName === "isEdit" ? "Edit Role" : "Add Role"}
                open={editDepartmentModal}
                onClose={() => onHandleCloseEditDepartModal(false)}
                handleSubmit={() => onHandleEditClick(inputsValue, oneItem)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MyTextfield
                            name="name"
                            label="Role Name"
                            onChange={onChangeInput}
                            value={inputsValue.name}
                            type="text"
                            placeholder="Enter Role name"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid>
                            Color :
                        </Grid>
                        <Grid className={classes.flex}>
                            <Grid>
                                <InputBase type="color"
                                    className={classes.inputBase} inputProps={{ className: classes.inputBase }}
                                    onChange={onChangeInput} name="colorcode" value={inputsValue.colorcode}
                                />
                            </Grid>
                            <Grid className={classes.colorTitle}>
                                {inputsValue.colorcode == '' ? '#000000' : inputsValue.colorcode}
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            maxRows={6}
                            name="description"
                            label="Description"
                            aria-label="maximum height"
                            placeholder="Enter Description"
                            defaultValue={inputsValue.description}
                            style={{ width: '-webkit-fill-available', height: 81 }}
                        />
                    </Grid>
                </Grid>
            </MyLeftModal>

            <DeleteModal
                bgColor={ovHrColor.main}
                desc="Are you sure you want to delete?"
                open={deleteRoleModal}
                onClose={onHandleCloseDeleteModal}
                handleDelete={() => onHandleDeleteClick(inputsValue.id)}
            />
        </>
    )
};
export default EditDepartmentModal;