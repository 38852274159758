import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  DeleteModal,
  MyDatePicker,
  MySelectField,
  MyMultiSelect,
  MyLeftModal,
  MyTextfield,
  MyAutocomplete,
} from "../../../components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getScheduleData,
  updateScheduleData,
} from "../Settings/modules/actions";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
import {
  alert,
  localTimeToUtc,
  validationIsEmpty,
  sliderTimeReturn,
} from "../../../utilities";
import moment from "moment";
import Backdrop from "../../../components/Backdrop";
import Modal from "../../../components/Modal";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TimeZone from "../Settings/Timezone";
import { history } from "../../../history";
import { settingsServices } from "../Settings/modules/services";
import "./setting.css";
import EditIcon from "@mui/icons-material/Edit";
import SaveSection from "../Settings/SaveSection";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { StickyContainer, Sticky } from "react-sticky";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { utcToTimeZone } from "../../../utilities/utcToTimeZone";
import {
  CardContent,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  OutlinedInput,
  FormGroup,
  Tooltip,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  ButtonGroup,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
// import ClearModal from './ClearModal';
// import NewModal from './NewModal';
// import NotifyManager from './NotifyManager';
// import ViewBreakModal from "../Settings/ViewBreakModal";
// import AddAvailability from './AddAvailability'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import TimePicker from 'rc-time-picker';
import "rc-time-picker/assets/index.css";
import "./news.css";
import "./tabelCss.css";
import { teamAvailabilityService } from "./modules/services";
// import CollapsedTableTeamAvailability from "./CollapsedTableTeamAvailability";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const schUID = cookies.get('ovUid')
const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
    marginLeft: 10,
  },
  listCell1: {
    width: "10.5rem",
    // padding:'8px 4px'
  },
  listCell2: {
    width: "6rem",
  },
  listCell3: {
    width: "6rem",
    // padding:'8px 0'
  },
  listCell4: {
    width: "6rem",
  },
  listCell5: {
    width: "5rem",
    // padding:'8px 0'
  },
  listCell6: {
    width: "6rem",
    // padding:'8px 0'
  },
  listCell7: {
    width: "8rem",
    // padding:'8px 0'
  },
  listCell8: {
    width: "6rem",
  },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },
  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    background: "#FFFCFC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: " 16px 68px 0px 68px",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: 70,
    borderRadius: "6px",
    cursor: "pointer",
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
  mainDivScale: {
    paddingTop: "1%",
    lineHeight: "1%",
    // border: "1px solid #F3F2F2",
    margin: "2%",
    width: "97%",
  },
  timeRang: {
    width: "100%",
    paddingLeft: "10%",
    marginTop: "-2%",
  },
  mainweek: {
    paddingLeft: "0%",
  },
  root: {
    padding: theme.spacing(2),
  },
  btnChild: {
    cursor: "pointer",
  },
});

export function fetchSCSettingsScheduling() {
  window.Scheduling.getScheduleData();
}

var format = "h:mm a";
var setTimePreStartNew = dayjs("2022-04-07");
var setTimePreEndNew = dayjs("2022-04-07");
var setTimeNew = dayjs("2022-04-07");
var setTimeEndNew = dayjs("2022-04-07");
class Scheduling extends Component {
  constructor(props) {
    super(props);
    window.Scheduling = this;
    this.state = {
      disable: true,
      isWeek: false,
      flageed: [
        { value: 1, label: "Flagged" },
        { value: 0, label: "Blocked" },
      ],
      applyToAll: 0,
      workschedule: false,
      workRestriction: false,
      Reminders: false,
      breakss: false,
      alertsAndNotification: false,
      emoji: 1,
      anchorEl: null,
      checked: true,
      open: false,
      openLeft: false,
      startDate: new Date(),
      scheduler_lid: cookies.get("scheduler_lid"),
      startTime: moment().hour(0).minute(0),
      timezone: "",
      openModalSch: false,
      setTime: setTimeNew,
      setTimeEnd: setTimeEndNew,
      setTimePreStart: setTimePreStartNew,
      // setTimePreStartNew: '',
      setTimePreEnd: setTimePreEndNew,
      valueStartTime: moment(),
      valueStartTimeNew: moment(),
      valueEndtime: moment(),
      valueEndTimeNew: moment(),
      settings: {},
      valueTab: "0",
      data: {},
      allBreaks: [],
      timeArray: [],
      isWholeChecked: 0,
      addBreaksData: [],
      myavailabilityData: [],
      usersData: [],
      location_timezone: "",
      newLocationState: "",
      dayState: "",
      deleterowId: "",
      break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      getApiResponse: [],
      isMustAknowledge_comment: false,
      BlockDropdownData: [
        { value: 2, label: "Block" },
        { value: 1, label: "Flag" },
      ],
      PaidDropdownData: [
        { value: 1, label: "Paid" },
        { value: 2, label: "Unpaid" },
      ],
      MandatoryDropdownData: [
        { value: 1, label: "Mandatory" },
        { value: 2, label: "Optional" },
      ],
      ScheduleDay: [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
      ],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
      ],
      weeks: [
        {
          id: 0,
          name: "Sunday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 1,
          name: "Monday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 2,
          name: "Tuesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 3,
          name: "Wednesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 4,
          name: "Thursday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 5,
          name: "Friday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 6,
          name: "Saturday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
      ],
      weeksOrignal: [
        {
          id: 0,
          name: "Sunday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 1,
          name: "Monday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 2,
          name: "Tuesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 3,
          name: "Wednesday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 4,
          name: "Thursday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 5,
          name: "Friday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
        {
          id: 6,
          name: "Saturday",
          value: {
            start: "00:00",
            end: "00:00",
          },
        },
      ],
      dropdownData: [],
    };
  }

  toggleDisable = () => {
    if (this.state.breakss === true) {
      this.setState((prevState) => ({
        disable: !prevState.disable,
      }));
    }
  };

  fetchChildDataByIdNew = (index, time) => {
    // var weekData = this.state.weeks
    // var data = {
    //     av_id: this.state.av_id,
    //     lid: this.props.scheduler_lid
    // }
    // myAvailabilityService.fetchDataByIdNew(data).then(response => {
    //     this.setState({
    //         getApiResponse: response.data
    //     })
    //     console.log("this.state.getApiResponse >>>>>>", this.state.getApiResponse)
    //     if (response.data.success === 2) {
    //         // invalidToken(response.data.message)
    //     } else {
    //         if (response.data.eventsData.length > 0) {
    //             let temp = [...this.state.weeks]
    //             for (var i = 0; i < response.data.eventsData.length; i++) {
    //                 temp = temp.map((el, index1) => {
    //                     if (response.data.eventsData[i].day_id == el.id) {
    //                         return { ...el, value: { start: response.data.eventsData[i].start_time, end: response.data.eventsData[i].end_time } }
    //                     }
    //                     else {
    //                         return { ...el }
    //                     }
    //                 }
    //                 )
    //             }
    //             this.setState({
    //                 weeks: temp
    //             })
    //         }
    //         else {
    //             this.setState({
    //                 weeks: this.state.weeksOrignal
    //             })
    //         }
    //     }
    //     this.setState({
    //         eventsData: response.data.eventsData,
    //         isLoading: false
    //     })
    // });
  };

  handleChangeMainAvailability = (e) => {
    this.setState(
      {
        isLoading: true,
        av_id: e.target.value,
      },
      () => {
        history.push(`/scheduler/myavailabilitynew?av_id=${this.state.av_id}`);
        this.fetchChildDataByIdNew();
      }
    );
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleViewModalOpen = () => {
    this.setState({
      viewModalOpen: true,
    });
  };

  showPreviewBreak = () => {
    this.handleViewModalOpen();
    this.setState({
      isLoading: true,
    });
    settingsServices
      .previewBreaks(this.props.scheduler_lid)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            previewBreakData: response.data.PayrollData,
            isLoading: false,
          });

          alert.success(response.data.message);
        }
      });
  };

  onDeleteBreak = (id) => {
    settingsServices.deleteBreaks(id).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        this.setState({
          previewBreakData: response.data.PayrollData,
        });
        alert.success(response.data.message);
      }
    });
  };

  handleViewModalClose = () => {
    this.setState({
      viewModalOpen: false,
    });
  };

  onChangeSetAsAllWeeks = (e) => {
    this.setState({ isWeek: !this.state.isWeek });
    if (e.target.checked) {
      var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
        const openValue =
          this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
        const closeValue =
          this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
        var cloneItem = item;
        cloneItem["schedule_day_start_time"] = openValue;
        cloneItem["schedule_day_end_time"] = closeValue;
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    } else {
      var newRoutes = [...this.state.weekDaysTimingsData].map(
        (item, myIndex) => {
          const openValue =
            this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
          const closeValue =
            this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
          var cloneItem = item;
          cloneItem["schedule_day_start_time"] = "";
          cloneItem["schedule_day_end_time"] = "";
          if (myIndex === 0) {
            cloneItem["schedule_day_start_time"] = openValue;
            cloneItem["schedule_day_end_time"] = closeValue;
          }
          return cloneItem;
        }
      );
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    }
  };

  handleChangeWeekTiming = (e, isOpen, index) => {
    var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
      var cloneItem = item;
      if (index === myIndex) {
        cloneItem[isOpen] = e.value;
      }
      return cloneItem;
    });
    this.setState({
      weekDaysTimingsData: newRoutes,
    });
  };

  getScheduleData = () => {
    this.setState({
      isLoading: true,
    });
    var lid = this.props.scheduler_lid;
    this.props.getScheduleData(lid);
  };

  handleChangeBreaksCheckbox = (e, index) => {
    var allBreaks = this.state.allBreaks;
    var state = e.target.name;
    var value = e.target.value;

    allBreaks[index][state] = allBreaks[index][state] ? 0 : 1;
    allBreaks[index].add_edit_status =
      allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
    if (value) {
      this.setState((prevState) => ({
        allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
        isBreakUpdate: 1,
        data: {
          ...prevState.data,
          active_breakrule_id: value,
        },
        isBreakUpdate: 1,
      }));
    }
  };

  handleChangeBreaksValue = (e, index) => {
    var allBreaks = this.state.allBreaks;
    var state = e.target.name;
    var value = e.target.value;
    allBreaks[index][state] = value;
    allBreaks[index].add_edit_status =
      allBreaks[index] && allBreaks[index]._id ? "edit" : "add";
    this.setState({
      allBreaks: JSON.parse(JSON.stringify(this.state.allBreaks)),
      isBreakUpdate: 1,
    });
  };

  addNewRow = () => {
    this.setState((prevState) => ({
      addBreaksData: [
        ...prevState.addBreaksData,
        {
          status: 0,
          break_minutes: "0",
          is_paid: "0",
          break_hours: "0",
          is_mendatory: "0",
        },
      ],
    }));
  };

  handleChangeNewBreaksValue = (e, index) => {
    var addBreaksData = this.state.addBreaksData;
    var state = e.target.name;
    var value = e.target.value;
    addBreaksData[index][state] = value;
    addBreaksData[index].add_edit_status = "add";
    this.setState({
      addBreaksData: JSON.parse(JSON.stringify(this.state.addBreaksData)),
      isBreakUpdate: 1,
    });
  };

  deleteRow = (data) => {
    this.setState({
      deleteModal: true,
      deleterowId: data._id,
    });
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    settingsServices
      .deleteBreakData(this.state.deleterowId)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            allBreaks: this.state.allBreaks.filter(
              (item) => item._id !== this.state.deleterowId
            ),
          });
          alert.success(response.data.message);
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
      if (this.props.LocationDetaildata.WorkScheduleData) {
        const newWeekDaysTimingsData = [
          ...this.props.LocationDetaildata.WorkScheduleData,
        ].map((item) => {
          var cloneItem = item;
          var schedule_day_start_time = moment(item.schedule_day_start_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          var schedule_day_end_time = moment(item.schedule_day_end_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          cloneItem["schedule_day_start_time"] = schedule_day_start_time;
          cloneItem["schedule_day_end_time"] = schedule_day_end_time;
          return cloneItem;
        });
        this.setState({
          weekDaysTimingsData: newWeekDaysTimingsData,
        });
      }
      this.setState({
        isLoading: false,
        location_timezone:
          this.props.LocationDetaildata.locationDetail.location_timezone,
        data: this.props.LocationDetaildata.locationDetail,
      });
    }
  }

  handleChangeData = (e) => {
    let state = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: checked === true ? 1 : 0,
      },
    }));
  };

  handleChangeDate = (date, state) => {
    let d = new Date(date);
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [state]: date,
        },
      }),
      () => { }
    );
  };

  editworkRestriction = () => {
    var sendObj = {
      early_clock_in_before_shift_start:
        this.state.data.early_clock_in_before_shift_start,
      early_clock_in_before_shift_start_type:
        this.state.data.early_clock_in_before_shift_start_type,
      early_clock_in_before_shift_start_minutes:
        this.state.data.early_clock_in_before_shift_start_minutes,
      ending_break_earlier_than_break_settings:
        this.state.data.ending_break_earlier_than_break_settings,
      automatically_clock_out_employees:
        this.state.data.automatically_clock_out_employees,
      automatically_clock_out_employees_minutes:
        this.state.data.automatically_clock_out_employees_minutes,
      automatically_clock_out_employees_flag_issue:
        this.state.data.automatically_clock_out_employees_flag_issue,
      automatically_end_breaks_employees:
        this.state.data.automatically_end_breaks_employees,
      automatically_end_breaks_employees_minutes:
        this.state.data.automatically_end_breaks_employees_minutes,
      automatically_end_breaks_employees_flag_issue:
        this.state.data.automatically_end_breaks_employees_flag_issue,
      mark_employee_as_late: this.state.data.mark_employee_as_late,
      mark_employee_as_late_minutes:
        this.state.data.mark_employee_as_late_minutes,
      mark_employee_as_late_flag_issue:
        this.state.data.mark_employee_as_late_flag_issue,
    };
    settingsServices
      .workRestriction(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            workRestriction: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editReminders = () => {
    var sendObj = {
      clock_out_reminder_before_shift_end:
        this.state.data.clock_out_reminder_before_shift_end,
      clock_out_reminder_before_shift_end_minutes:
        this.state.data.clock_out_reminder_before_shift_end_minutes,
      clock_out_reminder_after_shift_end:
        this.state.data.clock_out_reminder_after_shift_end,
      clock_out_reminder_after_shift_end_minutes:
        this.state.data.clock_out_reminder_after_shift_end_minutes,
    };
    settingsServices
      .Reminders(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            Reminders: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editBreaks = () => {
    var sendObj = {
      isbreak_enable: this.state.data.isbreak_enable,
      break_start_date: this.state.data.break_start_date,
      break_end_date: this.state.data.break_end_date,
      break_Employees_get_minutes: this.state.data.break_Employees_get_minutes,
      break_Employees_get_count: this.state.data.break_Employees_get_count,
      break_Employees_get_hour: this.state.data.break_Employees_get_hour,
      isbreak_hours_type: this.state.data.isbreak_hours_type,
      paid_break_to_an_unpaid_break:
        this.state.data.paid_break_to_an_unpaid_break,
      automatically_apply_breaks_on_timecard:
        this.state.data.automatically_apply_breaks_on_timecard,
    };
    settingsServices
      .breaks(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            breakss: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  editAlertsAndNotification = () => {
    var sendObj = {
      manager_approval_for_availability_requests:
        this.state.data.manager_approval_for_availability_requests,
      employees_create_correction_requests:
        this.state.data.employees_create_correction_requests,
      shift_feedback_after_end: this.state.data.shift_feedback_after_end,
    };
    settingsServices
      .alertsandnotification(this.props.scheduler_lid, sendObj)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          this.setState({
            alertsAndNotification: false,
          });
          this.props.getScheduleData(this.props.scheduler_lid);
          alert.success(response.data.message);
        }
      });
  };

  handleChangeSelectValue = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeText = (e) => {
    let state = e.target.name;
    let value = e.target.value;

    if (state) {
      const validMin = value.match(/^[0-9]+$/);
      if (!validMin && value !== "") {
        alert("Not a valid number");
      }
    }
    // if (this.state.data.enforce_clockout_reminder_min < this.state.data.enforce_auto_clockout_min) {
    //     alert("Clock-out reminder should be less than Automatically clock-out employee")
    // }
    // else{
    //     // value = ""
    // }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [state]: value,
      },
    }));
  };

  handleChangeDays = (e) => {
    this.setState({
      location_timezone: e.target.value,
    });
  };

  handleApplyToAll = (e) => {
    this.setState({
      applyToAll: e.target.checked ? 1 : 0,
    });
  };

  rangeCalculater = (date, index) => {
    if (date === undefined || date === null) {
      return false;
    }
    var formatStart = moment(date.start_time, "hh:mm:ss").format("HH");
    var formatEnd = moment(date.end_time, "hh:mm:ss").format("HH");
    var formatStartNew = moment(date.start_time, "hh:mm:ss").format("HH:mm");
    var formatEndNew = moment(date.end_time, "hh:mm:ss").format("HH:mm");
    const startRange = formatStart !== "" ? Number(formatStart) : null;
    const endRange = formatEnd !== "" ? Number(formatEnd) : null;
    if (startRange === null || endRange === null) {
      return false;
    }

    const inbtwnRange = index >= startRange && index <= endRange ? true : false;

    return {
      inbtwnRange: inbtwnRange,
      startIndex: {
        index: startRange,
        value: formatStartNew,
      },
      endIndex: {
        index: endRange,
        value: formatEndNew,
      },
    };
  };

  handleChangeCheck = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleClickTwo = (event, index) => {


    this.state.getApiResponse.eventsData.filter((item) => {
      setTimePreStartNew = moment(item.start_time, "hh:mm:ss").format(
        "YYYY-MM-DDhh:mm:ss"
      );
      setTimePreEndNew = moment(item.end_time, "hh:mm:ss").format(
        "YYYY-MM-DDhh:mm:ss"
      );
      if (item.day_id === index) {
        return this.setState({
          openLeft: true,
          dayState: index,
          setTimePreStart: setTimePreStartNew,
          setTimePreEnd: setTimePreEndNew,
        });

        // setTimeNew = moment(this.state.setTime,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
        // setTimeEndNew = moment(this.state.setTimeEnd,"hh:mm:ss").format("YYYY-MM-DDhh:mm:ss")
      }
    });
  };

  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const date = moment(this.state.startDate).format("YYYY-MM-DD");
    const time = moment(this.state.startTime).format("THH:mm:ssZ");
    const total = date + time;
    const now = moment().hour(0).minute(0);
    const { dropdownData } = this.state;

    const {
      permissionsData,
      classes,
      LocationDetaildata,
      isLoading,
      timezone,
      getApiResponse,
    } = this.props;
    console.log("sche", this.props.scheduler_lid)
    var selectedDropdown = dropdownData.find(
      (dropdown) => parseInt(this.state.av_id) === dropdown.id
    );
    if (this.state.isLoading) return <LoadingData />;
    if (isLoading) return <LoadingData />;
    return (
      // permissionsData.scheduler_settings ?
      <>
        <Grid container spacing={2} style={{ padding: "8px" }}>
          <TableContainer component={Paper}>
            <Table
              style={{ tableLayout: "fixed" }}
              aria-label="collapsible table"
            >
              <TableHead style={{ backgroundImage: schedulerColor.main }}>
                <TableRow>
                  <TableCell
                    className={classes.listCell1}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.listCell2}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Show/Hide
                    <br /> in Scheduler
                  </TableCell>
                  <TableCell
                    className={classes.listCell3}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Availablity %
                  </TableCell>
                  <TableCell
                    className={classes.listCell4}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Available
                  </TableCell>
                  <TableCell
                    className={classes.listCell5}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Preferred
                  </TableCell>
                  <TableCell
                    className={classes.listCell6}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Unavailable
                  </TableCell>
                  <TableCell
                    className={classes.listCell7}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Payrule
                  </TableCell>
                  <TableCell
                    className={classes.listCell8}
                    style={{ color: "white", fontWeight: "300" }}
                  >
                    Availablity Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.getApiResponse.userData &&
                  this.props.getApiResponse.userData.map((items, index) => {
                    console.log("djchjdcjdcj", items)
                    return (
                      <>
                        <TableRow
                          onClick={() =>
                            this.props.handleClickToggle(items.open, index)
                          }
                          style={{ backgroundColor: "lightgray" }}
                          hover
                        >
                          <TableCell className={classes.listCell1} align="left">
                            <Box display={"flex"} style={{ gap: "0.4rem" }}>
                              <Box
                                style={{
                                  transform: items.open ? "rotate(90deg)" : "",
                                }}
                              >
                                <ArrowForwardIosIcon />
                              </Box>

                              <Typography
                                className={classes.subjectName}
                                style={{
                                  color: schedulerColor.main,
                                  opacity: 0.8,
                                }}
                                variant="h6"
                              >
                                {items.user_name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.listCell2} align="left">
                            <RemoveRedEyeIcon />
                            {/* <VisibilityOffIcon/> */}
                          </TableCell>
                          <TableCell className={classes.listCell3} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              10%
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell4} align="left">
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {items.hours_available}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell5} align="left">
                            <Typography
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              20
                            </Typography>
                          </TableCell>

                          <TableCell className={classes.listCell6} align="left">
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {items.hours_unavailable}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.listCell7} align="left">
                            <Typography
                              noWrap
                              className={classes.subjectName}
                              style={{
                                color: schedulerColor.main,
                                opacity: 0.8,
                              }}
                              variant="h6"
                            >
                              {items.pay_run_rule}
                            </Typography>
                          </TableCell>

                          <TableCell className={classes.listCell8} align="left">
                            <Typography
                              className={classes.subjectName}
                              variant="h6"
                            >
                              {/* {items.status === 1
                                ? "Failed"
                                : items.status === 2
                                  ? "Pass"
                                  : "Pending"
                              } */}
                              {items.avail_status === 1 ? (
                                <Typography
                                  style={{
                                    color: "#0F640D",
                                  }}
                                >
                                  Approve
                                </Typography>
                              ) : items.avail_status === 2 ? (
                                <Typography
                                  style={{
                                    color: "#8E0C0C ",
                                  }}
                                >
                                  Reject
                                </Typography>
                              ) : (
                                items.avail_status === 0 && (
                                  <Box
                                    display={"flex"}
                                    style={{ gap: "0.7rem" }}
                                  >
                                    <CheckCircleIcon
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        console.log('watchsee', items)
                                        this.props.approveRejectStatus(
                                          schUID, this.props.scheduler_lid,
                                          1
                                        );
                                      }}
                                    />
                                    <CancelIcon
                                      style={{
                                        color: "#b71c1c",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.props.approveRejectStatus(
                                          schUID, this.props.scheduler_lid,
                                          2
                                        );
                                      }}
                                    />
                                    {/* <FormControlLabel
                                      control={<Checkbox
                                        onChange={() => {
                                          if (items?.avail_status === 2
                                            ) {
                                            this.props.approveRejectStatus(items.av_id, 0)
                                           
                                          } else  {
                                            this.props.approveRejectStatus(items.av_id, 2)
                                      
                                          }
                                        }}
                                        checked={items?.avail_status === 2}
                                      />}
                                      label="Accept"
                                    />
                                    <FormControlLabel
                                      control={<Checkbox
                                        onChange={() => {
                                          if (items?.avail_status === 1) {
                                            console.log("avid new",items)
                                            this.props.approveRejectStatus(items.av_id, 0)
                                            
                                          } else {
                                            this.props.approveRejectStatus(items.av_id, 1)
                                            
                                          }
                                        }}
                                        checked={items?.avail_status === 1}
                                      />}
                                      label="Reject"
                                    /> */}
                                  </Box>
                                )
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse in={items.open}>
                              <Box
                                sx={{
                                  margin: "0px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={12} className="d-flex">
                                    <Grid item md={12}>
                                      <div
                                        style={{
                                          padding: "0px 16px 12px 16px",
                                        }}
                                      >
                                        <Card
                                          className={classes.scheduleCard}
                                          style={{
                                            width: "1000px",
                                            overflowX: "scroll",
                                          }}
                                        >
                                          <Box
                                            display={"flex"}
                                            style={{
                                              gap: "1rem",
                                              marginBottom: "0.6rem",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              variant="p"
                                              style={{ fontWeight: "700" }}
                                            >
                                              Payrun Rule :

                                              <Typography
                                                style={{ fontWeight: "500" }}
                                                variant="span"
                                              >
                                                {" "}
                                                &nbsp; {items.user_role}
                                              </Typography>
                                            </Typography>
                                            <IconButton
                                              size="small"
                                              disableRipple
                                              href="/scheduler/settings/payrollNew"
                                            >
                                              <EditOutlinedIcon
                                                style={{ color: "#000" }}
                                              />
                                            </IconButton>
                                          </Box>

                                          <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                          >
                                            {items.eventData &&
                                              items.eventData.map(
                                                (weekDay, index) => (
                                                  <div
                                                    onClick={(e) =>
                                                      this.handleClickTwo(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    key={index}
                                                  >
                                                    <Typography
                                                      style={{
                                                        marginBottom: "0.4rem",
                                                      }}
                                                      align="center"
                                                      variant="h6"
                                                    >
                                                      {weekDay.day_id === 0
                                                        ? "Sunday"
                                                        : weekDay.day_id === 1
                                                          ? "Monday"
                                                          : weekDay.day_id === 2
                                                            ? "Tuesday"
                                                            : weekDay.day_id === 3
                                                              ? "Wednesday"
                                                              : weekDay.day_id === 4
                                                                ? "Thursday"
                                                                : weekDay.day_id === 5
                                                                  ? "Friday"
                                                                  : weekDay.day_id === 6
                                                                    ? "Saturday"
                                                                    : ""}
                                                    </Typography>
                                                    <div
                                                      className={
                                                        classes.divWdth
                                                      }
                                                    >
                                                      {new Array(24)
                                                        .fill("")
                                                        .map((item, i) => {
                                                          const inbtwn =
                                                            this.rangeCalculater(
                                                              items.eventData[
                                                              index
                                                              ],
                                                              i
                                                            );
                                                          const cls =
                                                            "hour" +
                                                            " " +
                                                            `${inbtwn.inbtwnRange
                                                              ? `active ${i === 0 &&
                                                              "start_ele"
                                                              } ${i === 23 &&
                                                              "end_ele"
                                                              }`
                                                              : ""
                                                            }`;
                                                          return (
                                                            <>
                                                              <div
                                                                className={cls}
                                                                style={{
                                                                  borderTopLeftRadius:
                                                                    i === 0
                                                                      ? "10px"
                                                                      : "0",
                                                                  borderTopRightRadius:
                                                                    i === 0
                                                                      ? "10px"
                                                                      : "0",
                                                                  borderBottomLeftRadius:
                                                                    i === 23
                                                                      ? "10px"
                                                                      : "0",
                                                                  borderBottomRightRadius:
                                                                    i === 23
                                                                      ? "10px"
                                                                      : "0",
                                                                  margin: 0,
                                                                  height: 5,
                                                                  // backgroundColor: "#4d72f8"
                                                                  backgroundColor:
                                                                    inbtwn.inbtwnRange
                                                                      ? items
                                                                        .eventData[
                                                                        index
                                                                      ]
                                                                        .bg_color
                                                                      : // "#9CC3F1"
                                                                      "#FFFFFF",
                                                                }}
                                                              >
                                                                {inbtwn
                                                                  ?.startIndex
                                                                  ?.index ===
                                                                  i && (
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "#000",
                                                                        fontSize: 8,
                                                                        display:
                                                                          "grid",
                                                                        justifyContent:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {
                                                                        inbtwn
                                                                          ?.startIndex
                                                                          .value
                                                                      }
                                                                      {i <= 12
                                                                        ? " AM"
                                                                        : " PM"}
                                                                    </span>
                                                                  )}
                                                                {inbtwn
                                                                  ?.endIndex
                                                                  ?.index ===
                                                                  i && (
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "#000",
                                                                        fontSize: 8,
                                                                        display:
                                                                          "grid",
                                                                        justifyContent:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {
                                                                        inbtwn
                                                                          ?.endIndex
                                                                          .value
                                                                      }
                                                                      {i > 12
                                                                        ? " PM"
                                                                        : " AM"}
                                                                    </span>
                                                                  )}
                                                              </div>
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </Box>
                                          {console.log("avid outside", items)}
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                            mt={"1rem"}
                                          >
                                            {/* <Box display={"flex"}>
                                              <Typography
                                                style={{
                                                  fontWeight: "700",
                                                }}
                                              >
                                                Pay run Rule :&nbsp;
                                              </Typography>
                                              <Typography>
                                                Manager Template
                                              </Typography>
                                            </Box> */}

                                            <Box display={"flex"}>
                                              <Typography
                                                style={{
                                                  color: "#645E5E",
                                                }}
                                              >
                                                Availibility effective date
                                                :&nbsp;
                                              </Typography>
                                              <Typography
                                                style={{ color: "#645E5E" }}
                                              >
                                                {moment(
                                                  items.effective_date
                                                ).format("DD-MM-YYYY")}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Card>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <StickyContainer className="app">
                        <Sticky topOffset={194 - 64}>
                            {({ style }) => (
                                <div
                                    className="create-task"
                                    style={{
                                        ...style,
                                        top: 64,
                                        width: "82%"
                                    }}
                                >
                                    <Table>
                                        <TableHead style={{ backgroundColor: schedulerColor.main }}>
                                            <TableRow>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    Employee
                                                </TableCell>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    Available Hours
                                                </TableCell>
                                                <TableCell style={{ color: "white", fontWeight: "300" }}>
                                                    UnAvailable Hours
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            )}
                        </Sticky>
                        <div className="content">
                            <Paper style={{ width: "100%", backgroundColor: "red" }} className={classes.paper}>
                                sssss

                            </Paper>
                        </div>

                    </StickyContainer> */}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerSettingsLocationdata.isLoading,
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkScheduleData: state.schedulerSettingsLocationdata.WorkScheduleData,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
  locationData: state.settingsLocation.locationData,
});

const ConnectWith = connect(mapStateToProps, {
  getScheduleData,
  updateScheduleData,
})(Scheduling);
export default withStyles(styles)(ConnectWith);
