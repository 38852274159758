import {
  SET_FEEDBACK_LOCATION_LOADING,
  SET_FEEDBACK_LOCATION,
  EDIT_FEEDBACK_LOCATION,
  SET_FEEDBACK_FILTER_CAMPAIGN,
  ADD_FEEDBACK_LOCATION,
  DELETE_DEVICES,
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
  locationDropdown: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_LOCATION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_FILTER_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        locationDropdown: payload.locationDropDown,
      };
    case SET_FEEDBACK_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: payload.data,
      };
    case ADD_FEEDBACK_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: [...state.locationData, payload],
      };
    case EDIT_FEEDBACK_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: state.locationData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    case DELETE_DEVICES:
      return {
        ...state,
        isLoading: false,
        locationData: state.locationData.filter(
          (item) =>
            item.IpTvActivationCode || item.tabletActivationCode !== payload
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
