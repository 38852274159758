import {HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const rolesServices = {
    cloneRoles,
    fetchData,
    fetchUsersByRole,
    addData,
    editData,
    deleteData,
    userPermissions
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/role/mongo`, HEADER_TOKEN).then(response => response)
};

function fetchUsersByRole(_id) {
    return axios.get(`${MoNGO_DB_API_URL}/role/users/${_id}`, HEADER_TOKEN).then(response => response)
};

function userPermissions() {
    return axios.get(`${MoNGO_DB_API_URL}/role/permissions`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/role/mongo/v3/add`, { data }, HEADER_TOKEN).then(response => response)
};

function cloneRoles(data) {
    return axios.post(`${MoNGO_DB_API_URL}/role/mongo/Test/clone`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/role/mongo/v3/edit/${data._id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(_id) {
    return axios.delete(`${MoNGO_DB_API_URL}/role/${_id}`, HEADER_TOKEN).then(response => response)
};
