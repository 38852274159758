//MyCenterModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { schedulerColor, FILE_URL } from "../../../config";
const styles = (theme) => ({
  dialog: {
    borderRadius: 0,
  },
  buttonSignup: {
    boxShadow: "none",
    fontWeight: 400,
    color: "#ffffff",
    backgroundColor: "green",
    borderRadius: 0,
    border: "1px solid green",
    padding: "5px 22px",
  },
  buttonLogin: {
    boxShadow: "none",
    fontWeight: 400,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    border: "1px solid red",
    padding: "5px 22px",
  },
  DialogTitle: {
    padding: "10px 20px",
  },
  avatarText: {
    float: "left",
    textTransform: "uppercase",
    height: "45px",
    width: "45px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "#ffffff",
  },
  myTitle: {
    color: schedulerColor.main,
    fontSize: "14px",
    marginLeft: "5px",
    paddingTop: "5px",
    lineHeight: "20px",
    float: "left",
    fontWeight: 600,
  },
  closeIcon: {
    color: schedulerColor.main,
    cursor: "pointer",
    float: "right",
  },
  dialogcontent: {
    minHeight: "400px",
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  saveButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
  closeButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MyCenterModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        {this.props.displayHeader ? (
          <DialogTitle className={classes.DialogTitle}>
            {this.props.userImage ? (
              <Avatar
                src={FILE_URL + this.props.userImage}
                className={classes.avatarText}
              />
            ) : (
              <Avatar className={classes.avatarText}>
                {this.props.userName.charAt(0)}
              </Avatar>
            )}

            <Typography className={classes.myTitle} variant="h4">
              {this.props.userName}&nbsp;({this.props.roleName})
              <br />
              {/* {this.props.myItem.clock_in.getTime() > "24:00:00" ? */}
                {/* <b>
                  {moment(this.props.currentDate).format("dddd DD MMM, YYYY").add("dddd", 1)}
                </b> */}
                {/* : */}
                <b>
                  {new Date(this.props.currentDate).toDateString()}
                </b>
              {/* } */}
            </Typography>

            <Tooltip style={{ padding: "12px" }} arrow title="Add">
              <IconButton
                className={classes.closeIcon}
                size="small"
                onClick={this.props.onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
        ) : (
          ""
        )}

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>

        {this.props.displayFooter ? (
          <DialogActions style={{ padding: "12px 18px" }}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={this.props.onClose}
              className={classes.closeButton}
              style={{ backgroundColor: "black" }}
            >
              Close
            </Button>

            <Button
              style={{
                backgroundImage: this.props.bgColor
                  ? this.props.bgColor
                  : "gray",
              }}
              variant="outlined"
              size="medium"
              className={classes.saveButton}
              onClick={this.props.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyCenterModal);
