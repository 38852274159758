//TopRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const styles = (theme) => ({
  dialog: {
    margin: "0px auto 0px 0px",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "75%",
      height: "50%",
    },
  },
  DialogTitle: {
    padding: "10px",
  },
  myTitle: {
    lineHeight: "2.5rem",
    float: "left",
    color: "#ffffff",
    fontWeight: 600,
  },
  closeIcon: {
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 0",
    overflowX: "hidden",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class TopRightModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
          }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4">
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TopRightModal);
