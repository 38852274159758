import React, { Component } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    FormControl,
    Grid,
    OutlinedInput,
    RadioGroup,
    Radio,
}
    from '@material-ui/core';
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingData } from "../../../components";
import Checkbox from '@mui/material/Checkbox';
import { schedulerColor } from "../../../config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        minWidth: 800
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class ViewBreakModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isMustAknowledge_comment: false,
        }
    }

    // handleCheckboxAcknowledge = (e,item) => {
    //     console.log("eee",item)
    //     this.setState({
    //       isMustAknowledge_comment: e.target.checked
    //     })
    //   };

    render() {
        const { viewModalOpen, handleViewModalClose, previewBreakData, isLoading, handleCheckboxAcknowledge } = this.props;

        return (
            <div>
                <BootstrapDialog
                    onClose={handleViewModalClose}
                    aria-labelledby="customized-dialog-title"
                    open={viewModalOpen}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleViewModalClose}
                        style={{ background: schedulerColor.main, color: 'white' }}>
                        Previous Break Rule
                    </BootstrapDialogTitle>

                    <DialogContent dividers>
                        {isLoading === true ? <LoadingData /> :
                            previewBreakData && previewBreakData.map((item) => {
                                return (
                                    <div><Radio
                                        // indeterminate={permissionList.length > 0 && permissionList.length < row.IsCreate || row.IsEdit || row.IsAssign || row.IsDelete}
                                        name="IsFullAccess"
                                        key={item._id}
                                        // value={row.IsFullAccess}
                                        checked={item.isSelected === true ? true : false}
                                        onChange={(e) => this.props.handleCheckboxAcknowledge(e, item._id)}
                                    />
                                        <Grid style={{ display: "flex", marginBottom: 10 }}>

                                            <Typography style={{ color: "#232056", marginTop: 10, width: 90 }}>
                                                Start date
                                            </Typography>
                                            <div style={{ marginRight: 24, marginTop: 'auto' }}>
                                                {moment(item.break_start_date).format("YYYY-MM-DD")}
                                            </div>
                                            <Typography style={{ color: "#232056", marginTop: 10, width: 90 }}>
                                                End date
                                            </Typography>
                                            <div style={{ marginRight: 24, marginTop: 'auto' }}>
                                                {moment(item.break_end_date).format("YYYY-MM-DD")}
                                            </div>
                                        </Grid>

                                        <Grid style={{ display: "flex", marginBottom: 30 }}>
                                            <Typography style={{ color: "#232056", marginTop: 10 }}>
                                                Employess Get a &nbsp;
                                            </Typography>
                                            {/* <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                                <OutlinedInput
                                                    style={{ height: 20, width: 70, marginLeft: 10, marginTop: 10 }}
                                                    value={item.break_Employees_get_minutes}
                                                    placeholder="30"
                                                    name="break_Employees_get_minutes"
                                                />
                                            </FormControl> */}
                                            <Typography style={{ color: "#232056", marginTop: 10, fontWeight: 900 }}>
                                                {item.break_Employees_get_minutes}
                                            </Typography>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                min &nbsp;
                                            </Typography>
                                            {/* <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                                <OutlinedInput
                                                    style={{ height: 20, width: 70, marginLeft: 10, marginTop: 10 }}
                                                    value={item.break_Employees_get_count === 1 ? "Paid" : "Unpaid"}
                                                    placeholder="30"
                                                    name="break_Employees_get_minutes"
                                                />
                                            </FormControl> */}
                                            <Typography style={{ color: "#232056", marginTop: 10, fontWeight: 900 }}>
                                                {item.break_Employees_get_count === 1 ? "Paid" : "Unpaid"}
                                            </Typography>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                Break every &nbsp;
                                            </Typography>
                                            {/* <FormControl>
                                                <OutlinedInput
                                                    style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                                    value={item.break_Employees_get_hour}
                                                    name="break_Employees_get_hour"
                                                />
                                            </FormControl> */}
                                            <Typography style={{ color: "#232056", marginTop: 10, fontWeight: 900 }}>
                                                {item.break_Employees_get_hour}
                                            </Typography>
                                            <Typography style={{ color: "#232056", marginTop: 10, marginLeft: 10 }}>
                                                hours and it is &nbsp;
                                            </Typography>
                                            {/* <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                                <OutlinedInput
                                                    style={{ height: 20, width: 70, marginLeft: 10, marginTop: 10 }}
                                                    value={item.isbreak_hours_type === 1 ? "Mandatory" : "Optional"}
                                                    placeholder="30"
                                                    name="break_Employees_get_minutes"
                                                />
                                            </FormControl> */}
                                            <Typography style={{ color: "#232056", marginTop: 10, fontWeight: 900 }}>
                                                {item.isbreak_hours_type === 1 ? "Mandatory" : "Optional"}
                                            </Typography>
                                            <DeleteIcon onClick={() => this.props.onDeleteBreak(item._id)}
                                                style={{
                                                    margin: 'auto', color: '#b71c1c', cursor: 'pointer',
                                                    fontSize: 25
                                                }}
                                            />
                                        </Grid>

                                        <hr />

                                    </div>
                                )
                            })}

                    </DialogContent>
                    <Button
                        style={{ display: 'flex', marginLeft: '90%', backgroundColor: schedulerColor.main, color: 'white', marginBottom: '1%', marginTop: '1%', marginRight: 'auto' }}
                        variant="contained"
                        onClick={this.props.handleSubmitBreak}
                    >
                        Submit
                    </Button>
                </BootstrapDialog>
            </div>
        );
    }
}

export default ViewBreakModal;