//ExportCsv
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import { Button } from '@material-ui/core';
import { payrollSummaryServices } from './modules/services';
import { alert } from '../../../utilities';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  Download: {
    marginLeft: '3px',
    padding: '7px 30px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  }
});

class ExportDownload extends Component {
    csvLink = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
        payrollpedata:[]
    }
  }

  downloadData = () => {
    var data = {
       date:this.props.date,
       lid:this.props.lid,
       pi_id:this.props.valueSeclect?._id,
       is_work_date:this.props.dates.work_date === true ? 1 : 0,
       is_period_start:this.props.dates.period_start === true ? 1 : 0,
       is_period_end:this.props.dates.period_start === true ? 1: 0
    }
    if(this.props.valueSeclect){
       payrollSummaryServices.getDownloadFilterData(data).then((response) => {  
                var allData = []
                //var headData = ["EmployeeID","TimeCode","TotalHours", "HourRate", "Total", "WorkDate", "WorkDateEnd", "TimeIn", "TimeOut","Authorized"]
                var headData =[]
                //allData.push(headData)
                var valueDtaa = []
                response.data.HeaderData.map((dd) => {
                  console.log("dd",dd)
                  return(
                    dd.internal_name === "Employee ID" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    : dd.internal_name === "Timecode" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Approved Hours" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Hourly Rate" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Total Amount" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Work Date" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    //:dd.internal_name === "internal_name" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Authorized" ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    : dd.internal_name === "Period Start" && this.props.dates.period_start ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) 
                    :dd.internal_name === "Period End" && this.props.dates.period_start ? valueDtaa.push(dd.custom_name ? dd.custom_name : dd.internal_name) :""               
                  ) 
                })
                 allData.push(valueDtaa)

                response.data.payrollPEData?.map(item => {
                  var valueDaa= []
                  response.data.HeaderData.map((ff) => {
                    return(
                      ff.internal_name === "Employee ID" ?
                         valueDaa.push('=""' + item.EmployeeID + '""')
                      :ff.internal_name === "Timecode" ?
                          valueDaa.push(item.TimeCode)
                      : ff.internal_name === "Approved Hours" ?
                         valueDaa.push(item.TotalHours) 
                      : ff.internal_name === "Hourly Rate" ?
                      valueDaa.push(item.HourRate) 
                      :ff.internal_name === "Total Amount" ?
                      valueDaa.push(item.Total) 
                      : ff.internal_name === "Work Date" ?
                      valueDaa.push('=""' + item.WorkDate + '""') 
                      : ff.internal_name === "Authorized" ?
                      valueDaa.push(item.Authorized) 

                      : this.props.dates.period_start && ff.internal_name === "Period Start" ?
                      valueDaa.push( item.period_start) 
                      : this.props.dates.period_start &&  ff.internal_name === "Period End" ?
                      valueDaa.push( item.period_end) :""
                    )
                  })
                  allData.push(valueDaa)
                // var rowData = []
                // rowData.push(item.EmployeeID)
                // rowData.push(item.TimeCode)
                // rowData.push(item.TotalHours)
                // rowData.push(item.HourRate)
                // rowData.push(item.Total)
                // rowData.push(item.WorkDate)
                // rowData.push(item.WorkDateEnd)
                // rowData.push(item.TimeIn)
                // rowData.push(item.TimeOut)
                // rowData.push(item.Authorized)
                // allData.push(rowData)
                });
               this.setState({
                   //usersData:response.data.payrollPEData,
                     payrollpedata:allData
                   //headerData:response.data.HeaderData
               },() => {
                this.csvLink.current.link.click()
                this.props.closeDownloadModal()
               })
               
              
           })
    }else{
       alert.error("Please select payment method")
    }
}

  render() {
    const { classes } = this.props;
    
    return (
        <>
                <Button
           onClick={this.downloadData}
          className={classes.Download}
        >
          {/* PE */}
          Download
        </Button>
        <CSVLink
            filename={this.props.valueSeclect ? this.props.valueSeclect?.name : ""}
            data={this.state.payrollpedata ? this.state.payrollpedata : ""}
            ref={this.csvLink}
            target="_blank"
            style={{textDecoration: "none"}}
        >
      </CSVLink>
        </>
    );
  }
}

export default withStyles(styles)(ExportDownload);
