import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, TableMyPagination, TableRecordNotFound, LoadingData, MySearchBar, MyLeftTradeModal, MyLeftModal } from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { connect } from "react-redux";
import {
    Add as AddIcon,
    Delete as DeleteIcon
} from '@material-ui/icons';
import moment from "moment";
import { utcToLocal, validationIsEmpty, alert } from '../../../utilities';
import { FILE_URL, schedulerColor } from '../../../config';
import { MyAvailabilityService } from "./modules/services"
import AddMyAvailability from "./AddMyAvailability";


import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Tooltip,
    Paper,
    Grid,
    Avatar,
    Button
}
    from '@material-ui/core';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CollapsedMyAvailability from "./CollapsedMyAvailability";
import AddEditMYAV from "./AddEditMYAV";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    TableCell: {
        padding: '15px'
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontSize: '16px',
        lineHeight: '27px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#1E2134'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    userName: {
        maxWidth: "250px",
        // lineHeight: 3,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        lineHeight: '22.88px',
        fontWeight: 500,
        color: '#1E2134',
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "35px",
        width: "35px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    button: {
        color: '#ffffff',
        padding: '8px 22px',
        width: "150px",
        fontWeight: '400'
    },
});

export function fetchNewTeamAvailabilityData() {
    window.MyAvailability.fetchData();
}

class MyAvailability extends Component {
    constructor(props) {
        super(props)
        window.TradeShift = this;
        this.state = {
            myItem: {},
            order: 'asc',
            orderBy: "id",
            timeArray: [],
            isChecked: false,

            errors: {},
            errorsHelper: {},
            // getApiResponse:{my_avail:[
            //     {
            //         avail_status:0,
            //         avaliable:"-61px",
            //         avaliblity:"",
            //         effective_date:"",
            //         availabledates:[
            //             {
            //                 date:"",
            //                 eventData:[
            //                         {
            //                             bg_color:"#9CC3F1",
            //                             day_id:0,
            //                             id: "64019df8b1a5acea796b7e61",
            //                             is_prefered:1,
            //                             is_unavailable:1,
            //                             is_wholeday:0,
            //                             lid:"630347eeb1d4e83b2f2c096b",
            //                             loc_endtime:"00:00:00",
            //                             loc_starttime:"00:00:00",
            //                             prefered_end_time:"09:46:46",
            //                             prefered_start_time:"01:04:04",
            //                             unavailable_end_time:"21:45:45",
            //                             unavailable_start_time:"13:06:09"

            //                         },
            //                         {
            //                             bg_color:"#9CC3F1",
            //                             day_id:0,
            //                             id: "64019df8b1a5acea796b7e61",
            //                             is_prefered:1,
            //                             is_unavailable:1,
            //                             is_wholeday:0,
            //                             lid:"630347eeb1d4e83b2f2c096b",
            //                             loc_endtime:"00:00:00",
            //                             loc_starttime:"00:00:00",
            //                             prefered_end_time:"09:46:46",
            //                             prefered_start_time:"01:04:04",
            //                             unavailable_end_time:"21:45:45",
            //                             unavailable_start_time:"13:06:09"

            //                         }
            //                     ],
            //             }
            //         ],
            //         hours_available:99,
            //         hours_unavailable:0,
            //         is_draft:0,
            //         open:false,
            //         pay_run_rule:"",
            //         preferrd:38,
            //         show_hide_location_schedular:1,
            //         status:1,
            //         unavailable:-61,
            //         user_name:"Harshad Makwana1",
            //         user_role:"Company owner"
            //     },
            //     {
            //         avail_status:0,
            //         avaliable:"-61px",
            //         avaliblity:"",
            //         effective_date:"",
            //         eventData:[
            //             {
            //                 bg_color:"#9CC3F1",
            //                 day_id:0,
            //                 id: "64019df8b1a5acea796b7e61",
            //                 is_prefered:1,
            //                 is_unavailable:1,
            //                 is_wholeday:0,
            //                 lid:"630347eeb1d4e83b2f2c096b",
            //                 loc_endtime:"00:00:00",
            //                 loc_starttime:"00:00:00",
            //                 prefered_end_time:"09:46:46",
            //                 prefered_start_time:"01:04:04",
            //                 unavailable_end_time:"21:45:45",
            //                 unavailable_start_time:"13:06:09"

            //             },
            //             {
            //                 bg_color:"#9CC3F1",
            //                 day_id:0,
            //                 id: "64019df8b1a5acea796b7e61",
            //                 is_prefered:1,
            //                 is_unavailable:1,
            //                 is_wholeday:0,
            //                 lid:"630347eeb1d4e83b2f2c096b",
            //                 loc_endtime:"00:00:00",
            //                 loc_starttime:"00:00:00",
            //                 prefered_end_time:"09:46:46",
            //                 prefered_start_time:"01:04:04",
            //                 unavailable_end_time:"21:45:45",
            //                 unavailable_start_time:"13:06:09"

            //             },
            //             {
            //                 bg_color:"#9CC3F1",
            //                 day_id:0,
            //                 id: "64019df8b1a5acea796b7e61",
            //                 is_prefered:1,
            //                 is_unavailable:1,
            //                 is_wholeday:0,
            //                 lid:"630347eeb1d4e83b2f2c096b",
            //                 loc_endtime:"00:00:00",
            //                 loc_starttime:"00:00:00",
            //                 prefered_end_time:"09:46:46",
            //                 prefered_start_time:"01:04:04",
            //                 unavailable_end_time:"21:45:45",
            //                 unavailable_start_time:"13:06:09"

            //             }
            //         ],
            //         hours_available:99,
            //         hours_unavailable:0,
            //         is_draft:0,
            //         open:false,
            //         pay_run_rule:"",
            //         preferrd:38,
            //         show_hide_location_schedular:1,
            //         status:1,
            //         unavailable:-61,
            //         user_name:"Vruti Monani",
            //         user_role:"Company owner"
            //     },
            //     {
            //         avail_status:0,
            //         avaliable:"-61px",
            //         avaliblity:"",
            //         effective_date:"",
            //         eventData:[],
            //         hours_available:99,
            //         hours_unavailable:0,
            //         is_draft:0,
            //         open:false,
            //         pay_run_rule:"",
            //         preferrd:38,
            //         show_hide_location_schedular:1,
            //         status:1,
            //         unavailable:-61,
            //         user_name:"Rashi Jain",
            //         user_role:"Company owner"
            //     }
            // ]},
        }
    }

    componentDidMount = () => {
        this.fetchData();
        var timeArray = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                var id = moment({ hour, minute }).format();
                id = new Date(id)
                id = id.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                var name = moment(id).format("hh:mm A");
                timeArray.push({ id, name });
            }
        }
        this.setState({ timeArray })
    };

    fetchData = () => {
        // var data = {
        //   lid: this.props.scheduler_lid,
        // };
        var date = moment(this.state.effective_date).format("YYYY-MM-DD");
        this.setState({ isLoading: true });
        MyAvailabilityService.fetchMyAvailabilityData().then((response) => {
            if (response.data.success === 2) {
            } else {
                this.setState({
                    getApiResponse: response.data,
                    isLoading: false
                });
            }
        });
    };


    handlePopupModal = (item, index, loc) => {
        console.log('nothman', loc, index)

        this.setState({
            openModal: true,
            myItem: item,
            currentTitleLoc: loc
        });
    }

    handlePopupModalClose = () => {
        this.setState({
            openModal: false,
        });
    }

    handleSubmit = async () => {
        var myItem = this.state.myItem;
        var valid_start_time = await validationIsEmpty(myItem.start_time);
        var valid_end_time = await validationIsEmpty(myItem.end_time);
        if (myItem.is_wholeday === false) {
            if (valid_start_time) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        start_time: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        start_time: "Please select start time"
                    }
                }))
            }

            if (valid_end_time) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        end_time: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        end_time: "Please select end time"
                    }
                }))
            }

            if (valid_end_time || valid_start_time) {
                return;
            }
        }
        this.setState({ addEditModal: false })
        // if (this.state.isEdit) {
        //     this.editAvailability()
        // } else {
        //     this.addAvailability()
        // }
        const curstTIME = moment(myItem.start_time,).format('HH:mm:ss');
        const curendTIME = moment(myItem.end_time,).format('HH:mm:ss');
        const prefferedStart = moment(myItem.prefered_start_time).utc().format('HH:mm:ss');
        const prefferedEnd = moment(myItem.prefered_end_time).utc().format('HH:mm:ss');
        const unavStart = moment(myItem.unavailable_start_time).utc().format('HH:mm:ss');
        const unavEnd = moment(myItem.unavailable_end_time).utc().format('HH:mm:ss');
        console.log('spiker', myItem,)
        console.log('spiker222', curstTIME, curendTIME)
        console.log('spiker333', myItem.is_prefered)
        
        const data = {
            av_id: myItem.av_id,
            lid: myItem.lid,
            is_prefered: myItem.is_prefered,
            is_wholeday: myItem.is_wholeday,
            is_unavailable: myItem.is_unavailable,
            day_id: myItem.day_id,
            prefered_start_time: myItem.is_prefered === 1 ? curstTIME : prefferedStart,
            prefered_end_time: myItem.is_prefered === 1 ? curendTIME : prefferedEnd,
            unavailable_start_time: myItem.is_unavailable === 1 ? curstTIME : unavStart,
            unavailable_end_time: myItem.is_unavailable === 1 ? curendTIME : unavEnd
        }
        console.log('spiker444', data)
        
        MyAvailabilityService.submitAvailability(data).then((response) => {
            if (response.data.success === 2) {
                alert.error(response.data.message);
            } else {

                alert.success(response.data.message);
            }

        })
    };

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }))
    }

    handleChangeWholeDay = () => {
        var is_wholeday = this.state.myItem.is_wholeday
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                is_wholeday: is_wholeday ? 0 : 1
            }
        }))
    }

    handleChangePrefered = (prefUnv) => {
        {
            prefUnv === 1 && this.setState(prevState => ({
                myItem: {
                    ...prevState.myItem,
                    is_prefered: 1,
                    is_unavailable: 0,
                }
            }))
        }
        {
            prefUnv === 0 && this.setState(prevState => ({
                myItem: {
                    ...prevState.myItem,
                    is_prefered: 0,
                    is_unavailable: 1,
                }
            }))
        }
    }

    handleChangeStartTimePrefered = (newValue) => {
        var startTime = newValue ? newValue.id : ""
        var startTimeSelected = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                prefered_start_time: moment(startTime).format("HH:mm:ss")
            },
            startTimeSelected
       
        }))
    }

    handleChangeEndTimePrefered  = (newValue) => {
        var endTime = newValue ? newValue.id : ""
        var endTimeSelected = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                prefered_end_time:  moment(endTime).format("HH:mm:ss")
            },
            endTimeSelected
            
        }))
    }

    handleChangeStartTimeUnavailable = (newValue) => {
        var startTime = newValue ? newValue.id : ""
        var startTimeSelectedUnavailable = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                unavailable_start_time: moment(startTime).format("HH:mm:ss")
            },
            startTimeSelectedUnavailable
       
        }))
    }

    handleChangeEndTimeUnavailable  = (newValue) => {
        var endTime = newValue ? newValue.id : ""
        var endTimeSelectedUnavailable = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                unavailable_end_time:  moment(endTime).format("HH:mm:ss")
            },
            endTimeSelectedUnavailable
            
        }))
    }


    handleClickToggle = (open, index) => {
        const newData = this.state.getApiResponse?.my_avail.map(
            (items, newIndex) => {
                items.data && items.data.map((gg) => {
                    const ddd = gg;
                    ddd["openMYInside"] = false
                    return ddd
                })
                const cloneItem = items;
                if (index === newIndex) {
                    cloneItem["open"] = open ? false : true;
                } else {
                    cloneItem["open"] = false;
                }
                return cloneItem;
            }
        );
        this.setState((prevState) => ({
            getApiResponse: {
                ...prevState.getApiResponse,
                my_avail: newData,
            },
        }));
    };

    handleClickToggleMYInside = (open, index, id, av_id) => {
        var newData = []
        this.state.getApiResponse?.my_avail.map((bb) => {
            if (bb.data) {
                bb.data.map((ff) => {
                    newData.push(ff)
                })
            }
        })
        var tempDate = newData
        tempDate.map((inner) => {
            if (inner.av_id === av_id) {
                inner["openMYInside"] = open ? false : true
            }
            else {
                inner["openMYInside"] = false
            }
        })
        newData["data"] = tempDate
        var myAvaData = this.state.getApiResponse?.my_avail.map((myITems) =>
            myITems.lid === newData.lid ? { ...newData } : myITems
        );

        this.setState((prevState) => ({
            getApiResponse: {
                ...prevState.getApiResponse,
                my_avail: myAvaData,
            },
        }));
    };

    handleEffectiveDate = (date, e) => {
        this.setState({
            effective_date: date
        })
    }

    handleCheckBox = (e) => {
        this.setState({
            isChecked: e.target.checked ? 1 : 0
        })
    }

    newAvailability = () => {
        var effective_date = moment(this.state.effective_date).format("YYYY-MM-DD")
        var data = {
            effective_date: effective_date,
            user_notes: "",
            status: 3,
            lid: this.state.userValue.lid
        }

        MyAvailabilityService.addMyAvailability(data).then(response => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.newModalClose()
                this.fetchData()
            }
        });
    }

    newModalOpen = (userValue) => {

        this.setState({
            newModal: true,
            isChecked: false,
            userValue: userValue,
            effective_date: moment().format("YYYY-MM-DD"),
        })
    }

    newModalClose = () => {
        this.setState({
            newModal: false,
            userValue: '',
        });
    }

    render() {
        const { classes, pageNo, permissionsData, rowsPerPage } = this.props;
        console.log("jdkjdk",this.state.myItem)
        return (
            <Grid
                container
                spacing={4}
                className={classes.root}
            >
                <CollapsedMyAvailability
                    handleClickToggle={this.handleClickToggle}
                    handleClickToggleMYInside={this.handleClickToggleMYInside}
                    getApiResponse={this.state.getApiResponse}
                    newModalOpen={this.newModalOpen}
                    isLoading={this.state.isLoading}
                    handlePopupModal={this.handlePopupModal}
                />

                <AddMyAvailability
                    isChecked={this.state.isChecked}
                    handleEffectiveDate={this.handleEffectiveDate}
                    effective_date={this.state.effective_date}
                    handleCheckBox={this.handleCheckBox}
                    dropdownData={this.state.dropdownData}
                    newAvailability={this.newAvailability}
                    newModal={this.state.newModal}
                    newModalClose={this.newModalClose}
                />

                <MyLeftModal
                    bgColor={schedulerColor.main}
                    // userName={this.props.ovUserName}
                    // userImage={this.props.ovUserImage}
                    title={this.state.currentTitleLoc}
                    open={this.state.openModal}
                    onClose={this.handlePopupModalClose}
                    handleSubmit={this.handleSubmit}
                >
                    <AddEditMYAV
                        timeArray={this.state.timeArray}
                        errors={this.state.errors}
                        errorsHelper={this.state.errorsHelper}
                        startTimeSelected={this.state.startTimeSelected}
                        endTimeSelected={this.state.endTimeSelected}
                        startTimeSelectedUnavailable={this.state.startTimeSelectedUnavailable}
                        endTimeSelectedUnavailable={this.state.endTimeSelectedUnavailable}
                        myItem={this.state.myItem}
                        // locationData={locationData}
                        handleChangePrefered={this.handleChangePrefered}
                        handleChangeWholeDay={this.handleChangeWholeDay}
                        handleChange={this.handleChange}
                        handleFocus={this.handleFocus}
                        handleChangeStartTimePrefered={this.handleChangeStartTimePrefered}
                        handleChangeEndTimePrefered={this.handleChangeEndTimePrefered}
                        handleChangeStartTimeUnavailable={this.handleChangeStartTimeUnavailable}
                        handleChangeEndTimeUnavailable={this.handleChangeEndTimeUnavailable}
                    />
                </MyLeftModal>

            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerTradeShift.isLoading,
    mytradeData: state.schedulerTradeShift.mytradeData
});

const ConnectWith = connect(mapStateToProps, null)(MyAvailability);
export default withStyles(styles)(ConnectWith);
