import {
  SET_GRADING_DATA,
  SET_GRADING_LOADING,
  SET_GRADING_DATA_PAYLOAD,
  SET_GRADING_DATA_PAYLOAD1,
  SET_LESSON_DATA,
  SET_STATUS_DATA
} from "./constants";

const initialState = {
  isLoading: true,
  UserData: [],
  GradingPayload: [],
  pageNumber: 1,
  nPerPage: 10,
  locationLength:"",
  LessonPayload: [],
  LessonDetail: {},
  LessonArray: [],
  statusPayload: [],
  pNumber: 1,
  noPerPage: 10,
  isLessonquiz:1,
  isvideo_quiz:1,
  practical_quiz:1,
  manager_quiz:1,
  pageLength:"",
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GRADING_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_GRADING_DATA:
      return {
        ...state,
        isLoading: false,
        UserData: payload.UserData,
        locationLength: payload.locationLength
      };
      case SET_LESSON_DATA:
        return {
          ...state,
          isLoading: false,
          LessonDetail: payload.LessonDetail,
          LessonArray: payload.LessonDetail.lesson_slides

        };
      case SET_STATUS_DATA:
        return {
          ...state,
          isLoading: false,
          statusPayload: payload
        }; 
      case SET_GRADING_DATA_PAYLOAD:
        return {
          ...state,
          isLoading: false,
          GradingPayload: payload,
        };
        case SET_GRADING_DATA_PAYLOAD1:
          return {
            ...state,
            isLoading: false,
            LessonPayload: payload.assigned_lesson,
          };
    //     case SET_LESSON_CATEGORY:
    //       return {
    //         ...state,
    //         isLoading: false,
    //         isLessonQuiz: payload,
    //       };
    //       case SET_LESSON_CATEGORYDATA:
    //       return {
    //         ...state,
    //         isLoading: false,
    //         categoryData: payload
    //       };
    // case ADD_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: [...state.categoryData, payload]
    //   }
    // case EDIT_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: state.categoryData.map((item) => item._id === payload._id ? { ...payload } : item)
    //   }
    // case DELETE_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: state.categoryData.filter((item) => item._id !== payload)
    //   }
    default:
      return state;
  }
};

export default Reducer;