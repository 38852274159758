import {
  SET_HELPDESK_LOADING,
  SET_HELPDESK,
  ADD_HELPDESK,
  EDIT_HELPDESK,
  DELETE_HELPDESK,
  SET_VIEW_LESSONS,
  TOGGLE_ASSIGN_ROLES,
  MANAGE_DEVICE_COUNT,
  TOGGLE_HELPDESK_ASSIGN_LESSONS,
  TOGGLE_HELPDESK_ASSIGN_DEVICE,
  ADD_SLIDE,
  DELETE_SLIDE,
  FETCH_DEVICE,
  FETCH_BINDATA,
  BINDATA_DELETE,
  BINDATA,
  SET_HELPDESK_DEVICE_LOADING
} from "./constants";

const initialState = {
  isLoading: true,
  deviceLoading:true,
  helpdeskData: [],
  deviceData: [],
  locationAllData:[],
  allLessonData: [],
  SlideId:{},
  devicesData:[],
  getBinData:[],
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HELPDESK_LOADING:
      return {
        ...state,
        isLoading: payload
      };
      case SET_HELPDESK_DEVICE_LOADING:
        return {
          ...state,
          deviceLoading: payload
        };
    case TOGGLE_HELPDESK_ASSIGN_LESSONS:
      let existLessonH = state.helpdeskData.find(item => payload.desk_id === item._id)
      existLessonH.helpdesk_user_count = payload.isSelected ? existLessonH.helpdesk_user_count + 1 : existLessonH.helpdesk_user_count - 1
      var myLessons = existLessonH.helpdesk_user_list ? existLessonH.helpdesk_user_list.split(',').map(Number) : []
      if (payload.isSelected) {
        myLessons.push(payload.desk_id)
      } else {
        myLessons.splice(myLessons.indexOf(payload.desk_id), 1);
      }
      existLessonH.helpdesk_user_list = myLessons.join()
      return {
        ...state,
        helpdeskData: state.helpdeskData.map((item) => item._id === existLessonH._id ? { ...existLessonH } : item)
      }

      case TOGGLE_HELPDESK_ASSIGN_DEVICE:
        let existDevice = state.helpdeskData.find(item => payload.desk_id === item._id)
        existDevice.helpdesk_user_count = payload.isSelected ? existDevice.helpdesk_user_count + 1 : existDevice.helpdesk_user_count - 1
        var myDevice = existDevice.helpdesk_user_device ? existDevice.helpdesk_user_device.split(',').map(Number) : []
        if (payload.isSelected) {
          myDevice.push(payload.desk_id)
        } else {
          myDevice.splice(myDevice.indexOf(payload.desk_id), 1);
        }
        existDevice.helpdesk_user_device = myDevice.join()
        return {
          ...state,
          deviceData: state.deviceData.map((item) => item._id === existDevice._id ? { ...existDevice } : item)
        }

      // case TOGGLE_ASSIGN_ROLES:
      //   let existroles = state.helpdeskData.find(item => payload.desk_id === item._id)
      //   existroles.helpdesk_role_Count = payload.isSelected ? existroles.helpdesk_role_Count + 1 : existroles.helpdesk_role_Count - 1
      //   var myRoles = existroles.helpdesk_role_list ? existroles.helpdesk_role_list.split(',').map(Number) : []
      //   if (payload.isSelected) {
      //     myRoles.push(payload.role_id)
      //   } else {
      //     myRoles.splice(myRoles.indexOf(payload.role_id), 1);
      //   }
      //   existroles.helpdesk_role_list = myRoles.join()
      //   return {
      //     ...state,
      //     helpdeskData: state.helpdeskData.map((item) => item._id === existroles._id ? { ...existroles } : item)
      //   }
    case MANAGE_DEVICE_COUNT:
      // state.helpdeskData.map((item) =>{
      //   if(item._id === payload._id){
      //   }else{
      //     console.log("dsadsadasd else " ,item._id , payload._id )
      //   }
      // } 
      //item._id.toString() === existHelpDesk._id ? { ...existHelpDesk } : item)

      let existHelpDesk = state.helpdeskData.find(item => payload.desk_id.toString() === item._id.toString())
      existHelpDesk.helpdesk_device_count = payload.count ? existHelpDesk.helpdesk_device_count + 1 : existHelpDesk.helpdesk_device_count - 1

      return {
        ...state,
        helpdeskData: state.helpdeskData.map((item) => item._id.toString() === existHelpDesk._id.toString() ? { ...existHelpDesk } : item)
      }
    case SET_HELPDESK:
      return {
        ...state,
        isLoading: false,
        helpdeskData: payload.deviceData,
      };
      case FETCH_BINDATA:
        return {
          ...state,
          isLoading: false,
          getBinData: payload.HelpdeskData,
        };
    case SET_VIEW_LESSONS:
      return {
        ...state,
        isLoading: false,
        allLessonData: payload.allLessonData
      };
      case FETCH_DEVICE:
        return {
          ...state,
          deviceLoading: false,
          devicesData: payload.deviceData,
          locationAllData:payload.locationData
        };
    case ADD_HELPDESK:
      var newHelpdesk = payload
      newHelpdesk.helpdesk_device_count = 0
      return {
        ...state,
        isLoading: false,
        helpdeskData: [...state.helpdeskData, newHelpdesk]
      }
    case EDIT_HELPDESK:
      return {
        ...state,
        isLoading: false,
        helpdeskData: state.helpdeskData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_HELPDESK:
      return {
        ...state,
        isLoading: false,
        helpdeskData: state.helpdeskData.filter((item) => item._id !== payload)
      }
      case BINDATA_DELETE:
        return {
          ...state,
          isLoading: false,
          getBinData: state.getBinData.filter((item) => item._id !== payload)
        }
      case ADD_SLIDE:
        return {
          ...state,
          isLoading: false,
          SlideId: payload,
        };
        // case DELETE_SLIDE:
        //   return {
        //     ...state,
        //     isLoading: false,
        //     SlideId:  payload
        //   }
    default:
      return state;
  }
};

export default Reducer;