import { configOptions } from "final-form";
import moment from "moment";
import {
  SET_MYCORRECTIONREQ_LOADING,
  SET_MYCORRECTIONREQ,
  ADD_CORRECTIONREQ,
} from "./constants";

const initialState = {
  isLoading: true,
  myCorrectionReq: [],
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MYCORRECTIONREQ_LOADING:
      return {
        ...state,
        isLoading: payload
      };
      case SET_MYCORRECTIONREQ:
        return {
          ...state,
          isLoading: false,
          myCorrectionReq: payload
        };
    default:
      return state;
  }
};

export default Reducer;