import {
  SET_SC_DASHBOARD_LOADING,
  SET_SC_DASHBOARD_DETAILS_LOADING,
  SET_SC_DASHBOARD,
  SET_USER_DETAILS,
  SET_NO_SHOW,
  SET_EARLY_CLOCKOUT,
  SET_LATE_CLOCKIN,
  SET_MISSED_BREAKS,
  SET_UNSCHEDULED,
  SET_AUTO_CLOCKOUT,
  SET_NO_SHOW_MORE_DATA,
  SET_TIME_CARDS,
  SET_SC_DASHBOARD_ROLE,
  SET_SC_DASHBOARD_USER
} from "./constants";

const initialState = {
  isLoading: true,
  isDetailLoading: true,
  dashboardData: [],
  dashboardRoleData:[],
  dashboardUserData:[],
  userDetails: [],
  noShowDetails: [],
  timeCardsDetails: [],
  autoClockoutDetails: [],
  earlyClockoutDetails: [],
  lateClockoutDetails: [],
  missedBreakDetails: [],
  unscheduledDetails: [],
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SC_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC_DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        isDetailLoading: payload
      };
    case SET_SC_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        dashboardData: payload.locationData,
        custom_startDate:payload.custom_startDate,
        custom_endDate:payload.custom_endDate,
        current_startDate: payload.current_startDate,
        current_endDate: payload.current_endDate,
        previous_startDate: payload.previous_startDate,
        previous_endDate: payload.previous_endDate,
        yesterdayDate: payload.yesterdayDate,
        month_sartDate: payload.month_sartDate,
        month_endDate: payload.month_endDate,
        lastMonth_startDate: payload.lastMonth_startDate,
        lastMonth_endDate: payload.lastMonth_endDate,
        week_startDate: payload.week_startDate,
        week_endDate: payload.week_endtDate,
        lastWeek_startDate: payload.lastWeek_startDate,
        lastWeek_endDate: payload.lastWeek_endtDate,
      };
      case SET_SC_DASHBOARD_ROLE :
        return {
          ...state,
          isLoading:false,
          dashboardRoleData:payload.Role_data
        }

    case SET_SC_DASHBOARD_USER:
          return {
            ...state,
            isLoading:false,
            dashboardUserData:payload.user_Data
          }
    case SET_USER_DETAILS:
      return {
        ...state,
        isDetailLoading: false,
        userDetails: payload
      }
    case SET_NO_SHOW:
      return {
        ...state,
        isDetailLoading: false,
        noShowDetails: payload
      }
    case SET_NO_SHOW_MORE_DATA : 
    return{
      ...state,
      noShowUserDetails:payload.userData,
      isDetailLoading: false,
      noShowuserName:payload.user_name
    }
    case SET_TIME_CARDS:
      return {
        ...state,
        isDetailLoading: false,
        timeCardsDetails: payload
      }
    case SET_EARLY_CLOCKOUT:
      return {
        ...state,
        isDetailLoading: false,
        earlyClockoutDetails: payload
      }
    case SET_LATE_CLOCKIN:
      return {
        ...state,
        isDetailLoading: false,
        lateClockinDetails: payload
      }
    case SET_MISSED_BREAKS:
      return {
        ...state,
        isDetailLoading: false,
        missedBreakDetails: payload.detailData
      }
    case SET_UNSCHEDULED:
      return {
        ...state,
        isDetailLoading: false,
        unscheduledDetails: payload.detailData
      }
    case SET_AUTO_CLOCKOUT:
      return {
        ...state,
        isDetailLoading: false,
        autoClockoutDetails: payload
      }
    default:
      return state;
  }
};

export default Reducer;