export const SET_SC_DASHBOARD_LOADING = 'SET_SC_DASHBOARD_LOADING'
export const SET_SC_DASHBOARD_DETAILS_LOADING = 'SET_SC_DASHBOARD_DETAILS_LOADING'
export const SET_SC_DASHBOARD = 'SET_SC_DASHBOARD'
export const SET_SC_DASHBOARD_ROLE = 'SET_SC_DASHBOARD_ROLE'
export const SET_SC_DASHBOARD_USER = 'SET_SC_DASHBOARD_USER'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_NO_SHOW = 'SET_NO_SHOW'
export const SET_EARLY_CLOCKOUT = 'SET_EARLY_CLOCKOUT'
export const SET_LATE_CLOCKIN = 'SET_LATE_CLOCKIN'
export const SET_MISSED_BREAKS = 'SET_MISSED_BREAKS'
export const SET_UNSCHEDULED = 'SET_UNSCHEDULED'
export const SET_AUTO_CLOCKOUT= 'SET_AUTO_CLOCKOUT'
export const SET_TIME_CARDS= 'SET_TIME_CARDS'
export const SET_NO_SHOW_MORE_DATA= 'SET_NO_SHOW_MORE_DATA'



