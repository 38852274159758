import {
    ADD_ROLES_DATA, FETCH_USERS, ADD_HEIRARACHY_CHART, FETCH_HEIRARACHY_CHART,
    EDIT_HEIRARACHY_CHART, DELETE_HEIRARACHY_CHART, FETCH_ROLE_USERS
} from './constant';

const initialState = {
    addRolesData: {
        "name": "OPERATIONS",
        "color": "#7a86e1",
        "id": "1",
        "mainRole": [
        ],
        "organizationChildRelationship": [
            {
                "name": "DTL",
                "color": "#e6c1c1",
                "id": "1",
                "account": [
                    {
                        "name": "Marukh",
                        "title": "Manager",
                        "id": "1",
                        "product": [
                            {
                                "id": "1",
                                "name": "Samdisha",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "2",
                                "name": "Nadeem",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "3",
                                "name": "Jasmine",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "4",
                                "name": "Neha",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "5",
                                "name": "sahil",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "6",
                                "name": "Anisha",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "7",
                                "name": "Pallavi",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "8",
                                "name": "Vruti",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            }
                        ]
                    },
                    {
                        "name": "Aleezay",
                        "title": "Manager",
                        "id": "2",
                        "product": [
                            {
                                "id": "1",
                                "name": "Harshit",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "2",
                                "name": "Navdeep",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "3",
                                "name": "Rushita",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "4",
                                "name": "Kartik",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "5",
                                "name": "Varun",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "6",
                                "name": "Meet",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "7",
                                "name": "ArshDeep",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Assistant",
                "color": "#e6c1c1",
                "id": "2",
                "account": [
                    {
                        "name": "Sohaib",
                        "title": "Manager",
                        "id": "1",
                        "product": []
                    },
                    {
                        "name": "Ahmad",
                        "title": "Manager",
                        "id": "2",
                        "product": []
                    }
                ]
            },
            {
                "name": "HR Assistant",
                "color": "#e6c1c1",
                "id": "3",
                "account": []
            },
            {
                "name": "Sur Assistant",
                "color": "#e6c1c1",
                "id": "4",
                "account": [
                    {
                        "name": "",
                        "title": "",
                        "id": "1",
                        "product": [
                            {
                                "id": "1",
                                "name": "Najma",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            },
                            {
                                "id": "2",
                                "name": "500 Plans",
                                "mobile_no": "647-674-8723",
                                "title": "Team Members",
                                "date_of_birth": "",
                                "address": "",
                                "location": ""
                            }
                        ]
                    }
                ]
            }
        ]
    },
    isLoading: true,
    heirarachyData: [],
    usersData: [],
    RoleData: []
};

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_ROLES_DATA:
            return {
                ...state,
                addRolesData: payload
            };
        case FETCH_HEIRARACHY_CHART:
            return {
                ...state,
                isLoading: false,
                heirarachyData: payload.HirarchyData
            };
        case ADD_HEIRARACHY_CHART:
            return {
                ...state,
                isLoading: false,
                heirarachyData: [...state.heirarachyData, payload]
            }
        case FETCH_USERS:
            return {
                ...state,
                isLoading: false,
                usersData: payload.usersData,
            };
        case FETCH_ROLE_USERS:
            return {
                ...state,
                isLoading: false,
                RoleData: payload.rolesData,
            };
        case EDIT_HEIRARACHY_CHART:
            return {
                ...state,
                isLoading: false,
                heirarachyData: state.heirarachyData.map((item) => item._id === payload.HirarchyData && payload.HirarchyData.map(item => item._id) ? { ...payload } : item)
            }
        case DELETE_HEIRARACHY_CHART:
            return {
                ...state,
                isLoading: false,
                heirarachyData: state.heirarachyData.map((item) => item._id === payload.HirarchyData && payload.HirarchyData.map(item => item._id) ? { ...payload } : item)
            }
        default:
            return state;
    }
};

export default Reducer;