import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const myCourseServices = {
    fetchData,
    getLessonByCourses,
    getLessonDetailsById,
    questionCompleted,
    lessonCompleted,
    getQuestionsByLessonId,
    updateSlideCompelete,
    getMyLessons
}

// function fetchData() {
//     return axios.get(`${MoNGO_DB_API_URL}/elearning/mycourse`, HEADER_TOKEN).then(response => response)
// };
function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2`, HEADER_TOKEN).then(response => response)
};

// function getLessonByCourses(id) {
//     return axios.get(`${MoNGO_DB_API_URL}/elearning/mycourse/${id}`, HEADER_TOKEN).then(response => response)
// };

function getLessonByCourses(id) {
    return axios.get(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/${id}`, HEADER_TOKEN).then(response => response)
};

function getMyLessons(data) {
    return axios.post(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lessons/all`, {
        ecid: data
    }, HEADER_TOKEN).then(response => response)
};

function updateSlideCompelete(data) {
    return axios.put(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/slide/complete`, { data }, HEADER_TOKEN).then(response => response)
};

function getLessonDetailsById(data) {
    return axios.post(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lesson_details`, { data }, HEADER_TOKEN).then(response => response)
};

function getQuestionsByLessonId(slide_id) {
    return axios.get(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/slide/${slide_id}`, HEADER_TOKEN).then(response => response)
};

function questionCompleted(data) {
    return axios.put(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/question/complete`, { data }, HEADER_TOKEN).then(response => response)
};

function lessonCompleted(data) {
    return axios.put(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lesson/complete`, { data }, HEADER_TOKEN).then(response => response)
};