//ExportCsv
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import { Button } from '@material-ui/core';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  Download: {
    padding: '7px 30px',
    marginLeft: '3px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  }
});

class ExportCsv extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { classes } = this.props;
    // var headData = ["First Name", "Last Name", "Payroll ID", "Payroll Rule", "Rate", "Scheduled", "Actual Hours", "Unpaid Breaks", "Paid & Sch Hours", "Total Paid", "Estimated Wages"]


    var allData = []
    var payrollPeriod = ["Payroll Period", this.props.printDate]
    allData.push(payrollPeriod)
    // var headData = this.props.items && this.props.items?.map(item => {
    //   var rowData = []
    //   rowData.push(item.content)
    //   rowData.push(item.user_name);
    //   rowData.push(item.payroll_id)
    //   rowData.push(item.payroll_name)
    //   rowData.push(item.payroll_rule)
    //   rowData.push(item.rate)
    //   rowData.push(item.total_scheduled_hrs)
    //   rowData.push(item.total_actual_hrs)
    //   rowData.push(item.totalBreakTime)
    //   rowData.push(item.total_sch_vs_actual_hrs)
    //   rowData.push(item.total_paid_hrs)
    //   rowData.push(item.total_est_wages)
    //    allData.push(rowData)
    //  });

      var HeadName  = this.props.items && this.props.items?.map((ff) => ff.content)
        allData.push(HeadName)

     this.props.rowData && this.props.rowData?.map((item) => {
      var valueDtaa= []
      this.props.items.map((ff) => {
        return(
        ff.content === "Username" ? 
        valueDtaa.push(item.user_name) 
        :
        ff.pi_id &&
        ff.content ? valueDtaa.push(item[`${ff.content.replaceAll(" ","_")}_total_hrs`])
         : ff.content === "Role" ? 
        valueDtaa.push(item.user_role) 
        : ff.content === "Payroll Rule" ?
        valueDtaa.push(item.payroll_rule)
        :ff.content === "Public Holiday" ?
        valueDtaa.push(item.isconected_holiday ? "yes" : "No")
        : ff.content === "Employee ID" ?
        valueDtaa.push('=""' + item.mapped_code + '""') 
        : ff.content === "Template Name" ?
        valueDtaa.push(item.template_name) 
        : ff.content === "Scheduled Hrs" ?
        valueDtaa.push(item.total_scheduled_hrs) 
        : ff.content === "Actual Hours" ?
        valueDtaa.push(item.total_actual_hrs) 
        : ff.content === "Paid Hours" ?
        valueDtaa.push(item.total_paid_hrs) 
        : ff.content === "Unsch hours" ?
        valueDtaa.push(item.unsch_hrs) 
        : ff.content === "Break diff" ?
        valueDtaa.push(item.total_break_hrs) 
        : ff.content === "Estimated Wages" ?
        valueDtaa.push(item.total_est_wages ? (`$` + item.total_est_wages ): "") 
        : ff.content === "Manual hours" ?
        valueDtaa.push(item.manual_hours) 
        : ff.content === "Correction Req" ?
        valueDtaa.push(item.correction_request) 
        : ff.content === "OverTime Hours" ? 
        valueDtaa.push(item.overtime_hours) 
        : ff.content === "Regular Hrs" ?
        valueDtaa.push(item.regular_hours) 
        : ff.content === "Holiday Hrs" ?
        valueDtaa.push(item.holiday_hours)   : ""
      )}
      )
      allData.push(valueDtaa)
     })

    // this.props.rowData && this.props.rowData?.map(item => {
    //     var rowData = []
    //     rowData.push(item.user_name);
    //     rowData.push(item.payroll_id)
    //     rowData.push(item.payroll_name)
    //     rowData.push(item.payroll_rule)
    //     rowData.push(item.rate)
    //     rowData.push(item.total_scheduled_hrs)
    //     rowData.push(item.total_actual_hrs)
    //     rowData.push(item.totalBreakTime)
    //     rowData.push(item.total_sch_vs_actual_hrs)
    //     rowData.push(item.total_paid_hrs)
    //     rowData.push(item.total_est_wages)
    //     allData.push(rowData)
    //   }
    // });

    return (
      <CSVLink
        filename={"summary.csv"}
        data={allData}
        style={{ textDecoration: "none" }}
      >
        <Button
          className={classes.Download}
        >
          Summary
        </Button>
      </CSVLink>
    );

  }
}

export default withStyles(styles)(ExportCsv);
