import {
  SET_LESSON_LOADING,
  ADD_LESSON,
  EDIT_LESSON,
  DELETE_LESSON,
  SET_ALL_LESSON
} from "./constants";

const initialState = {
  isLoading: true,
  allLessonData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LESSON_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_ALL_LESSON:
      return {
        ...state,
        allLessonData: payload.allLessonData
      };
    case ADD_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: [...state.allLessonData, payload]
      }
    case EDIT_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: state.allLessonData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_LESSON:
      return {
        ...state,
        isLoading: false,
        allLessonData: state.allLessonData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;