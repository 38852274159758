import {
  SET_ELE_CATEGORY_LOADING,
  SET_ELE_CATEGORY,
  ADD_ELE_CATEGORY,
  EDIT_ELE_CATEGORY,
  DELETE_ELE_CATEGORY,
  SET_ELE_CATEGORY1,
  SET_LESSON_CATEGORY,
  SET_LESSON_CATEGORYDATA,
  SET_LESSON_NEWDATA,
  SET_FILTER_DATA
} from "./constants";

const initialState = {
  isLoading: true,
  categoryData: [],
  newCategory: [],
  categoryDataNews: [],
  extraDataPayload: [],
  lessonData: [],
  page: 1,
  pageNumber: 1,
  nPerPage: 10,
  isfeedback:2,
  isLessonQuiz:2,
  isviedioQuiz:2,
  ispracticalQuiz:2,
  ismangerQuiz:2,
  slidetype:3
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELE_CATEGORY_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_ELE_CATEGORY:
      return {
        ...state,
        isLoading: false,
        categoryData: payload.categoryData,
        // newCategory: payload.categoryData,
        extraDataPayload: payload
      };
      case SET_FILTER_DATA: 
      return {
        ...state,
        isLoading: false,
        newCategory: payload,
      };
      case SET_ELE_CATEGORY1:
        return {
          ...state,
          isLoading: false,
          categoryDataNews: payload,
        };
        case SET_LESSON_CATEGORY:
          return {
            ...state,
            isLoading: false,
            isLessonQuiz: payload,
          };
          case SET_LESSON_CATEGORYDATA:
          return {
            ...state,
            isLoading: false,
            categoryData: payload
          };
          case SET_LESSON_NEWDATA:
            return {
              ...state,
              isLoading: false,
              lessonData: payload.categoryData.map((item) => item)
            };
    case ADD_ELE_CATEGORY:
      return {
        ...state,
        isLoading: false,
        categoryData: [...state.categoryData, payload]
      }
    case EDIT_ELE_CATEGORY:
      return {
        ...state,
        isLoading: false,
        categoryData: state.categoryData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_ELE_CATEGORY:
      return {
        ...state,
        isLoading: false,
        categoryData: state.categoryData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;