export const GET_LOCATION_DETAIL_DATA = "GET_LOCATION_DETAIL_DATA";
export const GET_STATE_DATA = "GET_STATE_DATA";
export const GET_CITY_DATA = "GET_CITY_DATA";
export const UPDATE_LOCATION_DETAIL_DATA = "UPDATE_LOCATION_DETAIL_DATA";
export const GET_SCHEDULE_SETTING_DATA = "GET_SCHEDULE_SETTING_DATA";
export const UPDATE_SCHEDULE_SETTING_DATA = "UPDATE_SCHEDULE_SETTING_DATA";
export const SCHEDULER_SETTINGS_LOADING = "SCHEDULER_SETTINGS_LOADING";
export const GET_BREAK_DATA = "GET_BREAK_DATA";
export const DELETE_BREAK_DATA = "DELETE_BREAK_DATA";
export const ADD_WORK_SCEDULER = "ADD_WORK_SCEDULER";
export const GET_ROLE_DATA = "GET_ROLE_DATA";
