import {
  SET_IDEAS_LOADING,
  SET_IDEAS,
  ADD_IDEAS,
  EDIT_IDEAS,
  DELETE_IDEAS
} from "./constants";

const initialState = {
  isLoading: true,
  ideaData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IDEAS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_IDEAS:
      return {
        ...state,
        isLoading: false,
        ideaData: payload.ideasData
      };
    case ADD_IDEAS:
      return {
        ...state,
        isLoading: false,
        ideaData: [...state.ideaData, payload]
      }
    case EDIT_IDEAS:
      return {
        ...state,
        isLoading: false,
        ideaData: state.ideaData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_IDEAS:
      return {
        ...state,
        isLoading: false,
        ideaData: state.ideaData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;