import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MyGoogleSearch, MyTextfield, MySelectField,MySelectFieldNew, NoPermission, LoadingData, MyTimePicker } from '../../../components';
import { getScheduleData, updateScheduleData, deleteBreakData } from './modules/actions';
import TimePicker from 'rc-time-picker';
import { connect } from "react-redux";
import ApplyToAllLocation from "./ApplyToAllLocation";
import { schedulerColor, GOOGLE_API_KEY } from '../../../config';
import { settingsServices } from './modules/services';
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import Map from "./Map";
import {
    Button,
    CardContent,
    MenuItem,
    Typography,
    Grid,
    Card,
    Select,
    Checkbox,
    FormControlLabel,
    FormControl,
    TextField,
    Tooltip, IconButton
}
    from '@material-ui/core';
import { alert } from "../../../utilities";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import SaveSection from "./SaveSection";
import { RotateLeftSharp } from "@material-ui/icons";
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const format = 'h:mm a';
const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    mainDiv: {
        border: "2px solid #7C7C7C",
        borderRadius: "6px",
        margin: "20px",
        BoxSizing: "border-box"
    },
    HeadCell: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        color: "#232056",
        marginLeft: 10
    },
    buttonMain: {
        textAlign: "right",
        fontWeight: 700,
    },
    button: {
        background: "#005F73",
        borderRadius: 8,
        color: "white",
        padding: 10,
    },
    flex: {
        display: "flex",
        margin: "20px",
    },
    Heading: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        fontWeight: 700
    },
    HeadingValue: {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "36px",
    },
    HeadingColor: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        color: "#B71C1C",
        fontWeight: 700
    },
    HeadingCompany: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "33px",
    },
    locationCheckbox: {
        fontWeight: '500',
        fontSize: '18px'
    }
});

export function fetchSCSettingsTimeTracking() {
    window.TimeTracking.getScheduleData();
}

class TimeTracking extends Component {
    constructor(props) {
        super(props)
        window.TimeTracking = this;
        this.state = {
            applyToAll: 0,
            anchorEl: null,
            deleteModal: false,
            data: {},
            allBreaks: [],
            platform: false,
            verification: false,
            location: false,
            liveTracking: false,
            deviceLock: false,
            addBreaksData: [],
            deleterowId: "",
            isBreakUpdate: 0,
            newTime: "",
            newMin: "",
            newSec: "",
            enable_clock_from_different_device1: true,
            timeArray: [],
            minArray: [],
             secArray: [],
            distanceData: [
                {
                    id: 150,
                    name: "150 Feet"
                },
                {
                    id: 300,
                    name: "300 Feet"
                },
                {
                    id: 500,
                    name: "500 Feet"
                },
                {
                    id: 700,
                    name: "700 Feet"
                },
                {
                    id: 1000,
                    name: "1000 Feet"
                },
                {
                    id: 1500,
                    name: "1500 Feet"
                }
            ],
            flageed: [
                { value: 1, label: "Flagged" },
                { value: 2, label: "Blocked" }],
                flageedNew: [
                    { value: 1, label: "Flagged" },
                    { value: 2, label: "Blocked" }],
            livetracking: [
                { value: 1, label: "At all time" },
                { value: 2, label: "Intervals" }],
            MandatoryDropdownData: [{
                value: 1, label: "Mandatory"
            }, {
                value: 0, label: "Optional"
            }]
        }
    };

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };


    componentDidMount = () => {
        this.getScheduleData();
       
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 5) {
                for(let second = 0; second < 60; second += 1){
                    // var id = moment({ hour }).format('HH:mm');
                    // var name = moment({ minute }).format('HH:mm:');
                    var secondss = moment({ second }).format('ss')
                    if(!this.state.secArray.includes(secondss))
                    {
                        this.state.secArray.push(secondss); 
                    }
                   
                }
                // var id = moment({ hour, minute }).format('HH:mm:ss');
                var name = moment({ minute }).format('mm');
                if(!this.state.minArray.includes(name))
                {
                    this.state.minArray.push(name); 
                }
            }
                 var id = moment({ hour }).format('HH');
                 if(!this.state.timeArray.includes(id))
                 {
                     this.state.timeArray.push(id); 
                 }
                //  this.state.timeArray.push({id});
        }
        // this.setState({ 
        //     timeArray,
        //     minArray,
        //     secArray
        // })
    }

    getScheduleData = () => {
        this.setState({
            isLoading: true
        })
        var lid = this.props.scheduler_lid
        this.props.getScheduleData(lid)
    };

    componentDidUpdate(prevProps) {
        if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
            this.setState({
                data: this.props.LocationDetaildata.locationDetail,
                isLoading: false,
            }
            , () => {
                var data = this.state.data
                // Geocode.fromLatLng(data.mobile_time_clock_latitude, data.mobile_time_clock_longitude).then(
                //     (response) => {
                //         const address = response.results[0].formatted_address;
                //         this.setState({
                //             address
                //         })
                //     });
            }
            )
        }
    };

    handleChangeLatLong = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: value
            }
        }), () => {
        })
    };

    handleChangeData = (e) => {
        let state = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: checked ? 1 : 0
            }

        }))
        if(checked){
            this.setState({
                enable_clock_from_different_device1 : false
            })
        }
        else{
            this.setState({
                enable_clock_from_different_device1 : true
            }) 
        }
        if (state === "mobile_time_clock_enable_geofence") {
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    mobile_time_clock_latitude: "",
                    mobile_time_clock_longitude: "",
                    mobile_time_clock_distance: ""
                }
            }))
            this.setState({
                address: ""
            })
        }
    };

    handleChangeDataNewOne = (e) => {
        let state = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: checked ? 1 : 0
            }

        }))
        if (state === "mobile_time_clock_enable_geofence") {
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    mobile_time_clock_latitude: "",
                    mobile_time_clock_longitude: "",
                    mobile_time_clock_distance: ""
                }
            }))
            this.setState({
                address: ""
            })
        }
    };

    handleChangeSelectValue = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: value
            }
        }))
    };

    handleChangeSelectValueNew = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: value
            }
        }))
    };

    onChangelivetrackingTime = (date) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                live_tracking_clocked_in_time: date
            }
        }))
    };



    // onsubmitData = () => {
    //     const sendAllBreakData = [
    //         ...this.state.allBreaks,
    //         ...this.state.addBreaksData
    //     ];
    //     var data = {
    //         lid: this.state.data.lid,
    //         applyToAll: this.state.applyToAll,
    //         enforce_clockin_clockout_selfie: this.state.data.enforce_clockin_clockout_selfie,
    //         time_clock_emp_shift_feedback: this.state.data?.time_clock_emp_shift_feedback,
    //         mobile_time_clock_unscheduled_shift_clockin: this.state.data.mobile_time_clock_unscheduled_shift_clockin,
    //         mobile_time_clock_enable_geofence: this.state.data.mobile_time_clock_enable_geofence,
    //         mobile_time_clock_distance: this.state.data.mobile_time_clock_distance,
    //         mobile_time_clock_latitude: this.state.data.mobile_time_clock_latitude,
    //         mobile_time_clock_longitude: this.state.data.mobile_time_clock_longitude,
    //         tablet_time_clock_unscheduled_shift_select: this.state.data.tablet_time_clock_unscheduled_shift_select,
    //         web_time_clock_allow_launch_webtime_clock: this.state.data.web_time_clock_allow_launch_webtime_clock,
    //         breaks_subtract_unpaid_breaks: this.state.data.breaks_subtract_unpaid_breaks,
    //         breaks_award_missed_breaks: this.state.data.breaks_award_missed_breaks,
    //         breaks_award_missed_breaks_hrs: this.state.breaks_award_missed_breaks_hrs,
    //         breaks_convert_excesstime_paid_unpaid: this.state.data.breaks_convert_excesstime_paid_unpaid,
    //         active_breakrule_id: this.state.data.active_breakrule_id,
    //         isBreakUpdate: this.state.isBreakUpdate,
    //         allBreaks: sendAllBreakData
    //     }
    //     this.props.updateScheduleData(data);
    //     this.getBreakData(this.props.scheduler_lid)
    //     this.setState({
    //         addBreaksData: [],
    //     })
    //     // var lid = this.props.scheduler_lid

    // };

    onMarkerDragEnd = (e) => {
        var latitude = e.latLng.lat();
        var longitude = e.latLng.lng();
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                mobile_time_clock_latitude: latitude,
                mobile_time_clock_longitude: longitude
            }
        }))
    };

    platformMobile = () => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                platforms_mobile: !this.state.data.platforms_mobile,
            }
        }))
    }

    platformTablet = () => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                platforms_tablet: !this.state.data.platforms_tablet,
            }
        }))
    }

    platformweb = () => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                platforms_web: !this.state.data.platforms_web,
            }
        }))
    }

    editplatform = (value) => {
        var sendObj = {
            platforms_mobile: this.state.data.platforms_mobile === true ? 1 : 0,
            platforms_tablet: this.state.data.platforms_tablet === true ? 1 : 0,
            platforms_web: this.state.data.platforms_web === true ? 1 : 0
        }
        settingsServices.platform(this.props.scheduler_lid, sendObj).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    platform: value === "Platform" ? false : this.state.platform,
                })
                this.props.getScheduleData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
    }

    editVerification = (value) => {
        var sendObj = {
            enable_selfie_capturing: this.state.data.enable_selfie_capturing,
            enable_face_recognition: this.state.data.enable_face_recognition,
            enable_face_recognition_type: this.state.data.enable_face_recognition_type
        }
        settingsServices.verification(this.props.scheduler_lid, sendObj).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    verification: value === "Verfication" ? false : this.state.verification,
                })
                this.props.getScheduleData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
    }

    editLocation = (value) => {
        var sendObj = {
            mobile_time_clock_enable_geofence: this.state.data.mobile_time_clock_enable_geofence,
            mobile_time_clock_latitude: this.state.data.mobile_time_clock_latitude,
            mobile_time_clock_longitude: this.state.data.mobile_time_clock_longitude,
            mobile_time_clock_distance: this.state.data.mobile_time_clock_distance
        }
        settingsServices.location(this.props.scheduler_lid, sendObj).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    location: value === "Location" ? false : this.state.location,
                })
                this.props.getScheduleData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
    }

    editLiveTracking = (value) => {
        var newHour = this.state.newTime + ":" + this.state.newMin + ":" + this.state.newSec
        var inputFormat = moment(newHour,"HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss")
        var sendObj = {
            enable_live_tracking: this.state.data.enable_live_tracking,
            live_tracking_clocked_in: this.state.data.live_tracking_clocked_in,
            live_tracking_clocked_in_type: this.state.data.live_tracking_clocked_in_type,
            live_tracking_clocked_in_time: inputFormat
        }
        settingsServices.liveTracking(this.props.scheduler_lid, sendObj).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    liveTracking: value === "LiveTracking" ? false : this.state.liveTracking,
                })
                this.props.getScheduleData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
    }

    editdeviceLock = (value) => {
        var sendObj = {
            enable_device_lock_for_members: this.state.data.enable_device_lock_for_members,
            enable_clock_from_different_device: this.state.data.enable_clock_from_different_device,
            enable_clock_from_different_device_type: this.state.data.enable_clock_from_different_device_type,
        }
        settingsServices.deviceLock(this.props.scheduler_lid, sendObj).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    deviceLock: value === "DeviceLock" ? false : this.state.deviceLock
                })
                this.props.getScheduleData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
    }



    selectLatLong = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                var latitude = latLng.lat
                var longitude = latLng.lng
                this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        mobile_time_clock_latitude: latitude,
                        mobile_time_clock_longitude: longitude
                    }
                }))
                this.setState({
                    address,
                    latLng: latLng
                })
            })
            .catch(error => console.error('Error', error));
    };

    onPlaceSelected = (place) => {
        if (place) {
            var latitude = place.geometry.location.lat()
            var longitude = place.geometry.location.lng()
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    mobile_time_clock_latitude: latitude,
                    mobile_time_clock_longitude: longitude
                }
            }))
        }
    };

    handleApplyToAll = (e) => {
        this.setState({
            applyToAll: e.target.checked ? 1 : 0
        })
    };

    handleChangePlaceAddress = address => {
        this.setState({ address });
    };




    openEditTableModal = (value) => {
        if (value === "Platform") {
            this.setState({
                platform: true
            })
        }
        if (value === "Verfication") {
            this.setState({
                verification: true
            })
        }
        if (value === "Location") {
            this.setState({
                location: true
            })
        }
        if (value === "LiveTracking") {
            this.setState({
                liveTracking: true
            })
        }
        if (value === "DeviceLock") {
            this.setState({
                deviceLock: true
            })
        }
    };

    closeEditModal = (value) => {
        this.setState({
            platform: value === "Platform" ? false : this.state.platform,
            verification: value === "Verfication" ? false : this.state.verification,
            location: value === "Location" ? false : this.state.location,
            liveTracking: value === "LiveTracking" ? false : this.state.liveTracking,
            deviceLock: value === "DeviceLock" ? false : this.state.deviceLock
        })
    };

    handleChangeArray = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        // this.setState(prevState => ({
        //     data: {
        //         ...prevState.data,
        //         [state]: value
        //     }
        // }), () => {
        // })
        this.setState({
            newTime: value
        })
    }

    handleChangeArray1 = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        // this.setState(prevState => ({
        //     data: {
        //         ...prevState.data,
        //         [state]: value
        //     }
        // }), () => {
        // })
        this.setState({
            newMin: value
        })
    }

    handleChangeArray2 = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        // this.setState(prevState => ({
        //     data: {
        //         ...prevState.data,
        //         [state]: value
        //     }
        // }), () => {
        // })
        this.setState({
            newSec: value
        })
    }

    render() {
        const cardBg = "#faf9f9"
        const cardRadius = "12px"
        const { permissionsData, classes } = this.props;
        return (
            permissionsData.scheduler_settings ?
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    {/* <Grid item md={12} >
                        <ApplyToAllLocation
                            applyToAll={this.state.applyToAll}
                            handleApplyToAll={this.handleApplyToAll}
                            handleSave={this.onsubmitData}
                        />
                     <Typography style={{marginLeft:22,fontSize:10,lineHeight:0}}><span style={{color:"red"}}>*</span> Except payroll rule , geo-fence and Break Rules </Typography>
                    </Grid>
 */}

                    <Grid
                        item
                        md={12}
                    >
                        <Card style={{ backgroundColor: cardBg, borderRadius: cardRadius, boxShadow: this.state.platform ? "-4px -4px 16px #999" : "" }}>
                            <CardContent>
                                <Grid item md={12} className="d-flex">
                                    <Grid item md={11}>
                                        <Typography style={{ fontSize: 18, fontWeight: 700, color: 'black' }} gutterBottom>
                                            Platforms
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} className="editIcons">
                                        <Tooltip arrow title="Edit" className="editIcon">
                                            <IconButton size="small" onClick={() => this.openEditTableModal("Platform")}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" component="div" color="textSecondary">
                                    Select the platforms your team members and managers are allowed to clock in and out from.
                                </Typography>

                                <Typography variant="h6" component="div" style={{ padding: "1rem 1rem", color: 'black', display: "flex", margin: 20, justifyContent: "center", gap: "8rem" }}>

                                    <Button
                                        onClick={this.platformMobile}
                                        disabled={this.state.platform === false ? true : false}
                                        style={{
                                            textTransform: "capitalize",
                                            fontWeight: 800,
                                            padding: ".5rem 3rem",
                                            border: "1px solid #F0EBEB",
                                            backgroundColor: this.state.data.platforms_mobile ? "green" : "#F0EBEB",
                                            color: this.state.data.platforms_mobile ? "#FFFFFF" : "#707070",
                                        }}>
                                        Mobile
                                    </Button>
                                    <Button
                                        onClick={this.platformTablet}
                                        disabled={this.state.platform === false ? true : false}
                                        style={{
                                            textTransform: "capitalize",
                                            fontWeight: 800,
                                            padding: ".5rem 3rem",
                                            border: "1px solid #F0EBEB",
                                            backgroundColor: this.state.data.platforms_tablet ? "green" : "#F0EBEB",
                                            color: this.state.data.platforms_tablet ? "#FFFFFF" : "#707070",
                                        }}>
                                        Tablet
                                    </Button>
                                    <Button
                                        onClick={this.platformweb}
                                        disabled={this.state.platform === false ? true : false}
                                        style={{
                                            textTransform: "capitalize",
                                            fontWeight: 800,
                                            padding: ".5rem 3rem",
                                            border: "1px solid #F0EBEB",
                                            backgroundColor: this.state.data.platforms_web ? "green" : "#F0EBEB",
                                            color: this.state.data.platforms_web ? "#FFFFFF" : "#707070"
                                        }}>
                                        Web
                                    </Button>
                                </Typography>

                                {/* <Typography variant="h6" component="div">
                                <FormControlLabel
                                    control={<Checkbox
                                        color={schedulerColor.main}
                                    />}
                                />
                                Round clock-in and clock-out times to  the nearest minute increment.
                            </Typography> */}

                                {this.state.platform &&
                                    <SaveSection
                                    handleSubmit={() => {this.editplatform("Platform")}}
                                        handleCancel={() => { this.closeEditModal("Platform") }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        md={12}
                    >
                        <Card style={{ backgroundColor: cardBg, borderRadius: cardRadius, boxShadow: this.state.verification ? "-4px -4px 16px #999" : "" }}>
                            <CardContent>
                                <Grid item md={12} className="d-flex">
                                    <Grid item md={11}>
                                        <Typography style={{ fontSize: 18, fontWeight: 700, color: 'black' }} gutterBottom>
                                            Verification
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} className="editIcons">
                                        <Tooltip arrow title="Edit" className="editIcon">
                                            <IconButton size="small" onClick={() => this.openEditTableModal("Verfication")}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" component="div" style={{ fontWeight: 700 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enable_selfie_capturing"
                                            disabled={this.state.verification === false ? true : false}
                                            checked={this.state.data.enable_selfie_capturing ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Enable Selfie Capturing
                                </Typography>

                                <Typography variant="h6" component="div" style={{ marginBottom: "1rem", fontWeight: 700, verticalAlign: "-webkit-baseline-middle" }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enable_face_recognition"
                                            disabled={this.state.verification === false ? true : false}
                                            checked={this.state.data.enable_face_recognition ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Enable Face recognition and if Unrecognised face will be
                                    <TextField
                                        variant="outlined"
                                        select
                                        size="small"
                                        value={this.state.data.enable_face_recognition_type === 1 ? 1 : 2}
                                        disabled={this.state.data.enable_face_recognition === 1 ? 0 : 1}
                                        style={{ width: 90, marginTop: 5, marginLeft: 10, height: 20 }}
                                        name="enable_face_recognition_type"
                                        onChange={this.handleChangeSelectValue}
                                    >
                                        {this.state.flageed.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Typography>

                                {this.state.verification &&
                                    <SaveSection
                                    handleSubmit={() => {this.editVerification("Verfication")}}
                                        handleCancel={() => { this.closeEditModal("Verfication") }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        md={12}
                    >
                        <Card style={{ backgroundColor: cardBg, borderRadius: cardRadius, boxShadow: this.state.location ? "-4px -4px 16px #999" : "" }}>
                            <CardContent>
                                <Grid item md={12} className="d-flex">
                                    <Grid item md={11}>
                                        <Typography style={{ fontSize: 18, fontWeight: 700, color: 'black' }} gutterBottom>
                                            Location
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} className="editIcons">
                                        <Tooltip arrow title="Edit" className="editIcon">
                                            <IconButton size="small" onClick={() => this.openEditTableModal("Location")}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" component="div" style={{ color: 'black' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="mobile_time_clock_enable_geofence"
                                            checked={this.state.data.mobile_time_clock_enable_geofence ? true : false}
                                            onChange={this.handleChangeData}
                                            disabled={this.state.location === false ? true : false}
                                            style={{
                                                color: schedulerColor.main,
                                            }}
                                        />}
                                    />
                                    Enable GEO - Fence
                                </Typography>

                                {this.state.data.mobile_time_clock_enable_geofence ?
                                    <Grid style={{ marginTop: "1rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={4}>
                                                <MyTextfield
                                                    name="mobile_time_clock_latitude"
                                                    label=""
                                                    onChange={this.handleChangeLatLong}
                                                    value={this.state.data.mobile_time_clock_latitude}
                                                    type="text"
                                                    disabled={this.state.location === false ? true : false}
                                                    placeholder=""
                                                />
                                            </Grid>

                                            <Grid item sm={4}>
                                                <MyTextfield
                                                    name="mobile_time_clock_longitude"
                                                    label=""
                                                    onChange={this.handleChangeLatLong}
                                                    value={this.state.data.mobile_time_clock_longitude}
                                                    type="text"
                                                    disabled={this.state.location === false ? true : false}
                                                    placeholder=""
                                                />
                                            </Grid>
                                            <Grid item sm={4}>
                                                <MySelectFieldNew
                                                    label=""
                                                    name="mobile_time_clock_distance"
                                                    onChange={this.handleChangeLatLong}
                                                    value={this.state.data.mobile_time_clock_distance}
                                                    disabled={this.state.location === false ? true : false}
                                                    placeholder=""
                                                >
                                                    {this.state.distanceData.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </MySelectFieldNew>
                                            </Grid>

                                            <Grid item sm={12}>
                                                <MyGoogleSearch
                                                    handleChangePlaceAddress={this.handleChangePlaceAddress}
                                                    address={this.state.address}
                                                    latLng={{
                                                        lat: parseFloat(this.state.data.mobile_time_clock_latitude),
                                                        long: parseFloat(this.state.data.mobile_time_clock_longitude)
                                                    }}
                                                    selectLatLong={this.selectLatLong}
                                                />
                                            </Grid>

                                            <Grid item sm={12} style={{ margin: "1rem 0" }}>
                                                <Grid container justifyContent="center">
                                                    <Grid item sm={7}>
                                                        <Map
                                                            onMarkerDragEnd={this.onMarkerDragEnd}
                                                            onPlaceSelected={this.onPlaceSelected}
                                                            distance={this.state.data.mobile_time_clock_distance}
                                                            latitude={parseFloat(this.state.data.mobile_time_clock_latitude)}
                                                            longitude={parseFloat(this.state.data.mobile_time_clock_longitude)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Typography variant="h6" component="div" style={{ color: 'black' }}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    name="mobile_time_clock_enable_geofence"
                                                    disabled={this.state.location === false ? true : false}
                                                    checked={this.state.data.mobile_time_clock_enable_geofence ? true : false}
                                                    onChange={this.handleChangeData}
                                                    style={{
                                                        color: schedulerColor.main,
                                                    }}
                                                />}
                                            />
                                            Flag as issue if employees leaves geo fence radius.
                                        </Typography>

                                    </Grid>
                                    : ""}
                                {this.state.location &&
                                    <SaveSection
                                    handleSubmit={() => {this.editLocation("Location")}}
                                        handleCancel={() => { this.closeEditModal("Location") }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        md={12}
                    >
                        <Card style={{ backgroundColor: cardBg, borderRadius: cardRadius, boxShadow: this.state.liveTracking ? "-4px -4px 16px #999" : "" }}>
                            <CardContent>
                                <Grid item md={12} className="d-flex">
                                    <Grid item md={11}>
                                        <Typography style={{ fontSize: 18, fontWeight: 700, color: 'black' }} gutterBottom>
                                            Live Tracking
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} className="editIcons">
                                        <Tooltip arrow title="Edit" className="editIcon">
                                            <IconButton size="small" onClick={() => this.openEditTableModal("LiveTracking")}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" component="div" style={{ color: 'black' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="enable_live_tracking"
                                            checked={this.state.data.enable_live_tracking ? true : false}
                                            disabled={this.state.liveTracking === false ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }}
                                        />}
                                    />
                                    Enable live tracking
                                </Typography>
                                {this.state.data.enable_live_tracking ? 
                                <>
                                <Typography variant="h6" component="div" style={{ verticalAlign: "-webkit-baseline-middle", fontWeight: 700, marginLeft: 46 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="live_tracking_clocked_in"
                                            checked={this.state.data.live_tracking_clocked_in ? true : false}
                                            onChange={this.handleChangeData}
                                            disabled={this.state.liveTracking === false ? true : false}
                                            style={{
                                                color: schedulerColor.main,
                                            }} />}
                                    />
                                    Record live Tracking during Clocked in hours
                                    {this.state.data.live_tracking_clocked_in ?
                                    <TextField
                                        variant="outlined"
                                        select
                                        size="small"
                                        value={this.state.data.live_tracking_clocked_in_type === 1 ? 1 : 2}
                                        disabled={this.state.liveTracking === false ? true : false}
                                        style={{ width: 90, marginTop: 5, marginLeft: 10, height: 20 }}
                                        name="live_tracking_clocked_in_type"
                                        onChange={this.handleChangeSelectValueNew}
                                    >
                                        {this.state.livetracking.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    : ""}
                                </Typography> 
                                  
                                  
                                {this.state.data.live_tracking_clocked_in_type === 2 ?
                                <>
                                <Typography variant="h6" component="div" style={{ fontWeight: 700, marginLeft: 46 }}>
                                    Select Intervals
                                </Typography>

                                <Typography variant="h6" component="div" style={{ marginBottom: "1rem", fontWeight: 700, marginTop: 10, marginLeft: 100, marginBottom: 25 }}>
                                    <TextField
                                        variant="outlined"
                                        select
                                        size="small"
                                        label="Hours"
                                        value={moment(this.state.data.live_tracking_clocked_in_time,"YYYY-MM-DDTHH:mm:ss").format("hh")}
                                        style={{ width: 90, marginLeft: 10, height: 20 }}
                                        disabled={this.state.liveTracking === false ? true : false}
                                        name="is_paid"
                                        onChange={(e) => this.handleChangeArray(e)}
                                    >
                                        {this.state.timeArray && this.state.timeArray.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        variant="outlined"
                                        select
                                        size="small"
                                        label="Minutes"
                                        value={moment(this.state.data.live_tracking_clocked_in_time,"YYYY-MM-DDTHH:mm:ss").format("mm")}
                                        style={{ width: 90, marginLeft: 10, height: 20 }}
                                        name="is_paid"
                                        disabled={this.state.liveTracking === false ? true : false}
                                        onChange={(e) => this.handleChangeArray1(e)}
                                    >
                                        {this.state.minArray.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        variant="outlined"
                                        select
                                        size="small"
                                        label="Second"
                                        value={moment(this.state.data.live_tracking_clocked_in_time,"YYYY-MM-DDTHH:mm:ss").format("ss")}
                                        style={{ width: 90, marginLeft: 10, height: 20 }}
                                        disabled={this.state.liveTracking === false ? true : false}
                                        name="is_paid"
                                        onChange={(e) => this.handleChangeArray2(e)}
                                    >
                                        {this.state.secArray.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Typography>
                                </>
                                : "" }
                                </>
                                : 
                                  "" }
                            
                                {this.state.liveTracking &&
                                    <SaveSection
                                        handleSubmit={() => {this.editLiveTracking("LiveTracking")}}
                                        handleCancel={() => { this.closeEditModal("LiveTracking") }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid
                        item
                        md={12}
                    >
                        <Card style={{ backgroundColor: cardBg, borderRadius: cardRadius, boxShadow: this.state.deviceLock ? "-4px -4px 16px #999" : "" }}>
                            <CardContent>
                                <Grid item md={12} className="d-flex">
                                    <Grid item md={11}>
                                        <Typography style={{ fontSize: 18, fontWeight: 700, color: 'black' }} gutterBottom>
                                            Device Lock
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} className="editIcons">
                                        <Tooltip arrow title="Edit" className="editIcon">
                                            <IconButton size="small" onClick={() => this.openEditTableModal("DeviceLock")}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h6" component="div">
                                    Restrict members from changing the device they clock in and out from.
                                </Typography>
                                <Typography variant="h6" component="div">
                                    Enable device lock for members
                                </Typography>
                                <Typography variant="h6" component="div" style={{ color: 'black' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            disabled={this.state.deviceLock === false ? true : false}
                                            name="enable_device_lock_for_members"
                                            checked={this.state.data.enable_device_lock_for_members ? true : false}
                                            onChange={this.handleChangeData}
                                            style={{
                                                color: schedulerColor.main,
                                            }}
                                        />}
                                    />
                                    Enable Device  Lock for members
                                </Typography>
                                <Typography variant="h6" component="div" style={{ verticalAlign: "-webkit-baseline-middle", color: 'black', display: "flex" }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            disabled={this.state.data.enable_device_lock_for_members === 1 ? 0 : 1}
                                            name="enable_clock_from_different_device"
                                            checked={this.state.data.enable_clock_from_different_device ? true : false}
                                            onChange={this.handleChangeDataNewOne}
                                            style={{
                                                color: schedulerColor.main,
                                            }}
                                        />}
                                    />
                                    <FormControl style={{ marginTop: 12, width: '112px' }} size="small" variant="outlined">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={this.state.data.enable_clock_from_different_device === 1 ? 0 : 1}
                                            variant="outlined"
                                            size="small"
                                            value={this.state.data.enable_clock_from_different_device_type === 1 ? 1 : 2}
                                            style={{ width: 90, marginLeft: 10, height: 20 }}
                                            name="enable_clock_from_different_device_type"
                                            onChange={this.handleChangeSelectValueNew}
                                        >
                                            {this.state.flageedNew.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                    <p style={{ marginTop: 12, }}>if different device is being used to clock out.</p>
                                </Typography>
                                {this.state.deviceLock &&
                                    <SaveSection
                                        handleSubmit={() => { this.editdeviceLock("DeviceLock")}}
                                        handleCancel={() => { this.closeEditModal("DeviceLock") }}
                                    />}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
});

const ConnectWith = connect(mapStateToProps, { getScheduleData, updateScheduleData, deleteBreakData })(TimeTracking);
export default withStyles(styles)(ConnectWith);
