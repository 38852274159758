import React from "react";
// import DateRangePicker from "react-daterange-picker";
// import "react-daterange-picker/dist/css/react-calendar.css";
import DatePicker, {
  Calendar,
  getAllDatesInRange
} from "react-multi-date-picker";
import moment from "moment";
class Example extends React.Component {

  render() {
    var value = [this.props.startDate, this.props.endDate]
    return (
      <div style={{
        pointerEvents: "none",
        padding: "10px 0 0 0",
        display: "flex",
        justifyContent: "center"
      }}>
        <Calendar
        onlyShowInRangeDates={true}
          minDate={this.props.startDate}
          maxDate={this.props.endDate}
          range
          value={value}
          // singleDateRange={true}
        />
      </div>
    );
  }
}

export default Example;
