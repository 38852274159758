import {
  SET_SCHEDULE_LOADING,
  SET_SCHEDULE,
  DELETE_SHIFT,
  ADD_SHIFT,
  EDIT_SHIFT,
  DUPLICATE_SHIFT,
  PUBLISH_SHIFT,
  CLEAR_UNPUBLISH_SHIFT,
  CLEAR_ENTIRE_SCHEDULE,
  ADD_SCHEDULE,
  EDIT_SCHEDULE,
  INVITE_SCHEDULE_USER,
  VIEW_USER,
  UPDATE_USER
} from "./constants";


import { alert, invalidToken } from '../../../../utilities';
import { scheduleServices } from './services'

export function inviteSchedulerUser(data, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_SCHEDULE_LOADING,
        payload: true
      });
    }
    scheduleServices.inviteSchedulerUser(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: INVITE_SCHEDULE_USER,
            payload: response.data.user
          });
        }
      });
  };
}


export function fetchData(data, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_SCHEDULE_LOADING,
        payload: true
      });
    }
    scheduleServices.fetchData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_SCHEDULE,
            payload: response.data
          });
        }
      });
  };
}


export function addShifts(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.addShifts(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_SHIFT,
          payload: {
            uid: data.uid,
            shifts: response.data.shifts
          }
        });
      }
    });
  };
}

export function editShifts(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.editShifts(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_SHIFT,
            payload: {
              uid: data.uid,
              shift: response.data.shift
            }
          });
        }
      });
  };
}

export function deleteShifts(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.deleteShifts(data.job_id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: DELETE_SHIFT,
            payload: data
          });
        }
      });
  };
}

export function duplicateShifts(uid, shift) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.duplicateShifts(shift)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: DUPLICATE_SHIFT,
            payload: {
              uid,
              shift: response.data.post
            }
          });
        }
      });
  };
}

export function clearEntireSchedule(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.clearEntireSchedule(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: CLEAR_ENTIRE_SCHEDULE,
            payload: data
          });
        }
      });

  };
}

export function clearUnpublishShifts(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.clearUnpublishShifts(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: CLEAR_UNPUBLISH_SHIFT,
            payload: data
          });
        }
      });
  };
}

export function publishShifts(data) {
  return function (dispatch) {
    dispatch({
      type: SET_SCHEDULE_LOADING,
      payload: true
    });
    scheduleServices.publishShifts(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: PUBLISH_SHIFT,
            payload: data
          });
        }
      });
  };
}

export function addData(data) {
  return function (dispatch) {
    scheduleServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_SCHEDULE,
            payload: response.data.post
          });
        }
      });
  };
}

export function editData(data) {
  return function (dispatch) {
    scheduleServices.editData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_SCHEDULE,
            payload: response.data.post
          });
        }
      });
  };
}

export function fetchUser(data) {
  return function (dispatch) {
    scheduleServices.fetchUser(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          // alert.success(response.data.message)
          dispatch({
            type: VIEW_USER,
            payload: response.data
          });
        }
      });
  };
}

export function updateUserPayroll(data) {
  return function (dispatch) {
    scheduleServices.updateUserPayroll(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: UPDATE_USER,
            payload: response.data
          });
        }
      });
  };
}