import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MoreMenu, MySelectField, TableMyPagination, TableRecordNotFound, LoadingData, DeleteModal, MyLeftModal, MyTextfield, MyTimePicker } from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Close
} from '@material-ui/icons';

import moment from "moment";
import { utcToLocal, validationIsEmpty } from '../../../utilities';
import {
    FormHelperText,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    Tooltip,
    Modal,
    Box
}
    from '@material-ui/core';
import { eLearningColor } from "../../../config";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2, 0),
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontWeight: "700",
        fontSize: "16px",
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    // title: {
    //     flex: '1 1 100%',
    // },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    addQuizBtn: {
        borderRadius: "4px",
        height: "40px",
        width: "110px",
        backgroundColor: "#135098",
        border: "1px solid #135098",
        color: "white",
        fontSize: "14px",
        fontWeight: "700"
    },
    addQuizModal: {
        width: "600px",
        background: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "4px",
        overflow: "hidden"
    },
    addQuizModalHeader: {
        background: "#135098",
        color: "white",
        display: "flex",
        alignItems: "center",
        padding: "15px 20px"
    },
    addQuizModalHeaderTitle: {
        fontSize: "16px",
        fontWeight: "700"
    },
    addQuizModalContent: {
        padding: "20px"
    },
    optionsTitle: {
        fontSize: "16px",
        fontWeight: "700",
        padding: "7px 4px",
        margin: "0px"
    },
    answersTitle: {
        fontSize: "14px",
        fontWeight: "700",
        padding: "7px 4px",
        margin: "0px"
    },
    quizLabel: {
        fontSize: "#646464",
        fontSize: "14px"
    },
    cancelQuizBtn: {
        background: "#858585",
        border: "1px solid #858585",
        borderRadius: "4px",
        width: "110px",
        height: "48px",
        color: "white",
        fontSize: "16px",
        fontWeight: "400",
        marginRight: "5px"
    },
    saveQuizBtn: {
        background: "#135098",
        border: "1px solid #135098",
        borderRadius: "4px",
        width: "110px",
        height: "48px",
        color: "white",
        fontSize: "16px",
        fontWeight: "400",
        marginLeft: "5px"
    },
});


class Questions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            myItem: {},
            order: 'asc',
            orderBy: "_id",
            headCells: [
                { id: 'sr_no', label: "No", numeric: false },
                { id: 'question', label: "Question", numeric: false },
                { id: 'correct_answer', label: "Answer", numeric: false },
                { id: 'created_at', label: "Created at", numeric: false }
            ],
            IfAnswerisWrong: [
                { id: "0", label: "Show Correct Answer" },
                { id: "1", label: "Restart" }
            ],
            moreData: [
                {
                    icon: EditIcon,
                    title: "Edit Question",
                    sortName: "edit",
                    onClick: this.modifyData
                },
                {
                    icon: DeleteIcon,
                    title: "Delete Question",
                    sortName: "delete",
                    onClick: this.modifyData
                }
            ],
            videoInterval: [],
            errors: {},
            errorsHelper: {},
            haveSameOptions: 'false'
        }
    }

    componentDidMount = () => {
        var data = []
        for (i = 0; i < 100; i++) {
            var i = i;
            var object = {
                id: i,
                time: i,
            };
            data.push(object)
            this.setState({
                videoInterval: data
            })
        }
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    handleSubmit = async () => {
        var userItem = this.state.myItem;
        // var valid_title = await validationIsEmpty(userItem.title)
        var valid_question = await validationIsEmpty(userItem.question);
        var valid_option1 = await validationIsEmpty(userItem.option1);
        var valid_option2 = await validationIsEmpty(userItem.option2);
        var valid_option3 = await validationIsEmpty(userItem.option3);
        var valid_option4 = await validationIsEmpty(userItem.option4);
        var valid_correct_answer = userItem.correct_answer ? false : true

        // if (valid_title) {
        //     this.setState(prevState => ({
        //         errors: {
        //             ...prevState.errors,
        //             title: true
        //         },
        //         errorsHelper: {
        //             ...prevState.errorsHelper,
        //             title: "Please enter Bookmark name"
        //         }
        //     }))
        // }

        // if (userItem.time === null) {
        //     this.setState((prevState) => ({
        //         errors: {
        //             ...prevState.errors,
        //             time: true,
        //         },
        //         errorsHelper: {
        //             ...prevState.errorsHelper,
        //             time: "Please select time",
        //         },
        //     }));
        // }

        if (valid_question) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    question: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    question: "Please enter question"
                }
            }))
        }

        if (valid_option1) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    option1: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    option1: "Please enter option1"
                }
            }))
        }

        if (valid_option2) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    option2: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    option2: "Please enter option2"
                }
            }))
        }

        if (valid_option3) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    option3: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    option3: "Please enter option3"
                }
            }))
        }

        if (valid_option4) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    option4: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    option4: "Please enter option4"
                }
            }))
        }

        if (valid_correct_answer) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    correct_answer: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    correct_answer: "Please select answer"
                }
            }))
        }

        if (valid_question || valid_option1 || valid_option2 || valid_option3 || valid_option4 || valid_correct_answer) {
            return;
        }

        if (this.props.lesson_type === 2) {
            if (this.state.myItem.video_interval < 1) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        video_interval: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        video_interval: "Please select time"
                    }
                }))
                return
            }
        }

        if (this.state.myItem.option1 === this.state.myItem.option2
            || this.state.myItem.option3 === this.state.myItem.option1 ||
            this.state.myItem.option1 === this.state.myItem.option4 ||
            this.state.myItem.option2 === this.state.myItem.option4
            || this.state.myItem.option3 === this.state.myItem.option4) {
            this.setState({
                haveSameOptions: 'true'
            })
            return;
        }
        else {
            this.setState({
                haveSameOptions: 'false'
            })
        }

        this.setState({ addEditModal: false })
        if (this.state.isEdit) {
            this.props.editQuestion(this.state.myItem)
        } else {
            this.props.addQuestion(this.state.myItem)
        }
    };

    deleteData = () => {
        this.setState({ deleteModal: false })
        this.props.deleteQuestion(this.state.myItem._id)
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };

    handleChangeRadio = (e) => {
        let state = e.target.name;
        let value = parseInt(e.target.value);

        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }))
    }

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }))
    }

    handleChangeDateTime = (date, state) => {
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                [state]: date,
            },
        }));
    };

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }

    moreMenuClick = (item, value) => {
        var myItem = {
            lesson_id: this.props._id,
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            correct_answer: "",
            video_interval: 5,
            question_type: this.props.question_type,
            slide_id: this.props.slideid
        }

        if (item) {
            myItem = item
        }

        this.setState({
            myItem,
            errors: {},
            errorsHelper: {},
            isEdit: 0,
            addEditModal: value === "add" ? true : false
        })
    }

    modifyData = (value) => {
        this.setState({
            isEdit: value === "edit" ? 1 : 0,
            addEditModal: value === "add" || value === "edit" ? true : false,
            deleteModal: value === "delete" ? true : false
        })
    }

    render() {
        const { questionsData, isLoading, classes, pageNo, rowsPerPage } = this.props;
        if (isLoading) return <LoadingData />
        return (
            <Grid
                container
                spacing={4}
                className={classes.root}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Modal
                        open={this.state.addEditModal}
                        onClose={this.addEditModalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box
                            className={classes.addQuizModal}
                            style={{
                                width: window.innerWidth >= 610 ? "600px" : "90%"
                            }}
                        >
                            <div className={classes.addQuizModalHeader}>
                                <p className={"mb-0 " + classes.addQuizModalHeaderTitle}>{this.state.isEdit ? "Edit Question" : "Add Quiz"}</p>
                                <Close
                                    className="ml-auto"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={this.addEditModalClose}
                                />
                            </div>
                            <Grid
                                container
                                spacing={1}
                                className={classes.addQuizModalContent}
                            >
                                <Grid item xs={12}>
                                    <MyTextfield
                                        name="question"
                                        label="Question"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.question}
                                        type="text"
                                        error={this.state.errors.question}
                                        helperText={this.state.errors.question ? this.state.errorsHelper.question : ""}
                                        placeholder="Enter question"
                                    />
                                </Grid>

                                <p className={classes.optionsTitle}>Options</p>

                                <Grid item xs={12}>
                                    <MyTextfield
                                        name="option1"
                                        label="Option1"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.option1}
                                        type="text"
                                        error={this.state.errors.option1}
                                        helperText={this.state.errors.option1 ? this.state.errorsHelper.option1 : ""}
                                        placeholder="Enter option1"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <MyTextfield
                                        name="option2"
                                        label="Option2"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.option2}
                                        type="text"
                                        error={this.state.errors.option2}
                                        helperText={this.state.errors.option2 ? this.state.errorsHelper.option2 : ""}
                                        placeholder="Enter option2"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <MyTextfield
                                        name="option3"
                                        label="Option3"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.option3}
                                        type="text"
                                        error={this.state.errors.option3}
                                        helperText={this.state.errors.option3 ? this.state.errorsHelper.option3 : ""}
                                        placeholder="Enter option3"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <MyTextfield
                                        name="option4"
                                        label="Option4"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.option4}
                                        type="text"
                                        error={this.state.errors.option4}
                                        helperText={this.state.errors.option4 ? this.state.errorsHelper.option4 : ""}
                                        placeholder="Enter option4"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className={classes.answersTitle}>Answer</FormLabel>
                                        <RadioGroup
                                            onChange={this.handleChangeRadio}
                                            onFocus={this.handleFocus}
                                            value={this.state.myItem.correct_answer}
                                            name="correct_answer"
                                            style={{
                                                flexDirection: "row"
                                            }}
                                        >
                                            <FormControlLabel
                                                value={1}
                                                control={<Radio />}
                                                label="A"
                                                className="ml-0"
                                                style={{
                                                    width: "65px"
                                                }}
                                            />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label="B"
                                                style={{
                                                    width: "65px"
                                                }}
                                            />
                                            <FormControlLabel
                                                value={3}
                                                control={<Radio />}
                                                label="C"
                                                style={{
                                                    width: "65px"
                                                }}
                                            />
                                            <FormControlLabel
                                                value={4}
                                                control={<Radio />}
                                                label="D"
                                                style={{
                                                    width: "65px"
                                                }}
                                            />
                                        </RadioGroup>
                                        {this.state.errors.correct_answer ?
                                            <FormHelperText
                                                style={{ color: "red" }}
                                            >{this.state.errorsHelper.correct_answer}
                                            </FormHelperText> : ""}
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <p className={"m-0 " + classes.quizLabel}>If answer is wrong&nbsp;</p>
                                    <MySelectField
                                        name="if_wrong_answer"
                                        label=""
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.if_wrong_answer}
                                        error={this.state.errors.if_wrong_answer}
                                        helperText={this.state.errors.if_wrong_answer ? this.state.errorsHelper.if_wrong_answer : ""}
                                        placeholder="Please Select "
                                        className={classes.quizLabel}
                                    >
                                        {this.state.IfAnswerisWrong.map((item) => {
                                            return (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                    className={classes.quizLabel}
                                                >
                                                    {item.label}
                                                </option>
                                            )
                                        })}
                                    </MySelectField>
                                </Grid>

                                {this.props.question_type === 2 ?
                                    <Grid item xs={12}>
                                        <MySelectField
                                            name="video_interval"
                                            label="Time (seconds)"
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            value={this.state.myItem.video_interval}
                                            error={this.state.errors.video_interval}
                                            helperText={this.state.errors.video_interval ? this.state.errorsHelper.video_interval : ""}
                                            placeholder="Please Select Time"
                                        >
                                            {/* {this.props.videoDurations.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id} >
                                                    {item.time}
                                                </option>
                                            )
                                        })} */}

                                            {this.state.videoInterval.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id} >
                                                        {item.time}
                                                    </option>
                                                )
                                            })}
                                        </MySelectField>
                                    </Grid>
                                    : ""
                                }

                                <div className="mt-4 mb-2 w-100 d-flex justify-content-end">
                                    <input
                                        type="button"
                                        value="Cancel"
                                        className={classes.cancelQuizBtn}
                                        onClick={this.addEditModalClose}
                                    />
                                    <input
                                        type="button"
                                        value="Save"
                                        className={classes.saveQuizBtn}
                                        onClick={this.handleSubmit}
                                    />
                                </div>

                            </Grid>
                        </Box>
                    </Modal>
                    <MyLeftModal
                        bgColor={eLearningColor.main}
                        title={this.state.isEdit ? "Edit Question" : "Add Quiz "}
                        // open={this.state.addEditModal}
                        onClose={this.addEditModalClose}
                        handleSubmit={this.handleSubmit}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div style={{ paddingBottom: '1rem', color: 'red' }}>{this.state.haveSameOptions === 'true' ? "Two options cannot be same" : ""}</div>
                                <MyTextfield
                                    name="question"
                                    label="Question"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.question}
                                    type="text"
                                    error={this.state.errors.question}
                                    helperText={this.state.errors.question ? this.state.errorsHelper.question : ""}
                                    placeholder="Enter question"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <MyTextfield
                                    name="option1"
                                    label="Option1"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.option1}
                                    type="text"
                                    error={this.state.errors.option1}
                                    helperText={this.state.errors.option1 ? this.state.errorsHelper.option1 : ""}
                                    placeholder="Enter option1"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <MyTextfield
                                    name="option2"
                                    label="Option2"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.option2}
                                    type="text"
                                    error={this.state.errors.option2}
                                    helperText={this.state.errors.option2 ? this.state.errorsHelper.option2 : ""}
                                    placeholder="Enter option2"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <MyTextfield
                                    name="option3"
                                    label="Option3"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.option3}
                                    type="text"
                                    error={this.state.errors.option3}
                                    helperText={this.state.errors.option3 ? this.state.errorsHelper.option3 : ""}
                                    placeholder="Enter option3"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <MyTextfield
                                    name="option4"
                                    label="Option4"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.option4}
                                    type="text"
                                    error={this.state.errors.option4}
                                    helperText={this.state.errors.option4 ? this.state.errorsHelper.option4 : ""}
                                    placeholder="Enter option4"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Answer</FormLabel>
                                    <RadioGroup
                                        onChange={this.handleChangeRadio}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.correct_answer}
                                        name="correct_answer"
                                    >
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label="A"
                                        />
                                        <FormControlLabel
                                            value={2}
                                            control={<Radio />}
                                            label="B"
                                        />
                                        <FormControlLabel
                                            value={3}
                                            control={<Radio />}
                                            label="C"
                                        />
                                        <FormControlLabel
                                            value={4}
                                            control={<Radio />}
                                            label="D"
                                        />
                                    </RadioGroup>
                                    {this.state.errors.correct_answer ?
                                        <FormHelperText
                                            style={{ color: "red" }}
                                        >{this.state.errorsHelper.correct_answer}
                                        </FormHelperText> : ""}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <MySelectField
                                    name="if_wrong_answer"
                                    label="If answer is wrong"
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    value={this.state.myItem.if_wrong_answer}
                                    error={this.state.errors.if_wrong_answer}
                                    helperText={this.state.errors.if_wrong_answer ? this.state.errorsHelper.if_wrong_answer : ""}
                                    placeholder="Please Select "
                                >
                                    {this.state.IfAnswerisWrong.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id} >
                                                {item.label}
                                            </option>
                                        )
                                    })}
                                </MySelectField>
                            </Grid>

                            {this.props.question_type === 2 ?
                                <Grid item xs={12}>
                                    <MySelectField
                                        name="video_interval"
                                        label="Time (seconds)"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.video_interval}
                                        error={this.state.errors.video_interval}
                                        helperText={this.state.errors.video_interval ? this.state.errorsHelper.video_interval : ""}
                                        placeholder="Please Select Time"
                                    >
                                        {/* {this.props.videoDurations.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id} >
                                                    {item.time}
                                                </option>
                                            )
                                        })} */}

                                        {this.state.videoInterval.map((item) => {
                                            return (
                                                <option key={item.id} value={item.id} >
                                                    {item.time}
                                                </option>
                                            )
                                        })}
                                    </MySelectField>
                                </Grid>
                                : ""
                            }

                        </Grid>
                    </MyLeftModal>

                    <DeleteModal
                        bgColor={eLearningColor.main}
                        desc="Are you sure you want to delete?"
                        open={this.state.deleteModal}
                        onClose={this.deleteModalClose}
                        handleDelete={this.deleteData}
                    />
                    <div className="mb-3 text-right">
                        <input
                            type="button"
                            value="Add Quiz"
                            className={classes.addQuizBtn}
                            onClick={() => { this.moreMenuClick(null, "add") }}
                        />
                    </div>

                    <Paper
                        style={{
                            overflow: "hidden"
                        }}
                    >
                        {/* <Toolbar variant="regular" className={classes.rootToolbar}>
                            <Typography
                                display="inline"
                                className={classes.title}
                                variant="h6" >
                                Questions
                            </Typography>
                            <Tooltip arrow title="Add">
                                <IconButton style={{ float: 'right', marginLeft: "90%" }} size="small"
                                    onClick={() => { this.moreMenuClick(null, "add") }}
                                >
                                    <AddIcon
                                        style={{ backgroundColor: eLearningColor.main, color: "white" }} />
                                </IconButton>
                            </Tooltip>
                        </Toolbar> */}

                        <TableContainer>
                            <Table
                                className={classes.table}
                                size='medium'
                            >
                                <TableHead style={{ backgroundColor: eLearningColor.main }}>
                                    <TableRow>
                                        {this.state.headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                            >
                                                <TableSortLabel
                                                    className={classes.headCell}
                                                    active={this.state.orderBy === headCell.id}
                                                    direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                    onClick={this.createSortHandler(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {this.state.orderBy === headCell.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            style={{ paddingRight: '20px' }}
                                            className={classes.headCell}
                                            align="right"
                                        >Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {questionsData.map((row, index) => {
                                        // const sec = parseInt(row.video_interval, 10);
                                        // let hours = Math.floor(sec / 3600);
                                        // let minutes = Math.floor((sec - hours * 3600) / 60);
                                        // let seconds = sec - hours * 3600 - minutes * 60;
                                        // hours = hours > 0 ? hours + " hrs " : "";
                                        // minutes = minutes > 0 ? minutes + " min " : "";
                                        // seconds = seconds > 0 ? seconds + " sec" : "";
                                        // var myTime = hours + minutes + seconds;

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row._id}
                                            >
                                                <TableCell className={classes.TableCell}>{index + 1}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.question}</TableCell>
                                                <TableCell className={classes.TableCell}>
                                                    {row.correct_answer === 1 ? "A" : row.correct_answer === 2 ? "B" : row.correct_answer === 3 ? "C" : "D"}</TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}
                                                >
                                                    {moment(utcToLocal(row.created_at)).locale('en-US').format("DD MMM YYYY,hh:mm A")}
                                                </TableCell>

                                                <TableCell align="right" className={classes.TableCell}>
                                                    <MoreMenu
                                                        moreMenuClick={() => { this.moreMenuClick(row) }}
                                                        moreData={this.state.moreData}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {questionsData && questionsData.length < 1 ?
                                        <TableRecordNotFound
                                            colSpan={8}
                                            label="No Data Found.."
                                        />
                                        : ""
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableMyPagination
                            count={questionsData ? questionsData.length : 0}
                        />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Questions);