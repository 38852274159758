import {
  SET_TICKET_LOADING,
  SET_TICKET,
  ADD_TICKET,
  EDIT_TICKET,
  DELETE_TICKET
} from "./constants";

const initialState = {
  isLoading: true,
  ticketData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TICKET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TICKET:
      return {
        ...state,
        isLoading: false,
        ticketData: payload.ticketsData
      };
    case ADD_TICKET:
      return {
        ...state,
        isLoading: false,
        ticketData: [...state.ticketData, payload]
      }
    case EDIT_TICKET:
      return {
        ...state,
        isLoading: false,
        ticketData: state.ticketData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_TICKET:
      return {
        ...state,
        isLoading: false,
        ticketData: state.ticketData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;