import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Cookies from "universal-cookie";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    ListItem: {
      
        marginBottom: '3px',
        borderRadius:"100px",
        '&:hover': {
            backgroundColor: "#2759cd",
            boxShadow: "0 3px 20px #0000000b",
            transform: "matrix(1, 0, 0, 1, 0, 0)",
            borderRadius:"100px",
            color:"white",

        },
    },
    title: {
        color: "#0000008a",
        fontSize: '14px',
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "36px",
        textDecoration:'none',
        fontFamily: "century_gothic",
    },
    avatarIcons: {
        height: '30px',
        width: '30px'
    }
}));

export default function CheckboxListSecondary(props) {
    const classes = useStyles();
    const cookies = new Cookies();
    const ovCid = cookies.get('ovCid')
    return (
        <List dense disablePadding className={classes.root}>
            {props.settingsData.map((value) => {
                if (value.title === "Profile")
                    return (
                        <Link to={value.href}
                        style={{textDecoration:'none'}}>
                            <ListItem
                                className={classes.ListItem}
                                key={value} button>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        src={value.imageUrl}
                                        className={classes.avatarIcons}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        className={classes.title}
                                    >{value.title}
                                    </Typography>}
                                />
                            </ListItem>
                        </Link>
                    );
            })}
            {props.settingsData.map((value) => {
                if (value.title === "Company" && ovCid.toString() === "624e80fff8ee75131034af66")
                    return (
                        <Link to={value.href}>
                            <ListItem
                                className={classes.ListItem}
                                key={value} button>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        src={value.imageUrl}
                                        className={classes.avatarIcons}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        className={classes.title}
                                    >{value.title}
                                    </Typography>}
                                />
                            </ListItem>
                        </Link>
                    );
            })}
            {props.settingsData.map((value) => {
                if (props.permissionsData[value.permission])
                    return (
                        <Link to={value.href}
                        style={{textDecoration:'none'}}>
                            <ListItem
                                className={classes.ListItem}
                                key={value} button>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        src={value.imageUrl}
                                        className={classes.avatarIcons}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        className={classes.title}
                                    >{value.title}
                                    </Typography>}
                                />
                            </ListItem>
                        </Link>
                    );
            })}
        </List>
    );
}