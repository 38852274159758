import React from 'react'
import ReactDom from 'react-dom'
import { IoMdCloseCircle } from 'react-icons/io'
import './modal.css'

function Modal(props) {
    return(
            <div className="modal_container">
                <div className="modal_header">
                    <div className="modal_title">
                        <p>{props?.title}</p>
                    </div>
                    <div className="modal_close_icon" onClick={props.handlePopupModal}>
                        <IoMdCloseCircle size={20} color="gray" />
                    </div>
                </div>
                <hr style={{marginTop:-5}}/>
                <div className="modal_body">
                    <div>
                       
                    </div>
                    {props.children}
                </div>
            </div>
    )
}

export default Modal
