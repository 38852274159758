import {
  SET_TIMECARD_LOADING,
  SET_TIMECARD
} from "./constants";
import { invalidToken } from '../../../../utilities';
import { timecardServices } from './services'

export function fetchData(lid, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_TIMECARD_LOADING,
        payload: true
      });
    }

    timecardServices.fetchData(lid).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_TIMECARD,
          payload: response.data
        });
      }
    });
  };
}




