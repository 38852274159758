import {  HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const usersServices = {
    fetchData,
    fetchCountData,
    checkUserVerified,
    toggleActiveDeactiveUser,
    resendInvitation,
    locationUserCheckOutAndVerify,
    addData,
    editData,
    deleteData,
    loginAsUser,
    userLogin,
    userLogout,
    verifyUser,
    getNotifications,
    ExportUserData
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/users`, HEADER_TOKEN).then(response => response)
};

function fetchCountData(lid) {
    var data = {
        lid: lid
    }
    return axios.post(`${MoNGO_DB_API_URL}/users/count`, { data }, HEADER_TOKEN).then(response => response)
};

function resendInvitation(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/invitation/resend`, { data }, HEADER_TOKEN).then(response => response)
};

function toggleActiveDeactiveUser(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/verify/toggle`, { data }, HEADER_TOKEN).then(response => response)
};

function checkUserVerified(uid) {
    var data = {
        uid: uid
    }
    return axios.post(`${MoNGO_DB_API_URL}/users/verify/check`, { data }, HEADER_TOKEN).then(response => response)
};

function locationUserCheckOutAndVerify(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/location/checkout`, { data }, HEADER_TOKEN).then(response => response)
};

function userLogin(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/login`, { data }).then(response => response)
};

function loginAsUser(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/login_as_user`, { data }).then(response => response)
};

function verifyUser(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/verify`, { data }).then(response => response)
};

function userLogout() {
    return axios.post(`${MoNGO_DB_API_URL}/users/logout`, {}, HEADER_TOKEN).then(response => response)
};

function getNotifications(data) {
    return axios.get(`${MoNGO_DB_API_URL}/users/notifications`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/users/mongo/add`,  data , HEADER_TOKEN).then(response => response)
};

function editData(data, id) {
    return axios.put(`${MoNGO_DB_API_URL}/users/${id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(uid) {
    return axios.delete(`${MoNGO_DB_API_URL}/users/${uid}`, HEADER_TOKEN).then(response => response)
};

function ExportUserData () {
    return axios.get(`${MoNGO_DB_API_URL}/users/export`, HEADER_TOKEN).then(response => response)
};
