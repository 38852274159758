import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, LoadingData } from '../../../components'
import { getLocationdata, getStateData, getCityData, updateLocationdata } from './modules/actions';
import { MyTextfield } from '../../../components'
import {
    Button,
    Typography,
    Grid
}
    from '@material-ui/core';
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    mainDiv: {
        border: "2px solid #7C7C7C",
        borderRadius: "6px",
        margin: "20px",
        BoxSizing: "border-box"
    },
    HeadCell: {
        fontStyle: "normal",
        fontSize: "18px",
        fontWeight: 700,
        color: "#232056",
        marginLeft: 10
    },
    buttonMain: {
        textAlign: "right"
    },
    button: {
        background: "#005F73",
        borderRadius: 8,
        color: "white",
        padding: 10,
    },
    flex: {
        display: "flex",
        margin: "20px",
        marginBottom: "-12px"
    },
    Heading: {
        fontStyle: "normal",
        fontSize: "15px",
        fontWeight: 700,
        color: 'black',
    },
    HeadingValue: {
        fontSize: "14px",
        fontWeight: 500,
        color: '#7C7C7C',
    },
    HeadingColor: {
        fontStyle: "normal",
        fontSize: "16px",
        color: schedulerColor.main,
        fontWeight: 700
    },
    HeadingCompany: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "33px",
    },
});

export function fetchSCSettingsLocation() {
    window.LocationBasicInfo.getLocationdata();
}

class LocationBasicInfo extends Component {
    constructor(props) {
        super(props)
        window.LocationBasicInfo = this;
        this.state = {
            updateState: false,
            data: {},
            companyInfo: [],
            companyLocation: [],
            errors: {},
            errorsHelper: {}
        }
    }

    modifyData = (e) => {
        this.setState({
            updateState: !this.state.updateState,
        })
    }

    componentDidMount = () => {
        this.getLocationdata()
    }

    getLocationdata = () => {
        this.setState({
            isLoading: true
        })
        var lid = this.props.scheduler_lid
        this.props.getLocationdata(lid)
    }

    componentDidUpdate(prevProps) {
        if (this.props.locationData !== prevProps.locationData) {
            this.setState({
                isLoading: false,
                data: this.props.locationData.locationDetail,
                companyInfo: this.props.locationData.companyDetail,
                companyLocation: this.props.locationData.companyLocations
            })
        }
    }

    onSumbitForm = () => {
        this.props.updateLocationdata(this.state.data)
        this.getLocationdata()
        this.setState({
            updateState: false
        })
    }

    render() {
        const { permissionsData, classes, locationData, stateData, cityData, isLoading } = this.props;
        if (isLoading) return <LoadingData />
        const businesstyperesult = locationData.success === 1 && locationData.businessTypes.filter(businessType => businessType._id === this.state.data.location_business_type);
        const businessTypename = Object.assign({}, businesstyperesult[0]);
        var BusinessCategory = []
        locationData.success === 1 && locationData.businessCategory.map((item) => {
            var object = {
                value: item._id,
                label: item.name
            }
            BusinessCategory.push(object)
        })
        const businesscategoryresult = locationData.success === 1 && locationData.businessCategory.filter(businessCategory => businessCategory._id === this.state.data.location_business_category);
        const businessCategoryname = Object.assign({}, businesscategoryresult[0]);
        const Companydetails = locationData.success === 1 ? locationData.locationDetail : ""
        return (
            permissionsData.scheduler_settings ?
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <Button className={classes.button} onClick={this.onSumbitForm}>
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid container spacing={2}
                    >
                        <Grid item md={7} className={classes.flex}>
                            <Grid style={{ background: "#005F73", height: 10, width: 10, color: '#232056', marginTop: '5px' }}></Grid>
                            <Typography className={classes.HeadCell}>
                                Location Details
                            </Typography>
                            {/* <FiEdit style={{paddingLeft:'5px', paddingTop:'2px', color:  '#7C7C7C', cursor:'pointer' }} onClick={(e) => this.modifyData(e)} /> */}
                        </Grid>
                        <Grid item md={4} className={classes.flex}>
                            <Grid style={{ background: "#005F73", height: 10, width: 10, marginTop: '5px', color: '#232056' }}></Grid>
                            <Typography className={classes.HeadCell}>
                                Company Info
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        md={7}
                        container
                        spacing={2}
                        className={classes.mainDiv}
                    >
                        <Grid item md={12}>
                            <Grid
                                container
                                spacing={2}
                            >

                                {/* location Name */}
                                <Grid item md={5} >
                                    <Typography className={classes.HeadingColor}>
                                        Location Name
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {Companydetails.location_name}
                                    </Typography>
                                </Grid>

                                {/* Location Phone number */}
                                <Grid item md={5} >
                                    <Typography className={classes.HeadingColor}>
                                        Location Phone Number*
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {Companydetails.location_mobile_no}
                                    </Typography>
                                </Grid>

                                {/* Address 1 */}
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Address
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {Companydetails.location_address}
                                    </Typography>
                                </Grid>


                                {/* Country */}
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Country
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {Companydetails.location_country}
                                    </Typography>
                                </Grid>


                                {/* State/Province */}
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        State/Province
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>

                                    <Typography className={classes.HeadingValue} >
                                        {Companydetails.location_state}
                                    </Typography>
                                </Grid>


                                {/* City */}
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        City
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>

                                    <Typography className={classes.HeadingValue}>
                                        {Companydetails.location_city}
                                    </Typography>
                                </Grid>


                                {/* Zip/Postal Code */}
                                <Grid item md={5}>
                                    <Typography className={classes.Heading}>
                                        Zip/Postal Code
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue}>
                                        {Companydetails.location_zipcode}
                                    </Typography>
                                </Grid>


                                {/* Business Type* */}
                                <Grid item md={5} >
                                    <Typography className={classes.HeadingColor}>
                                        Business Type*
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {businessTypename.name}
                                    </Typography>
                                </Grid>


                                {/* Business Category* */}
                                <Grid item md={5} >
                                    <Typography className={classes.HeadingColor}>
                                        Business Category*
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    <Typography className={classes.HeadingValue} >
                                        {businessCategoryname.name}
                                    </Typography>
                                </Grid>


                                {/* Website */}
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Website
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue}>
                                    {this.state.updateState ?
                                        <MyTextfield
                                            label="website"
                                            value={this.state.data.location_website}
                                            variant="outlined"
                                            name="location_website"
                                            onChange={this.handleChangedata}
                                            size="small"
                                            error={this.state.errors.location_website}
                                            helperText={this.state.errors.location_website ? this.state.errorsHelper.location_website : ""}
                                        />
                                        :
                                        <Typography className={classes.HeadingValue} >
                                            {Companydetails.location_website}
                                        </Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        md={4}
                        container
                        spacing={2}
                        className={classes.mainDiv}
                    >
                        <Grid item md={12}>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Company Name
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} className={classes.HeadingValue} >
                                    {/* {this.state.companyInfo.map((item) => {
                                        return ( */}
                                    <Typography className={classes.HeadingValue}>
                                        {this.state.companyInfo.company_name}
                                    </Typography>
                                    {/* )
                                    })} */}
                                </Grid>

                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Account Owner
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} >
                                    {/* {this.state.companyInfo.map((item) => {
                                        return ( */}
                                    <Typography className={classes.HeadingValue}>
                                        {this.state.companyInfo.company_email}
                                    </Typography>
                                    {/* )
                                    })} */}
                                </Grid>

                                <Grid item md={5} >
                                    <Typography className={classes.Heading}>
                                        Phone Number
                                    </Typography>
                                </Grid>
                                <Grid item md={2} >
                                    <Typography className={classes.Heading} >
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item md={5} >
                                    {/* {this.state.companyInfo.map((item) => {
                                        return ( */}
                                    <Typography className={classes.HeadingValue}>
                                        {this.state.companyInfo.company_phone}
                                    </Typography>
                                    {/* )
                                    })} */}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={11} className={classes.flex}
                    >
                        <Grid style={{ background: "#005F73", height: 10, width: 10, color: '#232056', marginTop: '5px' }}></Grid>
                        <Typography className={classes.HeadCell}>
                            Company Locations
                        </Typography>
                    </Grid>

                    <Grid item
                        md={11}
                        className={classes.mainDiv}>
                        <Grid item md={5}>
                            {this.state.companyLocation.map((item) => {
                                return (
                                    <Typography className={classes.HeadingCompany}>
                                        {item.location_name} |
                                        {item.location_address ? item.location_address : ""}
                                        {item.location_address2 ? "," + item.location_address2 : ""}
                                        {item.city_name ? "," + item.city_name : ""}
                                        {item.state_name ? "," + item.state_name : ""}
                                        {item.country_name ? "," + item.country_name : ""}
                                        {item.location_zipcode ? "," + item.location_zipcode : ""}
                                    </Typography>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid> :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}


//schedulerSettingsLocationdata

const mapStateToProps = (state) => ({
    isLoading: state.schedulerSettingsLocationdata.isLoading,
    locationData: state.schedulerSettingsLocationdata.locationData,
    stateData: state.schedulerSettingsLocationdata.stateData,
    cityData: state.schedulerSettingsLocationdata.cityData
});

const ConnectWith = connect(mapStateToProps, { getLocationdata, getStateData, getCityData, updateLocationdata })(LocationBasicInfo);
export default withStyles(styles)(ConnectWith);