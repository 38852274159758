import {  HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const coverServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    approveRejectTeamCover,
    fetchTeamDetailsById,
    approveRejectMyCover
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/app/scheduler/cover`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/cover`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/cover/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/app/scheduler/cover/${id}`, HEADER_TOKEN).then(response => response)
};

function approveRejectTeamCover(id,data) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/cover/team/${id}`, {data},HEADER_TOKEN).then(response => response)
};

function approveRejectMyCover(id,data) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/cover/my/${id}`, {data},HEADER_TOKEN).then(response => response)
};

function fetchTeamDetailsById(id) {
    return axios.get(`${MoNGO_DB_API_URL}/app/scheduler/cover/team/625a4b804f61a6705ada5cee`, HEADER_TOKEN).then(response => response)
};
