import {
  SET_LOADING,
  SET_SC,
  // ADD_SC_ROLES,
  EDIT_SC,
  // DELETE_SC_ROLES
} from "./constants";

const initialState = {
  isLoading: true,
  // rolesData: [],
  // settingsRoles: []
  newColorPro: []
}

const Reducer = (state = initialState, { type, payload }) => {
  // var newPayload = state.settingsRolesm.productsColorsData
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC:
      return {
        ...state,
        isLoading: false,
        newColorPro: payload.ProductColor
      };
    // case EDIT_SC:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     // settingsRoles: state.settingsRoles.productsColorsData 
    //     newColorPro : state.newColorPro._id === payload._id ? payload : state.newColorPro
    //   } 
    default:
      return state;
  }
};

export default Reducer;