import { combineReducers } from 'redux';
//local
import localData from "../components/modules/reducer";

//common
import settingsCoupons from "../pages/common/Coupons/modules/reducer";
import settingsCompany from "../pages/common/Company/modules/reducer";
import settingsLocation from "../pages/common/Location/modules/reducer";
import settingsUsers from "../pages/common/UsersList/modules/reducer";
import settingsRoles from "../pages/common/Roles/modules/reducer";
import settingsTickets from "../pages/common/Tickets/modules/reducer";
import settingsIdeas from "../pages/common/Ideas/modules/reducer";
import settingsProfile from "../pages/common/Profile/modules/reducer";
import SettingsDeviceManagement from "../pages/common/DeviceManagement/modules/reducer";
import settingsProfileData from "../pages/common/Profile/modules/reducer";
import settingsCountryData from "../pages/common/Profile/modules/reducer";
import holidayData from "../pages/common/PublicHoliday/modules/reducer";

import eLearningDashboard from "../pages/eLearning/Dashboard/modules/reducer";
import eLearningCategory from "../pages/eLearning/Category/modules/reducer";
import eLearningCourses from "../pages/eLearning/Courses/modules/reducer";
import eLearningMyCourses from "../pages/eLearning/MyCourses/modules/reducer";
import eLearningLessons from "../pages/eLearning/Lessons/modules/reducer";
import eLearningHelpDesk from "../pages/eLearning/HelpDesk/modules/reducer";
import eLearningReports from "../pages/eLearning/Reports/modules/reducer";
import eLearningmyhelpdesk from "../pages/eLearning/MyHelpDesk/modules/reducer";
import eLearningTeam from "../pages/eLearning/Team/modules/reducer";
import eLearningGrading from "../pages/eLearning/Grading/modules/reducer";
import eLearningSetting from "../pages/eLearning/Settings/modules/reducer";

import feedbackDashboard from "../pages/Feedback/Dashboard/modules/reducer";
import feedbackCampaign from "../pages/Feedback/CampaignManagement//modules/reducer";
import feedbackFeedbackQR from "../pages/Feedback/FeedbackQR/modules/reducer";
import feedbackFollowups from "../pages/Feedback/Followups/modules/reducer";
import feedbackReports from "../pages/Feedback/Reports/modules/reducer";
//new feedback
import feedbackWorkFlowAutomation from "../pages/Feedback/WorkFlowAutomation/modules/reducer";
import feedbackBusinessLocations from "../pages/Feedback/BusinessLocations/modules/reducer";
import feedbackAutoTags from "../pages/Feedback/AutoTag/modules/reducer";
import schedulerSchedule from "../pages/Scheduler/Schedule/modules/reducer";
import schedulerTeam from "../pages/Scheduler/Team/modules/reducer";
import schedulerDepartments from "../pages/Scheduler/Departments/modules/reducer";
import schedulerRoles from "../pages/Scheduler/Roles/modules/reducer";
import schedulerTimeOffRequest from "../pages/Scheduler/TimeOffRequest/modules/reducer";
import schedulerTimeOffHistory from "../pages/Scheduler/TimeOffHistory/modules/reducer";
import schedulerTradeShift from "../pages/Scheduler/TradeShift/modules/reducer";
import schedulerCoverShift from "../pages/Scheduler/CoverShift/modules/reducer";
import schedulerSettingsLocationdata from "../pages/Scheduler/Settings/modules/reducer"
import todoDashboard from "../pages/Todo/Dashboard/modules/reducer";
import schedulerPayrollTemplate from "../pages/Scheduler/PayrollTemplate/modules/reducer";
import schedulerTimecode from "../pages/Scheduler/TimeCode/modules/reducer";
import schedulerTimecard from "../pages/Scheduler/Timecard/modules/reducer";
import schedulerTimsheets from "../pages/Scheduler/Timesheets/modules/reducer";
import schedulerPayrollSummary from '../pages/Scheduler/PayrollSummary/modules/reducer';
import schedulerPayrollManager from '../pages/common/PayrollManager/modues/reducer';
import schedulerDashboard from '../pages/Scheduler/Dashboard/modules/reducer';
import viewUserData from "../pages/Scheduler/Schedule/modules/reducer";
import newsUpdateNews from "../pages/News/NewsUpdate/modules/reducer";
import HierarachyChart from "../pages/ovHR/Hierarachy_chart/modules/reducers"
import CorrectionRequest from "../pages/Scheduler/CorrectRequest/modules/reducer"
import mycorrectionRequest from "../pages/Scheduler/MyCorrectionRequest/modules/reducer"
import ovrating from "../pages/Rating/modules/reducer"
import newColorPro from "../pages/common/ProductColor/modules/reducer"
// import MyAvailability from "../pages/Scheduler/MyAvailability/modules/reducer"
// import rating from "../pages/News/NewsUpdate/modules/reducer";
import newProduct from "../pages/productList/modules/reducer"

const rootReducer = combineReducers({
    newProduct,
    localData,
    todoDashboard,
    viewUserData,
    settingsCoupons,
    SettingsDeviceManagement,
    settingsCompany,
    settingsLocation,
    settingsUsers,
    holidayData,
    settingsRoles,
    settingsTickets,
    settingsIdeas,
    settingsProfile,
    settingsProfileData,
    settingsCountryData,
    feedbackDashboard,
    feedbackCampaign,
    feedbackFollowups,
    feedbackReports,
    feedbackFeedbackQR,

    feedbackWorkFlowAutomation,
    feedbackBusinessLocations,
    feedbackAutoTags,
    schedulerPayrollManager,
    eLearningCourses,
    eLearningMyCourses,
    eLearningCategory,
    eLearningDashboard,
    eLearningLessons,
    eLearningHelpDesk,
    eLearningReports,
    eLearningmyhelpdesk,
    eLearningTeam,
    eLearningGrading,
    eLearningSetting,

    schedulerSchedule,
    schedulerTeam,
    schedulerRoles,
    schedulerTimeOffRequest,
    schedulerTimeOffHistory,
    schedulerTradeShift,
    schedulerCoverShift,
    schedulerSettingsLocationdata,
    schedulerDepartments,
    schedulerPayrollTemplate,
    schedulerTimecode,
    schedulerTimecard,
    schedulerTimsheets,
    schedulerPayrollSummary,
    schedulerDashboard,
    newsUpdateNews,
    HierarachyChart,
    ovrating,
    CorrectionRequest,
    mycorrectionRequest,
    newColorPro,
    // MyAvailability,

})

export default rootReducer;