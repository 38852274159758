import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
        Button,
        Grid,
        Typography,
        Divider,
        TextField
}
        from '@material-ui/core';
import {
        LessonTypeCard, LoadingData, Confirmation, MyEditor
} from ".";
import { eLearningColor, FILE_URL, schedulerColor } from "../config";
import DeleteIcon from '@mui/icons-material/Delete';
import AddContent from "../pages/eLearning/HelpDesk/AddContent"
import Questions from "../pages/eLearning/Lessons/Questions"
import Bookmarks from "../pages/eLearning/Lessons/Bookmarks";
import { file_slide } from "../icons/index"
import VideocamIcon from '@mui/icons-material/Videocam';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { FaPlus } from "react-icons/fa";
import CloudComputingIcon from "../assets/images/cloud-computing.png";

const styles = (theme) => ({
        root: {
                padding: theme.spacing(3)
        },
        editor: {
                position: 'sticky',
                top: '5rem',
        },
        button: {
                zIndex: 99,
                backgroundColor: "#ffffff",
                color: "#000000",
                padding: 0,
                // marginTop: "11px",
                fontWeight: "400",
                justifyContent: "center",
                marginLeft: "10px",
                fontSize: '10px',
                width: "10px",
                height: "30px",
                cursor: "pointer",
                '&:hover': {
                        backgroundColor: '#ffffff',
                }
        },
        button1: {
                zIndex: 99,
                backgroundColor: "#ffffff",
                color: "#000000",
                padding: 0,
                marginTop: "11px",
                marginLeft: "-65%",
                marginLeft: "24%",
                fontWeight: "400",
                fontSize: '10px',
                width: "10px",
                height: "30px",
                cursor: "pointer",
                '&:hover': {
                        backgroundColor: '#ffffff',
                }
        },
        box: {
                justifyContent: "left",
                display: "flex",
                cursor: 'pointer',
                border: '1px solid #D9D9D9',
                backgroundColor: '#D9D9D9',
                // padding: '3rem',
                borderRadius: "5px",
                marginLeft: "10px",
                height: "110px",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "120px"
        },
        box2: {
                justifyContent: "left",
                cursor: 'pointer',
                border: '1px solid #eeeeee',
                backgroundColor: '#fcfcfc',
                padding: '1rem',
                marginTop: "5%",
                borderRadius: "5px",
                '&:hover': {
                        backgroundColor: eLearningColor.main,
                        color: "#ffffff"
                }
        },
        centerdialog: {
                width: "900px",
                maxWidth: "900px",
                height: "550px"
        },
        previousIcon: {
                backgroundColor: '#ffffff',
                padding: '1rem',
                top: '50%',
                left: '9rem',
                position: 'fixed',
                '&:hover': {
                        backgroundColor: '#ffffff',
                }
        },
        videoButton: {
                textTransform: "capitalize",
                padding: "10px",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: eLearningColor.main,
                border: "1px solid #CCCCCC",
                boxSizing: "borderBox",
                boxShadow:
                        "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
                borderRadius: "6px",
                "&:hover": {
                        backgroundColor: eLearningColor.main,
                }
        },
        videoButtons: {
                textTransform: "capitalize",
                padding: "10px",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: eLearningColor.main,
                border: "1px solid #CCCCCC",
                boxSizing: "borderBox",
                boxShadow:
                        "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
                borderRadius: "6px",
                "&:hover": {
                        backgroundColor: eLearningColor.main,
                }
        },
        videoButtonUpload: {
                textTransform: "capitalize",
                padding: "10px",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: eLearningColor.main,
                border: "1px solid #CCCCCC",
                boxSizing: "borderBox",
                boxShadow:
                        "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
                borderRadius: "6px",
                "&:hover": {
                        backgroundColor: eLearningColor.main,
                }
        },
        uploadFileDiv: {
                position: "relative",
                height: "340px",
                border: "1px solid #DFDFDF",
                borderRadius: "6px"
        },
        uploadIcon: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
        },
        uploadFileText: {
                fontSize: "28px",
                fontWeight: "400",
                color: "#5A5A5A"
        },
        videoButtonUploadsss: {
                textTransform: "capitalize",
                padding: "10px",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: eLearningColor.main,
                border: "1px solid #CCCCCC",
                boxSizing: "borderBox",
                boxShadow:
                        "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
                borderRadius: "6px",
                "&:hover": {
                        backgroundColor: eLearningColor.main,
                }
        },
        nextIcon: {
                backgroundColor: '#ffffff',
                padding: '1rem',
                top: '50%',
                right: '9rem',
                position: 'fixed',
                '&:hover': {
                        backgroundColor: '#ffffff',
                }
        },
        btnPrimary: {
                backgroundColor: schedulerColor.main,
                padding: "5px",
                // marginLeft: '2px',
                // padding: '7px 80px',
                color: '#fff',
                '&:hover': {
                        cursor: "pointer",
                        border: '1px solid'+`${schedulerColor.main}`,
                        color: '#fff'
                }
        },
        slidetitle: {
                display: "flex",
                justifyContent: "center",
                // marginTop: "7%",
                // marginBottom: "-7%",
                alignItems: "center",
                textAlign: "center"
        },
        tit: {
                fontSize: "large",
                fontWeight: "600"
        },
        addSlideBtn: {
                marginTop: "10px",
                width: "100%",
                color: "white",
                fontWeight: "500"
        }
});

class SliderDemo extends Component {
        constructor(props) {
                super(props);
                this.state = {
                        selectedType: null,
                        errors: {},
                        errorsHelper: {},
                        selectedDocsFile: null,
                        addContentDropdown: [
                                {
                                        id: "content",
                                        label: "content",
                                        title: "Content",
                                        sortName: "add_content",
                                        type: 1,
                                },
                                {
                                        id: "video",
                                        label: "video",
                                        title: "Video",
                                        sortName: "add_video",
                                        type: 2,
                                },
                                {
                                        id: "docs",
                                        label: "docs",
                                        title: "PPT",
                                        sortName: "add_docs",
                                        type: 3,
                                },
                        ],
                        // removeModal: false
                }
        }



        render() {
                const { classes, myCurrentSlide, mySlides, isLoading } = this.props;
                const { selectType } = this.state;
                //  if (isLoading) return <LoadingData />
                return (
                        <Grid spacing={3} container className={classes.root} >
                                {this.props.isEditlesson === 1 ?
                                        <Grid container spacing={1}>
                                                <Grid item
                                                       md={2}
                                                       sm={12}
                                                       xs={12}
                                                        style={{ position: "relative" }} >
                                                        {/* <Grid
                                                                item
                                                                sm={1}
                                                                xs={12}
                                                                className="ml-auto"
                                                                style={{ paddingBottom: "0.5%", textAlign: "right" }}>
                                                                <Button
                                                                        style={{ backgroundColor: eLearningColor.main, minWidth: "unset", borderRadius: "50%" }}
                                                                        onClick={this.props.addContentOpen}
                                                                        className={classes.btnPrimary}>
                                                                        <FaPlus />
                                                                </Button>
                                                        </Grid> */}

                                                        {this.props.toolsAnchor ?
                                                                <AddContent
                                                                        addContentDropdown={this.state.addContentDropdown}
                                                                        toolsAnchor={this.props.toolsAnchor}
                                                                        slides={this.props.myItem}
                                                                        setSelectType={this.props.setSelectTypeOld}
                                                                        myCurrentSlide={this.props.myCurrentSlide}
                                                                        addContentClose={this.props.addContentClose}
                                                                /> : ""}

                                                        {/* FOR EDIT SLIDES */}
                                                        {this.props.myItem && this.props.myItem?.map((item, index) => {
                                                                console.log("datataa",item)
                                                                return (
                                                                        <Grid
                                                                                container
                                                                                style={{
                                                                                        marginTop: "10px"
                                                                                }}>
                                                                                <Grid style={{
                                                                                        margingTop: "5%",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        width: "100%"
                                                                                }}>
                                                                                        <>
                                                                                                {/* <div>
                                                                        {item.questions.length > 0 && <AssignmentIcon style={{fontSize: "200%"}}/> }
                                                                </div> */}
                                                                                                <div style={{ alignItems: "end" }}>
                                                                                                        {item.lesson_type === 1 ? <AssignmentIcon style={{ fontSize: "200%" }} /> : item.lesson_type === 2 ? <VideocamIcon style={{ fontSize: "200%" }} /> : <FileCopyIcon style={{ fontSize: "200%" }} />}
                                                                                                </div>
                                                                                        </>


                                                                                        <Grid
                                                                                                key={index}
                                                                                                style={{ backgroundColor: myCurrentSlide === index ? eLearningColor.main : '#fcfcfc', justifyContent: "center", textAlign: "center" }}
                                                                                                onClick={() => this.props.currentSlide(index, item._id)}
                                                                                                // onClick={() => this.props.handleChangeSlideEditor(index)}
                                                                                                className={classes.box}
                                                                                                item md={12} >
                                                                                                <h1 className="mb-0">{index + 1}</h1>
                                                                                                <Button
                                                                                                        onClick={() => { this.props.deleteSlideOld(index, item._id) }}
                                                                                                        size="small"
                                                                                                        className={classes.button}
                                                                                                        variant="contained"
                                                                                                ><DeleteIcon /></Button>
                                                                                        </Grid>
                                                                                </Grid>

                                                                        </Grid>
                                                                )
                                                        })}
                                                        
                                                        <Grid
                                                                item
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className="ml-auto"
                                                                style={{ paddingBottom: "0.5%", textAlign: "right" }}>
                                                                <Button
                                                                        style={{ backgroundColor: eLearningColor.main, minWidth: "unset" }}
                                                                        onClick={this.props.addContentOpen}
                                                                        className={classes.addSlideBtn}>
                                                                        Add Slide
                                                                </Button>
                                                                {this.props.errorSlide ? (
                                                                        <Typography variant="body2" style={{ color: "red", paddingLeft: '65px' }}>
                                                                        {this.props.errorSlideHelp}
                                                                        </Typography>
                                                                ) : (
                                                                        " "
                                                                )}
                                                        </Grid>
                                                </Grid>

                                                <Grid
                                                        item
                                                        md={10}
                                                        sm={12}
                                                        xs={12} style={{ textAlign: "center", justifyContent: "center", padding: "0px 30px" }}>
                                                        {this.props.myItem && this.props.myItem?.map((item, index) => {
                                                                if (myCurrentSlide === index)
                                                                        return (
                                                                                item.lesson_type ?
                                                                                        <Grid className={classes.editor}>
                                                                                                <Grid className={classes.slidetitle}>
                                                                                                        <Typography className={classes.tit}>Slide Title : </Typography> &nbsp; &nbsp;
                                                                                                        <TextField
                                                                                                                id="outlined-basic"
                                                                                                                label="Slide Title"
                                                                                                                style={{ width: "60%" }}
                                                                                                                variant="outlined"
                                                                                                                name="slide_title"
                                                                                                                value={item.slide_title}
                                                                                                                onChange={(e) => this.props.handleChangeSlideTitleOLD(e, index)}
                                                                                                        />
                                                                                                </Grid>
                                                                                                {item.lesson_type === 1 ?
                                                                                                        <Grid container style={{ marginTop: "30px", display: "block" }}>

                                                                                                                <MyEditor
                                                                                                                        value={item.content}
                                                                                                                        handleEditor={(e) => this.props.handleChangeSlideEditorOLD(e, index)}
                                                                                                                />

                                                                                                                {/* upload video - parms name videoUrl
                                                                                                        upload pptx - params name pptUrl */}


                                                                                                        </Grid>
                                                                                                        :
                                                                                                        item.lesson_type === 2 ?
                                                                                                                <Grid container>
                                                                                                                        <Grid item sm={12} xs={12} style={{ marginTop: "30px" }}>
                                                                                                                                <input
                                                                                                                                        accept="video/mp4,video/x-m4v,video/*"
                                                                                                                                        style={{ display: "none" }}
                                                                                                                                        type="file"
                                                                                                                                        id="text-button-file"
                                                                                                                                        className="preview_video_input"
                                                                                                                                        onChange={(e) => { this.props.handleChangeVideoUrlOld(e, index) }}
                                                                                                                                />

                                                                                                                                <label htmlFor="text-button-file" className="w-100"
                                                                                                                                        style={{
                                                                                                                                                cursor: "pointer"
                                                                                                                                        }}>
                                                                                                                                        <div className={classes.uploadFileDiv}>
                                                                                                                                                <div className={classes.uploadIcon}>
                                                                                                                                                        <img src={CloudComputingIcon} width="90px" height="90px" />
                                                                                                                                                        <p className={"m-0 " + classes.uploadFileText}>{item.videoUrl ? "Update Video" : "Upload Video"}</p>
                                                                                                                                                </div>
                                                                                                                                                {/* <Button
                                                                                                                                                        fullWidth
                                                                                                                                                        variant="contained"
                                                                                                                                                        color="primary"
                                                                                                                                                        component="span"
                                                                                                                                                        className={classes.videoButtonUpload}
                                                                                                                                                >
                                                                                                                                                        {item.videoUrl ? "Update Video" : "Upload Video"}
                                                                                                                                                </Button> */}
                                                                                                                                        </div>
                                                                                                                                </label>
                                                                                                                                {/* <label htmlFor="text-button-file">
                                                                                                                                        <Button
                                                                                                                                                fullWidth
                                                                                                                                                variant="contained"
                                                                                                                                                color="primary"
                                                                                                                                                component="span"
                                                                                                                                                className={classes.videoButtonUpload}
                                                                                                                                        >
                                                                                                                                                {item.videoUrl ? "Update Video" : "Upload Video"}
                                                                                                                                        </Button>
                                                                                                                                </label> */}
                                                                                                                                <br />
                                                                                                                                {isLoading ? <LoadingData /> : ""}
                                                                                                                                <br />
                                                                                                                                {item.videoUrl ?
                                                                                                                                        <Button
                                                                                                                                                onClick={() => this.props.removeVideo(this.props.data._id, index)}
                                                                                                                                                style={{ marginTop: "5rem", display: "initial" }}
                                                                                                                                                fullWidth
                                                                                                                                                variant="contained"
                                                                                                                                                color="primary"
                                                                                                                                                component="span"
                                                                                                                                                className={classes.videoButtonUpload}
                                                                                                                                        >
                                                                                                                                                Remove Video
                                                                                                                                        </Button> : ""}


                                                                                                                                <Grid style={{
                                                                                                                                        display: item.videoUrl || this.props.selectedVideoFile ? "flex"
                                                                                                                                                : "none",
                                                                                                                                }} item sm={12} xs={12} >
                                                                                                                                        <br />
                                                                                                                                        <video
                                                                                                                                                controls
                                                                                                                                                style={{ width: "100%", height: "400px", marginTop: "3%" }}
                                                                                                                                                id="prevideo"
                                                                                                                                                type="video/mp4/mpeg-4/webm/mov/wmv"
                                                                                                                                        >
                                                                                                                                                
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        // type="video/mp4"
                                                                                                                                                        type="video/mp4"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/mpeg"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/mpeg-4"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/mov"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/wmv"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/avi"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/avchd"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/flv"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/f4v"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/swf"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/mvk"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/webm"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/html5"
                                                                                                                                                />
                                                                                                                                                <source
                                                                                                                                                        class="preview_video_src"
                                                                                                                                                        src={item.videoUrl}
                                                                                                                                                        type="video/mpeg-2"
                                                                                                                                                />
                                                                                                                                        </video>
                                                                                                                                </Grid>

                                                                                                                        </Grid>

                                                                                                                </Grid>
                                                                                                                :
                                                                                                                // <Grid container>
                                                                                                                //         <Grid item sm={12} xs={12}>
                                                                                                                //         {item.documenturl ?
                                                                                                                //         <Button
                                                                                                                //         style={{ cursor: "pointer", marginBottom: "1rem", backgroundColor: eLearningColor.main, color: 'white' }}
                                                                                                                //         variant="h6"
                                                                                                                //         onClick={() => this.props.downloadFile(item.documenturl)}
                                                                                                                //         >
                                                                                                                //                  Click here to download
                                                                                                                //          </Button>
                                                                                                                //         : ""}
                                                                                                                //         <br/>
                                                                                                                //         <input
                                                                                                                //                 style={{ display: "none" }}
                                                                                                                //                 type="file"
                                                                                                                //                 id="text-button-file-docs"
                                                                                                                //                 onChange={(e) => { this.props.handleChangeDocsOld(e, index) }}
                                                                                                                //         />
                                                                                                                //         <label htmlFor="text-button-file-docs">
                                                                                                                //                 <Button
                                                                                                                //                 fullWidth
                                                                                                                //                 variant="contained"
                                                                                                                //                 color="primary"
                                                                                                                //                 component="span"
                                                                                                                //                 className={classes.videoButtonUpload}
                                                                                                                //                 >
                                                                                                                //                         Upload New Docs
                                                                                                                //                 </Button>
                                                                                                                //         </label>
                                                                                                                // </Grid>
                                                                                                                <Grid container spacing={2} style={{ padding: "15px 0px" }}>
                                                                                                                        <Grid item sm={12} xs={12} style={{ marginTop: "8%" }}>
                                                                                                                                <input
                                                                                                                                        style={{ display: "none" }}
                                                                                                                                        type="file"
                                                                                                                                        id="text-button-file-pptx"
                                                                                                                                        className="preview_pptx_input"
                                                                                                                                        onChange={(e) => { this.props.handleChangePptxOld(e, index) }}
                                                                                                                                />

                                                                                                                                <label htmlFor="text-button-file-pptx" className="w-100"
                                                                                                                                        style={{
                                                                                                                                                cursor: "pointer"
                                                                                                                                        }}>
                                                                                                                                        <div className={classes.uploadFileDiv}>
                                                                                                                                                <div className={classes.uploadIcon}>
                                                                                                                                                        <img src={CloudComputingIcon} width="90px" height="90px" />
                                                                                                                                                        <p className={"m-0 " + classes.uploadFileText}>{item.documenturl ? "Update PPT" : "Upload PPT"}</p>
                                                                                                                                                </div>
                                                                                                                                                {/* <Button
                                                                                                                                                        fullWidth
                                                                                                                                                        variant="contained"
                                                                                                                                                        color="primary"
                                                                                                                                                        component="span"
                                                                                                                                                        className={classes.videoButtonUpload}
                                                                                                                                                >
                                                                                                                                                        {item.documenturl ? "Update PPT" : "Upload PPT"}
                                                                                                                                                </Button> */}
                                                                                                                                        </div>
                                                                                                                                </label>

                                                                                                                                {/* {item.documenturl !== "" ? (
                                                                <Typography variant="body2" style={{ color: "red" }}>
                                                                        {item.documenturl}
                                                                </Typography>
                                                        ) :
                                                                ""
                                                        } */}

                                                                                                                                {/* {item.pptUrl ?
                                                                <Button
                                                                        onClick={(e) => this.props.removePPT(e, index)}
                                                                        style={{ marginTop: "5rem" }}
                                                                        fullWidth
                                                                        variant="contained"
                                                                        color="primary"
                                                                        component="span"
                                                                        className={classes.videoButton}
                                                                >
                                                                        Remove PPT
                                                                </Button> : ""} */}
                                                                                                                        </Grid>
                                                                                                                        <Grid item sm={10} xs={12} style={{ marginLeft: "30px" }}>
                                                                                                                                {/* <Grid
                                                                item
                                                                sm={12}
                                                                xs={12}
                                                                style={{marginLeft: "20%"}}
                                                                > */}

                                                                                                                                {
                                                                                                                                        item.documenturl && (
                                                                                                                                                <iframe
                                                                                                                                                        id="myIframe"
                                                                                                                                                        class="preview_pptx_src"
                                                                                                                                                        height="400px"
                                                                                                                                                        width="100%"
                                                                                                                                                        src={
                                                                                                                                                                `https://view.officeapps.live.com/op/embed.aspx?src=` +

                                                                                                                                                                item.documenturl
                                                                                                                                                        }
                                                                                                                                                        title="description"
                                                                                                                                                />
                                                                                                                                        )}
                                                                                                                                {/* </Grid> */}

                                                                                                                                <Grid
                                                                                                                                        item
                                                                                                                                        sm={12}
                                                                                                                                        xs={12}
                                                                                                                                >
                                                                                                                                        <Typography variant="body1" style={{ marginTop: "1rem", color: "red", paddingLeft: "44%" }}>
                                                                                                                                                OV eLearning or Helpdesk supports PPTs less than 40 MB size.
                                                                                                                                        </Typography>
                                                                                                                                </Grid>
                                                                                                                        </Grid>
                                                                                                                </Grid>
                                                                                                }
                                                                                                {item.lesson_type === 2 && item.videoUrl &&
                                                                                                        <Bookmarks
                                                                                                                pageNo={this.props.pageNo}
                                                                                                                rowsPerPage={this.props.rowsPerPage}
                                                                                                                deleteBookmark={this.props.deleteBookmark}
                                                                                                                addBookmark={this.props.addBookmark}
                                                                                                                editBookmark={this.props.editBookmark}
                                                                                                                bookmarksData={this.props.bookmarksData}
                                                                                                                slideid={item._id}
                                                                                                                videoUrl={item.videoUrl}
                                                                                                        />}
                                                                                                <Questions
                                                                                                        pageNo={this.props.pageNo}
                                                                                                        rowsPerPage={this.props.rowsPerPage}
                                                                                                        deleteQuestion={this.props.deleteQuestion}
                                                                                                        addQuestion={this.props.addQuestion}
                                                                                                        editQuestion={this.props.editQuestion}
                                                                                                        questionsData={this.props.questionsData}
                                                                                                        isLoading={this.props.isLoading}
                                                                                                        slideid={item._id}
                                                                                                        question_type={item.lesson_type}
                                                                                                />
                                                                                        </Grid>
                                                                                        :
                                                                                        ""

                                                                        )
                                                        })}
                                                </Grid>
                                        </Grid>
                                        :

                                        //   For add lesson
                                        <>
                                                <Grid item
                                                        md={2}
                                                        sm={3}
                                                        xs={12}
                                                        style={{ position: "relative" }} >
                                                        {/* <Grid
                                                                item
                                                                sm={1}
                                                                xs={12}
                                                                className="ml-auto"
                                                                style={{ paddingBottom: "0.5%", justifyContent: "center", textAlign: "right" }}>
                                                                <Button
                                                                        style={{ backgroundColor: eLearningColor.main, minWidth: "unset", borderRadius: "50%" }}
                                                                        onClick={this.props.addContentOpen}
                                                                        className={classes.btnPrimary}>
                                                                        <FaPlus />
                                                                </Button>
                                                        </Grid> */}
                                                        {this.props.toolsAnchor ?
                                                                <AddContent
                                                                        addContentDropdown={this.state.addContentDropdown}
                                                                        toolsAnchor={this.props.toolsAnchor}
                                                                        slides={mySlides}
                                                                        setSelectType={this.props.setSelectType}
                                                                        myCurrentSlide={this.props.myCurrentSlide}
                                                                        addContentClose={this.props.addContentClose}
                                                                /> : ""}
                                                        <Grid
                                                                container
                                                        >
                                                                {mySlides.map((item, index) => {
                                                                        return (
                                                                                <Grid style={{
                                                                                        marginTop: "10px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        width: "100%"
                                                                                }}>
                                                                                        <>
                                                                                                <div>
                                                                                                        {item.lesson_type === 1 ? <AssignmentIcon style={{ fontSize: "200%", marginLeft: "-5%" }} /> : item.lesson_type === 2 ? <VideocamIcon style={{ fontSize: "200%", marginLeft: "-5%" }} /> : <FileCopyIcon style={{ fontSize: "200%", marginLeft: "-5%" }} />}
                                                                                                </div>
                                                                                        </>
                                                                                        <Grid

                                                                                                key={index}
                                                                                                style={{
                                                                                                        backgroundColor: '#D9D9D9',
                                                                                                        border: myCurrentSlide === index ? "2px solid " + eLearningColor.main : "1px solid #D9D9D9"
                                                                                                }}
                                                                                                onClick={() => this.props.currentSlide(index, item._id)}
                                                                                                // onClick={() => this.props.handleChangeSlideEditor(index)}
                                                                                                className={classes.box}
                                                                                                item md={12} >
                                                                                                {/* <Button
                                                                                onClick={() => { this.props.deleteSlide(index,item._id) }}
                                                                                size="small"
                                                                                className={classes.button1}
                                                                                variant="contained"
                                                                        ><DeleteIcon/></Button> */}
                                                                                                <h1 className="mb-0">{index + 1}</h1>
                                                                                                <Button
                                                                                                        onClick={() => { this.props.deleteSlide(index, item._id) }}
                                                                                                        size="small"
                                                                                                        className={classes.button}
                                                                                                        variant="contained"
                                                                                                ><DeleteIcon /></Button>

                                                                                        </Grid>
                                                                                </Grid>
                                                                        )
                                                                })}
                                                        </Grid>
                                                        <Grid
                                                                item
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className="ml-auto"
                                                                style={{ paddingBottom: "0.5%", textAlign: "right" }}>
                                                                <Button
                                                                        style={{ backgroundColor: eLearningColor.main, minWidth: "unset" }}
                                                                        onClick={this.props.addContentOpen}
                                                                        className={classes.addSlideBtn}>
                                                                        Add Slide
                                                                </Button>
                                                                {this.props.errorSlide ? (
                                                                        <Typography variant="body2" style={{ color: "red", paddingLeft: '65px' }}>
                                                                        {this.props.errorSlideHelp}
                                                                        </Typography>
                                                                ) : (
                                                                        " "
                                                                )}
                                                        </Grid>
                                                </Grid>
                                                <Grid
                                                        item
                                                        md={10}
                                                        sm={9}
                                                        xs={12}
                                                        style={{ textAlign: "center", justifyContent: "center", padding: "inherit" }}>
                                                        {mySlides.map((item, index) => {
                                                                if (myCurrentSlide === index)
                                                                        return (
                                                                                <Grid className={classes.editor}>
                                                                                        <Grid className={classes.slidetitle}>
                                                                                                <Typography className={classes.tit}>Slide Title : </Typography> &nbsp; &nbsp;
                                                                                                <TextField
                                                                                                        id="outlined-basic"
                                                                                                        label="Slide Title"
                                                                                                        style={{ width: "60%" }}
                                                                                                        variant="outlined"
                                                                                                        name="slide_title"
                                                                                                        value={item.slide_title}
                                                                                                        onChange={(e) => this.props.handleChangeSlideTitle(e, index)}
                                                                                                />
                                                                                        </Grid>
                                                                                        {item.lesson_type ?
                                                                                                <Grid container spacing={1} style={{ marginTop: "30px" }}>
                                                                                                        {item.lesson_type === 1 ?
                                                                                                                <Grid container style={{ justifyContent: "center", textAlign: "center", display: "block" }}>
                                                                                                                        <MyEditor
                                                                                                                                value={item.content}
                                                                                                                                handleEditor={(e) => this.props.handleChangeSlideEditor(e, index)}
                                                                                                                        />
                                                                                                                </Grid>


                                                                                                                : item.lesson_type === 2 ?
                                                                                                                        <Grid container>
                                                                                                                                <Grid item sm={12} xs={12} style={{ margin: "auto" }}>
                                                                                                                                        <input
                                                                                                                                                accept="video/mp4,video/x-m4v,video/*"
                                                                                                                                                style={{ display: "none" }}
                                                                                                                                                type="file"
                                                                                                                                                id="text-button-file"
                                                                                                                                                className="preview_video_input"
                                                                                                                                                onChange={(e) => { this.props.handleChangeVideoUrl(e, index) }}
                                                                                                                                        />

                                                                                                                                        <label htmlFor="text-button-file" className="w-100"
                                                                                                                                                style={{
                                                                                                                                                        cursor: "pointer"
                                                                                                                                                }}>
                                                                                                                                                <div className={classes.uploadFileDiv}>
                                                                                                                                                        <div className={classes.uploadIcon}>
                                                                                                                                                                <img src={CloudComputingIcon} width="90px" height="90px" />
                                                                                                                                                                <p className={"m-0 " + classes.uploadFileText}>{item.videoUrl ? "Update Video" : "Upload Video"}</p>
                                                                                                                                                        </div>
                                                                                                                                                        {/* <Button
                                                                                                                                                                fullWidth
                                                                                                                                                                variant="contained"
                                                                                                                                                                color="primary"
                                                                                                                                                                component="span"
                                                                                                                                                                className={classes.videoButtonUpload}
                                                                                                                                                        >
                                                                                                                                                                {item.videoUrl ? "Update Video" : "Upload Video"}
                                                                                                                                                        </Button> */}
                                                                                                                                                </div>
                                                                                                                                        </label>
                                                                                                                                        {isLoading ? <LoadingData /> : ""}

                                                                                                                                        <Grid style={{
                                                                                                                                                display: item.videoUrl || this.props.selectedVideoFile ? "flex"
                                                                                                                                                        : "none",
                                                                                                                                        }} item sm={12} xs={12} >
                                                                                                                                                <video
                                                                                                                                                        controls
                                                                                                                                                        style={{ width: "100%", height: "400px" }}
                                                                                                                                                        id="prevideo"
                                                                                                                                                >
                                                                                                                                             
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mp4"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mpeg"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mpeg-4"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mov"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/wmv"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/avi"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/avchd"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/flv"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/f4v"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/swf"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mvk"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/webm"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/html5"
                                                                                                                                                        />
                                                                                                                                                        <source
                                                                                                                                                                class="preview_video_src"
                                                                                                                                                                src={item.videoUrl}
                                                                                                                                                                type="video/mpeg-2"
                                                                                                                                                        />

                                                                                                                                                </video>
                                                                                                                                        </Grid>

                                                                                                                                </Grid>

                                                                                                                        </Grid>
                                                                                                                        :
                                                                                                                        // <Grid container>
                                                                                                                        //         <Grid item sm={12} xs={12}>
                                                                                                                        //                   {item.documenturl ?
                                                                                                                        //                         <Button
                                                                                                                        //                           style={{ cursor: "pointer", marginBottom: "1rem", backgroundColor: eLearningColor.main, color: 'white', justifyContent: "center", textAlign: "center" }}
                                                                                                                        //                           variant="h6"
                                                                                                                        //                           onClick={() => this.props.downloadFile(item.documenturl)}
                                                                                                                        //                         >
                                                                                                                        //                           Click here to download
                                                                                                                        //                         </Button>
                                                                                                                        //                    : ""}

                                                                                                                        //                 <input
                                                                                                                        //                         style={{ display: "none" }}
                                                                                                                        //                         type="file"
                                                                                                                        //                         id="text-button-file-docs"
                                                                                                                        //                         onChange={(e) => { this.props.handleChangeDocs(e, index) }}
                                                                                                                        //                 />
                                                                                                                        //                 <label htmlFor="text-button-file-docs">
                                                                                                                        //                         <Button
                                                                                                                        //                                 fullWidth
                                                                                                                        //                                 variant="contained"
                                                                                                                        //                                 color="primary"
                                                                                                                        //                                 component="span"
                                                                                                                        //                                 className={classes.videoButtons}
                                                                                                                        //                         >
                                                                                                                        //                           Upload New Docs
                                                                                                                        //                         </Button>
                                                                                                                        //                 </label>
                                                                                                                        //                 {isLoading ?  <LoadingData /> : ""}
                                                                                                                        //         </Grid>
                                                                                                                        // </Grid>
                                                                                                                        <Grid container spacing={2} style={{ padding: "15px 0px" }}>
                                                                                                                                <Grid item sm={12} xs={12}>
                                                                                                                                        <input
                                                                                                                                                style={{ display: "none" }}
                                                                                                                                                type="file"
                                                                                                                                                id="text-button-file-pptx"
                                                                                                                                                className="preview_pptx_input"
                                                                                                                                                onChange={(e) => { this.props.handleChangePptx(e, index) }}
                                                                                                                                        />


                                                                                                                                        <label htmlFor="text-button-file-pptx" className="w-100"
                                                                                                                                                style={{
                                                                                                                                                        cursor: "pointer"
                                                                                                                                                }}>
                                                                                                                                                <div className={classes.uploadFileDiv}>
                                                                                                                                                        <div className={classes.uploadIcon}>
                                                                                                                                                                <img src={CloudComputingIcon} width="90px" height="90px" />
                                                                                                                                                                <p className={"m-0 " + classes.uploadFileText}>{item.documenturl ? "Update PPT" : "Upload PPT"}</p>
                                                                                                                                                        </div>
                                                                                                                                                        {/* <Button
                                                                                                                                                        fullWidth
                                                                                                                                                        variant="contained"
                                                                                                                                                        color="primary"
                                                                                                                                                        component="span"
                                                                                                                                                        className={classes.videoButtonUpload}
                                                                                                                                                >
                                                                                                                                                        {item.documenturl ? "Update PPT" : "Upload PPT"}
                                                                                                                                                </Button> */}
                                                                                                                                                </div>
                                                                                                                                        </label>

                                                                                                                                        {/* <label htmlFor="text-button-file-pptx">
                                                                                                                                                <Button
                                                                                                                                                        fullWidth
                                                                                                                                                        variant="contained"
                                                                                                                                                        color="primary"
                                                                                                                                                        component="span"
                                                                                                                                                        className={classes.videoButton}
                                                                                                                                                >
                                                                                                                                                        {item.documenturl ? "Update PPT" : "Upload PPT"}
                                                                                                                                                </Button>
                                                                                                                                        </label> */}
                                                                                                                                        {/* {item.documenturl !== "" ? (
                                                                                                                        <Typography variant="body2" style={{ color: "red" }}>
                                                                                                                                {item.documenturl}
                                                                                                                        </Typography>
                                                                                                                ) :
                                                                                                                        ""
                                                                                                                } */}

                                                                                                                                        {/* {item.pptUrl ?
                                                                                                                        <Button
                                                                                                                                onClick={(e) => this.props.removePPT(e, index)}
                                                                                                                                style={{ marginTop: "5rem" }}
                                                                                                                                fullWidth
                                                                                                                                variant="contained"
                                                                                                                                color="primary"
                                                                                                                                component="span"
                                                                                                                                className={classes.videoButton}
                                                                                                                        >
                                                                                                                                Remove PPT
                                                                                                                        </Button> : ""} */}
                                                                                                                                </Grid>
                                                                                                                                <Grid item sm={10} xs={12} className="py-0">
                                                                                                                                        {/* <Grid
                                                                                                                        item
                                                                                                                        sm={12}
                                                                                                                        xs={12} 
                                                                                                                        style={{marginLeft: "40%"}}
                                                                                                                        > */}

                                                                                                                                        {
                                                                                                                                                item.documenturl && (
                                                                                                                                                        <iframe
                                                                                                                                                                id="myIframe"
                                                                                                                                                                class="preview_pptx_src"
                                                                                                                                                                height="400px"
                                                                                                                                                                width="100%"
                                                                                                                                                                src={
                                                                                                                                                                        `https://view.officeapps.live.com/op/embed.aspx?src=` +

                                                                                                                                                                        item.documenturl
                                                                                                                                                                }
                                                                                                                                                                title="description"
                                                                                                                                                        />
                                                                                                                                                )}
                                                                                                                                        {/* </Grid> */}

                                                                                                                                        <Grid
                                                                                                                                                item
                                                                                                                                                sm={12}
                                                                                                                                                xs={12}
                                                                                                                                        >
                                                                                                                                                <Typography variant="body1" style={{ color: "red", textAlign: "left" }}>
                                                                                                                                                        OV eLearning or Helpdesk supports PPTs less than 40 MB size.
                                                                                                                                                </Typography>
                                                                                                                                        </Grid>
                                                                                                                                </Grid>
                                                                                                                        </Grid>
                                                                                                        }
                                                                                                        {item.lesson_type === 2 &&
                                                                                                                // item.videoUrl &&
                                                                                                                <Bookmarks
                                                                                                                        pageNo={this.props.pageNo}
                                                                                                                        rowsPerPage={this.props.rowsPerPage}
                                                                                                                        deleteBookmark={this.props.deleteBookmark}
                                                                                                                        addBookmark={this.props.addBookmark}
                                                                                                                        videoUrl={item.videoUrl}
                                                                                                                        editBookmark={this.props.editBookmark}
                                                                                                                        bookmarksData={this.props.bookmarksData}
                                                                                                                        slideid={item._id}
                                                                                                                />
                                                                                                        }
                                                                                                        <Questions
                                                                                                                pageNo={this.props.pageNo}
                                                                                                                rowsPerPage={this.props.rowsPerPage}
                                                                                                                deleteQuestion={this.props.deleteQuestion}
                                                                                                                addQuestion={this.props.addQuestion}
                                                                                                                editQuestion={this.props.editQuestion}
                                                                                                                questionsData={this.props.questionsData}
                                                                                                                isLoading={this.props.isLoading}
                                                                                                                slideid={item._id}
                                                                                                                question_type={item.lesson_type}
                                                                                                        />
                                                                                                </Grid>

                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                </Grid>

                                                                        )
                                                        })}
                                                        {/* {this.state.removeModal === true ?
                                                <Confirmation
                                                        // bgColor={settingColor.main}
                                                        desc={`Are you sure you want to remove the video`}
                                                        open={this.state.removeModal}
                                                        onClose={this.confirmModalClose}
                                                        // handleSubmit={this.removeVideo}
                                                        handleSubmit={(e) => { this.removeVideo(e) }}
                                                />
                                                : ''} */}
                                                        {/* <Confirmation
                                                // bgColor={elearningColor.main}
                                                desc={`Are you sure you want to remove the video`}
                                                open={this.props.removeModal}
                                                onClose={this.props.confirmModalClose}
                                                handleSubmit={this.props.removeVideo}
                                        /> */}
                                                </Grid>
                                        </>}
                        </Grid>

                );
        }
}

export default withStyles(styles)(SliderDemo);