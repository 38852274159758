import { API_URL, MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const myAvailabilityService = {
  fetchData,
  fetchDataById,
  fetchDataByIdNew,
  newAvailability,
  addAvailability,
  clearAvailability,
  notifyToManager,
  approveRejectStatus,
  notifyToManagerNew,
  editAvailability,
  deleteAvailability,
};

function fetchData(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/avaliblity`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchDataById(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/detail/${data.av_id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchDataByIdNew() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/getLocation/detail`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addAvailability(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/addLocation/avaliblity`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function newAvailability(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/v2/add/avaliblity`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function clearAvailability(data) {
  var av_id = data.av_id;
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/clear/${av_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function notifyToManager(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/notify/manager`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function notifyToManagerNew(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/notify/manager/new`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveRejectStatus(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/teamavailability/v2/approve`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editAvailability(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteAvailability(id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/delete/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
