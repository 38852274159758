import {
  SET_NEWSUPDATE_LOADING,
  SET_NEWSUPDATE,
  SET_ASSIGN_USERS,
  ADD_NEWS,
  EDIT_NEWS,
  SET_ASSIGN_ROLES_AND_USERS,
  DELETE_NEWS,
  ROLE_ID_DATA,
} from "./constants";

const initialState = {
  isLoading: true,
  newsUpdateData: [],
  assignUserData: [],
  assignRolesData: [],
  assignUserdataa: [],
  newsData: [],
  roleIdData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NEWSUPDATE_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_NEWSUPDATE:
      return {
        ...state,
        isLoading: false,
        newsUpdateData: payload.NewsData,
        newsData: payload,
      };
    case SET_ASSIGN_USERS:
      return {
        ...state,
        isLoading: false,
        assignUserData: payload.rolesData,
      };
    case ROLE_ID_DATA:
      return {
        ...state,
        isLoading: false,
        roleIdData: payload.rolesData,
      };
    case SET_ASSIGN_ROLES_AND_USERS:
      return {
        ...state,
        isLoading: false,
        assignRolesData: payload.rolesData,
        assignUserdataa: payload.UserData,
      };
    case ADD_NEWS:
      return {
        ...state,
        isLoading: false,
        newsUpdateData: [...state.newsUpdateData, payload],
      };
    case EDIT_NEWS:
      return {
        ...state,
        isLoading: false,
        newsUpdateData: state.newsUpdateData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    case DELETE_NEWS:
      return {
        ...state,
        isLoading: false,
        newsUpdateData: state.newsUpdateData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
