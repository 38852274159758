//import Geocode from "react-geocode";
// Geocode.setApiKey(GOOGLE_API_KEY);
// Geocode.enableDebug();
import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Circle, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '../../../config';
import { Fragment } from 'react';



class Map extends Component {
	constructor(props) {
		super(props)
		this.state = {
			options: {
				strokeColor: "#68B488",
				strokeOpacity: 0.8,
				fillColor: "#68B488",
				fillOpacity: 0.4
			},
		}
	}

	render() {
		const AsyncMap = withScriptjs(
			withGoogleMap(
				props => (
					<Fragment>
						{/* <Autocomplete
							types={['establishment']}
							style={{
								width: '50%',
								height: '40px',
								// marginTop: '2px',
								// marginBottom: '500px'
							}}
							// establishment
							onPlaceSelected={this.props.onPlaceSelected}
						/> */}
						<GoogleMap
							defaultZoom={15}
							center={{ lat: this.props.latitude, lng: this.props.longitude }}
						>
							<Marker draggable={true}
								onDragEnd={(e) => this.props.onMarkerDragEnd(e)}
								position={{ lat: this.props.latitude, lng: this.props.longitude }}
							/>
							<Circle
								center={{ lat: this.props.latitude, lng: this.props.longitude }}
								radius={parseInt(this.props.distance) === 150 ? 47.69 : parseInt(this.props.distance) === 300 ? 95.39 : parseInt(this.props.distance) === 500 ? 310.69 : parseInt(this.props.distance) === 1500 ? 476.95 : parseInt(this.props.distance) === 700 ? 370.69 : parseInt(this.props.distance) === 1000 ? 406.95 : 0}
								options={this.state.options}
							/>
						</GoogleMap>
					</Fragment>
				)
			)
		);

		let map = <AsyncMap
			googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=initMap`}
			loadingElement={
				<div style={{ height: `100%`, backgroundColor: 'yellow' }} />
			}
			containerElement={
				<div style={{
					height: '350px'
				}} />
			}
			mapElement={
				<div style={{
					height: `100%`,
					backgroundColor: 'silver'
				}} />
			}
		/>
		return (map)
	}
}

export default Map;