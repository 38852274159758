import { makeStyles, TableHead, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: '#5679F9',
  },
  tableCellRow: {
    fontSize: 15,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: 0.217535,
    color: 'white',
    border: 'none',
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    }
  },
}));



export function TableHeaderComponent(props) {
  const classes = useStyles();


  const TableHeadItem = (props) => {
    return (
      <TableCell className={classes.tableCellRow} title={props.item}>
        {props.item}
      </TableCell>
    );
  };

  return (
    <>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {props.theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </TableRow>
      </TableHead>
    </>
  );
}
