import {
  SET_ROWS,
  SET_PAGE,
  SET_SIDEBAR
} from "./constants";

const initialState = {
  pageNo: 0,
  rowsPerPage: 50,
  isSidebarOpen: true
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: payload
      };
    case SET_ROWS:
      return {
        ...state,
        rowsPerPage: payload
      };
    case SET_PAGE:
      return {
        ...state,
        pageNo: payload
      };
    default:
      return state;
  }
};

export default Reducer;
