import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchData } from "../pages/common/Location/modules/actions";
import { userPermissions } from "../pages/common/Roles/modules/actions";
import { fetchCountData } from "../pages/common/UsersList/modules/actions";
import { onChangePage, onChangeSidebar } from "../components/modules/actions";
import { userLogout, userExitAccess } from "../pages/common/UsersList/modules/actions";
import classnames from "classnames";
import Header from "./components/HeaderScheduler";
import Sidebar from "./components/Sidebar";
import LogoutMenus from "./components/LogoutMenus";
import LocationMenus from "./components/LocationMenus";
import Cookies from "universal-cookie";
import { Grid, Typography } from "@material-ui/core";
import { COOKIE_MAX_AGE } from "../config";

import { fetchScheduleData } from "../pages/Scheduler/Schedule/Schedule";
import { fetchAvailabilityData } from "../pages/Scheduler/NewAvailability/AvailabilityMain";
// import { fetchMyAvailabilityData } from "../pages/Scheduler/MyAvailability/MyAvailability";
 import { fetchNewTeamAvailabilityData } from "../pages/Scheduler/TeamAvailability/NewTeamAvalibility";
import { fetchTeamAvailabilityData } from "../pages/Scheduler/TeamAvailability/TeamAvailability";
import { fetchTimeOffRequestData } from "../pages/Scheduler/TimeOffRequest/MyTimeOffRequest";
import { fetchTimesheetsData } from "../pages/Scheduler/Timesheets/Timesheets";
import { fetchTradeShiftsData } from "../pages/Scheduler/TradeShift/TradeShift";
import { fetchCoverShiftsData } from "../pages/Scheduler/CoverShift/TeamCoverShift";
import { fetchPayrollSummaryData } from "../pages/Scheduler/PayrollSummary/PayrollSummary";
import { fetchSCTeamData } from "../pages/Scheduler/Team/Team";
import { fetchTimecardData } from "../pages/Scheduler/Timecard/Timecard";
import { fetchSCSettingsLocation } from "../pages/Scheduler/Settings/LocationBasicInfo";
import { fetchSCSettingsTimeTracking } from "../pages/Scheduler/Settings/TimeTracking";
import { fetchSCSettingsScheduling } from "../pages/Scheduler/Settings/Scheduling";
import { fetchSCSettingsPayroll } from "../pages/Scheduler/Settings/Payroll";
import { fetchSCSettingsTimeoff } from "../pages/Scheduler/Settings/TimeOffPTO";
import { LoadingData } from "../components";
import { history } from "../history";

const cookies = new Cookies();
const styles = (theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflow: "hidden",
  },
  noLocationassign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    overflowX: "hidden",
    padding: "0 2rem",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: "70px",
    width: `calc(100vw - 200px)`,
    minHeight: `calc(100vh - 70px)`,
  },
  contentShift: {
    width: `calc(100vw - ${200 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor:"#F6F6F6",
    borderRadius:'20px 20px 20px 20px'
  },
});

export function fetchSidebarCountData() {
  window.SchedulerLayout.fetchSidebarCountData();
}

class SchedulerLayout extends Component {
  constructor(props) {
    super(props);
    window.SchedulerLayout = this;
    this.state = {
      scheduler_lid: cookies.get("scheduler_lid"),
      listTitle: "",
      activeProduct: "Scheduler",
      errors: {},
      helperText: {},
    };
  }

  fetchSidebarCountData = () => {
    this.props.fetchCountData(this.state.scheduler_lid);
  };

  componentDidMount = () => {
    this.props.fetchCountData(this.state.scheduler_lid);
    var isPermissionReload = Object.keys(this.props.permissionsData).length > 0 ? false : true;
    var isLocationReload = this.props.locationData.length > 0 ? false : true;
    this.props.userPermissions(isPermissionReload);
    this.props.fetchData(isLocationReload);
    window.scrollTo(0, 0);
    this.props.onChangePage();
    this.getActiveProduct();

    if (!this.state.scheduler_lid && this.props.locationData.length) {
      this.setState({
        scheduler_lid: this.props.locationData[0]._id,
      });
      cookies.set("scheduler_lid", this.props.locationData[0]._id, {
        path: "/",
        maxAge: COOKIE_MAX_AGE,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.locationData.length &&
      JSON.stringify(prevProps.locationData) !==
      JSON.stringify(this.props.locationData)
    ) {
      var scheduler_lid = this.state.scheduler_lid;
      var myLocationArray = [];
      this.props.locationData.map((location) => {
        myLocationArray.push(location._id);
      });

      if (!myLocationArray.includes(scheduler_lid)) {
        scheduler_lid = this.props.locationData[0]._id;
        this.props.fetchCountData(scheduler_lid);
        this.setState({
          scheduler_lid,
        });
        cookies.set("scheduler_lid", scheduler_lid, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
      }
      this.props.fetchCountData(scheduler_lid);
    }
  }

  getActiveProduct = () => {
    var currentLocation = window.location.pathname;
    var productName =  "Scheduler";
    this.setState({ activeProduct: productName });
    if (productName === "Todo") {
      this.props.fetchTodoList();
    }
  };

  handleSidebarToggle = () => {
    this.props.onChangeSidebar(!this.props.isSidebarOpen)
  };

  handleLocationOpen = (event) => {
    this.setState({
      locationAnchor: event.currentTarget,
    });
  };

  handleLocationClose = (value) => {

    var scheduler_lid = value;
    if (value) {
      cookies.set("scheduler_lid", scheduler_lid, {
        path: "/",
        maxAge: COOKIE_MAX_AGE,
      });
    } else {
      scheduler_lid = cookies.get("scheduler_lid");
    }
    this.setState(
      {
        scheduler_lid,
        locationAnchor: null,
      },
      () => {
        if (value) {

          this.fetchLocationData();
        }
      }
    );
  };

  fetchLocationData = () => {
    var currentLocation = window.location.pathname;
    console.log("roman",currentLocation)
    if (currentLocation.includes("/scheduler/scheduler")) {
      fetchScheduleData();
    }
    if (currentLocation.includes("/scheduler/availability")) {
      fetchAvailabilityData();
    }
    // if (currentLocation.includes("/scheduler/MyAvailabilityNew")) {
    //   console.log('roman1','whasl')
    //   fetchMyAvailabilityData();
    // }
    if (currentLocation.includes("/scheduler/teamavailability")) {
      // fetchTeamAvailabilityData();
      fetchNewTeamAvailabilityData()
    }
    if (currentLocation.includes("/scheduler/timeoffrequest")) {
      fetchTimeOffRequestData();
    }
    if (currentLocation.includes("/scheduler/tradeshift")) {
      fetchTradeShiftsData();
    }
    if (currentLocation.includes("/scheduler/covershift")) {
      fetchCoverShiftsData();
    }
    if (currentLocation.includes("/scheduler/timesheets")) {
      fetchTimesheetsData();
    }
    if (currentLocation.includes('/scheduler/pay_summary')) {
      fetchPayrollSummaryData()
    }
    if (currentLocation.includes("/scheduler/teams")) {
      fetchSCTeamData();
    }
    if (currentLocation.includes("/scheduler/timecard")) {
      fetchTimecardData();
    }
    if (currentLocation.includes("/scheduler/settings/location")) {
      fetchSCSettingsLocation();
    }
    if (currentLocation.includes("/scheduler/settings/time_tracking")) {
      fetchSCSettingsTimeTracking();
    }
    if (currentLocation.includes("/scheduler/settings/scheduling")) {
      fetchSCSettingsScheduling();
    }
    if (currentLocation.includes('/scheduler/settings/payroll')) {
      fetchSCSettingsPayroll()
    }
    if (currentLocation.includes("/scheduler/settings/timeoff_pto")) {
      fetchSCSettingsTimeoff();
    }
  };

  handleLogoutOpen = (event) => {
    this.setState({
      logoutAnchor: event.currentTarget,
    });
  };

  handleLogoutClose = (value) => {
    if (value === "exit_access") {
      this.props.userExitAccess();
    }
    if (value === "logout") {
      this.props.userLogout();
    }
    if (value === "profile") {
      history.push("/settings/profile")
    }
    this.setState({
      logoutAnchor: null,
    });
  };

  render() {
    const {
      classes,
      children,
      history,
      locationData,
      countData,
      permissionsLoading,
      permissionsData,
      pageNo,
      locationTimezone,
      rowsPerPage,
    } = this.props;
    const { activeProduct, scheduler_lid } = this.state;
    var ovUid = cookies.get("ovUid");
    var ovUserName = cookies.get("ovUserName");
    var ovUserImage = cookies.get("ovUserImage");

    var activeLocation = locationData.find((location) => scheduler_lid === location._id);
    var schedulerLocationName = activeLocation
      ? activeLocation.name
      : "";
   var timezone = locationTimezone.filter((item) => item._id === scheduler_lid)
   console.log("cjnkjc",timezone)


    if (permissionsLoading) return <LoadingData />;
    return (
      <div className={classes.root}>
        <Header
          scheduler_lid={scheduler_lid}
          schedulerLocationName={schedulerLocationName}
          handleLogoutOpen={this.handleLogoutOpen}
          handleLocationOpen={this.handleLocationOpen}
          drawerWidth={200}
          userName={ovUserName}
          userImage={ovUserImage}
          locationData={locationData}
          activeProduct={activeProduct}
          handleSidebarToggle={this.handleSidebarToggle}
          isSidebarOpen={this.props.isSidebarOpen}
          history={history}
          timezoneName={timezone}
        />

        <LogoutMenus
          handleLogoutClose={this.handleLogoutClose}
          logoutAnchor={this.state.logoutAnchor}
        />


        <LocationMenus
          scheduler_lid={scheduler_lid}
          locationData={locationData}
          handleLocationClose={this.handleLocationClose}
          locationAnchor={this.state.locationAnchor}
        />

        <Sidebar
          countData={countData}
          permissionsData={permissionsData}
          timezone={timezone}
          activeProduct={activeProduct}
          handleSidebarToggle={this.handleSidebarToggle}
          isSidebarOpen={this.props.isSidebarOpen}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: this.props.isSidebarOpen,
          })}
        >
          {scheduler_lid ? (
            React.cloneElement(children, {
              onChangePage: this.props.onChangePage,
              permissionsData,
              ovUid,
              ovUserName,
              timezone,
              ovUserImage,
              scheduler_lid,
              locationData,
              pageNo,
              rowsPerPage,
            })
          ) : (
            <Grid container justify="center" spacing={4}>
              <Grid item sm={6} xs={12}>
                <div className={classes.noLocationassign}>
                  <Typography variant="h1" style={{ marginBottom: "2rem" }}>
                    No Location assigned
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countData: state.settingsUsers.countData,
  permissionsLoading: state.settingsRoles.permissionsLoading,
  permissionsData: state.settingsRoles.permissionsData,
  locationData: state.settingsLocation.locationData,
  pageNo: state.localData.pageNo,
  isSidebarOpen: state.localData.isSidebarOpen,
  rowsPerPage: state.localData.rowsPerPage,
  locationTimezone:state.settingsRoles.locationTimezone
});

const ConnectWith = connect(mapStateToProps, {
  userExitAccess,
  userLogout,
  onChangePage,
  fetchData,
  userPermissions,
  fetchCountData,
  onChangeSidebar
})(SchedulerLayout);
export default withStyles(styles)(ConnectWith);
