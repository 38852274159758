import React, { useEffect } from "react";
import _ from "lodash";
import Box from "@material-ui/core/Box";
import { createTheme } from "@material-ui/core";
import MainTitleChart from "./MainTitleChart";
import { ThemeProvider } from '@material-ui/styles';
import withDragDropContext from './withDnDContext'
import { connect } from "react-redux";
import AddRolesHierarachy from "./AddRolesHierarachy";
import { fetchHeirarachyChartData } from './modules/action';
import AddRole from "./AddRole";

const theme = createTheme({
    fontFamily: "Roboto, sans-serif"
});

function HierarachyChart(props) {
    const { fetchHeirarachyChartData, heirarachyData } = props;

    useEffect(() => {
        fetchHeirarachyChartData();
    }, []);

    let newArray = {}
    heirarachyData && heirarachyData.map((item) => {
        let returmName = item.departments && item.departments.map((row) => {
            let accountArray = row.department_role && row.department_role.map((child) => {
                let productArray = child && child.department_role_assis.map((parentItem) => {
                    let user_obj = {
                        name: parentItem.user_id && parentItem.user_id.user_name,
                        id: parentItem && parentItem._id,
                        title: parentItem.role_id && parentItem.role_id.role_name,
                    }
                    return user_obj
                })
                let child_obj = {
                    name: child.user_id && child.user_id.user_name,
                    id: child && child._id,
                    title: child.role_id && child.role_id.role_name,
                    product: productArray
                }
                return child_obj
            })
            let reurn_obj = {
                name: row && row.department_name,
                colorcode: row.colorcode,
                id: row && row._id,
                account: accountArray,
            }
            return reurn_obj
        })

        let assistantArray = item.assiatant && item.assiatant.map((item) => {
            let assistant_obj = {
                name: item.user_id && item.user_id.user_name,
                id: item && item._id,
                title: item.role_id && item.role_id.role_name,
            }
            return assistant_obj
        })
        newArray = {
            name: item.user_id && item.user_id.user_name,
            title: item.role_id && item.role_id.role_name,
            id: item.user_id && item._id,
            assiatant: assistantArray,
            organizationChildRelationship: returmName,
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Box bgcolor="background" padding={4}>
                <AddRole />
                {/* <AddRolesHierarachy /> */}
                <MainTitleChart o={newArray} />
            </Box>
        </ThemeProvider>
    );
};

const mapStateToProps = (state) => ({
    heirarachyData: state.HierarachyChart.heirarachyData,
});

const ConnectWith = connect(mapStateToProps, { fetchHeirarachyChartData })(HierarachyChart);
export default withDragDropContext(ConnectWith)