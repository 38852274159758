import {
  SET_TODO_DASHBOARD_LOADING,
  SET_TODO_DASHBOARD,
  ADD_TODO_DASHBOARD,
  EDIT_TODO_DASHBOARD,
  DELETE_TODO_DASHBOARD,
  SET_TODO_LIST,
  ADD_TODO_SUBTASKS,
  DELETE_TODO_SUBTASKS,
  TOGGLE_TODO_SUBTASKS,
  TOGGLE_TODO,
  EDIT_TODO_LIST,
  DELETE_TODO_LIST,
  ADD_TODO_LIST
} from "./constants";

const initialState = {
  isLoading: true,
  todoData: [],
  listData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TODO_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TODO_LIST:
      return {
        ...state,
        listData: payload.listData
      };
    case SET_TODO_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        todoData: payload.todoData
      };
    case ADD_TODO_LIST:
      return {
        ...state,
        listData: [...state.listData, payload]
      }
    case ADD_TODO_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        todoData: [...state.todoData, payload]
      }
    case ADD_TODO_SUBTASKS:
      let existed_item = state.todoData.find(item => payload === item._id)
      existed_item.totalSubtasks = existed_item.totalSubtasks + 1
      return {
        ...state,
        todoData: state.todoData.map((item) => item._id === existed_item._id ? { ...existed_item } : item)
      }
    case TOGGLE_TODO_SUBTASKS:
      let todoexisted = state.todoData.find(item => payload.todo_id === item._id)
      todoexisted.compltedSubtasks = payload.isCompleted ? todoexisted.compltedSubtasks + 1 : todoexisted.compltedSubtasks - 1
      return {
        ...state,
        todoData: state.todoData.map((item) => item._id === todoexisted._id ? { ...todoexisted } : item)
      }
    case TOGGLE_TODO:
      let existed = state.todoData.find(item => payload._id === item._id)
      existed.isCompleted = payload.isCompleted
      return {
        ...state,
        todoData: state.todoData.map((item) => item._id === existed._id ? { ...existed } : item)
      }
    case EDIT_TODO_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        todoData: state.todoData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case EDIT_TODO_LIST:
      return {
        ...state,
        listData: state.listData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_TODO_LIST:
      return {
        ...state,
        listData: state.listData.filter((item) => item._id !== payload)
      }
    case DELETE_TODO_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        todoData: state.todoData.filter((item) => item._id !== payload)
      }
    case DELETE_TODO_SUBTASKS:
      let exist = state.todoData.find(item => payload.todo_id === item._id)
      exist.totalSubtasks = exist.totalSubtasks - 1
      exist.compltedSubtasks = payload.isCompleted ? exist.compltedSubtasks - 1 : exist.compltedSubtasks
      return {
        ...state,
        todoData: state.todoData.map((item) => item.id === exist.id ? { ...exist } : item)
      }
    default:
      return state;
  }
};

export default Reducer;