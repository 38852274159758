import { toast } from 'react-toastify';

export const alert = {
    success,
    error,
    clear,
    comingSoon
};

function success(message) {
    toast(message, { containerId: 'TOP_RIGHT', autoClose: 2000, type: toast.TYPE.SUCCESS });
}

function error(message) {
    toast(message, { containerId: 'TOP_RIGHT', autoClose: 2000, type: toast.TYPE.ERROR });
}

function comingSoon(message) {
    toast(message, { containerId: 'TOP_RIGHT', autoClose: 2000, type: toast.TYPE.WARNING });
}


function clear() {
    // toast.dismiss();
}