import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Confirmation, NoPermission, MyTextArea, TableMyPagination, TableRecordNotFound, LoadingData, DeleteModal, MySelectField, MoreMenu ,MySearchBar} from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { fetchData, addData, editData, deleteData, updateStatus } from './modules/actions';
import { connect } from "react-redux";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    Close as CloseIcon,
    Check as CheckIcon
} from '@material-ui/icons';
import moment from "moment";
import { localTimeToUtc, utcToLocal } from '../../../utilities';
import {utcToTimeZone} from "../../../utilities/utcToTimeZone"
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
// import { MuiThemeProvider, muiTheme } from 'material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { alpha, styled } from '@mui/material/styles';
import "moment/locale/fr";
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    Tooltip,
    Switch,
    FormGroup,
    FormControlLabel,
    TextField,
    Avatar,
    Dialog, DialogTitle, DialogContent,
    Slide, DialogActions,
}
    from '@material-ui/core';
import { FILE_URL, schedulerColor, settingColor } from "../../../config";
import { localToUtc, validationIsEmpty } from '../../../utilities'
import { scheduler } from "../../../icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '27px',
        fontSize: '18px'

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    subtitle: {
        marginBottom: '3px',
        color: '#7C7C7C'
    },

    dialog: {
        margin: '0px 0px 0px auto',
        right: '0px',
        left: "auto",
        [theme.breakpoints.up('md')]: {
            width: "35%"
        },
    },
    dialogcontent: {
        backgroundColor: '#fcfcfc',
        padding: '1rem 1.5rem',
    },
    myTitle: {
        lineHeight: '2rem',
        // float: "left",
        color: "#ffffff",
        fontWeight: 600,
        textAlign: 'center'
    },
    closeIcon: {
        cursor: 'pointer',
        float: 'right',
        color: 'white'
    },
    userName: {
        maxWidth: "250px",
        // lineHeight: 3,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        // WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        color: "white",
    },
    closeButton: {
        textTransform: "capitalize",
        padding: '8px 22px',
        color: 'white'
    },
    saveButton: {
        textTransform: "capitalize",
        color: '#ffffff',
        padding: '8px 22px',
        width: "150px"
    },
    dialogOptions: {
        padding: '5px 1px'
    },

    moreData: {
        '&:hover': {
            backgroundColor: 'red'
        }
    }

});

const BlueSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: schedulerColor.main
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: schedulerColor.main,
        backgroundColor: alpha(schedulerColor.main),
        '&:hover': {
            backgroundColor: alpha(schedulerColor.main),
            color: schedulerColor.main
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: schedulerColor.main,
        color: schedulerColor.main
    },
}));

export function fetchTimeOffRequestData() {
    window.TimeOffRequest.fetchData(true);
}

class TeamTimeOffRequest extends Component {
    constructor(props) {
        super(props)
        window.TimeOffRequest = this;
        this.state = {
            isApprove: 0,
            usersData: [],
            timeOffData: [],
            selectedValue: "all",
            searchUsers:"",
            myItem: {
            },
            order: 'asc',
            orderBy: "_id",
            headCells: [
                {
                    id: 'user_name',
                    label: "Employee Name",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'created_at',
                    label: "Request",
                    numeric: false,
                    align: "left"
                }
            ],
            errors: {},
            errorsHelper: {},
            moreData: [
                {
                    icon: EditIcon,
                    title: "Edit",
                    sortName: "edit",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_timeoffrequests_edit
                },
                {
                    icon: DeleteIcon,
                    title: "Delete",
                    sortName: "delete",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_timeoffrequests_delete
                },
                // {
                //     icon: CheckIcon,
                //     title: "Approve",
                //     sortName: "approve",
                //     onClick: this.modifyData,
                //     permission: props.permissionsData.scheduler_timeoffrequests_approve
                // },
                // {
                //     icon: CloseIcon,
                //     title: "Reject",
                //     sortName: "reject",
                //     onClick: this.modifyData,
                //     permission: props.permissionsData.scheduler_timeoffrequests_approve
                // }
            ],
            moreData2: [
                {
                    icon: EditIcon,
                    title: "Edit",
                    sortName: "edit",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_timeoffrequests_edit
                },
                {
                    icon: DeleteIcon,
                    title: "Delete",
                    sortName: "delete",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_timeoffrequests_delete
                }
            ]
        }
    }

    componentDidMount = () => {
        var isReload = this.props.timeOffData.length > 0 ? false : true
        this.fetchData(isReload)
        if (this.props.timeOffData.length > 0) {
            this.setState({
                timeOffData: this.props.timeOffData,
                timeOffDataTemp: this.props.timeOffData,
                usersData: this.props.usersData
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.usersData) !== JSON.stringify(this.props.usersData)) {
            this.setState({
                usersData: this.props.usersData
            })
        }
        if (JSON.stringify(prevProps.timeOffData) !== JSON.stringify(this.props.timeOffData)) {
            this.setState({
                timeOffData: this.props.timeOffData,
                timeOffDataTemp: this.props.timeOffData,
            })
        }
    }

    fetchData = (isReload) => {
        var lid = this.props.scheduler_lid
        this.props.fetchData(lid, isReload)
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    handleSubmit = async () => {
        var userItem = this.state.myItem;
        var valid_uid = !userItem.uid ? true : false
        var valid_category = !userItem.category ? true : false
        var valid_reason = await validationIsEmpty(userItem.reason);
        var valid_wholeday = !userItem.wholeday ? true : false;
        if (valid_wholeday === true) {
            if (userItem.startTime === null) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        setDate: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        setDate: "Please select start time"
                    }
                }))
            }

            if (userItem.endTime === null) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        setDateEnd: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        setDateEnd: "Please select end time"
                    }
                }))
            }
            if (userItem.startTime === null || userItem.endTime === null) {
                return;
            }
        }

        if (valid_category) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    category: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    category: "Please select category"
                }
            }))
        }

        if (valid_reason) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    reason: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    reason: "Please enter reason"
                }
            }))
        }
        // if (valid_uid) {
        //     this.setState(prevState => ({
        //         errors: {
        //             ...prevState.errors,
        //             user_name: true
        //         },
        //         errorsHelper: {
        //             ...prevState.errorsHelper,
        //             user_name: "Please enter name"
        //         }
        //     }))
        // }


        if ((userItem.startTime === null || userItem.endTime === null || valid_reason  || valid_category) && valid_wholeday === true) {
            return;
        }

        if (userItem.startTime !== null || userItem.endTime !== null) {
            var startbreakdate = moment(moment(userItem.startTime)).format("YYYY-MM-DD HH:mm:ss Z");
            var endbreakdate = moment(moment(userItem.endTime)).format("YYYY-MM-DD HH:mm:ss Z");
            if (startbreakdate >= endbreakdate) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        endTime: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        endTime: "End time must be greater than start time"
                    }
                }))
                return
            }
        }
        if (userItem.wholeday) {
            var data = {
                lid: this.state.myItem.lid,
                //uid: this.state.myItem.uid,
                reason: this.state.myItem.reason,
                wholeday: this.state.myItem.wholeday ? "1" : "0",
                startTime: null,
                endTime: null,
                setDate: null,
                setDateEnd: null,
                category: this.state.myItem.category
            }
        }
        else {
            var data = {
                lid: this.state.myItem.lid,
                //uid: this.state.myItem.uid,
                reason: this.state.myItem.reason,
                wholeday: this.state.myItem.wholeday ? 1 : 0,
                startTime:moment(this.state.myItem.startTime).format("YYYY-MM-DD")+ "T" + moment(this.state.myItem.startTime).format("HH:mm:ss") + this.props.timezone[0].location_offset,
                endTime:moment(this.state.myItem.endTime).format("YYYY-MM-DD")+ "T" + moment(this.state.myItem.endTime).format("HH:mm:ss") + this.props.timezone[0].location_offset,
                //startTime: moment(this.state.myItem.startTime).utc().format('YYYY-MM-DD HH:mm:ssZ'),
                //endTime: moment(this.state.myItem.endTime).utc().format('YYYY-MM-DD HH:mm:ssZ'),
                category: this.state.myItem.category
            }
        }

        // }


        this.setState({ addEditModal: false })
        if (this.state.isEdit) {
            //  data.id = this.state.myItem._id
            this.props.editData(data, this.state.myItem._id)
            this.props.fetchData(this.props.scheduler_lid)
        } else {
            this.props.addData(data)
        }
    };

    deleteData = () => {
        this.setState({ deleteModal: false })
        this.props.deleteData(this.state.myItem._id)
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };

    modifyData = (value, item) => {
        this.setState({
            isEdit: value === "edit" ? 1 : 0,
            isApprove: value === "approve" ? 1 : 2,
            confirmModal: value === "approve" || value === "reject" ? true : false,
            addEditModal: value === "add" || value === "edit" ? true : false,
            deleteModal: value === "delete" ? true : false
        })
    }

    moreMenuClick = (item, value) => {
        console.log("vfjkv",item)
        // var startTiime = new Date(item ? item.startTime : "")
        // var endTiime = new Date(item ? item.endTime : "")
        // var startTime = startTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
        // var endTime = endTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
        // var strtTime =  moment(startTime).format("DD/MM/YYYY, hh:mm:ss A")
        let myItem = {
            lid: this.props.scheduler_lid,
            uid: this.props.ovUid,
            reason: "",
            wholeday: 0,
            startTime: new Date().toLocaleString("en-US", { timeZone: this.props.timezone[0].location_timezone }),
            endTime: new Date().toLocaleString("en-US", { timeZone: this.props.timezone[0].location_timezone }),
            category: 0
        }
        if (item) {
            // var startTime = moment(utcToLocal(item.startTime)).format("YYYY-MM-DD HH:mm:ss")
            // var endTime = moment(utcToLocal(item.endTime)).format("YYYY-MM-DD HH:mm:ss")
            myItem = item
            // myItem.startTime = startTime
            // myItem.endTime = endTime
        }
        this.setState({
            isEdit: 0,
            myItem,
            addEditModal: value === "add" ? true : false
        })
    }

    toggleWholeDay = () => {
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                wholeday: !this.state.myItem.wholeday
            }
        }));
    }

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }));
    }

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }


    filterTimeOffRequest = (e) => {
        var target = e.target.value;
        if (target === "all") {
            this.setState({ selectedValue: target }, () => {
                var updatedList = this.state.timeOffDataTemp;
                this.setState({
                    timeOffData: updatedList
                });
            });
        } else {
            this.setState({ selectedValue: target }, () => {
                var updatedList = this.state.timeOffDataTemp;
                updatedList = updatedList.filter(function (item) {
                    return (item.status === parseInt(target));
                });
                this.setState({
                    timeOffData: updatedList
                });
            });
        }
    }

    confirmModalClose = () => {
        this.setState({
            confirmModal: false
        })
    }

    updateStatus = () => {
        var data = {
            status: this.state.isApprove
        }
        this.setState({
            confirmModal: false
        })
        this.props.updateStatus(data,this.state.myItem._id)
        this.props.fetchData(this.props.scheduler_lid)
    }




    handleChangeStartDate = (date) => {
        this.setState((prevState) => ({
            myItem: {
                ...prevState.myItem,
                // startTime: moment(localTimeToUtc(date).format("MMMM d, yyyy h:mma")),
                startTime: date,
            },
        }));
    };


    handleChangeEndDate = (date, e) => {
        this.setState((prevState) => ({
            myItem: {

                ...prevState.myItem,
                endTime: date,
            },
        }));
    };

    handleSearch = (target) => {
        var updatedList = this.state.timeOffDataTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                return (
                    item.user_name.toLowerCase().search(target.toLowerCase()) !== -1
                );
            });
        }
            var timeOffData = updatedList.filter((item) => item.user_name)
            this.setState({
                searchUsers: target,
                timeOffData,
            });
        
    };

    clearSearch = () => {
        var timeOffData = this.state.timeOffDataTemp.filter((item) => item.user_name)
        this.setState({
            searchUsers: "",
            timeOffData,
        });
    }

    render() {
        const { classes, isLoading, pageNo, rowsPerPage, timeOffCategory, permissionsData, timezone } = this.props;
        const { timeOffData, usersData } = this.state;
        if (isLoading) return <LoadingData />
        return (
            permissionsData.scheduler_timeoff_request ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Dialog
                            className={classes.dialog}
                            maxWidth="sm"
                            fullScreen
                            TransitionComponent={Transition}
                            open={this.state.addEditModal}
                            onClose={this.addEditModalClose}
                            handleSubmit={this.handleSubmit}
                        >
                            <DialogTitle
                                style={{ backgroundColor: schedulerColor.main }}
                                className={classes.DialogTitle}
                            >   <Typography
                                className={classes.myTitle}
                                variant="h4" >
                                    {this.state.isEdit ? "Edit Time Off" : "Add Time Off"}
                                </Typography>
                            </DialogTitle>
                            <DialogContent
                                className={classes.dialogcontent}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {/* <Grid item xs={12}
                                            className={classes.dialogOptions}
                                        >
                                            <MySelectField
                                                label="Name"
                                                name="uid"
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                value={this.state.myItem.uid}
                                                error={this.state.errors.uid}
                                                helperText={this.state.errors.uid ? this.state.errorsHelper.uid : ""}
                                                placeholder="Please Select Name"
                                             error={this.state.errors.uid}
                                            >
                                                {usersData.map((item) => {
                                                    return (
                                                        <option key={item.uid} value={item.uid}>
                                                            {item.user_name}
                                                        </option>
                                                    )
                                                })}
                                                {usersData.map((user) => {
                                                    return (
                                                        <option key={user._id} value={user._id} >
                                                            {user.user_name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            {/* <MuiThemeProvider muiTheme={muiTheme}> */}
                                            <FormGroup style={{ float: 'right', marginRight: '0' }}>
                                                {/* <FormControlLabel
                                                            control={<Switch
                                                                className={classes.Switch}
                                                                checked={this.state.myItem.wholeday}
                                                                onChange={this.toggleWholeDay}
                                                            />}
                                                            label='Whole Day'

                                                        /> */}
                                                <FormControlLabel
                                                    control={
                                                        <BlueSwitch
                                                            // checked={data.status ? true : false}
                                                            className={classes.switchBase}
                                                            checked={this.state.myItem.wholeday}
                                                            onChange={this.toggleWholeDay}
                                                        />}
                                                    label='Whole Day'>
                                                </FormControlLabel>
                                            </FormGroup>

                                            {/* </MuiThemeProvider> */}
                                        </Grid>
                                        <Grid item xs={12}
                                            className={classes.dialogOptions}>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <KeyboardDateTimePicker
                                                    disabled={this.state.myItem.wholeday ? true : false}
                                                    // fullWidth
                                                    // size="small"
                                                    // variant="inline"
                                                    // inputVariant="outlined"
                                                    // disablePast={true}
                                                    // format="MMMM d, yyyy h:mma"
                                                    // name="startTime"
                                                    // label="Start"
                                                    // onChange={this.handleChangeStartDate}
                                                    // onFocus={this.handleFocus}
                                                    // value={this.state.myItem.startTime}
                                                    // error={this.state.errors.startTime}
                                                    // helperText={
                                                    //     this.state.errors.startTime
                                                    //         ? this.state.errorsHelper.startTime
                                                    //         : ""
                                                    // }
                                                    // placeholder="Select start-time"
                                                    key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                                    fullWidth
                                                    size="small"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    name="startTime"
                                                    label="Start"
                                                    className={classes.dateTimePicker}
                                                    format="MMMM d, yyyy h:mma"
                                                    onChange={this.handleChangeStartDate}
                                                    onFocus={this.handleFocus}
                                                    showTodayButton
                                                    value={this.state.myItem.startTime }
                                                    error={this.state.errors.startTime}
                                                    helperText={
                                                        this.state.errors.startTime
                                                            ? this.state.errorsHelper.startTime
                                                            : ""
                                                    }
                                                    placeholder="Select start-time"
                                                // disabled="true"
                                                ></KeyboardDateTimePicker>
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                        <Grid item xs={12}
                                            className={classes.dialogOptions}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <KeyboardDateTimePicker
                                                    // disabled={this.state.myItem.wholeday ? true : false}
                                                    // fullWidth
                                                    // size="small"
                                                    // variant="inline"
                                                    // inputVariant="outlined"
                                                    // disablePast={true}
                                                    // format="MMMM d, yyyy h:mma"
                                                    // name="endTime"
                                                    // label="End"
                                                    // onChange={this.handleChangeEndDate}
                                                    // onFocus={this.handleFocus}
                                                    // value={this.state.myItem.endTime}
                                                    // error={this.state.errors.endTime}
                                                    // helperText={
                                                    //     this.state.errors.endTime
                                                    //         ? this.state.errorsHelper.endTime
                                                    //         : ""
                                                    // }
                                                    // placeholder="Select end-time"
                                                    key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                                    fullWidth
                                                    size="small"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    name="startTime"
                                                    label="End"
                                                    className={classes.dateTimePicker}
                                                    value={this.state.myItem.endTime}
                                                    format="MMMM d, yyyy h:mma"
                                                    onChange={this.handleChangeEndDate}
                                                    onFocus={this.handleFocus}
                                                    error={this.state.errors.endTime}
                                                     helperText={
                                                        this.state.errors.endTime
                                                            ? this.state.errorsHelper.endTime
                                                            : ""
                                                    }
                                                    showTodayButton

                                                    placeholder="Select end-time"
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>

                                        <Grid item xs={12}
                                            className={classes.dialogOptions}>
                                            <MySelectField
                                                label="Category"
                                                name="category"
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                value={this.state.myItem.category}
                                                error={this.state.errors.category}
                                                helperText={this.state.errors.category ? this.state.errorsHelper.category : ""}
                                                placeholder="Please Select Category"
                                            >
                                                <option key={0} value={0}>
                                                    Select Category
                                                </option>
                                                {timeOffCategory.map((item, id) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </MySelectField>
                                        </Grid>

                                        <Grid item xs={12}
                                            className={classes.dialogOptions}>
                                            <MyTextArea
                                                name="reason"
                                                label="Reason"
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                value={this.state.myItem.reason}
                                                type="text"
                                                error={this.state.errors.reason}
                                                helperText={this.state.errors.reason ? this.state.errorsHelper.reason : ""}
                                                placeholder="Enter reason"
                                                rows={8}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={this.addEditModalClose}
                                    startIcon={<CloseIcon />}
                                    className={classes.closeButton}
                                    style={{ backgroundColor: '#7C7C7C' }}
                                >
                                    Close
                                </Button>

                                <Button
                                    style={{ backgroundColor: schedulerColor.main }}
                                    variant="outlined"
                                    size="medium"
                                    className={classes.saveButton}
                                    startIcon={<SaveIcon />}
                                    onClick={this.handleSubmit}
                                >
                                    Save
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Toolbar variant="regular" className={classes.rootToolbar}>
                            <Typography
                                display="inline"
                                className={classes.title}
                                style={{ marginLeft: '-1rem' }}
                                variant="h4" >
                                MY TIME OFF REQUEST
                            </Typography>
                        </Toolbar>
                        <Paper>
                            <Toolbar variant="regular" className={classes.rootToolbar}>
                                <Grid container xs={12}
                                    style={{ padding: '0.8rem 0rem' }}
                                >
                                    <Grid item xs={4}>
                                        <TextField
                                            autoFocus={false}
                                            medium
                                            select
                                            SelectProps={{ native: true }}
                                            style={{ width: '250px' }}
                                            required={this.props.required ? this.props.required : false}
                                            label="Time Off Request"
                                            onChange={this.filterTimeOffRequest}
                                            value={this.state.selectedValue}
                                            variant="outlined"
                                            size="small"
                                        >
                                            <option key={0} value="all">
                                                All
                                            </option>
                                            <option key={0} value={0} >
                                                In Review
                                            </option>
                                            <option key={1} value={1}>
                                                Approved
                                            </option>
                                            <option key={2} value={2}>
                                                Declined
                                            </option>
                                        </TextField>
                                    </Grid>

                                    <Grid item md={7} style={{display:"flex",justifyContent:"flex-end"}}>
                                        <div style={{width:"250px"}}>
                                        <MySearchBar
                                    clearSearch={this.clearSearch}
                                    handleSearch={this.handleSearch}
                                    value={this.state.searchUsers}
                                    placeholder="Search user"
                                />
                                        </div>
                                    </Grid>

                                    <Grid item xs={1}>
                                        {permissionsData.scheduler_timeoffrequests_add ?
                                            <Tooltip arrow title="Add">
                                                <IconButton
                                                    style={{ float: 'right' }} size="small"
                                                    onClick={() => { this.moreMenuClick(null, "add") }}
                                                >
                                                    <AddIcon
                                                        style={{ border: '3px', borderRadius: '50%', backgroundColor: schedulerColor.main, color: 'white', fontWeight: '500' }} />

                                                </IconButton>
                                            </Tooltip> : ""}
                                    </Grid>
                                </Grid>
                            </Toolbar>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    size='medium'
                                >
                                    <TableHead style={{ backgroundImage: schedulerColor.main }}>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    align={headCell.align}
                                                    key={headCell.id}
                                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                >
                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                style={{ paddingRight: '20px' }}
                                                className={classes.headCell}
                                                align="right"
                                                colSpan={2}></TableCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {stableSort(timeOffData, getComparator(this.state.order, this.state.orderBy))
                                            .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                var category = timeOffCategory.find((cat) => cat.id === parseInt(row.category))
                                                // var user = usersData.find((user) => user._id === row.uid)
                                                // var user_name = user ? user.user_name : ""
                                                // var user_image = user ? user.user_image : ""
                                                // var startTiime = new Date(row.startTime)
                                                // var endTiime = new Date(row.endTime)
                                                // var startTime = startTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                                // var endTime = endTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                                var startTimeold = new Date(row.approved_date)
                                                var startTimeee = startTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                                var startTimeeeee = moment(startTimeee).locale('en-US').format("DD MMM YYYY, hh:mm:ss A") 
                                                var startTime =  startTimeeeee
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row._id}
                                                    >
                                                        <TableCell
                                                            style={{ verticalAlign: "baseline", minWidth: "140px" }}
                                                            className={classes.TableCell}>
                                                            {row.user_image ?
                                                                <Avatar
                                                                    src={row.user_image}
                                                                    className={classes.avatar}
                                                                />
                                                                :
                                                                <Avatar
                                                                    style={{ backgroundColor: settingColor.main }}
                                                                    className={classes.avatar}
                                                                >
                                                                    {row?.user_name?.charAt(0)}
                                                                </Avatar>
                                                            }
                                                            <Typography
                                                                className={classes.userName}
                                                            >
                                                                {row.user_name}
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell align="left" className={classes.TableCell} style={{ minWidth: "300px" }}>
                                                            <Typography
                                                                className={classes.subtitle}
                                                            >
                                                                Requested : {row.requested_time} hours
                                                            </Typography>
                                                            {/* <Typography
                                                                className={classes.subtitle}
                                                                style={{ color: '#323C47' }}
                                                            >
                                                                {moment(row.startTime).format("DD-MM-YYYY hh:mm:ss A")} - {moment(row.endTime).format("DD-MM-YYYY hh:mm:ss")}
                                                
                                                            </Typography> */}
                                                            <Typography
                                                                className={classes.subtitle}
                                                                style={{ color: '#323C47' }}
                                                            >
                                                               
                                                               {moment(row.startTime).locale('en-US').format("DD MMM YYYY, hh:mm:ss A") } -
                                                               {moment(row.endTime).locale('en-US').format("DD MMM YYYY, hh:mm:ss A") }
                                                                {/* {new Date(utcToTimeZone(row.startTime,this.props.timezone[0].location_timezone)).toLocaleString()} - {new Date(utcToTimeZone(row.endTime,this.props.timezone[0].location_timezone)).toLocaleString()} */}
                                                            </Typography>

                                                            <Typography
                                                                className={classes.subtitle}
                                                            >
                                                                Category : {category ? category.name : ""}
                                                            </Typography>
                                                            {row.reason ?
                                                                <Typography
                                                                    className={classes.subtitle}

                                                                >
                                                                    <span style={{ color: '#323C47' }}> Reason </span>: {row.reason}
                                                                </Typography> : ""}

                                                            {row.status ?
                                                                <Typography
                                                                    className={classes.subtitle}
                                                                >
                                                                    {row.status === 1 ?
                                                                        <b style={{ color: "green" }} >Approved by</b>
                                                                        : <b style={{ color: "red" }} >Declined by</b>
                                                                    } : {row.approved_by}&nbsp;at&nbsp;{startTime}
                                                                </Typography> : ""}
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.TableCell}>
                                                            <Button
                                                                style={{ backgroundImage: row.status === 1 ? "green" : row.status === 2 ? "red" : schedulerColor.main ,backgroundColor: row.status === 1 ? "green" : row.status === 2 ? "red" : schedulerColor.main }}
                                                                variant="outlined"
                                                                size="medium"
                                                                className={classes.saveButton}
                                                            >
                                                                {row.status === 1 ? "Approved" : row.status === 2 ? "Declined" : "In Review"}
                                                            </Button>
                                                        </TableCell>

                                                        <TableCell colSpan={3} align="right" className={classes.TableCell}>
                                                            <MoreMenu
                                                                className={classes.moreData}
                                                                moreMenuClick={() => { this.moreMenuClick(row) }}
                                                                moreData={row.status ? this.state.moreData2 : this.state.moreData}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}

                                        {timeOffData && timeOffData.length < 1 ?
                                            <TableRecordNotFound
                                                colSpan={8}
                                                label="No Data Found.."
                                            />
                                            : ""
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableMyPagination
                                count={timeOffData ? timeOffData.length : 0}
                            />
                        </Paper>

                        <Confirmation
                            bgColor={schedulerColor.main}
                            desc={"Are you sure you want to update?"}
                            open={this.state.confirmModal}
                            onClose={this.confirmModalClose}
                            handleSubmit={this.updateStatus}
                        />

                        <DeleteModal
                            bgColor={schedulerColor.main}
                            desc="Are you sure you want to delete?"
                            open={this.state.deleteModal}
                            onClose={this.deleteModalClose}
                            handleDelete={this.deleteData}
                        />
                    </Grid>
                </Grid >
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerTimeOffRequest.isLoading,
    timeOffData: state.schedulerTimeOffRequest.teamTimeOffData,
    timeOffCategory: state.schedulerTimeOffRequest.timeOffCategory,
    usersData: state.schedulerTimeOffRequest.usersData
});

const ConnectWith = connect(mapStateToProps, {
    fetchData,
    addData,
    editData,
    deleteData,
    updateStatus
})(TeamTimeOffRequest);
export default withStyles(styles)(ConnectWith);