import {
  ADD_ROLES_DATA, FETCH_USERS, FETCH_HEIRARACHY_CHART, ADD_HEIRARACHY_CHART, SET_HEIRARACHY_LOADING,
  EDIT_HEIRARACHY_CHART, DELETE_HEIRARACHY_CHART, FETCH_ROLE_USERS
} from './constant';
import { alert, invalidToken } from '../../../../utilities';
import { hierarachyServices } from './services';

export function fetchDataRoles(data) {
  return function (dispatch) {
    dispatch({
      type: ADD_ROLES_DATA,
      payload: data
    });
  };
}

export function fetchData(isReload) {
  return function (dispatch) {
    hierarachyServices.fetchUserData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: FETCH_USERS,
            payload: response.data
          });
        }
      });
  };
}

export function fetchRoleData(isReload) {
  return function (dispatch) {
    hierarachyServices.fetchRoleData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: FETCH_ROLE_USERS,
            payload: response.data
          });
        }
      });
  };
}

export function fetchHeirarachyChartData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_HEIRARACHY_LOADING,
      payload: true
    });

    hierarachyServices.fetchHeirarachyChartData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: FETCH_HEIRARACHY_CHART,
            payload: response.data
          });
        }
      });
  };
}

export function addHeirarachyChart(data) {
  return function (dispatch) {
    dispatch({
      type: SET_HEIRARACHY_LOADING,
      payload: true
    });
    hierarachyServices.addHeirarachyChart(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_HEIRARACHY_CHART,
            payload: response.data.post
          });
        }
      });
  };
}

export function editHeirarachyChart(data) {
  return function (dispatch) {
    dispatch({
      type: SET_HEIRARACHY_LOADING,
      payload: true
    });
    hierarachyServices.editHeirarachyChart(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_HEIRARACHY_CHART,
            payload: response.data.data
          });
        }
      });
  };
}

export function deleteHeirarachyChart(_id) {
  return function (dispatch) {
    dispatch({
      type: SET_HEIRARACHY_LOADING,
      payload: true
    });
    hierarachyServices.deleteHeirarachyChart(_id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: DELETE_HEIRARACHY_CHART,
            payload: response.data
          });
        }
      });
  };
}