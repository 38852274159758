import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  Grid,
  Button,
  Typography,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForever";

const styles = (theme) => ({
  avatar: {
    margin: "0px auto",
    // backgroundColor : theme.palette.primary.main,
    backgroundColor: "#ffffff",
    height: "60px",
    width: "60px",
  },
  avatarIcon: {
    fontSize: "4rem",
  },
  title: {
    textAlign: "center",
    fontWeight:700,

  },
  dialog: {
    borderRadius: 0,
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "2rem",
    borderRadius: 0,
    overflow: "hidden",
    "&:last-child": {
      borderRadius: 0,
      padding: "2rem",
    },
  },
  saveButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid green",
    padding: "10px 22px",
  },
  closeButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid gray",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DownloadModal extends Component {


  SaveInLocal = () => {
    localStorage.setItem("providerIntegration",JSON.stringify(this.props.id))
  }
  render() {
    const { classes } = this.props;
    console.log("vjfnvjf",this.props.id )
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogcontent} dividers>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              {/* <Typography className={classes.title} variant="h3" gutterBottom>
                {this.props.desc}
              </Typography> */}

              <Typography
                className={classes.title}
                variant="h3"
                gutterBottom
              >
                Employe Id is Missing! Do you really want to download data? 
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Button
                    onClick={this.props.onClose}
                    size="large"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "gray" }}
                    className={classes.closeButton}
                  >
                    No,cancel!
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button
                    style={{
                      backgroundImage: this.props.bgColor
                        ? this.props.bgColor
                        : "gray",
                        backgroundColor: this.props.bgColor
                        ? this.props.bgColor
                        : "gray"
                    }}
                    onClick={this.props.handleDelete}
                    size="large"
                    fullWidth
                    variant="contained"
                    className={classes.saveButton}
                  >
                    Download it!
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}  style={{textAlign:"center"}}>


              <Link
                className={classes.title}
                onClick={this.SaveInLocal}
                target="_blank"
                variant="h3"
                to={{ 
                    pathname:"/hr/payroll",
                    // state:{
                    // providerIntegration:this.props.id
                    // }

                }}
                gutterBottom
              >
                Click here to add employee ids
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DownloadModal);
