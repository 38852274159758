import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  NoPermission,
  MyLeftModal,
  LoadingData,
  DeleteModal,
  MyLeftModalWithUser,
  MyPlain25Modal,
} from "../../../components";
import { fetchData as fetchRolesData } from "../Roles/modules/actions";
import { fetchUser, updateUserPayroll } from "../Schedule/modules/actions";
import { fetchData as fetchUserRolesData } from "../../common/Roles/modules/actions";
import { history } from "../../../history";
import { scheduleServices } from "./modules/services";
import { connect } from "react-redux";
import CopyMenus from "./CopyMenus";
import ToolsMenus from "./ToolsMenus";
import PublishSchedule from "./PublishSchedule";
import Scheduler from "./Scheduler";
import SchedulerToolbar from "./SchedulerToolbar";
import AddEditShifts from "./AddEditShifts";
import AddEditEvents from "./AddEditEvents";
import PrintSchedule from "./PrintSchedule";
import moment from "moment";
import { schedulerColor, WEB_URL } from "../../../config";
import {
  alert,
  utcTimeToLocal,
  localTimeToUtc,
  utcToLocal,
  validationIsEmpty,
  validationEmail,
  validationIsMobilePhone,
} from "../../../utilities";
import { utcToTimeZone } from "../../../utilities/utcToTimeZone";
import { Grid } from "@material-ui/core";
import {
  ClearTwoTone as ClearIcon,
  Settings as SettingsIcon,
  Add as AddIcon,
  Publish as PublishIcon,
  FileCopy as CopyIcon,
  Print as PrintIcon,
  Build as ToolsIcon,
} from "@material-ui/icons";
import AddUser from "./AddUser";
import UserDetails from "./UserDetails";
import validator from "validator";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1.5),
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#ffffff",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export function fetchScheduleData() {
  window.Schedule.fetchData();
}

class Schedule extends Component {
  constructor(props) {
    super(props);
    window.Schedule = this;
    this.state = {
      eventsData: [],
      openShifts: [],
      dragLoader: false,
      myavailabilityData: [],
      toolbarSettings: {},
      toolsData: [
        {
          title: "Events",
          sortName: "events",
          isCheckbox: 1,
        },
        {
          title: "Open Shifts",
          sortName: "open_shifts",
          isCheckbox: 1,
        },
        {
          title: "Hours Forecast",
          sortName: "hours_forecast",
          isCheckbox: 1,
        },
        {
          title: "Wages Forecast",
          sortName: "wages_forecast",
          isCheckbox: 1,
        },
        {
          title: "People Forecast",
          sortName: "people_forecast",
          isCheckbox: 1,
        },
        {
          title: "Scheduled Employees Only",
          sortName: "scheduled_employees_only",
          isCheckbox: 1,
        },
        {
          title: "Break Forecast",
          sortName: "break_forecast",
          isCheckbox: 1,
        },
        {
          title: "Employee Total Hrs",
          sortName: "employee_total_hrs",
          isCheckbox: 1,
        },
        {
          title: "Hide employee if disable on scheduler",
          sortName: "hide_users_employes",
          isCheckbox: 1,
        },
        {
          icon: ClearIcon,
          title: "Clear Unpublished Shifts",
          sortName: "clear_unpublished_shifts",
        },
        {
          icon: ClearIcon,
          title: "Clear Entire Schedule",
          sortName: "clear_entire_schedule",
        },
      ],
      printList: [
        {
          type: 0,
          title: "White Background and Black Text",
          sub_title: "Does Not Include Any Color",
        },
        {
          type: 1,
          title: "With Full Color Background",
          sub_title: "Includes Filled Shift Colors",
        },
        {
          type: 2,
          title: "With Colored Borders",
          sub_title: "Includes Shift Colored Outlines",
        },
      ],
      publishNotificationsList: [
        {
          status: 1,
          title: "Notify everyone on the team",
        },
        {
          status: 2,
          title: "Notify team members with changes only",
        },
        {
          status: 0,
          title: "Do not notify anyone",
        },
      ],
      toolbarData: [
        {
          icon: ToolsIcon,
          title: "Tools",
          sortName: "edit",
          onClick: this.handleToolsOpen,
          permission: 1,
        },
        {
          icon: PrintIcon,
          title: "Print",
          sortName: "delete",
          onClick: this.printModalOpen,
          permission: props.permissionsData.scheduler_schedule_print,
        },
        {
          icon: CopyIcon,
          title: "Copy To",
          sortName: "edit",
          onClick: this.handleCopyOpen,
          permission: props.permissionsData.scheduler_schedule_copy,
        },
        {
          icon: PublishIcon,
          title: "Publish",
          sortName: "publish",
          onClick: this.publishModalOpen,
          conflictClick:this.ifConflictIsThere,
          permission: props.permissionsData.scheduler_schedule_publish,
        },
        // {
        //   icon: AddIcon,
        //   title: "Add Employee",
        //   sortName: "delete",
        //   onClick: this.userModalOpen,
        //   permission: props.permissionsData.scheduler_schedule_add_employee
        // }
      ],
      weatherData: [],
      usersData: [],
      hideusers: [],
      timeArray: [],
      copyData: [],
      columns: [],
      myItem: {},
      userItem: {},
      errorsHelper: {},
      errors: {},
      settings: {},
      usernameModal: false,
      user: {},
      locationsDetails: {},
      unique_pin: "",
      payrollData: [],
      scheduleData: {},
      addMyShift: {},
      startOldTime: "",
      endOldTime: "",
    };
  }

  fetchWeartherList = () => {
    scheduleServices.fetchWeartherList().then((response) => {
      this.setState({
        weatherData: response.data.daily,
      });
    });
  };

  componentDidMount = () => {
    var originalString = window.location.search;
    var myDate = moment().format("YYYY-MM-DD");
    if (originalString) {
      myDate = originalString.replace("?date=", "");
    }
    this.setState({ myDate }, () => {
      this.fetchData();
    });
    this.fetchWeartherList();
    this.props.fetchRolesData();
    var timeArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        var id = moment({ hour, minute }).format();
        id = new Date(id);
        // id = id.toLocaleString("en-US", {
        //   timeZone: this.props.timezone?.[0]?.location_timezone,
        // });
        var name = moment(id).format("hh:mm A");
        // id = moment(id).format("DD/MM/YYYY ,hh:mm:ss A")
        timeArray.push({ id, name });
      }
    }
    this.setState({ timeArray });
  };

  fetchData = () => {
    var data = {
      lid: this.props.scheduler_lid,
      date: moment(this.state.myDate).format("YYYY-MM-DD"),
    };
    this.setState({ isLoading: true });
    scheduleServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
      } else {
        var usersData = response.data.usersData;
        var toolbarSettings = response.data.toolbarSettings;
        var eventsData = response.data.eventsData;
        var openShifts = response.data.openShifts;
        var hideusers = response.data.hideusers;

        if (toolbarSettings && toolbarSettings.open_shifts) {
          var obj_events = {
            uid: "0",
            user_name: "Open Shifts",
            user_image: "",
            myShifts: openShifts,
          };
          usersData.unshift(obj_events);
        }

        if (toolbarSettings && toolbarSettings.events) {
          var obj_events = {
            uid: "events",
            user_name: "Events",
            user_image: "",
            myShifts: eventsData,
          };
          usersData.unshift(obj_events);
        }

        this.setState(
          {
            myavailabilityData: response.data.myavailabilityData,
            settings: response.data.settings,
            usersData: usersData,
            usersDataTemp: usersData,
            eventsData: eventsData,
            openShifts: openShifts,
            hideusers: hideusers,
            toolbarSettings: response.data.toolbarSettings,
          },
          () => {
            this.setHeaderDates(data.date);
            this.toggleScheduledEmployeesOnly();
          }
        );
      }
    });
  };

  // setHeaderDates = (newDate) => {
  //   var weekStartFrom = this.state.settings.enforce_start_day
  //   var myDate = moment(newDate);
  //   var myDateIndex = myDate.day()
  //   var weekStart1 = myDate.clone().startOf('week');
  //   var weekStartDate = moment(weekStart1).add(weekStartFrom, 'days').locale('en-US').format("YYYY-MM-DD")
  //   if (weekStartFrom > myDateIndex) {
  //     var diff = 7 - weekStartFrom
  //     weekStartDate = moment(weekStart1).subtract(diff, 'days').format("YYYY-MM-DD")
  //   }

  //   var columns = []
  //   for (var i = 0; i < 7; i++) {
  //     var currentDate = moment().format("YYYY-MM-DD");
  //     var date = moment(weekStartDate).add(i, 'days').format("YYYY-MM-DD")
  //     var isPast = moment().isAfter(date, 'day') ? true : false
  //     var getDay = moment(date).day()
  //     var getDate = moment(date).format("DD")
  //     var dayName = moment(date).format("ddd")
  //     var isCurrent = currentDate === date;

  //     var object = {
  //       date,
  //       isPast,
  //       dayName,
  //       getDay,
  //       getDate,
  //       isCurrent
  //     }
  //     columns.push(object)
  //   }

  setHeaderDates = (newDate) => {
    var weekStartFrom = this.state.settings.enforce_start_day;
    var myDate = moment(newDate).locale("en-US");
    var myDateIndex = myDate.day();
    var weekStart1 = myDate.clone().locale("en-US").startOf("week");
    var weekStartDate = moment(weekStart1)
      .add(weekStartFrom, "days")
      .locale("en-US")
      .format("YYYY-MM-DD");
    if (weekStartFrom > myDateIndex) {
      var diff = 7 - weekStartFrom;
      weekStartDate = moment(weekStart1)
        .subtract(diff, "days")
        .locale("en-US")
        .format("YYYY-MM-DD");
    }
    var columns = [];
    for (var i = 0; i < 7; i++) {
      var currentDate = moment().locale("en-US").format("YYYY-MM-DD");
      var date = moment(weekStartDate)
        .add(i, "days")
        .locale("en-US")
        .format("YYYY-MM-DD");
      var isPast = moment().locale("en-US").isAfter(date, "day") ? true : false;
      var getDay = moment(date).locale("en-US").day();
      var getDate = moment(date).locale("en-US").format("DD");
      var dayName = moment(date).locale("en-US").format("ddd");
      var isCurrent = currentDate === date;

      var object = {
        date,
        isPast,
        dayName,
        getDay,
        getDate,
        isCurrent,
      };
      columns.push(object);
    }

    var myavailabilityData = this.state.myavailabilityData.map((items) => {
      var cloneItem = items;
      var start_time = moment(localTimeToUtc(items.start_time)).format(
        "HH:mm:ss"
      );
      var end_time = moment(localTimeToUtc(items.end_time)).format("HH:mm:ss");
      columns.map((dayValue) => {
        if (dayValue.getDay === items.day_id) {
          cloneItem["type"] = "availability";
          cloneItem["title"] = "";
          cloneItem["currentDate"] = dayValue.date;
          if (items.is_wholeday) {
            cloneItem["startTime"] = dayValue.date + " " + "00:00:00";
            cloneItem["endTime"] = dayValue.date + " " + "23:45:00";
          } else {
            cloneItem["startTime"] = dayValue.date + " " + start_time;
            cloneItem["endTime"] = dayValue.date + " " + end_time;
          }
        }
      });
      return cloneItem;
    });

    let usersData = this.state.usersData.map((user) => {
      var cloneItem = user;
      var myShifts = user.myShifts;
      myavailabilityData.map((available) => {
        if (available.uid === user.uid) {
          myShifts.push(available);
        }
      });
      cloneItem.myShifts = myShifts;
      return cloneItem;
    });

    let hideusers = this.state.hideusers.map((user) => {
      var cloneItem = user;
      var myShifts = user.myShifts;
      myavailabilityData.map((available) => {
        if (available.uid === user.uid) {
          myShifts.push(available);
        }
      });
      cloneItem.myShifts = myShifts;
      return cloneItem;
    });

    this.setState({
      usersData,
      hideusers,
      isLoading: false,
      columns,
      myDate: moment(newDate).format("YYYY-MM-DD"),
    });
  };

  userModalOpen = () => {
    this.props.fetchUserRolesData(false);
    var userItem = {
      user_name: "",
      user_email: "",
      user_address: "",
      user_mobile_no: "",
      user_role: 0,
      show_location_schedule: 1,
      exclude_location_alert: 1,
      exclude_timeclock_errors: 1,
      eligible_open_shifts: 1,
    };

    this.setState({
      selectedLocationIds: [parseInt(this.props.scheduler_lid)],
      userItem,
      userModal: true,
      errors: {},
    });
  };

  userModalClose = () => {
    this.setState({
      userModal: false,
    });
  };

  deleteShiftsModalOpen = (item) => {
    var data = {
      uid: item.uid,
      job_id: item.job_id,
    };
    this.setState({
      deleteItem: data,
      deleteModal: true,
    });
  };

  deleteShiftsModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  deleteShifts = () => {
    this.setState({ isLoading: true });
    var data = this.state.deleteItem;
    scheduleServices.deleteShifts(data).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        let deleteUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            user.myShifts.map((item) => {
              if (item.uid === data.uid) {
                var newItems = cloneItem.myShifts.filter(
                  (shift) => data.job_id !== shift.job_id
                );
                cloneItem.myShifts = newItems;
              }
            });
          }
          if (user._id === data.uid) {
            var newItems = cloneItem.myShifts.filter(
              (shift) => data.job_id !== shift.job_id
            );
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        let hideDeleteUsers = this.state.hideusers.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            user.myShifts.map((item) => {
              if (item.uid === data.uid) {
                var newItems = cloneItem.myShifts.filter(
                  (shift) => data.job_id !== shift.job_id
                );
                cloneItem.myShifts = newItems;
              }
            });
          }
          if (user._id === data.uid) {
            var newItems = cloneItem.myShifts.filter(
              (shift) => data.job_id !== shift.job_id
            );
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        this.deleteShiftsModalClose();
        this.setState({
          usersData: deleteUsers,
          hideusers: hideDeleteUsers,
          usersDataTemp: deleteUsers,
          isLoading: false,
        });
      }
    });
  };

  duplicateShifts = (uid, shift) => {
    var startTimeold = new Date(shift.startTime);
    var startTimeee = startTimeold.toLocaleString("en-US", {
      timeZone: this.props.timezone?.[0]?.location_timezone,
    });
    var startTime =
      moment(shift.currentDate).format("YYYY-MM-DD") +
      "T" +
      moment(startTimeee).format("HH:mm:ss") +
      this.props.timezone[0].location_offset;
    var endTimeold = new Date(shift.endTime);
    var endTimeee = endTimeold.toLocaleString("en-US", {
      timeZone: this.props.timezone?.[0]?.location_timezone,
    });
    var endTime =
      moment(shift.currentDate).format("YYYY-MM-DD") +
      "T" +
      moment(endTimeee).format("HH:mm:ss") +
      this.props.timezone[0].location_offset;
    shift["startTime"] = startTime;
    shift["endTime"] = endTime;
    this.setState({ isLoading: true });
    scheduleServices.duplicateShifts(shift).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        this.fetchData()
        // var shift = response.data.post;
        // var role = this.props.rolesData.find(
        //   (roles) => shift.role_id === roles._id
        // );
        // shift.role_bgcolor = role ? role.role_bgcolor : "#B694D1";
        // shift.role_name = role ? role.role_name : "";
        // shift.type = "shifts";
        // let duplicateUsers = this.state.usersData.map((user) => {
        //   var cloneItem = user;
        //   if (user.uid) {
        //     if (user.uid === uid) {
        //       var newItems = [...cloneItem.myShifts, shift];
        //       cloneItem.myShifts = newItems;
        //     }
        //   }
        //   if (user._id === uid) {
        //     var newItems = [...cloneItem.myShifts, shift];
        //     cloneItem.myShifts = newItems;
        //   }
        //   return cloneItem;
        // });
        let hideduplicateUsers = this.state.hideusers.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            if (user.uid === uid) {
              var newItems = [...cloneItem.myShifts, shift];
              cloneItem.myShifts = newItems;
            }
          }
          if (user._id === uid) {
            var newItems = [...cloneItem.myShifts, shift];
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });


        this.setState({
          // usersData: duplicateUsers,
          hideusers: hideduplicateUsers,
          // usersDataTemp: duplicateUsers,
          isLoading: false,
        });
      }
    });
  };

  addShiftsModalOpen = (uid, date) => {
    var myItem = "";
    if (uid === "events") {
      myItem = {
        uid: uid,
        lid: this.props.scheduler_lid,
        title: "title",
        description: "description",
        currentDate: date,
      };
    } else {
      myItem = {
        uid: uid,
        lid: this.props.scheduler_lid,
        role_id: "",
        shift_notes: "",
        currentDate: [date],
        startTime: this.state.settings.enforce_hours_start,
        endTime: this.state.settings.enforce_hours_end,
      };
    }

    this.setState(
      {
        myItem,
        addEditModal: true,
        isEdit: 0,
      },
      () => {
        if (uid !== "events") {
          this.setSelectData(date);
        }
      }
    );
  };

  editEventsModalOpen = (myItem) => {
    myItem.uid = "events";
    this.setState({
      myItem,
      addEditModal: true,
      isEdit: 1,
    });
  };

  editShiftsModalOpen = (item) => {
    if (
      this.props.permissionsData.scheduler_schedule_edit_shifts &&
      !item.clock_in
    ) {
      // var startTime = moment(localTimeToUtc(item.startTime)).format("DD/MM/YYYY, hh:mm:ss A")
      // var endTime = moment(localTimeToUtc(item.endTime)).format("DD/MM/YYYY, hh:mm:ss A")
      var startTimeold = new Date(item.startTime);
      var startTimeee = startTimeold.toLocaleString("en-US", {
        timeZone: this.props.timezone?.[0]?.location_timezone,
      });
      var startTimeeeee = moment(startTimeee).format("DD/MM/YYYY, hh:mm:ss A");
      var endTimeold = new Date(item.endTime);
      var endTimeee = endTimeold.toLocaleString("en-US", {
        timeZone: this.props.timezone?.[0]?.location_timezone,
      });
      var endTimeeeeee = moment(endTimeee).format("DD/MM/YYYY, hh:mm:ss A'");
      var startTime = startTimeeeee;
      var endTime = endTimeeeeee;
      var myItem = {
        job_id: item.job_id,
        uid: item.uid,
        lid: item.lid,
        role_id: item.role_id,
        shift_notes: item.shift_notes,
        startTime,
        endTime,
        currentDate: [moment.utc(item.currentDate).format("YYYY-MM-DD")],
      };
      this.setState(
        {
          myItem,
          startOldTime: startTime,
          endOldTime: endTime,
          addEditModal: true,
          isEdit: 1,
        },
        () => {
          this.setSelectData();
        }
      );
    }
  };

  setSelectData = (date) => {
    var myItem = this.state.myItem;
    var roleSelected = this.props.rolesData.filter(function (role) {
      return role._id === myItem.role_id;
    });

    var startTimeSelected = this.state.timeArray.filter(function (time) {
      return (
        moment(time.id).format("hh:mm:ss A") ===
        moment(myItem.startTime, "DD/MM/YYYY,hh:mm:ss A").format("hh:mm:ss A")
      );
    });

    var endTimeSelected = this.state.timeArray.filter(function (time) {
      return (
        moment(time.id).format("hh:mm:ss A") ===
        moment(myItem.endTime, "DD/MM/YYYY,hh:mm:ss A").format("hh:mm:ss A")
      );
    });

    this.setState(
      {
        errors: {},
        errorsHelper: {},
        roleSelected: roleSelected.length > 0 ? roleSelected[0] : {},
        startTimeSelected:
          startTimeSelected.length > 0 ? startTimeSelected[0] : {},
        endTimeSelected: endTimeSelected.length > 0 ? endTimeSelected[0] : {},
      },
      () => {}
    );
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  handleSubmitUser = async () => {
    var userItem = this.state.userItem;
    var valid_user_name = await validationIsEmpty(userItem.user_name);
    var valid_user_email = await validationEmail(userItem.user_email);
    var valid_user_address = await validationIsEmpty(userItem.user_address);
    var valid_user_mobile_no = await validationIsMobilePhone(
      userItem.user_mobile_no
    );
    var valid_user_role = userItem.user_role === 0 ? true : false;

    if (valid_user_name) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          user_name: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          user_name: "Please enter valid name",
        },
      }));
    }

    if (valid_user_email) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          user_email: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          user_email: "Please enter valid email address",
        },
      }));
    }

    if (valid_user_address) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          user_address: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          user_address: "Please enter valid address",
        },
      }));
    }

    if (valid_user_mobile_no) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          user_mobile_no: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          user_mobile_no: "Please enter valid mobile no",
        },
      }));
    }

    if (valid_user_role) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          user_role: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          user_role: "Please select role",
        },
      }));
    }

    if (this.state.selectedLocationIds.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          locations: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          locations: "Please select location",
        },
      }));
      return;
    }

    if (
      valid_user_name ||
      valid_user_role ||
      valid_user_email ||
      valid_user_address ||
      valid_user_mobile_no
    ) {
      return;
    }
    this.userModalClose();
    this.setState({ isLoading: true });
    userItem["locations"] = this.state.selectedLocationIds;

    scheduleServices.inviteSchedulerUser(userItem).then((response) => {
      if (response.data.success === 2) {
        //  invalidToken(response.data.message)
      } else {
        this.setState({
          isLoading: false,
          usersData: [...this.state.usersData, response.data.user],
          usersDataTemp: [...this.state.usersData, response.data.user],
        });
      }
    });
  };

  handleSubmitEvents = async () => {
    var myItem = this.state.myItem;
    var valid_title = await validationIsEmpty(myItem.title);
    var valid_description = await validationIsEmpty(myItem.description);

    if (valid_title) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          title: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          title: "Please enter valid title",
        },
      }));
    }

    if (valid_description) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          description: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          description: "Please enter valid description",
        },
      }));
    }

    if (valid_title || valid_description) {
      return;
    }
    if (this.state.isEdit) {
      this.editEvents(myItem);
    } else {
      this.addEvents(myItem);
    }
    this.addEditModalClose();
  };

  handleSubmitShifts = async () => {
    var item = this.state.myItem;
    item["startTime"] = item.startTime.toString()
    item["endTime"] = item.endTime.toString()
    var valid_startTime = await validationIsEmpty(item.startTime);
    var valid_endTime = await validationIsEmpty(item.endTime);
    var valid_role_id = await validationIsEmpty(item.role_id);

    // var valid_startTime = validator.isEmpty(item.startTime, { ignore_whitespace: true });
    // var valid_endTime = item.endTime;
    // var valid_role_id = item.role_id;

    if (valid_role_id) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          role_id: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          role_id: "Please select role",
        },
      }));
    }

    if (valid_startTime) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          startTime: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          startTime: "Please select startTime",
        },
      }));
    }

    if (valid_endTime) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          endTime: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          endTime: "Please select endTime",
        },
      }));
    }

    if (valid_startTime || valid_endTime || valid_role_id) {
      return;
    }
    if (this.state.isEdit) {
      var oldStartTime = moment(
        this.state.startOldTime,
        "DD/MM/YYYY hh:mm:ss A"
      ).format("MM/DD/YYYY hh:mm:ss A");
      var oldeEndTime = moment(
        this.state.endOldTime,
        "DD/MM/YYYY hh:mm:ss A"
      ).format("MM/DD/YYYY hh:mm:ss A");
      // item["startTime"] =moment(item.startTime).utc().format('HH:mm:ss')
      // item["endTime"] = moment(item.endTime).utc().format('HH:mm:ss')
      item["startTime"] =
        item.currentDate[0] +
        "T" +
        moment(
          item.startTime === this.state.startOldTime
            ? oldStartTime
            : item.startTime
        ).format("HH:mm:ss") +
        this.props.timezone[0].location_offset;
      item["endTime"] =
        item.currentDate[0] +
        "T" +
        moment(
          item.endTime === this.state.endOldTime ? oldeEndTime : item.endTime
        ).format("HH:mm:ss") +
        this.props.timezone[0].location_offset;
    } else {
      // var startTimeeHour = moment(item.startTime).hour()
      // var startTimeeMin = moment(item.startTime).minute()
      // var time = moment().toDate();  // This will return a copy of the Date that the moment uses
      // time.setHours(10);
      // time.setMinutes(0);
      // time.setSeconds(0);
      // time.setMilliseconds(0);

      // var startTime = moment().format("YYYY-MM-DDTHH:mm:ssZ")
      // startTime.setHours(startTimeeHour);
      // startTime.setMinutes(startTimeeMin);
      // // startTime.setSeconds(0);

      // item["startTime"] = moment(localTimeToUtc(item.startTime)).format("HH:mm:ss")
      // item["startTime"] =  moment(item.startTime).format('YYYY-MM-DDTHH:mm:ssZ')
      item["startTime"] =
        item.currentDate[0] +
        "T" +
        moment(item.startTime).format("HH:mm:ss") +
        this.props.timezone[0].location_offset;
      item["endTime"] =
        item.currentDate[0] +
        "T" +
        moment(item.endTime).format("HH:mm:ss") +
        this.props.timezone[0].location_offset;
      // item["endTime"] = moment(item.endTime).format('YYYY-MM-DDTHH:mm:ssZ')
      // item["endTime"] = moment(localTimeToUtc(item.endTime)).format("HH:mm:ss")
    }

    // myItem["startTime"] = moment.utc(localTimeToUtc(myItem.startTime,this.state.timezone?.[0]?.location_timezone)).format("HH:mm:ss")
    // myItem["endTime"] = moment.utc(localTimeToUtc(myItem.endTime,this.state.timezone?.[0]?.location_timezone)).format("HH:mm:ss")
    // var startTime = new Date(myItem.date);
    // startTime.setDate(startTime.getDate())
    // startTime.setHours(myItem["startTime"].split(":")[0]);
    // startTime.setMinutes(myItem["startTime"].split(":")[1]);
    // startTime.setSeconds(myItem["startTime"].split(":")[2]);
    // startTime = moment(moment(startTime)).utc().format("YYYY-MM-DDTHH:mm:ssZ");
    // var endTime = new Date(myItem.date);
    // endTime.setDate(endTime.getDate())
    // endTime.setHours(myItem["endTime"].split(":")[0]);
    // endTime.setMinutes(myItem["endTime"].split(":")[1]);
    // endTime.setSeconds(myItem["endTime"].split(":")[2]);
    // endTime = moment(moment(endTime)).utc().format("YYYY-MM-DDTHH:mm:ssZ");

    // var addMyShift = {
    //   uid: myItem.uid,
    //   lid: this.props.scheduler_lid,
    //   role_id: myItem.role_id,
    //   shift_notes: myItem.shift_notes,
    //   currentDate: [{
    //     date: moment.utc(myItem.date).format("YYYY-MM-DD"),
    //     endTime: endTime,
    //     startTime: startTime
    //   }]
    // }

    // this.setState({
    //   myItem: addMyShift
    // })

    if (this.state.isEdit) {
      item["currentDate"] = item.currentDate[0];
      this.editShifts(item);
    } else {
      this.addShifts(item);
    }
    this.addEditModalClose();
  };


  addEvents = (data) => {
    this.setState({ isLoading: true });
    scheduleServices.addEvents(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        let addUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user.uid === "events") {
            var newItems = user.myShifts;
            newItems.push(response.data.events);
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        this.setState({
          usersData: addUsers,
          usersDataTemp: addUsers,
          isLoading: false,
        });
      }
    });
  };

  addShifts = (data) => {
    this.setState({ isLoading: true });
    scheduleServices.addShifts(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        let addUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            if (user.uid === data.uid) {
              var newItems = [...cloneItem.myShifts, ...response.data.shifts];
              cloneItem.myShifts = newItems;
            }
          }
          if (user._id === data.uid) {
            var newItems = [...cloneItem.myShifts, ...response.data.shifts];
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        let hideaddUsers = this.state.hideusers.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            if (user.uid === data.uid) {
              var newItems = [...cloneItem.myShifts, ...response.data.shifts];
              cloneItem.myShifts = newItems;
            }
          }
          if (user._id === data.uid) {
            var newItems = [...cloneItem.myShifts, ...response.data.shifts];
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        this.setState({
          usersData: addUsers,
          hideusers: hideaddUsers,
          usersDataTemp: addUsers,
          isLoading: false,
        });
      }
    });
  };

  editShifts = (data) => {
    this.setState({ isLoading: true });
    scheduleServices.editShifts(data).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        var shift = response.data.shift;
        var role = this.props.rolesData.find(
          (roles) => shift.role_id === roles._id
        );
        shift.role_bgcolor = role ? role.role_bgcolor : "#B694D1";
        shift.role_name = role ? role.role_name : "";
        shift.type = "shifts";
        let editUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user.uid) {
            user.myShifts.map((item) => {
              if (item.uid === data.uid) {
                var newItems = user.myShifts.map((item) =>
                  item.job_id === shift.job_id ? { ...shift } : item
                );
                cloneItem.myShifts = newItems;
              }
            });
          }
          if (user._id === data.uid) {
            var newItems = user.myShifts.map((item) =>
              item.job_id === shift.job_id ? { ...shift } : item
            );
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        this.fetchData();
        this.setState({
          usersData: editUsers,
          usersDataTemp: editUsers,
          isLoading: false,
        });
      }
    });
  };

  editEvents = (data) => {
    this.setState({ isLoading: true });
    scheduleServices.editEvents(data).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        var events = response.data.events;
        alert.success(response.data.message);
        let editUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user._id === "events") {
            var newItems = user.myShifts.map((item) =>
              item.job_id === events.job_id ? { ...events } : item
            );
            cloneItem.myShifts = newItems;
          }
          return cloneItem;
        });
        this.setState({
          usersData: editUsers,
          usersDataTemp: editUsers,
          isLoading: false,
        });
      }
    });
  };

  publishShifts = () => {
    var data = {
      lid: this.props.scheduler_lid,
      date: this.state.myDate,
      enforce_start_day: this.state.settings.enforce_start_day,
      status: this.state.publishStatus,
    };
    this.setState({ isLoading: true });
    scheduleServices.publishShifts(data).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        let publishUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          if (user.myShifts.length > 0) {
            var newShifts = user.myShifts.map((shift) => {
              var shiftItem = shift;
              if (shift.job_id) {
                shiftItem.isPublished = 1;
              }
              return shiftItem;
            });
            cloneItem.myShifts = newShifts;
          }
          return cloneItem;
        });
        alert.success(response.data.message);
        this.publishModalClose();
        this.setState({
          usersData: publishUsers,
          usersDataTemp: publishUsers,
          isLoading: false,
        });
      }
    });
  };

  clearUnpublishShifts = () => {
    var data = {
      lid: this.props.scheduler_lid,
      date: this.state.myDate,
      enforce_start_day: this.state.settings.enforce_start_day,
    };
    this.setState({ isLoading: true, unPublishModal: false });
    scheduleServices.clearUnpublishShifts(data).then((response) => {
      if (response.data.success === 2) {
        //  invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        let unPublishUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          var newShifts = cloneItem.myShifts.filter(
            (shift) => shift.isPublished === 1
          );
          cloneItem.myShifts = newShifts;
          return cloneItem;
        });

        this.setState({
          isLoading: false,
          usersData: unPublishUsers,
          usersDataTemp: unPublishUsers,
          toolsAnchor: null,
        });
      }
    });
  };

  clearEntireSchedule = () => {
    var data = {
      lid: this.props.scheduler_lid,
      date: this.state.myDate,
      enforce_start_day: this.state.settings.enforce_start_day,
    };
    this.setState({ isLoading: true, entireModal: false });
    scheduleServices.clearEntireSchedule(data).then((response) => {
      if (response.data.success === 2) {
        //invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        let entireUsers = this.state.usersData.map((user) => {
          var cloneItem = user;
          var newShifts = cloneItem.myShifts.filter((shift) => !shift.job_id);
          cloneItem.myShifts = newShifts;
          return cloneItem;
        });
        this.setState({
          usersData: entireUsers,
          usersDataTemp: entireUsers,
          isLoading: false,
          toolsAnchor: null,
        });
      }
    });
  };

  printModalOpen = () => {
    this.setState({
      printModal: true,
    });
  };

  printModalClose = () => {
    this.setState({
      printModal: false,
    });
  };

  publishModalOpen = () => {
    this.setState({
      publishStatus: 1,
      publishModal: true,
    });
  };

  ifConflictIsThere = () => {
    alert.error("Please resolve conflicts before publishing the shifts")
  }

  publishModalClose = () => {
    this.setState({
      publishModal: false,
    });
  };

  unPublishModalOpen = () => {
    this.setState({
      unPublishModal: true,
    });
  };

  unPublishModalClose = () => {
    this.setState({
      unPublishModal: false,
    });
  };

  clearEntireModalOpen = () => {
    this.setState({
      entireModal: true,
    });
  };

  clearEntireModalClose = () => {
    this.setState({
      entireModal: false,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // if (name === 'email') {
    //   const isEmailValid = value.match(/^([a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$)/);
    //   if (!isEmailValid && value !== '') {
    //     this.setState(prevState => ({
    //       errors: {
    //         ...prevState.errors,
    //         email: true
    //       },
    //       errorsHelper: {
    //         ...prevState.errorsHelper,
    //         email: "Invalid email address"
    //       }
    //     }))
    //   }
    //   else {
    //     this.setState(prevState => ({
    //       errors: {
    //         ...prevState.errors,
    //         email: false
    //       },
    //       errorsHelper: {
    //         ...prevState.errorsHelper,
    //         email: ""
    //       }
    //     }))
    //   }
    // }
    // if (name === 'mobile_no') {
    //   // const isNumberValid = value.match(/\d{10}/);
    //   const isNumberValid = value.match(/^[0-9]{10}$/);
    //   if (!isNumberValid && value !== '') {
    //     this.setState(prevState => ({
    //       errors: {
    //         ...prevState.errors,
    //         mobile_no: true
    //       },
    //       errorsHelper: {
    //         ...prevState.errorsHelper,
    //         mobile_no: "Invalid mobile number"
    //       }
    //     }))
    //   } else {
    //     this.setState(prevState => ({
    //       errors: {
    //         ...prevState.errors,
    //         mobile_no: false
    //       },
    //       errorsHelper: {
    //         ...prevState.errorsHelper,
    //         mobile_no: ""
    //       }
    //     }))
    //   }
    // }
    this.setState({
      [name]: value,
    });
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  toggleCheckbox = (e, name) => {
    let checked = e.target.checked;
    this.setState((prevState) => ({
      userItem: {
        ...prevState.userItem,
        [name]: checked ? 1 : 0,
      },
    }));
  };

  handleChangeUserItem = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      userItem: {
        ...prevState.userItem,
        [state]: value,
      },
    }));
  };

  handleChangeUser = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState({ payroll_id: value });
  };

  toggleUserCheckbox = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;
    this.setState((prevState) => ({
      locationsDetails: {
        ...prevState.locationsDetails,
        [name]: checked ? 1 : 0,
      },
    }));
  };

  handleChangeItem = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleCopyOpen = (event) => {
    console.log("djvfj",event,this.state.columns,this.state.myDate)
    var weekStartDate =
      this.state.columns.length > 0 ? this.state.columns[0].date : "";
    var weekEndDate =
      this.state.columns.length > 0 ? this.state.columns[6].date : "";
    var copyData = [];

    for (var i = 1; i <= 7; i++) {
      var diffDays =  8 * i;
      moment.locale("en");
      var actualTmp = new Date(this.state.myDate).toLocaleString("en-US", {
        timeZone: this.props.timezone?.[0]?.location_timezone,
      });
      var actual = moment(actualTmp).add(diffDays, "days").format("YYYY-MM-DD");

      console.log("hfjnvkfj",weekStartDate)
      var startTmp = new Date(weekStartDate).toLocaleString("en-US", {
        timeZone: this.props.timezone?.[0]?.location_timezone,
      });
      console.log("hfjnvkfj",startTmp)
      var startTime = moment(startTmp).add(diffDays , "days").format("MMM DD");

      var endTmp = new Date(weekEndDate).toLocaleString("en-US", {
        timeZone: this.props.timezone?.[0]?.location_timezone,
      });
      var endTime = moment(endTmp).add(diffDays , "days").format("MMM DD");

      // var actual = moment(this.state.myDate).add(diffDays, 'days').format("YYYY-MM-DD")
      // var startTime = moment(weekStartDate).add(diffDays, 'days').format("MMM DD")
      // var endTime = moment(weekEndDate).add(diffDays, 'days').format("MMM DD")

      var myObject = {
        actual: actual,
        startTime: startTime,
        endTime: endTime,
        diffDays: diffDays,
      };
      console.log("myObject" ,myObject)
      copyData.push(myObject);
    }

    this.setState({
      copyData,
      copyAnchor: event.currentTarget,
    });
  };

  handleCopyClose = (value) => {
    console.log("jcbjd",value)
    if (value) {
      this.pasteToWeek(value);
    }
    this.setState({ copyAnchor: null });
  };

  handleToolsOpen = (event) => {
    this.setState({ toolsAnchor: event.currentTarget });
  };

  handleToolsClose = (value) => {
    if (!value) {
      this.setState({ toolsAnchor: null });
    }
    if (
      value === "events" ||
      value === "open_shifts" ||
      value === "hours_forecast" ||
      value === "wages_forecast" ||
      value === "people_forecast" ||
      value === "employee_total_hrs" ||
      value === "scheduled_employees_only" ||
      value === "break_forecast" ||
      value === "hide_users_employes"
    ) {
      this.updateToolsSettings(value);
    }

    if (value === "clear_entire_schedule") {
      this.clearEntireModalOpen();
    }
    if (value === "clear_unpublished_shifts") {
      this.unPublishModalOpen();
    }
    if (value === "break_settings") {
      this.redirectToBreakSettings();
    }
  };

  redirectToBreakSettings = () => {
    var url = `${WEB_URL}/scheduler/settings/time_tracking`;
    window.open(url, "_blank");
  };

  updateToolsSettings = (state) => {
    let value = this.state.toolbarSettings[state] ? 0 : 1;
    this.setState(
      (prevState) => ({
        toolbarSettings: {
          ...prevState.toolbarSettings,
          [state]: value,
        },
      }),
      () => {
        if (state === "events") {
          this.toggleEvents();
        }
        if (state === "open_shifts") {
          this.toggleOpenShifts();
        }
        if (state === "scheduled_employees_only") {
          this.toggleScheduledEmployeesOnly();
        }
        if (state === "break_forecast") {
          this.toggleBreakForecast();
        }
        var data = {
          toolbarSettings: this.state.toolbarSettings,
        };
        scheduleServices.updateToolsSettings(data).then((response) => {});
      }
    );
  };

  toggleEvents = () => {
    var isChecked = this.state.toolbarSettings.events;
    var { usersData, eventsData } = this.state;
    var updateUserData = usersData;
    if (isChecked) {
      var obj_events = {
        uid: "events",
        user_name: "Events",
        user_image: "",
        myShifts: eventsData,
      };
      var uid = usersData.uid;
      if (uid in usersData && usersData.uid === "0") {
        usersData.splice(0, 0, obj_events);
      } else {
        usersData.splice(0, 0, obj_events);
      }
      this.setState({
        usersData: updateUserData,
        usersDataTemp: updateUserData,
      });
    } else {
      usersData = usersData.filter((user) => user.uid !== "events");
      this.setState({
        usersData: usersData,
        usersDataTemp: usersData,
      });
    }
  };

  toggleOpenShifts = () => {
    var isChecked = this.state.toolbarSettings.open_shifts;
    var { usersData, eventsData, openShifts } = this.state;
    if (isChecked) {
      var obj_open_shifts = {
        uid: "0",
        user_name: "Open Shifts",
        user_image: "",
        myShifts: openShifts,
      };
      var uid = usersData[0].uid;
      if (usersData[0].uid === "events") {
        usersData.splice(1, 0, obj_open_shifts);
      } else {
        usersData.splice(0, 0, obj_open_shifts);
      }
      this.setState({
        usersData: usersData,
        usersDataTemp: usersData,
      });
    } else {
      usersData = usersData.filter((user) => user.uid !== "0");
      this.setState({
        usersData: usersData,
        usersDataTemp: usersData,
      });
    }
  };

  toggleScheduledEmployeesOnly = () => {
    var isChecked = this.state.toolbarSettings.scheduled_employees_only;
    if (isChecked) {
      var usersData = this.state.usersData.filter(function (user) {
        var myShiftsLength = user.myShifts.filter(
          (childitem) => childitem.type === "shifts"
        );
        if (myShiftsLength.length > 0) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        usersData,
      });
    } else {
      this.setState({
        usersData: this.state.usersDataTemp,
      });
    }
  };

  toggleBreakForecast = () => {};

  printData = (pdfStyles) => {
    this.setState({
      printModal: false,
    });
    const tableColumn = [""];
    const tableRows = [];
    var rolesData = this.props.rolesData;

    this.state.columns.map((column) => {
      const date = column.date + "  " + column.dayName;
      tableColumn.push(date);
    });

    this.state.usersData.map((user, index) => {
      var weekArray = [];
      weekArray.push(user.user_name.trim());

      this.state.columns.map((days) => {
        var dataInner = [""];
        user.myShifts.map((shift) => {
          if (shift.type === "shifts" && shift.isPublished) {
            var startTimeold = new Date(shift.startTime);
            var startTimeee = startTimeold.toLocaleString("en-US", {
              timeZone: this.props.timezone?.[0]?.location_timezone,
            });
            var startTime = moment(startTimeee).format("hh:mm A");
            var endTimeold = new Date(shift.endTime);
            var endTimeee = endTimeold.toLocaleString("en-US", {
              timeZone: this.props.timezone?.[0]?.location_timezone,
            });
            var endTime = moment(endTimeee).format("hh:mm A");

            // var newStart = moment(shift.startTime).format("h:mmA")
            // var newEnd = moment(shift.endTime).format("h:mmA")

            var time =
              "\n" +
              startTime +
              " - " +
              endTime +
              "   " +
              shift.role_name +
              "\n";
            if (days.date === moment(shift.currentDate).format("YYYY-MM-DD")) {
              dataInner += time;
            }
          }
        });

        weekArray.push(dataInner);
      });
      tableRows.push(weekArray);
    });

    const doc = new jsPDF("l", "mm", "a4");
    doc.text("test harshad", 14, 15);
    doc.autoTable({
      theme: "plain",
      headStyles: {
        minCellHeight: 20,
        fillColor: "gray",
        fontSize: 12,
        textColor: "white",
        cellPadding: 3,
      },
      styles: {
        lineWidth: 0.001,
        lineColor: "silver",
        cellWidth: 34,
        fontSize: 9,
        minCellHeight: 15,
        halign: "center",
        valign: "middle",
        textColor: "black",
      },
      columnStyles: { 0: { halign: "center" } },
      margin: { top: 10 },

      didParseCell: function (data) {
        if (pdfStyles) {
          data.table.body.map((item, index) => {
            item.raw.map((raw, myIndex) => {
              if (myIndex && raw !== "") {
                rolesData.map((role, role_index) => {
                  if (raw.includes(role.role_name)) {
                    if (pdfStyles === 1) {
                      item.cells[myIndex].styles.fillColor = role.role_bgcolor;
                      item.cells[myIndex].styles.textColor = "#ffffff";
                    } else {
                      item.cells[myIndex].styles.lineColor = role.role_bgcolor;
                      item.cells[myIndex].styles.lineWidth = 0.5;
                    }
                  }
                });
              }
            });
          });
        }
      },
      head: [tableColumn],
      body: tableRows,
    });
    const date = Date().split(" ");
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    doc.save(`report_${dateStr}.pdf`);
  };

  prevClick = () => {
    var myDate = moment(this.state.myDate)
      .subtract(7, "days")
      .locale("en-US")
      .format("YYYY-MM-DD");
    var url = `/scheduler/scheduler?date=` + myDate + ``;
    this.setState({ myDate }, () => {
      this.fetchData();
      history.push(url);
    });
  };

  nextClick = () => {
    var myDate = moment(this.state.myDate)
      .add(7, "days")
      .locale("en-US")
      .format("YYYY-MM-DD");
    var url = `/scheduler/scheduler?date=` + myDate + ``;
    this.setState({ myDate }, () => {
      this.fetchData();
      history.push(url);
    });
  };

  todayClick = () => {
    var myDate = moment().format("YYYY-MM-DD");
    var url = `/scheduler/scheduler?date=` + myDate + ``;
    this.setState({ myDate }, () => {
      this.fetchData();
      history.push(url);
    });
  };

  handleChangeRoles = (newValue) => {
    var role_id = newValue ? newValue._id : "";
    var role_bgcolor = newValue ? newValue.role_bgcolor : "";

    var roleSelected = newValue ? newValue : null;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        role_id,
        role_bgcolor,
      },
      roleSelected,
    }));
  };

  handleChangeStartTime = (newValue) => {
    var startTime = newValue ? newValue.id : "";
    var endTime = this.state.myItem.endTime;
    var startTimeSelected = newValue ? newValue : null;
    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          startTime,
          endTime,
        },
        startTimeSelected,
      }),
      () => {}
    );
  };

  handleChangeEndTime = (newValue) => {
    var endTime = newValue ? newValue.id : "";
    var startTime = this.state.myItem.startTime;
    var endTimeSelected = newValue ? newValue : null;
    this.setState(
      (prevState) => ({
        myItem: {
          ...prevState.myItem,
          endTime,
          startTime,
        },
        endTimeSelected,
      }),
      () => {}
    );
  };

  handleChangeDates = (date, isChecked) => {
    var currentDate = [];
    if (!this.state.isEdit) {
      currentDate = this.state.myItem.currentDate;
    }
    if (isChecked) {
      currentDate.push(date);
    } else {
      currentDate.splice(currentDate.indexOf(date), 1);
    }

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        currentDate: false,
      },
    }));

    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        currentDate,
      },
    }));
  };

  pasteToWeek = (value) => {
    var pasteData = [];
    this.state.usersData.map((user) => {
      user.myShifts.map((shift) => {
        if (shift.type === "shifts") {
          var random_job_id = Math.floor(Math.random() * 89999999 + 10000000);
          var newStartTime = moment(shift.startTime, "YYYY-MM-DD HH:mm:ss")
            .add(value.diffDays -1 , "days")
            .format("YYYY-MM-DD HH:mm:ss");
          var newEndTime = moment(shift.endTime, "YYYY-MM-DD HH:mm:ss")
            .add(value.diffDays -1, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          var newCurrentDate = moment(shift.currentDate, "YYYY-MM-DD")
            .add(value.diffDays - 1 , "days")
            .format("YYYY-MM-DD");

           var newStartTimeZone =  moment(newStartTime).format("YYYY-MM-DD") +
            "T" +
            moment(newStartTime).format("HH:mm:ss") + "+00:00"

            var newendTimeZone =  moment(newEndTime).format("YYYY-MM-DD") +
            "T" +
            moment(newEndTime).format("HH:mm:ss")  + "+00:00"

            // var newCurrentDateZone =  moment(newCurrentDate).format("YYYY-MM-DD") +
            // "T" +
            // moment(newCurrentDate).format("HH:mm:ss") +
            // this.props.timezone[0].location_offset;

          var post = {
            job_id: random_job_id,
            cid: shift.cid,
            uid: shift.uid,
            lid: shift.lid,
            total_scheduled_hrs: shift.total_scheduled_hrs,
            role_id: shift.role_id,
            shift_notes: shift.shift_notes,
            startTime: newStartTimeZone,
            endTime: newendTimeZone,
            currentDate: newCurrentDate,
            createBy: shift.createBy,
          };
          pasteData.push(post);
        }
      });
    });

    if (pasteData.length > 0) {
      scheduleServices.pasteToWeek(pasteData).then((response) => {
        if (response.data.success === 2) {
          //invalidToken(response.data.message)
        } else {
          alert.success(response.data.message);
          var myDate = moment(value.actual).format("YYYY-MM-DD");
          var url = `/scheduler/scheduler?date=` + myDate + ``;
          this.setState(
            {
              myDate,
            },
            () => {
              this.fetchData();
              history.push(url);
            }
          );
        }
      });
    } else {
      alert.error("you have not enough schedule to publish");
    }
  };

  onDragEnd = (result) => {
    this.setState({ isLoaded: false });
    const { draggableId, source, destination } = result;
    if (!destination) {
      return;
    }
    var newEvent = JSON.parse(draggableId);
    var mySrc = JSON.parse(source.droppableId);
    var myDest = JSON.parse(destination.droppableId);
    var currentDate = myDest.date;
    var newUser = myDest.uid ? myDest.uid : "0";
    var oldUser = mySrc.uid;
    newEvent["uid"] = newUser;
    newEvent["currentDate"] = currentDate;
    var data = {
      uid: newUser,
      job_id: newEvent.job_id,
      currentDate: currentDate,
    };
    var usersData = this.state.usersData.map(function (item) {
      var cloneItem = item;
      if (item._id === oldUser) {
        var newItems = cloneItem.myShifts.filter(
          (childitem) => newEvent.job_id !== childitem.job_id
        );
        cloneItem.myShifts = newItems;
      }
      if (item.uid === newEvent.uid) {
        newEvent["isPublished"] = 0;
        cloneItem.myShifts.push(newEvent);
      }
      if (item._id === newEvent.uid) {
        newEvent["isPublished"] = 0;
        cloneItem.myShifts.push(newEvent);
      }
      return cloneItem;
    });
    var hideusers = this.state.hideusers.map(function (item) {
      var cloneItem = item;
      if (item._id === oldUser) {
        var newItems = cloneItem.myShifts.filter(
          (childitem) => newEvent.job_id !== childitem.job_id
        );
        cloneItem.myShifts = newItems;
      }
      if (item.uid === newEvent.uid) {
        newEvent["isPublished"] = 0;
        cloneItem.myShifts.push(newEvent);
      }
      if (item._id === newEvent.uid) {
        newEvent["isPublished"] = 0;
        cloneItem.myShifts.push(newEvent);
      }
      return cloneItem;
    });

    this.setState({
      usersData,
      hideusers,
      usersDataTemp: usersData,
    });
    scheduleServices.dragAndDropShift(data).then((response) => {});
  };


  onRowDragOver = (index) => {
    const draggedOverItem = this.state.usersData[index];
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    let usersData = this.state.usersData.filter(
      (item) => item !== this.draggedItem
    );
    usersData.splice(index, 0, this.draggedItem);
    this.setState({ usersData }, () => {
      const priority_id = this.state.usersData.map((items) => {
        if (items._id) {
          return items._id;
        }
      });
      this.setState({
        priotyIds: priority_id,
        dragLoader: false,
      });
    });
  };

  onRowDragOverHide = (index) => {
    const draggedOverItem = this.state.hideusers[index];
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    let hideusers = this.state.hideusers.filter(
      (item) => item !== this.draggedItem
    );
    hideusers.splice(index, 0, this.draggedItem);
    this.setState({ hideusers }, () => {
      const priority_id = this.state.hideusers.map((items) => {
        if (items._id) {
          return items._id;
        }
      });
      this.setState({
        priotyIdshide: priority_id,
        dragLoader: false,
      });
    });
  };

  onRowDragStartHide = (e, index) => {
    this.draggedItem = this.state.hideusers[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onRowDragStart = (e, index) => {
    this.draggedItem = this.state.usersData[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onRowDragEnd = () => {
    this.draggedIdx = null;
    setTimeout(() => {
      var data = {
        priority: this.state.priotyIds,
        lid: this.props.scheduler_lid,
      };
      scheduleServices.setUsersPriority(data).then((response) => {
        if (response.data.success === 2) {
          //invalidToken(response.data.message)
        } else {
          // alert.success(response.data.message)
        }
      });
    }, 500);
  };

  onRowDragEndHide = () => {
    this.draggedIdx = null;
    setTimeout(() => {
      var data = {
        priority: this.state.priotyIdshide,
        lid: this.props.scheduler_lid,
      };
      scheduleServices.setUsersPriority(data).then((response) => {
        if (response.data.success === 2) {
          //invalidToken(response.data.message)
        } else {
          // alert.success(response.data.message)
        }
      });
    }, 500);
  };

  handleUsersLocation = (event) => {
    const value = event.target.value;
    this.setState({
      selectedLocationIds: value,
    });
  };

  openUsernameModal = (user) => {
    let data = {
      uid: user._id,
      lid: this.props.scheduler_lid,
    };

    this.props.fetchUser(data);

    this.setState({
      usernameModal: true,
      user: user,
    });
  };

  closeUsernameModal = () => {
    this.setState({
      usernameModal: false,
    });
  };

  handleSubmitUserView = () => {
    const { user, scheduleData, locationsDetails, payroll_id, unique_pin } =
      this.state;
    // const { locationsDetails } = this.props;
    let data = {
      uid: user._id,
      lid: this.props.scheduler_lid,
      unique_pin,
      show_location_schedule: locationsDetails.show_location_schedule,
      exclude_location_alert: locationsDetails.exclude_location_alert,
      exclude_timeclock_errors: locationsDetails.exclude_timeclock_errors,
      eligible_open_shifts: locationsDetails.eligible_open_shifts,
      payroll_id,
    };
    this.setState({
      locationsDetails: {},
    });
    this.props.updateUserPayroll(data);
    this.fetchData();
    this.closeUsernameModal();
  };

  //   static getDerivedStateFromProps(props, state) {
  //     if(!props.isLoading && Object.keys(state.locationsDetails).length === 0) {
  //         //Change in props
  //         return{
  //             locationsDetails: {
  //               ...props.locationsDetails
  //             }
  //         };
  //     }
  //     return null; // No change to state
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.locationsDetails) !==
      JSON.stringify(this.props.locationsDetails)
    ) {
      this.setState({
        usernameModal: true,
        locationsDetails: {
          ...this.props.locationsDetails,
        },
        payrollData: [...this.props.payrollData],
        payroll_id: this.props.payroll_id,
        unique_pin: this.props.unique_pin,
      });
    }
  }

  render() {
    const {
      classes,
      userRolesData,
      usersLocations,
      permissionsData,
      rolesData,
      timezone,
    } = this.props;
    const {
      weatherData,
      isLoading,
      usersData,
      hideusers,
      userItem,
      timeArray,
      startTimeSelected,
      endTimeSelected,
      columns,
      myItem,
      roleSelected,
      errorsHelper,
      errors,
      unique_pin,
      locationsDetails,
      payrollData,
    } = this.state;
    var isFeatureWeek =
      this.state.columns.length > 0
        ? moment() < moment(this.state.columns[0].date)
        : false;
    var totalUnpublishedShifts = 0;
    var isConflicts = false;
    var totalUnpublishedScheduleHours = 0;

    var userName = "";
    var userImage = "";
    usersData.map((user) => {
      user?.myShifts?.map((shift) => {
        if (!shift.isPublished && shift.type === "shifts") {
          totalUnpublishedScheduleHours =
            parseFloat(totalUnpublishedScheduleHours) +
            parseFloat(shift.total_scheduled_hrs);
          totalUnpublishedShifts = totalUnpublishedShifts + 1;
        }
      });
      if (user.uid === myItem.uid) {
        userName = user.user_name;
        userImage = user.user_image;
      }
      if (user._id === myItem.uid) {
        userName = user.user_name;
        userImage = user.user_image;
      }
    });

    usersData.map((conflicts) => {
      conflicts?.myShifts?.map((shift) => {
        if (shift.is_conflict_other_lid && shift.type === "shifts") {
          isConflicts = true
        }
      });
    })

    var hideuserName = "";
    var hideuserImage = "";
    hideusers.map((user) => {
      user?.myShifts?.map((shift) => {
        if (!shift.isPublished && shift.type === "shifts") {
          totalUnpublishedScheduleHours =
            parseFloat(totalUnpublishedScheduleHours) +
            parseFloat(shift.total_scheduled_hrs);
          totalUnpublishedShifts = totalUnpublishedShifts + 1;
        }
      });
      if (user.uid === myItem.uid) {
        hideuserName = user.user_name;
        hideuserImage = user.user_image;
      }
      if (user._id === myItem.uid) {
        hideuserName = user.user_name;
        hideuserImage = user.user_image;
      }
    });

    if (myItem.uid === "events") {
      userName = "Events";
    }

    if (isLoading) return <LoadingData />;
    if (this.state.dragLoader) return <LoadingData />;
    return permissionsData.scheduler_schedule ? (
      <Grid container spacing={2} className={classes.root}>
        <Grid style={{ textAlign: "end" }} item sm={12} xs={12}>
          <SchedulerToolbar
            columns={this.state.columns}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            todayClick={this.todayClick}
            totalUnpublishedShifts={totalUnpublishedShifts}
            isConflicts={isConflicts}
            toolbarData={this.state.toolbarData}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <Scheduler
            permissionsData={permissionsData}
            toolbarSettings={this.state.toolbarSettings}
            columns={columns}
            usersData={usersData}
            timezone={timezone}
            hideusers={this.state.hideusers}
            deleteShiftsModalOpen={this.deleteShiftsModalOpen}
            duplicateShifts={this.duplicateShifts}
            addShiftsModalOpen={this.addShiftsModalOpen}
            editShiftsModalOpen={this.editShiftsModalOpen}
            editEventsModalOpen={this.editEventsModalOpen}
            weatherData={weatherData}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
            onRowDragOver={this.onRowDragOver}
            onRowDragStart={this.onRowDragStart}
            onRowDragEnd={this.onRowDragEnd}
            onRowDragOverHide={this.onRowDragOverHide}
            onRowDragStartHide={this.onRowDragStartHide}
            onRowDragEndHide={this.onRowDragEndHide}
            openUsernameModal={this.openUsernameModal}
          />
        </Grid>

        <ToolsMenus
          handleToolsClose={this.handleToolsClose}
          toolsAnchor={this.state.toolsAnchor}
          toolsData={this.state.toolsData}
          isFeatureWeek={isFeatureWeek}
          toolbarSettings={this.state.toolbarSettings}
        />

        <CopyMenus
          handleCopyClose={this.handleCopyClose}
          copyAnchor={this.state.copyAnchor}
          copyData={this.state.copyData}
        />
        {/* <MyLeftModal
            bgColor={schedulerColor.main}
            title=""
            open={this.state.usernameModal}
            onClose={this.closeUsernameModal}
            handleSubmit={this.handleSubmitUserView}
          >
            <UserDetails
              // userName={userName}
              // userImage={userImage}
              // userItem={userItem}
              toggleUserCheckbox={this.toggleUserCheckbox}
              selectedLocationIds={this.state.selectedLocationIds}
              handleUsersLocation={this.handleUsersLocation}
              usersLocations={usersLocations}
              rolesData={userRolesData}
              handleFocus={this.handleFocus}
              handleChangeUser={this.handleChangeUser}
              errors={errors}
              errorsHelper={errorsHelper}
              user={this.state.user}
              payrollData={this.state.payrollData}
              locationsDetails={this.state.locationsDetails}
              scheduleData={this.state.scheduleData}
              unique_pin={this.state.unique_pin}
              payroll_id={this.state.payroll_id}
            />
          </MyLeftModal> */}

        <MyLeftModalWithUser
          bgColor={schedulerColor.main}
          userName={userName ? userName : hideuserName}
          userImage={userImage ? userImage : hideuserImage}
          title={this.state.isEdit ? "Edit Shift" : "Add Shift"}
          open={this.state.addEditModal}
          onClose={this.addEditModalClose}
          handleSubmit={
            this.state.myItem.uid === "events"
              ? this.handleSubmitEvents
              : this.handleSubmitShifts
          }
        >
          {this.state.myItem.uid === "events" ? (
            <AddEditEvents
              errors={errors}
              errorsHelper={errorsHelper}
              myItem={myItem}
              handleFocus={this.handleFocus}
              handleChangeItem={this.handleChangeItem}
            />
          ) : (
            <AddEditShifts
              timeArray={timeArray}
              errors={errors}
              errorsHelper={errorsHelper}
              roleSelected={roleSelected}
              startTimeSelected={startTimeSelected}
              endTimeSelected={endTimeSelected}
              columns={columns}
              rolesData={rolesData}
              usersData={usersData}
              myItem={myItem}
              handleFocus={this.handleFocus}
              handleChangeDates={this.handleChangeDates}
              handleChangeItem={this.handleChangeItem}
              handleChangeRoles={this.handleChangeRoles}
              handleChangeStartTime={this.handleChangeStartTime}
              handleChangeEndTime={this.handleChangeEndTime}
              addMyShift={this.state.addMyShift}
            />
          )}
        </MyLeftModalWithUser>

        <DeleteModal
          bgColor={schedulerColor.main}
          desc="Are you sure you want to delete?"
          open={this.state.unPublishModal}
          onClose={this.unPublishModalClose}
          handleDelete={this.clearUnpublishShifts}
        />

        <DeleteModal
          bgColor={schedulerColor.main}
          desc="Are you sure you want to delete?"
          open={this.state.entireModal}
          onClose={this.clearEntireModalClose}
          handleDelete={this.clearEntireSchedule}
        />

        <DeleteModal
          bgColor={schedulerColor.main}
          desc="Are you sure you want to delete?"
          open={this.state.deleteModal}
          onClose={this.deleteShiftsModalClose}
          handleDelete={this.deleteShifts}
        />

        <MyPlain25Modal
          bgColor={schedulerColor.main}
          title="Print Schedule"
          open={this.state.printModal}
          onClose={this.printModalClose}
        >
          <PrintSchedule
            printData={this.printData}
            printList={this.state.printList}
          />
        </MyPlain25Modal>

        <MyPlain25Modal
          bgColor={schedulerColor.main}
          title="Publish This Schedule"
          open={this.state.publishModal}
          onClose={this.publishModalClose}
        >
          <PublishSchedule
            publishStartDate={
              this.state.columns.length > 0 ? this.state.columns[0].date : ""
            }
            publishEndDate={
              this.state.columns.length > 0 ? this.state.columns[6].date : ""
            }
            totalUnpublishedScheduleHours={parseFloat(
              totalUnpublishedScheduleHours
            ).toFixed(2)}
            totalUnpublishedShifts={totalUnpublishedShifts}
            publishShifts={this.publishShifts}
            handleChange={this.handleChange}
            publishStatus={this.state.publishStatus}
            publishNotificationsList={this.state.publishNotificationsList}
          />
        </MyPlain25Modal>

        <MyLeftModal
          bgColor={schedulerColor.main}
          title="Add User"
          open={this.state.userModal}
          onClose={this.userModalClose}
          handleSubmit={this.handleSubmitUser}
        >
          <AddUser
            toggleCheckbox={this.toggleCheckbox}
            selectedLocationIds={this.state.selectedLocationIds}
            handleUsersLocation={this.handleUsersLocation}
            usersLocations={usersLocations}
            rolesData={userRolesData}
            handleFocus={this.handleFocus}
            handleChangeUserItem={this.handleChangeUserItem}
            errors={errors}
            errorsHelper={errorsHelper}
            userItem={userItem}
          />
        </MyLeftModal>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.viewUserData.isLoading,
  rolesData: state.schedulerRoles.rolesData,
  userRolesData: state.settingsRoles.rolesData,
  usersLocations: state.settingsLocation.locationData,
  scheduleData: state.viewUserData.scheduleData,
  payrollData: state.viewUserData.payrollData,
  locationsDetails: state.viewUserData.locationsDetails,
  unique_pin: state.viewUserData.unique_pin,
  payroll_id: state.viewUserData.payroll_id,
});

const ConnectWith = connect(mapStateToProps, {
  fetchRolesData,
  fetchUserRolesData,
  fetchUser,
  updateUserPayroll,
})(Schedule);
export default withStyles(styles)(ConnectWith);
