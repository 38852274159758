import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FaUserAlt } from "react-icons/fa";
import {
  Grid,
  Card,
  LinearProgress,
  Button,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  startButton: {
    textTransform: "capitalize",
    marginTop: "7px",
    padding: "10px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    border: "1px solid #ebebeb",
    boxSizing: "borderBox",
    // boxShadow: "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
    borderRadius: "0px 0px 10px 10px",
    backgroundColor: "#245193",
    border: "1 px solid #245193",
    color: "white"
    // '&:hover': {
    //   backgroundColor: '#fcfcfc'
    // }
  },
  Card: {
    margin: '5px 10px',
    borderRadius: '10px',
    backgroundColor: "#ffffff",
    borderColor: "1px solid rgba(0,0,0,0.12)",
    boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.1), 0px 24px 48px rgba(44, 39, 56, 0.12)",
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  },
  media: {
    borderRadius: '8px',
    margin: "20px 20px 5px 20px",
    backgroundSize: '100% 100%',
    height: "155px"
  },
  CardContent: {
    padding: "0px 20px",
    "&:last-child": {
      paddingBottom: '10px'
    },
  },
  courseTitle: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#2C2738",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "capitalize",
    lineHeight: "20px"
  },
  courseDescription: {
    fontSize: "10px",
    lineHeight: '15px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '30px',
    color: "#000000"
  },
  courseAuthor: {
    fontWeight: 500,
    float: 'left',
    fontSize: '14px',
    lineHeight: '22px',
    color: "gray",
    height: "24px"
  },
  percentage: {
    fontWeight: 500,
    float: 'right',
    fontSize: '12px',
    lineHeight: '22px',
    color: "black"
  },
  profileDiv: {
    backgroundColor: "gray",
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    textAlign: "center"
  },
  progress: {
    height: '10px',
    borderRadius: '0px',
    margin: '10px 0px',
    backgroundColor: '#d9d9d9'
  },
  barColorGreen: {
    backgroundColor: 'green'
  },
  barColorRed: {
    backgroundColor: 'red'
  },
  barColorOrange: {
    backgroundColor: 'orange'
  },
  bgColorBlue: {
    backgroundColor: '#245193'
  }
}));





const MyCourseCard = props => {
  const { data } = props;
  const classes = useStyles();
  var progress = data.total_lesson > 0 ? (data.completed_lesson / data.total_lesson * 100) : 0
  return (
    <>

      <Card className={classes.Card}>
        <CardMedia
          className={classes.media}
          image={data?.ecid?.image}
        />

        <CardContent className={classes.CardContent} >
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography
                className={classes.courseTitle}
                component="p"
              >
                {data.ecid.category_name}
              </Typography>
            </Grid>

            {/* <Grid
            item
            sm={12} xs={12}>
            <Typography
              className={classes.courseDescription}
            >
              {data.desk_id.desk_description}
            </Typography>
          </Grid> */}

            <Grid item sm={12} xs={12} >
              <Typography
                display="inline"
                className={classes.courseAuthor}
              >
                By&nbsp;{data.author}
                {/* How to make beef burger */}
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12} >
              <Typography
                display="inline"
                className={classes.courseAuthor}
              >
                <div className={classes.profileDiv}>
                  <FaUserAlt color='white' />
                </div>
                &nbsp;{data.author}
              </Typography>

              <Typography
                display="inline"
                className={classes.percentage}
              // style={{ float: 'right', color: parseInt(progress) === 0 ? 'red' : parseInt(progress) === 100 ? 'green' : 'black' }}
              >
                {
                  (data.completed_lesson / data.total_lesson) * 100
                }%
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12}>
              {/* {data._id} */}
              <LinearProgress
                fullWidth
                classes={{
                  // barColorPrimary: parseInt(progress) === 0 ? classes.barColorRed : parseInt(progress) === 100 ? classes.barColorGreen : classes.bgColorBlue
                  barColorPrimary: classes.bgColorBlue
                }}
                className={classes.progress}
                // style={{ border: parseInt(progress) === 0 ? '1px solid red' : parseInt(progress) === 100 ? '1px solid green' : '1px solid orange' }}
                value={progress}
                variant="determinate"
              />
            </Grid>
          </Grid>
        </CardContent>

        <Grid item sm={12} xs={12}>
          <Button
            // style={{ color: parseInt(progress) === 0 ? 'red' : parseInt(progress) === 100 ? 'green' : 'orange' }}
            fullWidth
            variant="contained"
            onClick={() => { props.getLessonByCourses(data) }}
            className={classes.startButton}
          >
            {parseInt(progress) === 0 ? "Start" : parseInt(progress) === 100 ? "View" : "Continue"}
          </Button>
        </Grid>
      </Card>
    </>
  );
};

export default MyCourseCard;
