import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
        Grid,Button
} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom'
const settings = {
        dots: false,
        infinite: false,
        width: "200px",
        height: "200px",
        speed: 300,
        arrows:false,
        slidesToShow: 6,
        slidesToScroll: 1,
};

const styles = (theme) => ({
        mainRoot: {
                margin:'1rem'
            },
        root: {
                //backgroundColor: '#171a29',
                display: 'block',
                padding: "10px 64px",
                //paddingLeft: 130, paddingRight: 130,
                // [theme.breakpoints.down('md')]: {
                //         paddingLeft: 40,
                //         paddingRight: 40
                // }
        },
        image: {
                marginLeft: '1.5rem',
                cursor: 'pointer',
                display: 'block',
                width: '250px',
                height: '250px',
                '&:hover': {
                        transform: 'scale(1.09)',
                        backfaceVisibility: 'visible',
                },
                [theme.breakpoints.down('md')]: {
                        width: '180px',
                        height: '180px',
                        cursor: 'pointer',

                },
                [theme.breakpoints.down('sm')]: {
                        width: '120px',
                        height: '120px',
                        cursor: 'pointer',

                }
        }
})

class SliderDemo extends Component {
        constructor(props) {
                super(props)
                this.state = {
                }
        }

  

              forward = ()=>{
                this.props.showNewCard("Next")
            }
            backward = ()=>{ 
                this.props.showNewCard("Prev")
            }

        render() {
                const { classes, sliderData ,is_select_card,getData} = this.props;
                return (
                        <Grid container style={{marginLeft:'auto',marginRight:'auto',marginBottom:'1rem',position:"relative"}} spacing={2} className={classes.root}>
                            <Button style={{padding:'0',minWidth:'50px',position: "absolute", zIndex: 999,left: "22px",top: "50%",transform: "translate(0%, -50%)"}}
                            onClick={()=> this.backward()}
                            >
        
                                <ArrowLeftIcon style={{ fontSize: "50px", cursor: 'pointer' }}  />
                            </Button>
                            <Slider {...settings}>
                                {sliderData?.map((row) => {
                            return (
                                <Grid item>
                                <div style={{ padding: "15px", backgroundColor: "white", paddingTop: "2.3%" }}
                                    onClick={() => this.props.clickCard(row,this.props.payload_ids)}
                                    >
                                    <div
                                        style={{
                                            width: 169,
                                            height: 86,
                                            cursor: "pointer",
                                            border: "1px solid black",
                                            textAlign: "center",
                                            lineHeight: "28px",
                                            boxSizing: "border-box",
                                            visibility: "block",
                                            background: is_select_card === row.Date ? 'lightgray' : "white"
                                        }}
                                    >
                                        <div 
                                        onClick={() => getData(row)}
                                        >
                                            <div style={{ fontWeight: 700 }} 
                                            onClick={() => 
                                            getData(row)}
                                            >
                                                {row.Month}</div>
                                            <div >{row.Date}</div>
                                            {row.Status === 0 ?
                                                <div style={{ color: 'white', background: '#b71c1c' }}>
                                                    Pending</div>
                                                :
                                                <div style={{ color: 'green', background: '#E3E0E0' }}><CheckCircleIcon
                                                    style={{ marginRight: 8 }}
                                                />Completed</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                </Grid>
                            )
                        })}

                                </Slider>
                                <Button
                                
                               style={{padding:'0',minWidth:'50px',position: "absolute", zIndex: 999,right: "22px",top: "50%",transform: "translate(0%, -50%)"}}
                                onClick={() => this.forward()}
                                >
        
                                <ArrowRightIcon style={{ fontSize: "50px", cursor: 'pointer' }} />
                                </Button>
                        </Grid>
                    
                  
             
                );
        }
}
export default withStyles(styles)(SliderDemo);
