import React, { Component } from 'react'
import {
        TablePagination
}
        from '@material-ui/core';
import { onChangePage, onChangeRowsPerPage } from './modules/actions';
import { connect } from "react-redux";

class TableMyPagination extends Component {
        render() {
                const { count, pageNo, rowsPerPage } = this.props;
                return (
                        <div>
                                <TablePagination
                                        SelectProps={{
                                                style: {
                                                        fontSize: "10px",
                                                        lineHeight: "15px"
                                                }
                                        }}
                                        labelRowsPerPage="Rows"
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNo}
                                        onChangePage={this.props.onChangePage}
                                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                                />
                        </div>
                )
        }
}

const mapStateToProps = (state) => ({
        pageNo: state.localData.pageNo,
        rowsPerPage: state.localData.rowsPerPage
});

export default connect(mapStateToProps, { onChangePage, onChangeRowsPerPage })(TableMyPagination);