import {
  SET_TODO_DASHBOARD_LOADING,
  SET_TODO_DASHBOARD,
  ADD_TODO_DASHBOARD,
  EDIT_TODO_DASHBOARD,
  DELETE_TODO_DASHBOARD,
  ADD_TODO_SUBTASKS,
  DELETE_TODO_SUBTASKS,
  TOGGLE_TODO_SUBTASKS,
  TOGGLE_TODO,
  SET_TODO_LIST,
  EDIT_TODO_LIST,
  DELETE_TODO_LIST,
  ADD_TODO_LIST
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { todoServices } from './services'

export function fetchTodoList() {
  return function (dispatch) {
    todoServices.fetchTodoList().then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_TODO_LIST,
          payload: response.data
        });
      }
    });
  };
}

export function fetchData(id, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_TODO_DASHBOARD_LOADING,
        payload: true
      });
    }
    todoServices.fetchData(id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_TODO_DASHBOARD,
          payload: response.data
        });
      }
    });
  };
}

export function deleteSubtaks(data) {
  return function (dispatch) {
    dispatch({
      type: DELETE_TODO_SUBTASKS,
      payload: data
    });
  };
}

export function editList(data) {
  return function (dispatch) {
    todoServices.editList(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_TODO_LIST,
          payload: response.data.post
        });
      }
    });
  };
}

export function addList(data) {
  return function (dispatch) {
    todoServices.addList(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_TODO_LIST,
          payload: response.data
        });
      }
    });
  };
}

export function deleteList(id) {
  return function (dispatch) {
    todoServices.deleteList(id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_TODO_LIST,
          payload: parseInt(id)
        });
      }
    });
  };
}

export function deleteData(id) {
  return function (dispatch) {
    todoServices.deleteData(id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_TODO_DASHBOARD,
          payload: id
        });
      }
    });
  };
}

export function addData(data) {
  return function (dispatch) {
    todoServices.addData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_TODO_DASHBOARD,
          payload: response.data
        });
      }
    });
  };
}

export function addSubtaks(todo_id) {
  return function (dispatch) {
    dispatch({
      type: ADD_TODO_SUBTASKS,
      payload: todo_id
    });
  };
}

export function toggleSubtaks(data) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_TODO_SUBTASKS,
      payload: data
    });
  };
}

export function toggleTodo(data) {
  return function (dispatch) {
    todoServices.toggleTodo(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: TOGGLE_TODO,
          payload: data
        });
      }
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    todoServices.editData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_TODO_DASHBOARD,
          payload: response.data.post
        });
      }
    });
  };
}