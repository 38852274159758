export const SET_OV_RATING_LOADING = 'SET_OV_RATING_LOADING'
export const SET_OV_GET_LOCATIONS = 'SET_OV_GET_LOCATIONS'
export const SET_OV_GET_ROLES = 'SET_OV_GET_ROLES'
export const SET_OV_GET_QUESTION = 'SET_OV_GET_QUESTION'
export const SET_ADD_RATING = 'SET_ADD_RATING'
export const SET_EDIT_RATING = 'SET_EDIT_RATING'
export const SET_RATING_DATA = 'SET_RATING_DATA'
export const SET_DELETE_RATING = 'SET_DELETE_RATING'
export const SET_OV_RATING_QUESTION_LOADING = 'SET_OV_RATING_QUESTION_LOADING'

///////Team Rating
export const SET_TEAM_RATING_LOADING = 'SET_TEAM_RATING_LOADING'
export const SET_TEAM_RATING = 'SET_TEAM_RATING'
export const SET_USER_TEAM_RATING = 'SET_USER_TEAM_RATING'

/////My rating
export const SET_MY_TEAM_RATING_LOADING = 'SET_MY_TEAM_RATING_LOADING'
export const SET_MY_TEAM_RATING = 'SET_MY_TEAM_RATING'



