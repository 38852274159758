import {
  SET_COMPANY_LOADING,
  SET_COMPANY,
  ADD_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY
} from "./constants";

const initialState = {
  isLoading: true,
  companyData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_COMPANY:
      return {
        ...state,
        isLoading: false,
        companyData: payload.companyData
      };
    case ADD_COMPANY:
      return {
        ...state,
        isLoading: false,
        companyData: [...state.companyData, payload]
      }
    case EDIT_COMPANY:
      return {
        ...state,
        isLoading: false,
        companyData: state.companyData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_COMPANY:
      return {
        ...state,
        isLoading: false,
        companyData: state.companyData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;