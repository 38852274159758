import moment from "moment";
export function sliderTimeReturn(sta,tag,lo) {
    if(sta != "00:00")
    {
        if(lo == 1)
        {
            var start = sta.split(':')
            var time = moment().toDate();  // This will return a copy of the Date that the moment uses
                time.setHours(start[0]);
                time.setMinutes(start[1]);
                time.setSeconds(0);
                time.setMilliseconds(0);
                if(tag == 1)
                {
                    return (moment(time).format('HH:mm:ss')).toString()
                }
                else
                {
                    return (moment(time).format('hh:mm A')).toString()
                } 
        }
        else
        {
            var start = sta.split(':')
        var time = moment().toDate();  // This will return a copy of the Date that the moment uses
            time.setHours(start[0]);
            time.setMinutes(start[1]);
            time.setSeconds(0);
            time.setMilliseconds(0);
            if(tag == 1)
            {
                return (moment(time).format('HH:mm:ss')).toString()
            }
            else
            {
                return (moment(time).format('hh:mm A')).toString()
            } 
        }
        
    }
    else
    {
        return '00:00'
    }
   
}


// if (response == 0){
//     let temp = [...this.state.weeks]
//     for (var i=0; i < response; i++ ){
//         temp = temp.map((el, index1) => (
//             index === index1 ?  {...el, value:{start:time.start, end: time.end} } :   {...el}
//         ))
//         console.log("temppppp",temp)
// }
// }

// else{
//     console.log("else blog",response)
// }
// }