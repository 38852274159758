import React, { Component } from 'react'
import { RoutineRoutes } from '../../routes/RoutineRoutes'
import RoutineSidebarLink from './RoutineSidebarLink';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Avatar,
    Typography
  } from "@material-ui/core";
  import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
  import { makeStyles } from '@material-ui/core/styles';
  import { withStyles } from "@material-ui/core/styles";
import Input from '@material-ui/core/Input';

  const styles = (theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },

        
      },
      
  });

 class RoutineSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newRoutes: RoutineRoutes,
        };
    }

    handleToggleRoutes = (index, isOpen) => {
        var newRoutes = this.state.newRoutes.map((item, myIndex) => {
            var cloneItem = item
            if (myIndex === index) {
                cloneItem["isOpen"] = isOpen ? false : true
                this.props.clickLink()
            }
            return cloneItem
        })

        this.setState({
            newRoutes: newRoutes
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.newRoutes.map((page, key) => {
                    return (
                        <RoutineSidebarLink
                            handleToggleRoutes={() => { this.handleToggleRoutes(key, page.isOpen) }}
                            clickLink={this.props.clickLink}
                            key={key}
                            location={this.props.location}
                            isSidebarOpen={this.props.isSidebarOpen}
                            {...page}
                        />
                    )
                })}
                <ListItem
               style={{marginTop:"10px"}}
               disableRipple
               >
               <ListItemIcon
               
               >
                    <AddBoxOutlinedIcon style={{ fontSize: "27px",color:"#295CDC",marginTop:"-5px",marginLeft:"-11px" }}/>
                    <form className={classes.root} noValidate autoComplete="off" style={{marginTop:"-14px"}}>
                    <Input 
                    placeholder="Add List" 
                    disableUnderline={true}
                    sx={{
                        "& input": {
                            color: 'green',
                        }
                    }}
                    inputProps={{ 
                        'aria-label': 'description',
                        }} />
                    </form>
               </ListItemIcon>
               
               
               </ListItem>
            </div>
        )
    }
}

export default withStyles(styles)(RoutineSidebar);
