import {
  SET_SC_DEPARTMENT_LOADING,
  SET_SC_DEPARTMENT,
  ADD_SC_DEPARTMENT,
  EDIT_SC_DEPARTMENT,
  DELETE_SC_DEPARTMENT
} from "./constants";

const initialState = {
  isLoading: true,
  departmentData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SC_DEPARTMENT_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC_DEPARTMENT:
      return {
        ...state,
        isLoading: false,
        departmentData: payload.departmentData
      };
    case ADD_SC_DEPARTMENT:
      return {
        ...state,
        isLoading: false,
        departmentData: [...state.departmentData, payload]
      }
    case EDIT_SC_DEPARTMENT:
      return {
        ...state,
        isLoading: false,
        departmentData: state.departmentData.map((item) => item.did === payload.did ? { ...payload } : item)
      }
    case DELETE_SC_DEPARTMENT:
      return {
        ...state,
        isLoading: false,
        departmentData: state.departmentData.filter((item) => item.did !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;