//SchedulerClass
import React, { Component } from "react";
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { schedulerColor, FILE_URL } from '../../../config';
import Avatar from '@material-ui/core/Avatar';
import {
  Tooltip,
  FormControlLabel,
  Switch,
  Grid, Typography, TextField, Button, IconButton
} from '@material-ui/core';
import { utcToTimeZone } from '../../../utilities/utcToTimeZone';

class TeamAvailabilityScheduler extends Component {
  constructor(props) {
    super(props);

    moment.locale("us", {
      week: {
        dow: props.start_day
      }
    });

    let schedulerData = new SchedulerData(moment(),
      ViewTypes.Week, false, false, {
      schedulerWidth: '80%',
      weekResourceTableWidth: '16.7%',
      weekCellWidth: '11.9%',
      //tableHeaderHeight: 60,
      eventItemHeight: 40,
      eventItemLineHeight: 40,
      nonAgendaSlotMinHeight: 60,
      selectedAreaColor: '#f6f6f6',
      nonAgendaOtherCellHeaderFormat: 'dddd',
      nonWorkingTimeHeadColor: 'gray',
      nonWorkingTimeHeadBgColor: '#ffffff',
      nonWorkingTimeBodyBgColor: '#ffffff',
      startResizable: false,
      endResizable: false,
      movable: false,
      creatable: true,
      resourceName: `All Employees (` + props.resources.length + `)`,
      taskName: 'Task Name edit',
      eventItemPopoverEnabled: true,
      calendarPopoverEnabled: false,
      displayWeekend: true,
      headerEnabled: false,
    });

    schedulerData.setResources(props.resources);
    schedulerData.setEvents(props.events);
    // console.log("schedulerData :::: ",schedulerData)
    this.state = {
      viewRequests: [],
      viewModel: schedulerData,
      events: props.events,
      newResources: props.resources
    }
  }


  componentWillReceiveProps(newprops) {
    // this.state.viewModel.setEvents(newprops.events);
    // this.state.viewModel.setResources(newprops.resources);
    // this.setState({
    //   events: newprops.events,
    //   newResources: newprops.resources
    // })
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  eventClicked = (schedulerData, event) => {
    var slotId = event.resourceId
    schedulerData.resources.map((item) => {
      if (item.isUser)
        slotId = item.parentId
    })

    var dayName = ''
    if (event.day_id === 1) {
      dayName = 'Monday'
    }
    else if (event.day_id === 2) {
      dayName = 'Tuesday'
    }
    else if (event.day_id === 3) {
      dayName = 'Wednesday'
    }
    else if (event.day_id === 4) {
      dayName = 'Thursday'
    }
    else if (event.day_id === 5) {
      dayName = 'Friday'
    }
    else if (event.day_id === 6) {
      dayName = 'Saturday'
    }
    else {
      dayName = 'Sunday'
    }
    if (this.props.permissionsData.scheduler_teamavailability_edit) {
      this.props.editModalOpen(event, dayName)
    }
  };

  viewEventClick = (schedulerData, event) => {
  };

  deleteEventClick = (id) => {
    this.props.deleteModalOpen(id)
  };

  viewEventClick2 = (schedulerData, event) => {
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (window.confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData
    })
  }

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (window.confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData
    })
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (window.confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData
      })
    }
  }

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData
      });
      schedulerContent.scrollLeft = 10;
    }
  }

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  }

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
  }

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
  }

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  }


  slotItemTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    let divStyle = { textAlign: 'left', padding: '0px 5px' };
    return <div key={slot.slotId}
      className={clsName}
      style={divStyle}>
      <div style={{ width: '22%', float: 'left' }}>
        {this.state.newResources.map((item) => {
          if (item.id === slot.slotId && item.isUser)
            return (
              item.user_image ?
                <Avatar

                  style={{
                    float: 'left',
                    textTransform: 'uppercase',
                    height: '35px',
                    width: '35px',
                    fontWeight: 800,
                    fontSize: '15px',
                    backgroundColor: item.user_status === 2 ? "#979797" : schedulerColor.main,
                    color: '#ffffff',
                  }}
                  src={ item.user_image}
                />
                :
                <Avatar
                  style={{
                    float: 'left',
                    textTransform: 'uppercase',
                    height: '35px',
                    width: '35px',
                    fontWeight: 800,
                    fontSize: '15px',
                    backgroundColor: item.user_status === 2 ? "#979797" : schedulerColor.main,
                    color: '#ffffff',
                  }}
                >
                  {item.name.charAt(0)}
                </Avatar>
            )
        })}
      </div>
      {!slot.parentId ?
        <div style={{ width: '78%', float: 'right' }}>
          {this.state.newResources.map((item) => {
            // console.log("this.state.newResources",this.state.newResources)
            if (item.id === slot.slotId)
              return (
                <div>
                  <Typography
                    style={{
                      marginBottom: 0,
                      lineHeight: item.childCount > 0 ? '25px' : '36px',
                      maxWidth: "145px",
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: item.user_status === 2 ? "#979797" : schedulerColor.main,
                      fontSize: '12px',
                      fontWeight: 600,
                      marginLeft: '1rem'
                    }}
                  >
                    {item.name}
                  </Typography>
                  {item.id === slot.slotId && item.childCount > 0 ?
                    this.state.viewRequests.indexOf(item.id) > -1 ?
                      <Typography
                        onClick={() => { this.hideRequests(slot) }}
                        style={{ cursor: 'pointer', lineHeight: '5px', color: item.user_status === 2 ? "#979797" : '#04a2bd', fontSize: '11px', margin: '0px', fontWeight: '600', marginLeft: '1rem' }}
                        variant="body2"
                      >
                        Hide Requests
                      </Typography>
                      :
                      <Typography
                        onClick={() => { this.viewRequests(slot) }}
                        style={{ cursor: 'pointer', lineHeight: '5px', color: item.user_status === 2 ? "#979797" : '#04a2bd', fontSize: '11px', margin: '0px', fontWeight: '600', marginLeft: '1rem' }}
                        variant="body2"
                      >
                        View Requests
                      </Typography>
                    :
                    ""}
                </div>
              )
          })}

        </div>
        :
        <div style={{ width: '100%' }}>

          <Typography
            style={{
              marginBottom: "3px",
              textAlign: "center",
              //lineHeight: item.childCount > 0 ? '25px' : '36px',
              maxWidth: "200px",
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: schedulerColor.main,
              fontSize: '11px',
              fontWeight: 600
            }}
          >
            {slot.slotName}
          </Typography>

          {this.state.newResources.map((item) => {
            if (item.id === slot.slotId)
              return (
                <>
                  {item.status === 0 ?
                    this.props.permissionsData.scheduler_teamavailability_approve ?
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          onClick={() => { this.notifyModalOpen(slot.slotId, 2) }}
                          style={{ margin: "0px 2px", backgroundColor: "red" }}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          <Typography style={{ color: "#ffffff", marginBottom: 0 }}>
                            Reject
                          </Typography>
                        </Button>

                        <Button
                          style={{ margin: "0px 2px", backgroundColor: "green" }}
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => { this.notifyModalOpen(slot.slotId, 1) }}
                        >
                          <Typography style={{ color: "#ffffff", marginBottom: 0 }}>
                            Approve
                          </Typography>
                        </Button>
                      </div> : ""
                    :
                    <Typography style={{
                      textAlign: "center",
                      display: "block",
                      paddingLeft: "2px",
                      lineHeight: '15px',
                      color: item.status === 1 ? "green" : item.status === 2 ? "red" : "#293942",
                      fontSize: "11px",
                      fontWeight: '600',

                    }} variant="h6" display="inline">
                      {item.approvedBy ? "By " + item.approvedName : ""}
                    </Typography>
                  }
                </>
              )
          })}
        </div>
      }
    </div >;
  }

  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;
    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    }
    else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }
    style.textAlign = 'center';
    style.padding = '5px';
    style.fontWeight = '500';
    style.color = '#7c8288';
    style.backgroundColor = '#ffffff';

    return (
      <th key={item.time} className={`header3-text`} style={style} >
        {formattedDateItems.map((formattedItem, index) => {
          return (
            <span style={{ fontSize: '15px' }} key={index}
              dangerouslySetInnerHTML={{
                __html:
                  formattedItem
              }}
            />
          )
        })}
      </th>
    );
  }

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    //var newStart = moment(event.start).format("h:mmA")
    var newStart =  moment(event.start).format("h:mmA")
    var newEnd =  moment(event.end).format("h:mmA")
    let backgroundColor = event.bg_color;

    let available = 'UNAVAILABLE '
    var availableColor = '#969ba0'
    if (event.is_prefered) {
      available = 'PREFERRED '
      availableColor = '#293942'
    }

    let is_wholeday = newStart + '-' + newEnd
    if (event.is_wholeday === 1) {
      is_wholeday = 'WHOLE DAY'
    }
    return <div
      key={event.id}
      className={mustAddCssClass}
      style={{
        borderBottom: '1px solid',
        textAlign: 'center',
        height: '55px',
        margin: '2px 0px',
        padding: '8px 0px 8px 0px',
        backgroundColor: backgroundColor
      }}>
      <Typography
        style={{
          width: "130px",
          color: availableColor,
          fontSize: '12px',
          fontWeight: 'bold',
          marginBottom: 0
        }} variant="h6"
      >
        {available}
      </Typography>

      <Typography style={{ width: "130px", color: '#969ba0', marginBottom: 0 }} variant="body2">
        {is_wholeday}
      </Typography>
    </div>;
  }

  newEvent = (schedulerData, slotId, slotName, start, type, item) => {
    var newslotId = slotId
    var mainId = slotId
    schedulerData.resources.map((item) => {
      if (item.isUser)
        newslotId = item.parentId
    })

    schedulerData.resources.map((item) => {
      if (item.isUser && item.id === slotId) {
        mainId = item.mainId
        if (item.isUser) {
          mainId = item.av_id
        }
      }
    })

    const dayscount = new Date(start);
    const day1 = dayscount.getDay();
    var currentDay = ''
    if (day1 === 1) {
      currentDay = 'Monday'
    }
    else if (day1 === 2) {
      currentDay = 'Tuesday'
    }
    else if (day1 === 3) {
      currentDay = 'Wednesday'
    }
    else if (day1 === 4) {
      currentDay = 'Thursday'
    }
    else if (day1 === 5) {
      currentDay = 'Friday'
    }
    else if (day1 === 6) {
      currentDay = 'Saturday'
    }
    else {
      currentDay = 'Sunday'
    }

    if (this.props.permissionsData.scheduler_teamavailability_add) {
      this.props.addEditModalOpen(mainId, day1, currentDay)
    }
  }


  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
    var newstartTime = moment(eventItem.start).format("HH:mm:ss")
    var newendTime = moment(eventItem.end).format("HH:mm:ss")

    return (
      <Grid container
        spacing={2}
        style={{ width: '300px' }}
      >
        <Grid item
          style={{ borderBottom: '1px solid lightgray' }}
          md={6}
          xs={6}
        >
          {eventItem.is_prefered ? "Preferred" : "Unavailable"}
        </Grid>

        <Grid item
          style={{ borderBottom: '1px solid lightgray' }}
          md={6}
          xs={6}
        >
          <Tooltip
            style={{ marginLeft: "10px", padding: '3px', float: 'right' }}
            title="Click to Delete" arrow>
            <IconButton
              disabled={this.props.permissionsData.scheduler_teamavailability_delete ? false : true}
              onClick={() => { this.props.deleteModalOpen(eventItem.id) }}
              edge="end" aria-label="filter list">
              <DeleteIcon style={{ fontSize: '22px' }} color="primary" />
            </IconButton>
          </Tooltip>
          
          {/* <Tooltip
            style={{ padding: '3px', float: 'right' }}
            title="Duplicate" arrow>
            <IconButton
              onClick={() => { this.duplicateAvailability(eventItem) }}
              edge="end" aria-label="filter list">
              <AddIcon
                style={{ fontSize: '22px' }}
                color="primary" />
            </IconButton>
          </Tooltip> */}
        </Grid>

        <Grid
          item
          md={6}
          xs={6}
        >
          {moment(eventItem.start).format("hh:mm A")}
        </Grid>

        <Grid
          item
          md={6}
          xs={6}
        >
          {moment(eventItem.end).format("hh:mm A")}
        </Grid>


        <Grid
          item
          md={12}
          xs={12}
          style={{ padding: '0px 8px' }}
        >
          <FormControlLabel
            control={<Switch
              color="primary"
              checked={eventItem.is_wholeday}
            />}
            label="Whole Day"
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            size='small'
            variant="outlined"
            fullWidth
            autoComplete="current-categoryQuestion"
            select
            disabled
            SelectProps={{ native: true }}
            name='selectedLocation'
            value={eventItem.lid}
          >
            <option key="state1" value='0' >
              All Locations
            </option>

            {this.props.locationData.map((item) => {
              return (
                <option key="state1" value={item.lid} >
                  {item.location_name}
                </option>
              )
            })}
          </TextField>
        </Grid>
      </Grid>
    );
  }


  handleChangeAll = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleStartTime = (date) => {
    this.setState({
      startTime: date
    })
  }

  handleEndTime = (date, e) => {
    this.setState({
      endTime: date
    })
  }

  viewRequests = (slot) => {
    let schedulerData = this.state.viewModel;
    let myResources = this.state.newResources;
    let myRequests = this.state.viewRequests;
    myRequests.push(slot.slotId)

    this.props.allChilds.map((item) => {
      if (item.parentId === slot.slotId) {
        myResources.push(item)
        schedulerData.addResource(item)
      }
    })

    this.setState({
      viewRequests: myRequests,
      newResources: myResources,
      viewModel: schedulerData
    })
  }

  hideRequests = (slot) => {
    let schedulerData = this.state.viewModel;
    let myRequests = this.state.viewRequests;
    myRequests.splice(myRequests.indexOf(parseInt(slot.slotId)), 1);
    var newArray = this.state.newResources.filter(function (item) {
      return parseInt(slot.slotId) !== parseInt(item.parentId);
    });
    schedulerData.setResources(newArray);
    this.setState({
      viewRequests: myRequests,
      newResources: newArray,
      viewModel: schedulerData
    })
  }

  notifyModalOpen = (av_id, status) => {
    this.props.notifyModalOpen(av_id, status)
    
  }

  render() {
    //if (!this.state.isLoaded) return <div className="loader"></div>
    const { viewModel } = this.state;
    const { permissionsData } = this.state;

    return (
      <Grid >
        <Grid
          className="schedulerMain"
          container
          spacing={0}
        >
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            viewEventClick={this.viewEventClick}
            deleteEventClick={this.deleteEventClick}
            viewEventText="Ops 1"
            viewEvent2Text="Ops 2"
            viewEventClick2={this.viewEventClick2}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}
            slotItemTemplateResolver={this.slotItemTemplateResolver}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withDragDropContext(TeamAvailabilityScheduler)