import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, LoadingData } from '../../../components'
import { fetchData } from './modules/actions';
import { onChangePage } from '../../../components/modules/actions';
import { getScheduleData } from '../Settings/modules/actions';
import { payrollSummaryServices } from './modules/services';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import DetailPayroll from "./DetailPayroll";
import ExportDownload from "./ExportDownload";
import { alert } from '../../../utilities';
import moment from "moment";
import { history } from "../../../history";
import { addMonths } from 'date-fns';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
});

export function fetchPayrollSummaryData() {
    // window.PayrollSummary.fetchData(true);
    // window.PayrollSummary.getUserDetails();
    window.PayrollSummary.getScheduleData();
    window.PayrollSummary.getHeaderData()
}

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

class PayrollSummary extends Component {
    constructor(props) {
        super(props)
        window.PayrollSummary = this;
        this.state = {
            // openRow: false,
            myItem: {
                date: moment().format("YYYY-MM-DD"),
                newDate: new Date()
            },
            date:"",
            order: 'asc',
            orderBy: "id",
            isLoading :false,
            Employechecked:false,
            is_select_card:"",
            EditTableModal: false,
            download:false,
            newStateAR:[],
            headerData:[],
            toolbarData: [
                {
                    title: "summary",
                    sortName: "summary",
                    onClick: this.newModalOpen
                },
                {
                    title: "pe",
                    sortName: "pe",
                    onClick: this.clearModalOpen
                },
                {
                    title: "cash",
                    sortName: "cash",
                    onClick: this.notifyModalOpen
                }
            ],
            errors: {},
            errorsHelper: {},
            usersData: [],
            newDataResUserData: [],
            dates:{},
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    };

    componentDidMount = () => {
        this.props.onChangePage()
        // var isReload = this.props.payrollWeeks.length > 0 ? false : true
        this.getHeaderData()
        this.getScheduleData()

    };


    getHeaderData = () => {
        this.fetchData()
        this.setState({
            isLoading:true
        })
        var dataPayload = this.props.scheduler_lid
        payrollSummaryServices.getTableHeaderData(dataPayload).then((response) => {
                if(response.data.HeaderData.length>0){
                var newObjTT = response.data.HeaderData.map((itemd) => {
                    var objT = {
                        id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true , pi_id:itemd.pi_id
                    }
                    return objT
                })
                this.setState({
                    items: newObjTT,
                    payrollIds:response.data.payroll_ids,
                    isLoading:false
                },() => {
                    this.fetchData()
                  this.getSettings()
                })
            } 
            
        })
    }
    getScheduleData = () => {

        var lid = this.props.scheduler_lid
        this.props.getScheduleData(lid);
    };

    getSettings = () => {
        payrollSummaryServices.getSettingsData(this.props.scheduler_lid).then((response) => {
            this.setState({
                Employechecked:response.data.hide_employee
            })
        })
    }

    fetchData = () => {
        var data = {
            lid: this.props.scheduler_lid,
            date: this.state.myItem.date,
        }
        payrollSummaryServices.fetchData(data).then((response) => {
            this.setState({
                MonthData: response.data.MonthData,
            },() => {
                this.getUserDetails()
              
            })
        })
        
    };

    showNewCard = (name) => {
        //var isReload = this.props.payrollWeeks.length > 0 ? false : true
        if (name === "Prev") {
            const NewdateConvert = new Date(this.state.myItem.newDate)
            const nextMonth = addMonths(NewdateConvert, -1);
            const formatDate = moment(nextMonth).format("YYYY-MM-DD");
            var data = {
                lid: this.props.scheduler_lid,
                date: formatDate,
            }
            this.fetchData(data)
            this.setState(prevState => ({
                myItem: {
                    ...prevState.myItem,
                    newDate: formatDate
                }
            }))
        }

        if (name === "Next") {
            const NewdateConvert = new Date(this.state.myItem.newDate)
            const nextMonth = addMonths(NewdateConvert, 1);
            const formatDate = moment(nextMonth).format("YYYY-MM-DD");
            var data = {
                lid: this.props.scheduler_lid,
                date: formatDate,
            }
            this.fetchData(data)
            this.setState(prevState => ({
                myItem: {
                    ...prevState.myItem,
                    newDate: formatDate
                }
            }))
        }
    };

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }))
    };

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    };

    getUserDetails = (dates) => {
        this.setState({
            payrollLoadingCard: true,
   
            is_select_card: this.state.MonthData[0]?.Date,
            date: dates ? dates : this.state.MonthData[0]?.monthDate,
           
        }, () => {
            var data = {
                lid: this.props.scheduler_lid,
                date: dates ? dates : this.state.MonthData[0]?.monthDate,
                pi_id:this.state.payrollIds,
                hideEmployee:this.state.Employechecked 
            }
            payrollSummaryServices.fetchDetails(data).then(response => {
                if (response.data.success === 2) {
                } else {
                    if(this.state.Employechecked){
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData.filter((item) => item.total_paid_hrs != "0.00"),
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,
                
                        })
                    }else{
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData,
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,
                 
                        })
                    }
                }
            });
        });
    };

    clickCard = (row) => {
        this.setState({
            payrollLoadingCard: true,
            is_select_card: row.Date,
            date:row.monthDate,
        }, () => {
            var data = {
                lid: this.props.scheduler_lid,
                date: row.monthDate,
                pi_id:this.state.payrollIds,
                hideEmployee:this.state.Employechecked 
            }
            payrollSummaryServices.fetchDetails(data).then(response => {
                if (response.data.success === 2) {
                } else {
                    if(this.state.Employechecked){
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData.filter((item) => item.total_paid_hrs != "0.00"),
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,
                        })
                    }else{
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData,
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,

                        })
                    }
                  
                }
            });
        });
    };

    openDownloadModal = () => {
        this.setState({
            downloadModal:true
        })
    }

    closeDownloadModal= () => {
        this.setState({
            downloadModal:false
        })
    }


    handleChangeCheckBox = (event) => {
        this.setState({
            payrollLoadingCard:true,
            Employechecked:event.target.checked === true ? 1: 0
        })
            var data = {
                lid: this.props.scheduler_lid,
                date: this.state.date,
                pi_id:this.state.payrollIds,
                hideEmployee:event.target.checked === true ? 1 : 0
            }
            payrollSummaryServices.fetchDetails(data).then(response => {
                if (response.data.success === 2) {
                } else {
                    if(this.state.Employechecked){
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData.filter((item) => item.total_paid_hrs != "0.00"),
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,
                

                        })
                    }else{
                        this.setState({
                            Activity_log: response.data.Activity_log,
                            AprroveData: response.data.AprroveData,
                            payrollData: response.data.PayrollData,
                            usersData: response.data.usersData,
                            newDataResUserData: response.data.usersData,
                            payrollLoadingCard: false,
                   
                        })
                    }
                  
                }
            });
            // console.log("22",items.total_paid_hrs !== "0.00")
            // console.log("newDataResUserData", this.state.newDataResUserData);
            // console.log("usersData", this.state.usersData);
            //var newFilter = this.state.newDataResUserData.filter((item) => item.total_paid_hrs != "0.00")
            //    { 
            //     if()
            //         return item

            //     })
            //    {
            //     newFilter = 
            //     return newFilter
            //    }

        
        // if (event.target.checked === false) {
        //     // console.log("22",items.total_paid_hrs !== "0.00")
        //     // console.log("users",this.state.newDataResUserData);
        //     var newFilter = this.state.newDataResUserData
      
        // }
        // else{

        // }

        // this.setState({
        //     usersData: newFilter
        // })
    };

    handleChange = (event) => {
        this.setState({
          valueSeclect: event.target.value,
        })
    }

    handleChangeShowMenu = (e) => {
        var name = e.target.name
        this.setState(prevState => ({
            dates: {
                ...prevState.dates,
                [name]: e.target.checked
            }
        }))
    }


    
    saveCloumnRow = (array) => {
        this.setState({
            items: array
        })
        var dataPayload = {
            lid: this.props.scheduler_lid,
            headerArr: this.state.newStateAR
        }
        payrollSummaryServices.getTableAd(dataPayload).then(response => {
            if (response.data.success === 2) {
                alert.error(response.data.message)
            } else {
                this.setState({
                    items: this.state.items
                })
                this.closeEditTableModal();
                var dataPayload = this.props.lid
                // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
                //     if (response.data.HeaderData.length > 0) {
                //         var newObjTT = response.data.HeaderData.map((itemd) => {
                //             var objT = {
                //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true
                //             }
                //             return objT
                //         })

                //         this.setState({
                //             items: newObjTT
                //         })
                //     }
                // })
                this.getUserDetails()
                // this.props.fetchData(this.props.scheduler_lid)
                alert.success(response.data.message)
            }
        });
        // this.closeEditTableModal();
    };

    closeEditTableModal = () => {
        this.setState({
            EditTableModal: false
        })
    };




    openEditTableModal = () => {
        this.setState({
            EditTableModal: true
        })
        // var dataPayload = this.props.lid
        // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
        //     if(response.data.HeaderData.length>0){
        //         var newObjTT = response.data.HeaderData.map((itemd) => {
        //             var objT = {
        //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true 
        //             }
        //             return objT
        //         })
        //         console.log("newObjTT",newObjTT)
        //         this.setState({
        //             items: newObjTT
        //         })
        //     } 
        // })
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        var dataas = items.map((option, index) => {
            var newobj = {
                header_name: option.content,
                status: option.status === true ? 1 : 0,
                index: index
            }
            return newobj
        })
        this.setState({
            items,
            newStateAR: dataas
        });
    };

    handleChangeChecked = (e, index) => {
        const a = this.state.items && this.state.items.forEach((element) => {
            if (element.id === index) {
                element.status = e.target.checked
            }
        });

        this.setState({
            item: a,
        })
        var dataase = this.state.items && this.state.items.map((option, index) => {
            var newobjs = {
                header_name: option.content,
                status: option.status === true ? 1 : 0,
                index: index
            }
            return newobjs
        })
        this.setState({
            newStateAR: dataase
        })
    }





    render() {
        const { classes, payrollWeeks, permissionsData, timezone, rowsPerPage, pageNo, } = this.props;
        if (this.state.isLoading) return <LoadingData />
        return (
            permissionsData.scheduler_payroll_summary ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid item xs={12}>
                        <DetailPayroll
                            payrollWeeks={payrollWeeks} showNewCard={this.showNewCard}
                            toolbarData={this.state.toolbarData}
                        isLoading={this.state.payrollLoading}
                            lid={this.props.scheduler_lid}
                            MonthData={this.state.MonthData}
                            getUserDetails={this.getUserDetails}
                            clickCard={this.clickCard}
                            Activity_log={this.state.Activity_log}
                            AprroveData={this.state.AprroveData}
                            payrollData={ this.state.payrollData}
                            usersData={this.state.usersData}
                            newDataResUserData={this.state.newDataResUserData}
                            payrollLoadingCard={this.state.payrollLoadingCard}
                            handleChangeCheckBox={this.handleChangeCheckBox}
                            myItem={this.state.myItem}
                            handleChange={this.handleChange}
                            valueSeclect={this.state.valueSeclect}
                            handleChangeShowMenu={this.handleChangeShowMenu}
                            dates={this.state.dates}
                            downloadData={this.downloadData}
                            openDownloadModal={this.openDownloadModal}
                            closeDownloadModal={this.closeDownloadModal}
                            downloadModal={this.state.downloadModal}
                            downloaduserdata={this.state.downloaduserdata}
                            date={this.state.date}
                            is_select_card={this.state.is_select_card}

                            items={this.state.items}
                            saveCloumnRow={this.saveCloumnRow}
                            closeEditTableModal={this.closeEditTableModal}
                            openEditTableModal={this.openEditTableModal}
                            EditTableModal={this.state.EditTableModal}
                            getScheduleData={this.getScheduleData}
                            onDragEnd={this.onDragEnd}
                            handleChangeChecked={this.handleChangeChecked}
                            newStateAR={this.state.newStateAR}
                            Employechecked={this.state.Employechecked}

                        />
                    </Grid>
                    {/* {this.state.download &&
                    <ExportDownload
                    rowData ={this.state.usersData}
                     />
                    } */}
                </Grid>
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>

             
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerPayrollSummary.isLoading,
    payrollWeeks: state.schedulerPayrollSummary.payrollWeeks,
    MonthData: state.schedulerPayrollSummary.MonthData
});

const ConnectWith = connect(mapStateToProps, { fetchData, onChangePage,getScheduleData })(PayrollSummary);
export default withStyles(styles)(ConnectWith);


