import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  menu: {
    minWidth: "200px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
  },
  MenuItem: {
    textAlign: "center",
    border: "1px solid #f2f2f2",
    padding: "5px 10px",
    "&:hover": {
      boxShadow:
        "4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  Typography: {
    width: "100%",
    fontSize: "15px",
    lineHeight: "2rem",
  },
});

class CopyMenus extends Component {
  render() {
    const { classes, copyAnchor, copyData } = this.props;
    // const open = Boolean(copyAnchor);
    return (
      <>
        <Menu
          MenuListProps={{ disablePadding: true }}
          anchorEl={copyAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={Boolean(copyAnchor)}
          onClose={(e) => {
            this.props.handleCopyClose("");
          }}
          PopoverClasses={{
            paper: classes.menu,
          }}
        >
          {copyData.map((value) => {
            return (
              <MenuItem
                onClick={() => {
                  this.props.handleCopyClose(value);
                }}
                className={classes.MenuItem}
              >
                <Typography
                  className={classes.Typography}
                  color="textPrimary"
                  variant="inherit"
                >
                  {value.startTime} - {value.endTime}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(CopyMenus);
