export const SET_USERS_LOADING = 'SET_USERS_LOADING'
export const SET_USERS = 'SET_USERS'
export const TOGGLE_VERIFY_USERS = 'TOGGLE_VERIFY_USERS'
export const ADD_USERS = 'ADD_USERS'
export const EDIT_USERS = 'EDIT_USERS'
export const DELETE_USERS = 'DELETE_USERS'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_AS_USER = 'LOGIN_AS_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_COUNT = 'SET_COUNT'
export const SET_USERS_NOTIFICATIONS = 'SET_USERS_NOTIFICATIONS'
export const SET_USERS_NOTIFICATIONS_LOADING = 'SET_USERS_NOTIFICATIONS_LOADING'
export const USER_RESEND_INVITE = 'USER_RESEND_INVITE'