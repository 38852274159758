import { HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const scrolesServices = {
    fetchData,
    addData,
    editData,
    deleteData
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/role`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/role`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/role/${data._id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(role_id) {
    return axios.delete(`${MoNGO_DB_API_URL}/scheduler/role/${role_id}`, HEADER_TOKEN).then(response => response)
};