import {
  SET_TIMEOFF_HISTORY_LOADING,
  SET_TIMEOFF_HISTORY
} from "./constants";

const initialState = {
  isLoading: true,
  timeOffData: [],
  timeOffCategory: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMEOFF_HISTORY_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMEOFF_HISTORY:
      return {
        ...state,
        isLoading: false,
        timeOffData: payload.timeOffData,
        timeOffCategory: payload.timeOffCategory
      };
    default:
      return state;
  }
};

export default Reducer;