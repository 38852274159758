import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchData } from "../pages/common/Location/modules/actions";
import { userPermissions } from "../pages/common/Roles/modules/actions";
import { onChangePage, onChangeSidebar } from "../components/modules/actions";
import { userLogout, userExitAccess } from "../pages/common/UsersList/modules/actions";
import classnames from "classnames";
import Header from "./components/forms/Header";
import Sidebar from "./components/Sidebar";
import LogoutMenus from "./components/LogoutMenus";
import Cookies from "universal-cookie";

import { LoadingData } from "../components";
import { history } from "../history";

const cookies = new Cookies();
const styles = (theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflow: "hidden",
  },
  noLocationassign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    overflowX: "hidden",
    padding: "0 2rem",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: "55px",
    width: `calc(100vw - 200px)`,
    minHeight: `calc(100vh - 65px)`,
    backgroundColor: "#e9ecef"
  },
  contentShift: {
    width: `calc(100vw - ${200 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#e9ecef"
  },
});

class OVFormsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTitle: "",
      activeProduct: "Forms",
      errors: {},
      helperText: {},
    };
  }

  componentDidMount = () => {
    var isPermissionReload = Object.keys(this.props.permissionsData).length > 0 ? false : true;
    var isLocationReload = this.props.locationData.length > 0 ? false : true;
    this.props.userPermissions(isPermissionReload);
    this.props.fetchData(isLocationReload);
    window.scrollTo(0, 0);
    this.props.onChangePage();
    this.getActiveProduct();
  };

  getActiveProduct = () => {
    var productName = "Forms";
    this.setState({ activeProduct: productName });
  };

  handleSidebarToggle = () => {
    this.props.onChangeSidebar(!this.props.isSidebarOpen)
  };

  handleLocationOpen = (event) => {
    this.setState({
      locationAnchor: event.currentTarget,
    });
  };

  handleLogoutOpen = (event) => {
    this.setState({
      logoutAnchor: event.currentTarget,
    });
  };

  handleLogoutClose = (value) => {
    if (value === "exit_access") {
      this.props.userExitAccess();
    }
    if (value === "logout") {
      this.props.userLogout();
    }
    if (value === "profile") {
      history.push("/settings/profile")
    }
    this.setState({
      logoutAnchor: null,
    });
  };

  render() {
    const {
      classes,
      children,
      history,
      locationData,
      countData,
      permissionsLoading,
      permissionsData,
      pageNo,
      locationTimezone,
      rowsPerPage,
    } = this.props;
    const { activeProduct } = this.state;
    var ovUid = cookies.get("ovUid");
    var ovUserName = cookies.get("ovUserName");
    var ovUserImage = cookies.get("ovUserImage");

    // var timezone = locationTimezone.filter((item) => item._id === scheduler_lid)


    if (permissionsLoading) return <LoadingData />;
    return (
      <div className={classes.root}>
        <Header
          handleLogoutOpen={this.handleLogoutOpen}
          handleLocationOpen={this.handleLocationOpen}
          drawerWidth={200}
          userName={ovUserName}
          userImage={ovUserImage}
          locationData={locationData}
          activeProduct={activeProduct}
          handleSidebarToggle={this.handleSidebarToggle}
          isSidebarOpen={this.props.isSidebarOpen}
          history={history}
        />

        <LogoutMenus
          handleLogoutClose={this.handleLogoutClose}
          logoutAnchor={this.state.logoutAnchor}
        />

        <Sidebar
          countData={countData}
          permissionsData={permissionsData}
          // timezone={timezone}
          activeProduct={activeProduct}
          handleSidebarToggle={this.handleSidebarToggle}
          isSidebarOpen={this.props.isSidebarOpen}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: this.props.isSidebarOpen,
          })}
        >
          {
            React.cloneElement(children)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countData: state.settingsUsers.countData,
  permissionsLoading: state.settingsRoles.permissionsLoading,
  permissionsData: state.settingsRoles.permissionsData,
  locationData: state.settingsLocation.locationData,
  pageNo: state.localData.pageNo,
  isSidebarOpen: state.localData.isSidebarOpen,
  rowsPerPage: state.localData.rowsPerPage,
  locationTimezone: state.settingsRoles.locationTimezone
});

const ConnectWith = connect(mapStateToProps, {
  userExitAccess,
  userLogout,
  onChangePage,
  fetchData,
  userPermissions,
  onChangeSidebar
})(OVFormsLayout);
export default withStyles(styles)(ConnectWith);
