import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { MyTimePicker, MySelectField, MyTextfield, MyDateTimePicker, MyDatePicker } from '../../../components'
import DeleteIcon from '@material-ui/icons/DeleteForever';
import BackIcon from '@material-ui/icons/ArrowBack';
import { utcDateToLocal } from '../../../utilities';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import DatePicker from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import addDays from 'date-fns/addDays';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from '@date-io/date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlarmIcon from '@mui/icons-material/Alarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import ClockIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './viewtimecard.css'
import {
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button,
  Avatar,
  IconButton,
  TextField,
  Card
}
  from '@material-ui/core';
import { FILE_URL, schedulerColor, GOOGLE_API_KEY } from "../../../config";
import { localTimeToUtc, utcToLocal, alert } from '../../../utilities'
import {
  StaticGoogleMap,
  Marker,
} from 'react-static-google-map';

import { Check as CheckIcon } from '@material-ui/icons';
import { color } from "@mui/system";

const styles = (theme) => ({
  root1: {
    padding: theme.spacing(2),
  },
  mainDiv: {
    padding: theme.spacing(0),
    maxWidth: 1500
  },
  breakhelptext: {
    color: "red"
  },
  geoButton: {
    margin: "auto",
    height: "2rem",
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "12px",
    border: `1px solid "#AA076B"`,
    color: "#AA076B",
    backgroundColor: '#ffffff',
    padding: '0px 8px !important'
  },
  approveButton: {
    margin: "auto",
    height: "2rem",
    borderRadius: "100px",
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "12px",
    border: "none",
    color: "#ffffff",
    padding: '0px 35px !important'
  },
  saveButton: {
    backgroundImage: schedulerColor.main,
    color: '#ffffff',
    padding: '10px 22px'
  },
  shadow: {
    padding: "15px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  geoimage: {
    border: '1px solid gray',
    padding: '10px',
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      backfaceVisibility: 'visible',
    }
  },
  avatarText: {
    float: 'left',
    textTransform: 'uppercase',
    height: '45px',
    width: '45px',
    fontWeight: 800,
    fontSize: '15px',
    backgroundImage: schedulerColor.main,
    color: '#ffffff'
  },
  myTitle: {
    color: "#AA076B",
    fontSize: '18px',
    marginLeft: '10px',
    paddingTop: '5px',
    lineHeight: '20px',
    float: "left",
    fontWeight: 500
  },
  tagLine: {
    fontSize: '13px',
    fontWeight: '300'
  },
  tableCell: {
    color: '#000',
    fontSize: 14
  },
});


class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '2'
    }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      value: newValue
    })
  };

  render() {
    const {
      classes,
      myItem,
      activeTab,
      display_timecard,
      isGeo,
      permissionsData,
      errors,
      open,
      errorsHelper
    } = this.props;




      var startTime = moment( myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in ).format( "YYYY-MM-DD HH:mm" )
      var endTime = moment( myItem.revised_clock_out ?  myItem.revised_clock_out  : myItem.clock_out ).format( "YYYY-MM-DD HH:mm" )
      var duration = moment.duration( moment( endTime ).diff( moment( startTime ) ) )
      var minitus = duration.asMinutes()
      var hours = Math.floor(minitus / 60);
      var tempMinites111 = minitus % 60;
      var hm = hours > 0 ? hours + "hr " : ""
      var final_actual_diff = minitus ? hm +  Math.round(tempMinites111) + "min" : "0 Min"
 
    

      //  var actual_diff = myItem.actual_diff
    // var temp_diff2 = Math.abs(actual_diff)
    // var totalMinutes111 = parseFloat(temp_diff2) * 0.60 * 100
    // var tempHours111 = Math.floor(totalMinutes111 / 60);
    // var tempMinites111 = totalMinutes111 % 60;
    // var hm = tempHours111 > 0 ? tempHours111 + "hr " : ""
    // var final_actual_diff = totalMinutes111 ? hm +  Math.round(tempMinites111) + "min" : "0 Min"


    var break_diff = myItem.break_diff
    var temp_diff_break = Math.abs(break_diff)
    var totalMinuteBreak = parseFloat(temp_diff_break) * 0.60 * 100
    var tempHoursBreak = Math.floor(totalMinuteBreak / 60);
    var tempMiniteBreak = totalMinuteBreak % 60;
    var hm = tempHoursBreak > 0 ? tempHoursBreak + "hr " : ""
    var final_break_diff = totalMinuteBreak ? hm + Math.round(tempMiniteBreak) + "min" : "0 Min"


  
    var actuallDifference1 = Math.floor(parseInt(myItem.total_actual_hrs) / parseFloat(myItem.break_break_hours))
    var actualDiff = parseFloat(myItem.break_rule_minites) * actuallDifference1
    var BreakActualdiff = parseFloat(totalMinuteBreak) - parseFloat(actualDiff)
    var breakDifference = parseInt(BreakActualdiff)
    var timecodeValue = []
    this.props.timecodeData.map((item) => {
      if (item._id === myItem.timecode) {
        timecodeValue.push(item.timecode_name)
      }
    })



    if (!myItem.breakRule && open) {
      alert.error("Please add Break Rule")
    }
    if (!myItem.timecode && open) {
      alert.error("Please add timecode")
    }




    // var actuallDifference1Revised = Math.floor(parseInt(myItem.total_actual_hrs) / parseFloat(myItem.break_break_hours))
    // var actualDiffRevised = parseFloat(myItem.break_rule_minites) * actuallDifference1Revised
    // var BreakActualdiffRevised = parseFloat(totalMinuteBreak) - parseFloat(actualDiffRevised)
    // var breakDifferenceRevised = parseInt(BreakActualdiffRevised)

    return (
      display_timecard ?
        <Grid
          className={classes.mainDiv}
          container
          spacing={2}
        >

          <Grid style={{ padding: "15px" }} item sm={8} xs={12}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item sm={7} xs={12}>
                {this.props.userImage ?
                  <Avatar
                    src={this.props.userImage}
                    className={classes.avatarText}
                  />
                  :
                  <Avatar className={classes.avatarText} >
                    {this.props.userName.charAt(0)}
                  </Avatar>
                }

                <Typography
                  className={classes.myTitle}
                  variant="h4"
                >
                  {this.props.userName}&nbsp;({this.props.roleName})
                  <br />
                  {/* <b className={classes.tagLine}>{moment(this.props.currentDate).format("dddd DD MMM, YYYY")}</b> */}
                  <b className={classes.tagLine}>
                    {/* {moment(this.props.currentDate).format('mm/dd/yyyy')} */}
                    {new Date(this.props.currentDate).toDateString()}
                  </b>
                </Typography>
              </Grid>

              <Grid
                item
                style={{ display: "flex", justifyContent: "space-between" }}
                sm={5} xs={12}>
                {/* <Button
                  className={classes.geoButton}
                  onClick={this.props.toggleViewTimecard}>
                  GEO & Photos
                </Button> */}

                <Button
                  style={{
                    border: myItem.is_break_approved && myItem.is_revised_approved ? "1px solid green" : myItem.allBreaks.length === 0 && myItem.is_revised_approved ? "1px solid green" : "1px solid #DA3E3E",
                    backgroundColor: myItem.is_break_approved && myItem.is_revised_approved ? "green" : myItem.allBreaks.length === 0 && myItem.is_revised_approved ? "green" : "#B71C1C"
                  }}
                  className={classes.approveButton}
                //onClick={this.props.toggleViewTimecard}
                >
                  {myItem.is_break_approved && myItem.is_revised_approved ? "approve" : myItem.allBreaks.length === 0 && myItem.is_revised_approved ? "approve" : "pending"}
                </Button>
              </Grid>

              <Divider
                style={{ color: "gray", width: "100%" }}
              />

              {/* <Grid item sm={12} xs={12}>
                {this.props.isTimecardEdit ?
                  <MySelectField
                    label="Timecode"
                    name="timecode"
                    disabled={true}
                    onChange={this.props.handleChange}
                    onFocus={this.props.handleFocus}
                    value={myItem.timecode}
                    error={errors.timecode}
                    helperText={errors.timecode ? errorsHelper.timecode : ""}
                    placeholder="Please Select Timecode"
                  >
                    <option value={0}>
                      Select Timecode
                    </option>
                    {this.props.timecodeData.map((item) => {
                      return (
                        <option key={item._id} value={item._id} >
                          {item.timecode_name}
                        </option>
                      )
                    })}
                  </MySelectField>
                  :
                  <MyTextfield
                    name="name"
                    label="Timecode"
                    value={timecodeValue[0]}
                    type="text"
                    disabled={true}
                  />}
              </Grid> */}

              <Grid
                item sm={12} xs={12}>
                <Typography
                  variant="h4"
                >
                  Schedule
                </Typography>
              </Grid>

              {/* shadow div start */}
              <Grid
                container
                spacing={0}
                className={classes.shadow}
              >
                <Grid item sm={9} xs={12}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item sm={3} xs={12}>
                      <Typography className={classes.tableCell}>
                        Schedule
                      </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        {moment(myItem.startTime).format("hh:mm A")}
                        {/* {moment(myItem.startTime).fo()} */}
                      </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        {moment(myItem.endTime).format("hh:mm A")}
                        {/* {new Date(myItem.endTime).toDateString()} */}
                      </Typography>
                    </Grid>


                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        Actual
                      </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <span>
                        <Typography
                          variant="body1"
                          style={{ fontSize: '11px' }}
                        >
                          {myItem.clock_in ? moment(myItem.clock_in).format("YYYY/MM/DD") : ""}

                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          {/* {console.log("items 11 actuall",myItem.clock_in)} */}
                          {myItem.clock_in ?
                            moment(myItem.clock_in).format("hh:mm A")
                            // new Date(myItem.clock_in).toDateString()
                            : ""}
                        </Typography>
                      </span>
                      {/* <Typography
                        className={classes.tableCell}
                      >
                        {myItem.clock_in ?
                          moment(myItem.clock_in).format("hh:mm A")
                          : ""}
                      </Typography> */}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <span>
                        <Typography
                          variant="body1"
                          style={{ fontSize: '11px' }}
                        >
                          {/* {myItem.clock_out ? new Date(utcDateToLocal(myItem.clock_out)).toDateString() : ""} */}
                          {myItem.clock_out ? moment(myItem.clock_out).format("YYYY/MM/DD") : ""}
                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          {myItem.clock_out ?
                            moment(myItem.clock_out).format("hh:mm A")
                            : ""}
                        </Typography>
                      </span>
                      {/* <Typography
                        className={classes.tableCell}
                      >
                        {myItem.clock_out ?
                          moment(myItem.clock_out).format("hh:mm A")
                          : ""}
                      </Typography> */}
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        Approved
                      </Typography>
                    </Grid>



                    <Grid item sm={4} xs={12} className="form-group">
                      {this.props.isTimecardEdit ?

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            key={`dateTime-${this.props.timezone[0].location_timezone}`}
                            showTodayButton
                            fixWidth={true}
                            label=""
                            style={{ width: 170 }}
                            todayText="now"
                            openTo="hours"
                            name="revised_clock_in"
                            value={myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in}
                            onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_in") }}
                            // minDate={myItem.currentDate}
                            onFocus={this.props.handleFocus}
                            error={errors.revised_clock_in}
                            helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""}
                            components={{
                              LeftArrowIcon: AlarmIcon,
                              RightArrowIcon: SnoozeIcon,
                              OpenPickerIcon: ClockIcon,
                            }}
                            leftArrowButtonText="Open previous month"
                            rightArrowButtonText="Open next month"
                          // minTime={new Date(0, 0, 0, 9)}
                          // maxTime={new Date(0, 0, 0, 20)}
                          // renderInput={(params) => (
                          //   <TextField {...params} helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""} />
                          // )}
                          ></KeyboardDateTimePicker>
                        </MuiPickersUtilsProvider>
                        :
                        <span>
                          <Typography
                            variant="body1"
                            style={{ fontSize: '11px' }}
                          >
                            {myItem.revised_clock_in ? moment(myItem.revised_clock_in).format("YYYY/MM/DD") : ""}
                          </Typography>
                          <Typography
                            variant="body1"
                          >
                            {myItem.revised_clock_in ?
                              moment(myItem.revised_clock_in).format("hh:mm A")
                              : ""}
                          </Typography>
                        </span>
                      }
                    </Grid>



                    <Grid item sm={4} xs={12} className="form-group" style={{ width: 170 }}>
                      {this.props.isTimecardEdit ?

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            key={`dateTime-${this.props.timezone[0].location_timezone}`}
                            fixWidth={true}
                            label=""
                            // disableFuture
                            hideTabs
                            showTodayButton
                            name="revised_clock_out"
                            todayText="now"
                            openTo="hours"
                            value={myItem.revised_clock_out ? myItem.revised_clock_out : myItem.clock_out}
                            onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_out") }}
                            minDate={myItem.currentDate}
                            onFocus={this.props.handleFocus}
                            error={errors.revised_clock_out}
                            helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""}
                            components={{
                              LeftArrowIcon: AlarmIcon,
                              RightArrowIcon: SnoozeIcon,
                              OpenPickerIcon: ClockIcon,
                            }}
                            leftArrowButtonText="Open previous month"
                            rightArrowButtonText="Open next month"
                          //  minTime={new Date(0, 0, 0, 9)}
                          //  maxTime={new Date(0, 0, 0, 20)}
                          //  renderInput={(params) => (
                          //    <TextField {...params} helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""} />
                          //  )}
                          ></KeyboardDateTimePicker>
                        </MuiPickersUtilsProvider>


                        :
                        <span>
                          <Typography
                            variant="body1"
                            style={{ fontSize: '11px' }}
                          >
                            {myItem.revised_clock_out ? moment(myItem.revised_clock_out).format("YYYY/MM/DD") : ""}
                          </Typography>
                          <Typography
                            variant="body1"
                          >
                            {myItem.revised_clock_out ?
                              moment(myItem.revised_clock_out).format("hh:mm A")
                              : ""}
                          </Typography>
                        </span>
                      }
                    </Grid>

                  </Grid>
                </Grid>

                {myItem.revised_clock_out || myItem.clock_in ?
                  <Grid
                    style={{
                      textAlign: "center",
                      height: "auto",
                      borderRadius: "5px",
                      backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? " #008000" : " #b71c1c" : "grey"
                    }}
                    item sm={3} xs={12}>
                    <Grid container style={{ padding: "10px 5px" }}>
                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#ffffff" }}
                          variant="body1"
                        >
                          {final_actual_diff}&nbsp;&nbsp; {parseFloat(hours) > 0 ? parseFloat(hours) > 0 ? "Over" : "Less" : "Less"}
                        </Typography>
                      </Grid>

                      <Grid item sm={12}>
                        <IconButton
                          onClick={() => this.props.approveRevisedHours("Revised")}
                          disabled={permissionsData.scheduler_timesheet_lock ? false : true}
                          style={{ margin: "2px", padding: '6px', backgroundColor: "#ffffff" }}>
                          <CheckIcon
                            style={{ backgroundColor: "transparent" }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#ffffff" }}
                          variant="body1"
                        >
                          {myItem.is_revised_approved ? "Approved" : "Pending"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> : ""}
              </Grid>
              {/* shadow div end */}


              <Grid
                style={{ marginTop: "1rem" }}
                item sm={12} xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                >
                  Breaks
                </Typography>
              </Grid>

              {/* shadow div start */}
              <Grid
                container
                spacing={1}
                className={classes.shadow}
              >
                <Grid item sm={9} xs={12}>
                  <Typography
                    className={classes.tableCell}
                    gutterBottom
                  >
                    Break Rule : {myItem.breakRule}
                  </Typography>

                  {myItem.allBreaks?.map((breakItem, breakIndex) => {
                    return (
                      <Grid
                        className={classes.root2}
                        container
                        spacing={2}
                      >
                        <Grid item sm={3} xs={12}>
                          <Typography
                            style={{ lineHeight: "2rem" }}
                            className={classes.tableCell}
                          >
                            Break {breakIndex + 1}
                          </Typography>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                          {this.props.isTimecardEdit ?
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDateTimePicker
                                fixWidth={true}
                                key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                label=""
                                // disableFuture
                                hideTabs
                                showTodayButton
                                name="break_start"
                                todayText="now"
                                openTo="hours"
                                value={breakItem.break_start ? breakItem.break_start : myItem.currentDate}
                                onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_start") }}
                                //minDate={myItem.currentDate}
                                onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                                error={breakItem.break_start_error}
                                helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}
                              //  components={{
                              //    LeftArrowIcon: AlarmIcon,
                              //    RightArrowIcon: SnoozeIcon,
                              //    OpenPickerIcon: ClockIcon,
                              //  }}
                              //  leftArrowButtonText="Open previous month"
                              //  rightArrowButtonText="Open next month"
                              //  minTime={new Date(0, 0, 0, 9)}
                              //  maxTime={new Date(0, 0, 0, 20)}
                              //  renderInput={(params) => (
                              //    <TextField {...params}  helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""} className={classes.breakhelptext}/>
                              //  )}
                              ></KeyboardDateTimePicker>
                            </MuiPickersUtilsProvider>
                            // <MyTimePicker
                            //   fixWidth={true}
                            //   label=""
                            //   name="break_start"
                            //   onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //   onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_start") }}
                            //   error={breakItem.break_start_error}
                            //   helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}
                            //   value={breakItem.break_start}
                            //   placeholder="Please enter start"
                            // />
                            :
                            <span>
                              <Typography
                                variant="body1"
                                style={{ fontSize: '11px' }}
                              >
                                {breakItem.break_start ? moment(breakItem.break_start).format("YYYY/MM/DD") : ""}
                                {/* {breakItem.break_start ? new Date(utcDateToLocal(breakItem.break_start)).toDateString() : ""} */}
                              </Typography>
                              <Typography
                                style={{ lineHeight: "2rem" }}
                                className={classes.tableCell}
                              >
                                {/* {breakItem.break_start ?
                                new Date(breakItem.break_start).toDateString()
                                : ''} */}
                                {breakItem.break_start ?
                                  moment(breakItem.break_start).format("hh:mm A")
                                  : ""}
                              </Typography>
                            </span>

                          }
                        </Grid>

                        <Grid item sm={4} xs={12}>
                          {this.props.isTimecardEdit ?
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDateTimePicker
                                fixWidth={true}
                                key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                label=""
                                // disableFuture
                                hideTabs
                                showTodayButton
                                name="break_end"
                                todayText="now"
                                openTo="hours"
                                value={breakItem.break_end ? breakItem.break_end : myItem.currentDate}
                                onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_end") }}
                                minDate={myItem.currentDate}
                                onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                                error={breakItem.break_end_error}
                                helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                                components={{
                                  LeftArrowIcon: AlarmIcon,
                                  RightArrowIcon: SnoozeIcon,
                                  OpenPickerIcon: ClockIcon,
                                }}
                                leftArrowButtonText="Open previous month"
                                rightArrowButtonText="Open next month"
                              // minTime={new Date(0, 0, 0, 9)}
                              // maxTime={new Date(0, 0, 0, 20)}
                              // renderInput={(params) => (
                              //   <TextField {...params}  helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""} style={{color:"red"}}/>
                              // )}
                              ></KeyboardDateTimePicker>
                            </MuiPickersUtilsProvider>
                            // <MyTimePicker
                            //   fixWidth={true}
                            //   label=""
                            //   name="break_end"
                            //   onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //   onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_end") }}
                            //   error={breakItem.break_end_error}
                            //   helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                            //   value={breakItem.break_end}
                            //   placeholder="Please enter end"
                            // />
                            :
                            <span>
                              <Typography
                                variant="body1"
                                style={{ fontSize: '11px' }}
                              >
                                {/* {breakItem.break_end ? new Date(utcDateToLocal(breakItem.break_end)).toDateString() : ""} */}
                                {breakItem.break_end ? moment(breakItem.break_end).format("YYYY/MM/DD") : ""}
                              </Typography>
                              <Typography
                                style={{ lineHeight: "2rem" }}
                                className={classes.tableCell}
                              >
                                {/* {breakItem.break_end ?
                                new Date(breakItem.break_end).toDateString()
                                : ""} */}
                                {breakItem.break_end ?
                                  moment(breakItem.break_end).format("hh:mm A")
                                  : ""}
                              </Typography>
                            </span>
                          }
                        </Grid>

                        <Grid item sm={1} xs={12}>
                          {this.props.isTimecardEdit ?
                            <IconButton
                              style={{ padding: '5px' }}
                              onClick={() => { this.props.removeBreakRow(breakIndex) }}
                            >
                              <DeleteIcon />
                            </IconButton> : ""}
                        </Grid>

                      </Grid>
                    )
                  })}
                </Grid>

                {myItem.allBreaks.length > 0 ?
                  <Grid
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      height: "auto",
                      borderRadius: "5px",
                      // backgroundColor:"rgb(183, 28, 28)",
                      backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? " rgb(0, 128, 0)" : "rgb(183, 28, 28)" : "grey",
                    }}
                    item sm={3} xs={12}>
                    <Grid container style={{ padding: "10px 5px" }}>
                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#ffffff" }}
                          variant="body1"
                        >
                          {final_break_diff}&nbsp;&nbsp; {parseInt(BreakActualdiff) > parseFloat(myItem.break_rule_minites) ? "Over" : "less"}
                        </Typography>
                      </Grid>

                      <Grid item sm={12}>
                        <IconButton
                          disabled={permissionsData.scheduler_timesheet_lock ? false : true}
                          onClick={() => this.props.approveRevisedHours("Breaks")}
                          style={{ margin: "2px", padding: '6px', backgroundColor: "#ffffff" }}>
                          <CheckIcon
                            style={{ backgroundColor: "transparent" }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#ffffff" }}
                          variant="body1"
                        >
                          {myItem.is_break_approved ? "Approved" : "Pending"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> : ""}
              </Grid>
              {/* shadow div end */}

              {this.props.isTimecardEdit ?
                <Grid item sm={12} xs={12}
                  style={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                  <Button
                    style={{ backgroundImage: schedulerColor.main, marginTop: '5px' }}
                    onClick={this.props.addBreakRow}
                  >
                    <span style={{ color: 'white' }}>Add break</span>
                  </Button>
                </Grid> : ""}
            </Grid>
          </Grid>


          <Grid style={{
            // height: "auto",
            // minHeight: "500px",
            // borderLeft: "1px solid lightgray",
            // padding: "15px",
            // boxShadow: ' 0px 2px 10px rgb(0 0 0 / 20%)',
            // margin: '15px 0px',
            // borderRadius: '5px',
          }} item sm={4} xs={12}>

            <Grid
              container
              spacing={2}
            >
              <Grid item sm={12} xs={12} style={{ textAlign: "center", height: "250px" }}>
                {/* <Grid item md={12}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600px",
                      fontSize: "14px",
                      margin: '1px',
                      borderRadius: '10px',
                      border: "none",
                      color: "#ffffff",
                      width: "135px",
                      backgroundColor: schedulerColor.main
                    }}
                    //onClick={() => { this.props.handleActiveTab(0) }}
                    className={classes.button2}>Request & Issue</Button>
                  <hr />
                </Grid>

                <Typography
                  variant="body1"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  Issues
                </Typography>
                <List className={classes.listRoot}>
                  {myItem.allIssues?.map((items) => {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        style={{ padding: "0px 0px" }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              gutterBottom
                              display="inline"
                              style={{ cursor: "pointer", fontWeight: "600" }}
                            >
                              {items.issue}

                              {permissionsData.scheduler_timesheet_fix_issue ?
                                <Typography
                                  onClick={() => { this.props.toggleTimesheetIssue(items) }}
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    color: items.status ?
                                      schedulerColor.main : "red"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography> :
                                <Typography
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    color: items.status ?
                                      schedulerColor.main : "grey"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography>}

                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </List> */}
                <Card>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={this.state.value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList  orientation="vertical" onChange={this.handleChangeTab} aria-label="lab API tabs example">
                          <Tab label="Issue"
                              style={{ backgroundColor: '#217561',
                              color: 'white',
                              width: 'fit-content',
                              marginLeft: '40%',
                              fontSize: '11px',
                              borderRadius: '15px',
                              padding: '11px 9px'
                          }}
                          value="1" /><br />
                          <Tab
                            style={{ backgroundColor: '#217561',
                              color: 'white',
                              width: 'fit-content',
                              marginLeft: '40%',
                              fontSize: '11px',
                              borderRadius: '15px',
                              padding: '11px 9px'
                          }}
                          label="Activity" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                      <Typography
                  variant="body1"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  Issues
                </Typography>
                <List className={classes.listRoot}>
                  {myItem.allIssues?.map((items) => {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        style={{ padding: "0px 0px" }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              gutterBottom
                              display="inline"
                              style={{ cursor: "pointer", fontWeight: "600" }}
                            >
                              {items.issue}

                              {permissionsData.scheduler_timesheet_fix_issue ?
                                <Typography
                                  onClick={() => { this.props.toggleTimesheetIssue(items) }}
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    backgroundImage: items.status ?
                                      schedulerColor.main : "red"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography> :
                                <Typography
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    backgroundImage: items.status ?
                                      schedulerColor.main : "grey"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography>}

                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </List>
                      </TabPanel>
                      <TabPanel value="2">
                        {permissionsData.scheduler_timesheet_view_edit_history ?
                          myItem.allActivity?.map((item) => {
                            console.log("itemsss", item)
                           
                              var startTimeold = new Date(item.time)
                              var startTimeee = startTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                              var startTime = moment(startTimeee).format("hh:mm A")

                              // var endTimeold = new Date(item.endTime)
                              // var endTimeee = endTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                              // var endTime = moment(endTimeee).format("hh:mm A")
                           
                              return (
                                <>
                                  <Grid item xs={12} className="ActiveLogMain" style={{ paddingTop: 0 }}>
                                    <Grid item xs={5} md={5} lg={5} sm={5} >
                                    
                                      <Button className="newActivity-btn" style={{lineHeight:"7.75px"}}>
                                        {/* {item.time && moment(utcToLocal(item.time)).format("hh:mm A")} */}
                                        {item.time ?  moment(item.time).format("YYYY/MM/DD") : ""}
                                        </Button>
                                      <div className="ActivityverticalLine" />
                                      {/* <Button className="Account-btn">{moment(item.payroll_approve_date).locale('en-US').format("DD-MMM-YYYY")}</Button> */}
                                    </Grid>
                                    <Grid item xs={7} md={7} lg={7} sm={7}>
                                      <div className="ActiveTitle" style={{marginLeft: "13%"}}>
                                        {item.editeby ? "Editor" + " " + item.editeby : ""}&nbsp;{"@" + " " + item.editeby ? item.label === "Clocked In" ?    moment(item.time).format("hh:mm A") : item.label === "Started Break" ? moment(item.time).format("hh:mm A") :   item.label === "Clocked Out" ? moment(item.time).format("hh:mm A") : item.label === "Ended Break" ? moment(item.time).format("hh:mm A") :  "" : "" }<br/>
                                        {item.editeby && item.msg ? item.msg : ""}
                                      </div>
                                      <div className="ActiveTitle" style={{marginLeft: "13%"}}>{item.label}</div>
                                      {item.device ?
                                      <div className="ActiveTitle" style={{marginLeft: "13%"}}>{"With" + " " + item.device}</div>:""}
                                    </Grid>
                                  </Grid>

                                </>
                              )
                            })
                            : ""}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Card>
              </Grid>

              {/* {permissionsData.scheduler_timesheet_view_edit_history ?
                <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600px",
                      fontSize: "14px",
                      margin: '1px',
                      borderRadius: '10px',
                      border: "none",
                      color: "#ffffff",
                      width: "135px",
                      backgroundColor: schedulerColor.main
                    }}
                    //onClick={() => { this.props.handleActiveTab(1) }}
                    className={classes.button1}>Activity</Button>
                  <hr />
                  <Grid item sm={12} xs={12}>
                    <List className={classes.listRoot} style={{ overflow: "scroll", height: "250px" }}>
                      {myItem.allActivity?.map((items) => {
                        var startTimeold = new Date(items.time)
                        var startTimeee = startTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });

                        var startTime = moment(startTimeee).format("hh:mm A") */}

              {/* // var endTimeold = new Date(item.endTime)
                        // var endTimeee = endTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                        // var endTime = moment(endTimeee).format("hh:mm A") */}

              {/*  return (
                           <ListItem
                             alignItems="flex-start"
                             style={{ padding: "0px 0px" }}
                           >
                             <ListItemText
                               primary={
                                 <Typography
                                   variant="body1"
                                   gutterBottom
                                   display="inline"
                                   style={{ fontWeight: "600" }}
                                 > */}

              {/* vruti */}
              {/* {console.log("items 11",items," new date  ",startTime)} */}

              {/*                                   
                                  {items.label ? items.label : "Edit By" + " " + items.editeby}&nbsp;{items.editeby ? startTime : startTime}
                                   */}

              {/* {items.label ? items.label : "Edit By" + " " + items.editeby}&nbsp;{items.editeby ? new Date(utcToLocal(items.time)).toLocaleString() : items.time && moment(utcToLocal(items.time)).format("hh:mm A")} */}
              {/* {items.device &&
                                    <Typography
                                      variant="body1"
                                      style={{
                                        float: 'right',
                                        color: "#7C7C7C"
                                      }}
                                      display="inline"
                                    >
                                      {items.device}
                                    </Typography>}
                                </Typography>
                              }
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                </Grid> : ""} */}
            </Grid>

          </Grid >
        </Grid >
        :
        <Grid
          style={{ overflowX: "hidden" }}
          className={classes.root2}
          container
          spacing={2}
        >
          <Grid style={{ padding: "15px" }} item sm={8} xs={12}>
            <Button startIcon={<BackIcon />} onClick={this.props.toggleViewTimecard}>
              back
            </Button>
          </Grid>

          <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
            <ButtonGroup
              size="small"
              style={{
                border: `1px solid "#AA076B"`,
                backgroundColor: '#ffffff',
                borderRadius: '100px',
                padding: '2px'
              }}
            >
              <Button
                style={{
                  padding: "5px 25px",
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  border: isGeo ? "0px solid blue" : "1px solid blue",
                  borderRadius: '100px',
                  color: isGeo ? "#AA076B" : "#ffffff",
                  backgroundImage: isGeo ? '#ffffff' : "#AA076B"
                }}
                onClick={() => { this.props.handleActiveGeo(0) }}
                className={classes.button2}>Geo Location</Button>

              <Button
                style={{
                  padding: "5px 25px",
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  border: isGeo ? `1px solid #AA076B` : "0px solid blue",
                  borderRadius: '100px',
                  color: isGeo ? "#ffffff" : "#AA076B",
                  backgroundImage: isGeo ? schedulerColor.main : '#ffffff'
                }}
                onClick={() => { this.props.handleActiveGeo(1) }}
                className={classes.button1}>Photos</Button>
            </ButtonGroup>
          </Grid>

          {!this.props.isGeo ?
            <Grid
              container
              spacing={2}
              justifyContent="center"
            >
              {myItem.geoLocations.length > 0 ? myItem.geoLocations.map((item) => {
                if (item.latitude && item.longitude)
                  return (
                    <Grid item sm={3}>
                      <StaticGoogleMap
                        onClick={() => { this.props.redictToMap(item) }}
                        zoom="18"
                        size="200x200"
                        className={classes.geoimage}
                        apiKey={GOOGLE_API_KEY}
                      >
                        <Marker
                          location={`${item.latitude},${item.longitude}`}
                          color="red"
                        />
                      </StaticGoogleMap>
                      <Typography
                        className={classes.geotext}
                        variant="body1"
                      >
                        {
                          item.status === 1 ? "Clock In" :
                            item.status === 2 ? "Break Start" :
                              item.status === 3 ? "Break End" :
                                "Clock Out"
                        }
                      </Typography>
                    </Grid>
                  )
              }) :
                <Grid item sm={12} style={{ marginTop: "2rem" }}>
                  <Typography
                    align="center"
                    variant="h4"
                  >
                    No records found...
                  </Typography>
                </Grid>
              }
            </Grid>
            :
            <Grid
              container
              spacing={2}
              justifyContent="center"
            >
              {myItem.allSelfie.length > 0 ? myItem.allSelfie.map((item) => {
                if (item.image)
                  return (
                    <Grid item sm={4}>
                      <Avatar
                        style={{ margin: "1rem 0px", width: "100%", height: "300px" }}
                        variant="square"
                        src={item.image}
                        className={classes.avatarText}
                      />
                      <Typography
                        className={classes.geotext}
                        variant="body1"
                      >
                        {
                          item.status === 1 ? "Clock In" :
                            item.status === 2 ? "Break Start" :
                              item.status === 3 ? "Break End" :
                                "Clock Out"
                        }
                      </Typography>
                    </Grid>
                  )
              }) :
                <Grid item sm={12} style={{ marginTop: "2rem" }}>
                  <Typography
                    align="center"
                    variant="h4"
                  >
                    No records found...
                  </Typography>
                </Grid>
              }
            </Grid>
          }

        </Grid>
    )
  }
}

export default withStyles(styles)(AddUser);
