//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core';

const styles = (theme) => ({
        label: {
                fontSize: '18px',
                fontWeight: 500
        },
        input: {
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: 5,
                marginBottom: 0,
                backgroundColor: 'white'
        },
        labelColor: {
                color: 'gray'
        },
        addroles: {
                width: "65%",
                marginTop: "2%",
                marginLeft: "6%"
        },
        addtitle: {
                width: "250%",
        },
        adddesc: {
                height: "40%",
                width: "250%",
                marginTop: "3.5%",
        }
});

class MyTextField extends Component {

        render() {
                const { classes, styledemo } = this.props;


                return (
                        <div>
                                {/* <Typography
                                        gutterBottom
                                        className={classes.label}
                                >
                                        {this.props.label}
                                </Typography> */}

                                <TextField
                                        disabled={this.props.disabled ? this.props.disabled : false}
                                        required={this.props.required ? this.props.required : false}
                                        label={this.props.label}
                                        name={this.props.name}
                                        onChange={this.props.onChange}
                                        onFocus={this.props.onFocus}
                                        value={this.props.value}
                                        type={this.props.type}
                                        error={this.props.error}
                                        helperText={this.props.helperText}
                                        placeholder={this.props.placeholder}
                                        variant="outlined"
                                        size={this.props.size === undefined ? "small" : this.props.size}
                                        className={
                                                styledemo == 1 ? classes.addroles : styledemo == 2 ? classes.addtitle : classes.adddesc
                                        }
                                        inputlabelprops={{ classes: { root: classes.labelColor } }}
                                        inputProps={{
                                                className: classes.input
                                        }}
                                />
                        </div>
                )
        }
}

export default withStyles(styles)(MyTextField);