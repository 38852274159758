import {  HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const tradeServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    fetchTeamDataById,
    approveRejectTeamTrade,
    approveRejectMyTrade
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/app/scheduler/trade`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/trade`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/trade/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/scheduler/trade/${id}`, HEADER_TOKEN).then(response => response)
};

function fetchTeamDataById(id) {
    return axios.get(`${MoNGO_DB_API_URL}/app/scheduler/trade/team/${id}`, HEADER_TOKEN).then(response => response)
};

function approveRejectTeamTrade(id,data) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/trade/team/${id}`, { data }, HEADER_TOKEN).then(response => response)
};

function approveRejectMyTrade(id,data) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/trade/my/${id}`, { data }, HEADER_TOKEN).then(response => response)
};