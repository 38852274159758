import {
  SET_FEEDBACK_AUTO_TAG_LOADING,
  SET_FEEDBACK_AUTO_TAG,
  ADD_FEEDBACK_AUTO_TAG
} from "./constants";

const initialState = {
  isLoading: true,
  autoTagsData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_AUTO_TAG_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_AUTO_TAG:
      return {
        ...state,
        isLoading: false,
        autoTagsData: payload.autoTagsData,
      };
    case ADD_FEEDBACK_AUTO_TAG:
      return {
        ...state,
        isLoading: false,
        autoTagsData: [...state.autoTagsData, payload],
      };
    default:
      return state;
  }
};

export default Reducer;
