import {
    SET_DASHBOARD_LOADING,
    SET_DASHBOARD_LOCATION_BASED,
    SET_DASHBOARD_ROLE_BASED,
    SET_DASHBOARD_LESSON_BASED,
    SET_DASHBOARD_USER_BASED
  } from "./constants";
  
  const initialState = {
    isLoadingDashboard: true,
    dashboardLocationData: [],
    dashboardLessonData:[],
    dashboardRoleData:[],
    dashboardUserData:[],
    dashboardLocationData1: [],
    dashboardLessonData1:[],
    dashboardRoleData1:[],
    dashboardUserData1:[],
    locationLength:""
  }
  
  const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_DASHBOARD_LOADING:
        return {
          ...state,
          isLoadingDashboard: payload
        };
   

      case SET_DASHBOARD_LOCATION_BASED:
        return {
          ...state,
          isLoadingDashboard: false,
          dashboardLocationData: payload.locationData,
          dashboardLocationData1: payload,
          locationLength:payload.total_pages
        };  
       
        case SET_DASHBOARD_ROLE_BASED:
          return {
            ...state,
            isLoadingDashboard: false,
            dashboardRoleData: payload.roleData,
            dashboardRoleData1: payload,
            roleLength:payload.total_pages
          }; 

          case SET_DASHBOARD_LESSON_BASED:
            return {
              ...state,
              isLoadingDashboard: false,
              dashboardLessonData: payload.lessonData,
              dashboardLessonData1: payload,
              lessonLength:payload.total_pages
            }; 

          case SET_DASHBOARD_USER_BASED:
            return {
              ...state,
              isLoadingDashboard: false,
              dashboardUserData: payload.userData,
              dashboardUserData1: payload,
              userLength:payload.total_pages
            }; 
       
      default:
        return state;
    }
  };
  
  export default Reducer;