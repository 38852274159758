export const SET_FEEDBACK_REPORT_LOADING = 'SET_FEEDBACK_REPORT_LOADING'
export const SET_FEEDBACK_REPORT = 'SET_FEEDBACK_REPORT'
export const DELETE_CUSTOM_REPORT = 'DELETE_CUSTOM_REPORT'
export const DELETE_SCHEDULE_REPORT = 'DELETE_SCHEDULE_REPORT'
export const ADD_CUSTOM_REPORT = 'ADD_CUSTOM_REPORT'
export const SET_SCHEDULE_REPORT = 'SET_SCHEDULE_REPORT'
export const ADD_SCHEDULE_REPORT = 'ADD_SCHEDULE_REPORT'
export const EDIT_CUSTOM_REPORT = 'EDIT_CUSTOM_REPORT'
export const EDIT_SCHEDULE_REPORT = 'EDIT_SCHEDULE_REPORT'
export const SET_FEEDBACK_CATEGORY_REPORT = 'SET_FEEDBACK_CATEGORY_REPORT'
export const FETCH_ALL_CUSTOM_REPORT = 'FETCH_ALL_CUSTOM_REPORT'
export const FETCH_CAMPAIGN_DATA = 'FETCH_CAMPAIGN_DATA'
