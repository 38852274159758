import React from "react";
import { MdSpaceDashboard } from "react-icons/md";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { BsPlusLg } from "react-icons/bs";
import "../../../assets/support/css/user-tabs.scss";
import { Link } from "react-router-dom";

function UsersTabs() {
  return (
    <div className="d-flex user-tabs">
      <Link to="#">
        <div className="d-flex align-items-center tab">
          <MdSpaceDashboard
            size={24}
          />
          <p className="m-0 ml-1">My Dashboard</p>
        </div>
      </Link>
      <Link to="#">
        <div className="d-flex tab active">
          <VscCircleLargeFilled
            size={16}
            color="#4CFB3D"
            className="mt-1"
          />
          <div className="ml-1">
            <p className="m-0 user-name">Asad</p>
            <div className="d-flex align-items-center mt-1">
              <div className="type-div">
                <BsFillTelephoneFill
                  size={14}
                  color="gray"
                />
              </div>
              <p className="m-0 status ml-1">Connected</p>
            </div>
          </div>
          <IoCloseOutline
            size={20}
            className="ml-auto"
          />
        </div>
      </Link>
      <Link to="#">
        <div className="d-flex tab">
          <VscCircleLargeFilled
            size={16}
            color="#4CFB3D"
            className="mt-1"
          />
          <div className="ml-1">
            <p className="m-0 user-name">Elizabith</p>
            <div className="d-flex align-items-center mt-1">
              <div className="type-div">
                <BsFillTelephoneFill
                  size={14}
                  color="gray"
                />
              </div>
              <p className="m-0 status ml-1">Connected</p>
            </div>
          </div>
          <IoCloseOutline
            size={20}
            className="ml-auto"
          />
        </div>
      </Link>
      <Link to="#">
        <div className="d-flex tab">
          <VscCircleLargeFilled
            size={16}
            color="#4CFB3D"
            className="mt-1"
          />
          <div className="ml-1">
            <p className="m-0 user-name">John</p>
            <div className="d-flex align-items-center mt-1">
              <div className="type-div">
                <BsFillTelephoneFill
                  size={14}
                  color="gray"
                />
              </div>
              <p className="m-0 status ml-1">Connected</p>
            </div>
          </div>
          <IoCloseOutline
            size={20}
            className="ml-auto"
          />
        </div>
      </Link>
      <Link to="#">
        <div className="d-flex align-items-center tab">
          <BsPlusLg
            size={15}
          />
          <p className="user-name m-0 ml-2">Add</p>
        </div>
      </Link>
    </div>
  )
}

export default UsersTabs;