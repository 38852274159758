import {
  SET_MY_COURSE_LOADING,
  SET_DISPLAY_SECTION,
  SET_MY_COURSE,
  SET_MY_COURSE_LESSONS,
  SET_MY_COURSE_LESSONS_DETAILS,
  SET_MY_COURSE_QUESTION_COMPLETE,
  SET_MY_COURSE_INITIAL,
} from "./constants";

const initialState = {
  isLoading: true,
  displaySection: "course",
  courseLessonsData: [],
  active_lesson_details: {},
  active_course_details: {},
  myCourseData: [],
  lessonName:"",
  lessonId:"",
  myCourse_settings: "",
  myHelpdesk_setting:""
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MY_COURSE_INITIAL:
      return {
        ...state,
        isLoading: true,
        myCourseData: [],
        courseLessonsData: [],
        lessonSlider: [],
        active_course_details: {},
        displaySection: "course",
      };
    case SET_MY_COURSE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_MY_COURSE_QUESTION_COMPLETE:
      var temp_data = state.active_lesson_details
      temp_data.active_question_index = parseInt(temp_data.active_question_index) + parseInt(1)
      return {
        ...state,
        active_lesson_details: temp_data
      };

    case SET_MY_COURSE_LESSONS_DETAILS:
      return {
        ...state,
        isLoading: false,
        lessonSlider: payload.lessonData,
        lessonName:payload.lesson_name,
        lessonId:payload.lesson_id
      };
    case SET_MY_COURSE_LESSONS:
      return {
        ...state,
        isLoading: false,
        // active_course_details: payload.course.course_id,
        courseLessonsData: payload.lessonData,
        myCourse_settings: payload.myCourse_settings,
        myHelpdesk_setting: payload.myHelpdesk_setting

      };
    case SET_DISPLAY_SECTION:
      return {
        ...state,
        displaySection: "lesson"
      };
    case SET_MY_COURSE:
      return {
        ...state,
        isLoading: false,
        myCourseData: payload.myCourseData,
      };
    default:
      return state;
  }
};

export default Reducer;