import { lazy } from "react";
import {
  SchedulerLayout,
  MainLayout,
  PlainLayout,
  TopbarLayout,
  NewsLayout,
  ElearningGetStarted,
} from "../layout";
import { PublicRoute, PrivateRoute } from "../accesslevel";
import { BorderAll as TableIcon } from "@material-ui/icons";
import { BiCog } from "react-icons/bi";
import FeedIcon from "@mui/icons-material/Feed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  products,
  profile,
  company,
  locations,
  users,
  roles,
  payrollImage,
  tickets,
  ideas,
  billings,
  coupons,
  Coloricon,
  feed_image_dashboard,
  feed_image_dashboard_active,
  feed_image_followups,
  feed_image_followups_active,
  feed_image_campaign,
  feed_image_campaign_active,
  feed_image_reports,
  feed_image_reports_active,
  feed_image_team,
  feed_image_team_active,
  feed_image_settings,
  feed_image_settings_active,
  noun_campaigner,
  noun_campaigner_active,
  sc_image_dashboard,
  sc_image_dashboard_active,
  sc_image_scheduler,
  sc_image_scheduler_active,
  sc_image_myavailability,
  sc_image_myavailability_active,
  sc_image_teamavailability,
  sc_image_teamavailability_active,
  sc_image_timeoffrequest,
  sc_image_timeoffrequest_active,
  sc_image_tradeshift,
  sc_image_tradeshift_active,
  sc_image_covershift,
  sc_image_covershift_active,
  sc_image_timesheets,
  sc_image_timesheets_active,
  sc_image_pay_summary,
  sc_image_pay_summary_active,
  sc_image_roles,
  sc_image_roles_active,
  sc_image_team,
  sc_image_team_active,
  sc_image_back,
  sc_image_settings,
  sc_image_settings_active,
  sc_image_settings_scheduling,
  sc_image_settings_scheduling_active,
  sc_image_settings_timetracking,
  sc_image_settings_timetracking_active,
  sc_image_settings_timeofpto,
  sc_image_settings_timeofpto_active,
  ele_image_dashboard,
  ele_image_dashboard_active,
  ele_image_mycourses,
  ele_image_mycourses_active,
  ele_image_library,
  ele_image_library_active,
  ele_image_courses,
  ele_image_courses_active,
  ele_image_helpdesk,
  ele_image_helpdesk_active,
  ele_image_reports,
  ele_image_reports_active,
  ele_image_team,
  ele_image_team_active,
  sc_image_timecard,
  sc_image_timecard_active,
  sc_image_settings_location,
  sc_image_settings_location_active,
  news_update,
  ovChat,
  ovRoutine,
  gradingIcon,
  device_management,
  Wish,
} from "../icons";
import HierarachyChart from "../pages/ovHR/Hierarachy_chart/HierarachyChart";
import OVFormsLayout from "../layout/OVFormsLayout";
import EditQuestionnaire from "../pages/Forms/admin/Questionnaire/EditQuestionnaire";
import SupportLayout from "../layout/SupportLayout";
import RoutineLayout from "../layout/RoutineLayout";
const NotFound = lazy(() => import("../components/NotFound"));
const SignUp = lazy(() => import("../pages/common/SignUp/SignUp"));
const SignUpLocation = lazy(() =>
  import("../pages/common/SignUp/SignUpLocation/SignUpLocation")
);
const SignUpSummary = lazy(() =>
  import("../pages/common/SignUp/SignUpSummary/SignUpSummary")
);
const Settings = lazy(() => import("../pages/eLearning/Settings/Settings"));
const Grading = lazy(() => import("../pages/eLearning/Grading/Grading"));
const GradingLessons = lazy(() =>
  import("../pages/eLearning/Grading/GradingLessons")
);

const SignUpCheckout = lazy(() =>
  import("../pages/common/SignUp/SignUpCheckout/SignUpCheckout")
);
const SignUpCheckoutNew = lazy(() =>
  import("../pages/common/SignUp/SignUpCheckoutNew/SignUpCheckoutNew")
);
const VerifyUser = lazy(() => import("../pages/common/VerifyUser/VerifyUser"));
const Login = lazy(() => import("../pages/common/Login/Login"));
const ForgotPassword = lazy(() =>
  import("../pages/common/ForgotPassword/ForgotPassword")
);
const PrivacyPolicy = lazy(() =>
  import("../pages/common/PrivacyPolicy/PrivacyPolicy")
);
const MainDashboard = lazy(() => import("../pages/common/Dashboard/Dashboard"));
const SettingsProfile = lazy(() => import("../pages/common/Profile/Profile"));
const SettingsProducts = lazy(() =>
  import("../pages/common/Products/Products")
);
const SettingsCompany = lazy(() => import("../pages/common/Company/Company"));
const SettingsLocation = lazy(() =>
  import("../pages/common/Location/Location")
);
const SettingsUsers = lazy(() => import("../pages/common/Users/Users"));
const SettingsRoles = lazy(() => import("../pages/common/Roles/Roles"));
const SettingsDeviceManagement = lazy(() =>
  import("../pages/common/DeviceManagement/DeviceMangement")
);
const Settingspayroll = lazy(() =>
  import("../pages/common/PayrollManager/Payroll")
);
const Settingsholiday = lazy(() =>
  import("../pages/common/PublicHoliday/Holiday")
);
const SettingsTickets = lazy(() => import("../pages/common/Tickets/Tickets"));
const SettingsIdeas = lazy(() => import("../pages/common/Ideas/Ideas"));
const SettingsBillings = lazy(() =>
  import("../pages/common/Billings/Billings")
);
const SettingsCoupons = lazy(() => import("../pages/common/Coupons/Coupons"));
const SettingsColor = lazy(() =>
  import("../pages/common/ProductColor/proColor")
);
const FeedbackCampaignManagement = lazy(() =>
  import("../pages/Feedback/CampaignManagement/CampaignManagement")
);
const FeedbackCampaignManagementForm = lazy(() =>
  import("../pages/Feedback/CampaignManagement/CampaignManagementForm")
);
const FeedbackDashboard = lazy(() =>
  import("../pages/Feedback/Dashboard/Dashboard")
);
const SendFeedback = lazy(() =>
  import("../pages/Feedback/SendFeedback/SendFeedback")
);
const FeedbackFollowups = lazy(() =>
  import("../pages/Feedback/Followups/Followups")
);
const FeedbackCampaign = lazy(() =>
  import("../pages/Feedback/Campaign/Campaign")
);
const FeedbackQR = lazy(() =>
  import("../pages/Feedback/FeedbackQR/FeedbackQR")
);
const FeedbackBusinessLocations = lazy(() =>
  import("../pages/Feedback/BusinessLocations/BusinessLocations")
);
const FeedbackNewDashboard = lazy(() =>
  import("../pages/Feedback/NewDashboard/NewDashboard")
);
const FeedbackNewFeedback = lazy(() =>
  import("../pages/Feedback/NewFeedback/NewFeedback")
);
const FeedbackAutoTag = lazy(() => import("../pages/Feedback/AutoTag/AutoTag"));
const FeedbackWorkFlowAutomation = lazy(() =>
  import("../pages/Feedback/WorkFlowAutomation/WorkFlowAutomation")
);
const FeedbackReports = lazy(() => import("../pages/Feedback/Reports/Reports"));
const FeedbackScoreboardSettings = lazy(() =>
  import("../pages/Feedback/Settings/ScoreboardSettings")
);
const FeedbackScoreboardSettingsEdit = lazy(() =>
  import("../pages/Feedback/Settings/ScoreboardSettingsEdit")
);
const FeedbackSettings = lazy(() =>
  import("../pages/Feedback/Settings/Settings")
);
const FeedbackTeam = lazy(() => import("../pages/Feedback/Team/Team"));
const ELearningDashboard = lazy(() =>
  import("../pages/eLearning/Dashboard/DashboardNew")
);
const ELearningMyCourses = lazy(() =>
  import("../pages/eLearning/MyCourses/MyCourses")
);
const ELearningLibrary = lazy(() =>
  import("../pages/eLearning/Library/Library")
);
const ELearningLibraryNew = lazy(() =>
  import("../pages/eLearning/NewLibrary/Library")
);
const ELearningMainLibrary = lazy(() =>
  import("../pages/eLearning/MainLibrary/MainLibrary")
);
const ELearningCourses = lazy(() =>
  import("../pages/eLearning/Courses/Courses")
);
const ELearningHelpDesk = lazy(() =>
  import("../pages/eLearning/HelpDesk/HelpDesk")
);
const ELearningHelpDeskBin = lazy(() =>
  import("../pages/eLearning/HelpDesk/BinDeleted")
);
const ELearningReports = lazy(() =>
  import("../pages/eLearning/Reports/Reports")
);
const ELearningmyHelpdeskOld = lazy(() =>
  import("../pages/eLearning/MyHelpDesk/MyDesk")
);
const ELearningmyHelpdesk = lazy(() =>
  // import("../pages/eLearning/MyhelpdeskV2/MyAllLessonsDiv")
  import ("../pages/eLearning/MyhelpdeskV2/MyAllLessonsDivNew")
);
const ELearningmyHelpdeskFeatured = lazy(() =>
  import("../pages/eLearning/MyhelpdeskV2/FeaturedLessons")
);
// const ELearninguserss = lazy(() => import("../pages/eLearning/Dashboard/UserReport"))
const ELearningTeam = lazy(() => import("../pages/eLearning/Team/Team"));
const SchedulerSchedule = lazy(() =>
  import("../pages/Scheduler/Schedule/Schedule")
);
const SchedulerMyAvailability = lazy(() =>
  import("../pages/Scheduler/MyAvailability/MyAvailability")
);
const ELearningMyCoursesSlider = lazy(() =>
  // import("../pages/eLearning/MyCourses/Slider")
  import("../pages/eLearning/MyCourses/SliderNew")
);
const ELearningMyCoursesNew = lazy(() =>
  import("../pages/eLearning/MyhelpdeskV2/MyAllLessonsDivNew")
);
const ELearningMyHelpDeskCoursesSlider = lazy(() =>
  import("../pages/eLearning/MyhelpdeskV2/Slider")
);
const ELearningHelpdeskSlider = lazy(() =>
  import("../pages/eLearning/MyCourses/HelpdeskSlider")
);
const ELearningMyCoursesLessons = lazy(() =>
  import("../pages/eLearning/MyCourses/LessonsDiv")
);
const ELearningMyHelpdeskLessons = lazy(() =>
  import("../pages/eLearning/MyhelpdeskV2/LessonsDiv")
);
const ELearningMyAllCoursesLessons = lazy(() =>
  import("../pages/eLearning/MyCourses/MyAllLessonsDiv")
);
const ELearningMyAllCoursesLessonsNew = lazy(() =>
  import("../pages/eLearning/MyCourses/NewMyCourses")
);
const ElearningCoursesFeedback = lazy(() =>
  import("../pages/eLearning/Feedbacks/CourseFeedbacks")
);
const ElearningCourseFeedbackDetails = lazy(() =>
  import("../pages/eLearning/Feedbacks/CourseFeedbackDetails")
);

const SchedulerAvailability = lazy(() =>
  import("../pages/Scheduler/NewAvailability/AvailabilityMain")
);
// const SchedulerMyAvailabilityNew = lazy(() => import("../pages/Scheduler/MyAvailability/MyAvailabilityNew"));
const SchedulerNewMyAvailability = lazy(() =>
  import("../pages/Scheduler/MyAvailability/NewMyAvailability")
);
// const SchedulerTeamAvailability = lazy(() => import("../pages/Scheduler/TeamAvailability/TeamAvailability"));
const SchedulerTeamAvailability = lazy(() =>
  import("../pages/Scheduler/TeamAvailability/NewTeamAvalibility")
);
const SchedulerTimeOffRequest = lazy(() =>
  import("../pages/Scheduler/TimeOffRequest/TimeOffRequestMain")
);
const SchedulerTimeOffHistory = lazy(() =>
  import("../pages/Scheduler/TimeOffHistory/TimeOffHistory")
);
const SchedulerTradeShift = lazy(() =>
  import("../pages/Scheduler/TradeShift/TradeShiftMain")
);
const SchedulerCoverShift = lazy(() =>
  import("../pages/Scheduler/CoverShift/CoverShiftMain")
);
const SchedulerTimesheets = lazy(() =>
  import("../pages/Scheduler/Timesheets/Timesheets")
);
const SchedulerPayrollSummary = lazy(() =>
  import("../pages/Scheduler/PayrollSummary/PayrollSummary")
);
const SchedulerCorrectionRequest = lazy(() =>
  import("../pages/Scheduler/CorrectRequest/Correction")
);
const SchedulerMyCorrectionRequest = lazy(() =>
  import("../pages/Scheduler/MyCorrectionRequest/myCorrectionRequest")
);
const SchedulerTimeOffPTO = lazy(() =>
  import("../pages/Scheduler/Settings/TimeOffPTO")
);
const SchedulerRoles = lazy(() => import("../pages/Scheduler/Roles/Roles"));
const SchedulerTeam = lazy(() => import("../pages/Scheduler/Team/Team"));
const SchedulerSettingsLocation = lazy(() =>
  import("../pages/Scheduler/Settings/LocationBasicInfo")
);
const SchedulerSettingsTimeTracking = lazy(() =>
  import("../pages/Scheduler/Settings/TimeTracking")
);
const SchedulerSettingsScheduling = lazy(() =>
  import("../pages/Scheduler/Settings/Scheduling")
);
const SchedulerSettingsPayroll = lazy(() =>
  import("../pages/Scheduler/Settings/Payroll")
);
const SchedulerSettingsPayrollNew = lazy(() =>
  import("../pages/Scheduler/Settings/PayrollNew")
);
const SchedulerTimecard = lazy(() =>
  import("../pages/Scheduler/Timecard/Timecard")
);
const ToDoDashboard = lazy(() => import("../pages/Todo/Dashboard/Dashboard"));
const NewsUpdateNews = lazy(() => import("../pages/News/NewsUpdate/News"));
const NewsDashboard = lazy(() =>
  import("../pages/News/NewsUpdate/NewsDashboard")
);
const Preview = lazy(() => import("../pages/News/NewsUpdate/Preview"));
const PreviewNews = lazy(() => import("../pages/News/NewsUpdate/NewsPreview"));
const MyNews = lazy(() => import("../pages/News/NewsUpdate/MyNews"));
const SchedulerDashboard = lazy(() =>
  import("../pages/Scheduler/Dashboard/Dashboard")
);
const RoutineDashboard = lazy(() =>
  import("../pages/Forms/admin/Dashboard/Dashboard")
);

const RoutineNewDashboard = lazy(() =>
  import("../pages/RoutineNew/Dashboard")
);
const RoutineListPageDashboard = lazy(() =>
  import("../pages/RoutineNew/ListPage/ListPage")
);

const RoutineTaskLibrary = lazy(() =>
  import("../pages/RoutineNew/TaskLibrary/TaskLibrary")
);

const ViewForms = lazy(() => import("../pages/Forms/admin/Forms/ViewForms"));
const Questionnaire = lazy(() =>
  import("../pages/Forms/admin/Questionnaire/Questionnaire")
);
const Rating = lazy(() => import("../pages/Rating/ovRating/Rating"));
const TeamRating = lazy(() => import("../pages/Rating/TeamRating/TeamRating"));
const MyRatings = lazy(() => import("../pages/Rating/MyRatings/MyRatings"));
const Demo = lazy(() => import("../pages/Scheduler/Demo/Demo"));
// const HierarachyChart = lazy(() => import("../pages/ovHR/Hierarachy_chart/HierarachyChart"));

const Groups = lazy(() => import("../pages/Forms/admin/Groups/Groups"));
const NewGroup = lazy(() => import("../pages/Forms/admin/Groups/NewGroup"));
const EditGroup = lazy(() => import("../pages/Forms/admin/Groups/EditGroup"));
const NonRoutineTasks = lazy(() =>
  import("../pages/Forms/admin/NonroutineTasks/NonRoutineTasks")
);
const RoutineUserDashboard = lazy(() =>
  import("../pages/Forms/user/Dashboard/Dashboard")
);
const MyRoutineTasks = lazy(() =>
  import("../pages/Forms/user/RoutineTasks/MyRoutineTasks")
);
const RoutineTaskDetails = lazy(() =>
  import("../pages/Forms/user/RoutineTasks/RoutineTaskDetails")
);
const MyNonRoutineTasks = lazy(() =>
  import("../pages/Forms/user/NonroutineTasks/NonRoutineTasks")
);

const SupportDashboard = lazy(() => import("../pages/support/Dashboard"));
const Tickets = lazy(() => import("../pages/support/Tickets"));
const UserTickets = lazy(() => import("../pages/support/UserTickets"));
const UserCalls = lazy(() => import("../pages/support/UserCalls"));
const UserNotes = lazy(() => import("../pages/support/UserNotes"));
const UserTasks = lazy(() => import("../pages/support/UserTasks"));
const UserChat = lazy(() => import("../pages/support/UserChat"));
const UserEmails = lazy(() => import("../pages/support/UserEmails"));
const UserTimeline = lazy(() => import("../pages/support/UserTimeline"));
const ProductList = lazy(() => import("../pages/productList/ProductList"));
const AddProduct = lazy(() =>
  import("../pages/productList/addProduct/basicInfo/BasicInfo")
);
const AddImageVideo = lazy(() =>
  import("../pages/productList/addProduct/imagesvideos/ImagesVideos")
);
const FeatureList = lazy(() =>
  import("../pages/productList/addFeature/FeatureList")
);
const AddFeature = lazy(() =>
  import("../pages/productList/addFeature/FeatureBasicInfo/FeatureBasicInfo")
);
const EditFeature = lazy(() =>
  import("../pages/productList/addFeature/FeatureBasicInfo/EditFeatureBasicInfo")
);
const AddFeatureImage = lazy(() =>
  import("../pages/productList/addProduct/imagesvideos/ImagesVideos")
);
const AddFeatureSeo = lazy(() =>
  import("../pages/productList/addProduct/seo/Seo")
);
const AddFeaturePricing = lazy(() =>
  import("../pages/productList/addProduct/pricing/Pricing")
);
const AddFeatureDisplay = lazy(() =>
  import("../pages/productList/addProduct/display/Display")
);

const Routes = [
  // {
  //   path: "/elearning/harshad",
  //   label: "Dashboard",
  //   image: ele_image_dashboard,
  //   active_image: ele_image_dashboard_active,
  //   component: HarshadDemo,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_dashboard"
  // },
  {
    path: "/privacy-policy",
    label: "privacy",
    tooltip: "",
    icon: <TableIcon />,
    component: PrivacyPolicy,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/signup",
    label: "SignUp",
    tooltip: "",
    icon: <TableIcon />,
    component: SignUp,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/add_location",
    label: "Location",
    tooltip: "",
    icon: <TableIcon />,
    component: SignUpLocation,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/summary",
    label: "Summary",
    tooltip: "",
    icon: <TableIcon />,
    component: SignUpSummary,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/pricing",
    label: "Summary",
    tooltip: "",
    icon: <TableIcon />,
    component: SignUpCheckoutNew,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/checkout",
    label: "Summary",
    tooltip: "",
    icon: <TableIcon />,
    component: SignUpCheckout,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/verify-user",
    label: "VerifyUser",
    tooltip: "",
    icon: <TableIcon />,
    component: VerifyUser,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/",
    label: "Login",
    tooltip: "",
    icon: <TableIcon />,
    component: Login,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/forgot-password",
    label: "Forgot Password",
    tooltip: "",
    icon: <TableIcon />,
    component: ForgotPassword,
    product: "Demo",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/dashboard",
    label: "All Products",
    tooltip: "",
    icon: <TableIcon />,
    component: MainDashboard,
    product: "dashboard",
    layout: TopbarLayout,
    access: PrivateRoute,
  },
  {
    path: "/settings/products",
    label: "Products",
    tooltip: "",
    image: products,
    active_image: products,
    component: SettingsProducts,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/settings/profile",
    label: "Profile",
    tooltip: "",
    image: profile,
    active_image: profile,
    component: SettingsProfile,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/elearning/mycourses-new/slider/:cid/:id",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningMyCoursesSlider,
    // product: "eLearning",
    layout: PlainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },

  {
    path: "/elearning/grading/gradinglessons",
    tooltip: "",
    component: GradingLessons,
    layout: PlainLayout,
    access: PrivateRoute,
  },
  {
    path: "/elearning/helpdesk/slider/:cid/:course_id/:id",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningHelpdeskSlider,
    // product: "eLearning",
    layout: PlainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  {
    path: "/elearning/mycourses/lessons/:id",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningMyCoursesLessons,
    // product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  {
    path: "/elearning/mycourses/lessons",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningMyAllCoursesLessons,
    // product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  {
    path: "/settings/company",
    label: "Company",
    tooltip: "",
    image: company,
    active_image: company,
    component: SettingsCompany,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_company",
  },
  {
    path: "/settings/locations",
    tooltip: "",
    label: "Location",
    image: locations,
    active_image: locations,
    component: SettingsLocation,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_location",
  },
  {
    path: "/settings/users",
    label: "Users",
    tooltip: "",
    image: users,
    active_image: users,
    component: SettingsUsers,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_users",
  },
  {
    path: "/settings/device",
    label: "Device Management",
    tooltip: "",
    image: device_management,
    active_image: device_management,
    component: SettingsDeviceManagement,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_payroll",
  },
  {
    path: "/settings/tickets",
    label: "Tickets",
    tooltip: "",
    image: tickets,
    active_image: tickets,
    component: SettingsTickets,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_tickets",
  },
  {
    path: "/settings/ideas",
    label: "Ideas",
    tooltip: "",
    image: ideas,
    active_image: ideas,
    component: SettingsIdeas,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_ideas",
  },
  {
    path: "/settings/billings",
    label: "Billings",
    tooltip: "",
    image: billings,
    active_image: billings,
    component: SettingsBillings,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_billings",
  },
  {
    path: "/settings/coupons",
    label: "Coupons",
    tooltip: "",
    image: coupons,
    active_image: coupons,
    component: SettingsCoupons,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_coupons",
  },
  {
    path: "/settings/color",
    label: "Product Color",
    tooltip: "",
    image: Coloricon,
    active_image: Coloricon,
    component: SettingsColor,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_color",
  },
  {
    path: "/settings/productList",
    label: "Product List",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: ProductList,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_color",
  },
  // {
  //   path: "/features/basicinfo",
  //   label: "Basic Info",
  //   tooltip: "",
  //   image: Wish,
  //   active_image: Wish,
  //   component: AddProduct,
  //   layout: MainLayout,
  //   access: PrivateRoute,
  // },
  {
    path: "/settings/productList/basicinfo",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: AddProduct,
    product: "Settings",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_color",
  },
  {
    path: "/features/basicinfo/:id",
    label: "Edit Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: AddProduct,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/viewFeatures/basicinfo/:id",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: FeatureList,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/addFeatures/basicinfo/:id",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: AddFeature,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/editFeatures/basicinfo/:id",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: EditFeature,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/addFeatures/basicinfo",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: AddFeature,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/addFeatures/ImagesVideos/:id",
    label: "Add Products",
    tooltip: "",
    image: Wish,
    active_image: Wish,
    component: AddFeature,
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_products",
  },
  {
    path: "/features/imagesvideos/:id",
    component: AddImageVideo,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/imagesvideos",
    component: AddImageVideo,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/seo/:id",
    component: AddFeatureSeo,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/seo",
    component: AddFeatureSeo,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/pricing/:id",
    component: AddFeaturePricing,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/pricing",
    component: AddFeaturePricing,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/display/:id",
    component: AddFeatureDisplay,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/features/display",
    component: AddFeatureDisplay,
    layout: MainLayout,
    label: "Add Image",
    tooltip: "",
    access: PrivateRoute,
  },
  {
    path: "/todo/dashboard/personal",
    label: "Assign to me",
    tooltip: "",
    icon: <TableIcon />,
    component: ToDoDashboard,
    product: "Todo",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/todo/dashboard/byme",
    label: "Assign by me",
    tooltip: "",
    icon: <TableIcon />,
    component: ToDoDashboard,
    product: "Todo",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/todo/dashboard/urgent",
    label: "Urgent",
    tooltip: "",
    icon: <TableIcon />,
    component: ToDoDashboard,
    product: "Todo",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/todo/dashboard/lock",
    label: "Locked",
    tooltip: "",
    icon: <TableIcon />,
    component: ToDoDashboard,
    product: "Todo",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/todo/dashboard/list",
    label: "",
    tooltip: "",
    icon: <TableIcon />,
    component: ToDoDashboard,
    product: "TodoList",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/feedback/dashboard",
    label: "Dashboard",
    tooltip: "",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
    component: FeedbackDashboard,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_dashboard",
  },
  {
    path: "/feedback/campaign/management",
    label: "Dashboard",
    tooltip: "",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
    component: FeedbackCampaignManagement,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_dashboard",
  },
  {
    path: "/feedback/campaign/management/template",
    label: "Dashboard",
    tooltip: "",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
    component: FeedbackCampaignManagementForm,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_dashboard",
  },
  {
    path: "/feedback/followups",
    label: "Followups",
    tooltip: "",
    image: feed_image_followups,
    active_image: feed_image_followups_active,
    component: FeedbackFollowups,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_followups",
  },
  {
    path: "/feedback/campaign",
    label: "Campaign",
    tooltip: "",
    image: noun_campaigner,
    active_image: noun_campaigner_active,
    component: FeedbackCampaign,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_campaign",
  },
  {
    path: "/feedback/business/location",
    label: "Business Locations",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackBusinessLocations,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/autotag",
    label: "Auto Tag",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackAutoTag,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/dashboard/new",
    label: "Dashboard New",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackNewDashboard,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/new",
    label: "New Feedback",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackNewFeedback,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/workflow/automation",
    label: "Workflow automation",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackWorkFlowAutomation,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/location",
    label: "Locations",
    tooltip: "",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    component: FeedbackQR,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_qr_locations",
  },
  {
    path: "/feedback/reports",
    label: "Reports",
    tooltip: "",
    image: feed_image_reports,
    active_image: feed_image_reports_active,
    component: FeedbackReports,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_reports",
  },
  {
    path: "/feedback/settings",
    label: "Settings",
    tooltip: "",
    image: feed_image_settings,
    active_image: feed_image_settings_active,
    component: FeedbackSettings,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_settings",
  },
  {
    path: "/feedback/settings/scoreboard",
    label: "Scoreboard Settings",
    tooltip: "",
    image: feed_image_settings,
    active_image: feed_image_settings_active,
    component: FeedbackScoreboardSettings,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_settings",
  },
  {
    path: "/feedback/settings/scoreboard/edit",
    label: "Scoreboard Settings",
    tooltip: "",
    image: feed_image_settings,
    active_image: feed_image_settings_active,
    component: FeedbackScoreboardSettingsEdit,
    product: "Feedback",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "feedback_settings",
  },
  // {
  //   path: "/feedback/team",
  //   label: "Team",
  //   tooltip: "",
  //   image: feed_image_team,
  //   active_image: feed_image_team_active,
  //   component: FeedbackTeam,
  //   product: "Feedback",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "feedback_team"
  // },
  {
    path: "/elearning/get-started",
    label: "Get Started",
    tooltip: "",
    image: ele_image_dashboard,
    active_image: ele_image_dashboard_active,
    // component: ELearningDashboard,
    product: "eLearning",
    layout: ElearningGetStarted,
    access: PrivateRoute,
    permission: "elearning_dashboard",
    invisible: 1,
  },
  {
    path: "/elearning/dashboard",
    label: "Dashboard",
    tooltip: "",
    image: ele_image_dashboard,
    active_image: ele_image_dashboard_active,
    component: ELearningDashboard,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_dashboard",
  },
  // {
  //   path: "/elearning/myHelpDesk/old",
  //   label: "My HelpDesk",
  //   tooltip: "",
  //   image: ele_image_reports,
  //   active_image: ele_image_reports_active,
  //   component: ELearningmyHelpdeskOld,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_myhelpdesk"
  // },
  // {
  //   path: "/elearning/myHelpDesk",
  //   label: "My Library",
  //   tooltip: "",
  //   image: ele_image_reports,
  //   active_image: ele_image_reports_active,
  //   component: ELearningmyHelpdesk,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_myhelpdesk",
  // },
  {
    path: "/elearning/myhelpdesk",
    label: "My Library",
    tooltip: "",
    image: ele_image_reports,
    active_image: ele_image_reports_active,
    component: ELearningmyHelpdesk,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_myhelpdesk",
  },
  {
    path: "/elearning/myHelpDesk/lessons/:id",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningMyHelpdeskLessons,
    // product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  {
    path: "/elearning/myHelpDesk/featured-lessons",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningmyHelpdeskFeatured,
    // product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  {
    path: "/elearning/myHelpDesk/slider/:cid/:id",
    tooltip: "",
    // label: "My Slider",
    // image: ele_image_mycourses,
    // active_image: ele_image_mycourses_active,
    component: ELearningMyHelpDeskCoursesSlider,
    // product: "eLearning",
    layout: PlainLayout,
    access: PrivateRoute,
    //permission: "elearning_mycourse"
  },
  // {
  //   path: "/elearning/UsersDetails/:id",
  //   //label: "My HelpDesk",
  //   tooltip: "",
  //   image: ele_image_reports,
  //   active_image: ele_image_reports_active,
  //   component: ELearninguserss,
  //   //product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   //permission: "elearning_helpdesk"
  // },
  // {
  //   path: "/elearning/mycourses",
  //   label: "My Courses",
  //   tooltip: "",
  //   image: ele_image_mycourses,
  //   active_image: ele_image_mycourses_active,
  //   // component: ELearningMyCourses,
  //   component: ELearningMyAllCoursesLessons,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_mycourse",
  // },
  {
    path: "/elearning/mycourses-new",
    label: "My Courses",
    tooltip: "",
    image: ele_image_mycourses,
    active_image: ele_image_mycourses_active,
    // component: ELearningMyCourses,
    component: ELearningMyAllCoursesLessonsNew,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_mycourse",
  },
  {
    path: "/elearning/mycourses-old",
    label: "My Courses",
    tooltip: "",
    image: ele_image_mycourses,
    active_image: ele_image_mycourses_active,
    component: ELearningMyCourses,
    // component: ELearningMyAllCoursesLessons,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_mycourse",
    invisible: 1,
  },
  {
    path: "/elearning/feedbacks",
    label: "Courses Feedback",
    tooltip: "",
    image: ele_image_mycourses,
    active_image: ele_image_mycourses_active,
    // component: ELearningMyCourses,
    component: ElearningCoursesFeedback,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_mycourse",
  },
  {
    path: "/elearning/feedbacks/:id",
    label: "Slide Feedback",
    tooltip: "",
    image: ele_image_mycourses,
    active_image: ele_image_mycourses_active,
    // component: ELearningMyCourses,
    component: ElearningCourseFeedbackDetails,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_mycourse",
    invisible: 1,
  },
  // {
  //   path: "/elearning/category",
  //   label: "Library",
  //   tooltip: "",
  //   image: ele_image_library,
  //   active_image: ele_image_library_active,
  //   component: ELearningLibrary,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_library"
  // },
  {
    path: "/elearning/library",
    label: "Library",
    tooltip: "",
    image: ele_image_library,
    active_image: ele_image_library_active,
    component: ELearningLibraryNew,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_library",
  },
  // {
  //   path: "/elearning/MainLibrary",
  //   label: "Main Library",
  //   tooltip: "",
  //   image: ele_image_library,
  //   active_image: ele_image_library_active,
  //   component: ELearningMainLibrary,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_library",
  // },
  {
    path: "/elearning/grading",
    label: "Grading",
    tooltip: "",
    images: gradingIcon,
    active_image: gradingIcon,
    component: Grading,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_library",
  },
  {
    path: "/elearning/settings",
    label: "Settings",
    tooltip: "",
    component: Settings,
    product: "eLearning",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "elearning_library",
  },
  // {
  //   path: "/elearning/courses",
  //   label: "Courses",
  //   image: ele_image_courses,
  //   active_image: ele_image_courses_active,
  //   component: ELearningCourses,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_course"
  // },
  // {
  //   path: "/elearning/helpdesk_builder",
  //   label: "HelpDesk Builder",
  //   tooltip: "",
  //   image: ele_image_helpdesk,
  //   active_image: ele_image_helpdesk_active,
  //   component: ELearningHelpDesk,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_helpdesk"
  // },
  {
    path: "/elearning/helpdesk_builder/bin_delete",
    label: "HelpDesk Bin Delete",
    tooltip: "",
    // image: ele_image_helpdesk,
    // active_image: ele_image_helpdesk_active,
    component: ELearningHelpDeskBin,
    layout: MainLayout,
    access: PrivateRoute,
  },
  // {
  //   path: "/elearning/reports",
  //   label: "Reports",
  //   image: ele_image_reports,
  //   active_image: ele_image_reports_active,
  //   component: ELearningReports,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_reports"
  // },
  // {
  //   path: "/elearning/team",
  //   label: "Team",
  //   image: ele_image_team,
  //   active_image: ele_image_team_active,
  //   component: ELearningTeam,
  //   product: "eLearning",
  //   layout: MainLayout,
  //   access: PrivateRoute,
  //   permission: "elearning_team"
  // },
  {
    path: "/scheduler/dashboard",
    label: "Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: SchedulerDashboard,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_dashboard",
  },
  {
    path: "/scheduler/scheduler",
    label: "Scheduler",
    tooltip: "",
    image: sc_image_scheduler,
    active_image: sc_image_scheduler_active,
    component: SchedulerSchedule,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_schedule",
  },
  // {
  //   path: "/scheduler/myavailability",
  //   label: "My Availability",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   icon: <TableIcon />,
  //   component: SchedulerMyAvailability,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute,
  //   permission: "scheduler_availability"
  // },

  {
    path: "/scheduler/availability",
    label: "Availability",
    tooltip: "",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    icon: <TableIcon />,
    component: SchedulerAvailability,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    count: "scheduler_availability",
    // permission: "scheduler_availability"
  },
  // {
  //   path: "/scheduler/MyAvailabilityNew",
  //   label: "My Availability",
  //   tooltip: "",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   icon: <TableIcon />,
  //   component: SchedulerNewMyAvailability,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute,
  //   count: "scheduler_availability",
  //   // permission: "scheduler_availability"
  // },
  {
    path: "/scheduler/teamavailability",
    label: "Team Availability",
    tooltip: "",
    image: sc_image_teamavailability,
    active_image: sc_image_teamavailability_active,
    component: SchedulerTeamAvailability,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_team_availability",
    count: "scheduler_availability",
  },
  {
    path: "/scheduler/timeoffrequest",
    label: "Time Off Request",
    tooltip: "",
    image: sc_image_timeoffrequest,
    active_image: sc_image_timeoffrequest_active,
    component: SchedulerTimeOffRequest,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_timeoff_request",
    count: "scheduler_timeoff_requests",
  },
  {
    path: "/scheduler/tradeshift",
    label: "Trade Shift",
    tooltip: "",
    image: sc_image_tradeshift,
    active_image: sc_image_tradeshift_active,
    component: SchedulerTradeShift,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_trade_shift",
    count: "scheduler_trade_shifts",
  },
  {
    path: "/scheduler/covershift",
    label: "Cover Shift",
    tooltip: "",
    image: sc_image_covershift,
    active_image: sc_image_covershift_active,
    component: SchedulerCoverShift,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_cover_shift",
    count: "scheduler_cover_shifts",
  },
  {
    path: "/scheduler/timesheets",
    label: "Timesheets",
    tooltip: "",
    image: sc_image_timesheets,
    active_image: sc_image_timesheets_active,
    component: SchedulerTimesheets,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_timesheet",
  },
  {
    path: "/scheduler/pay_summary",
    label: "Payroll Summary",
    tooltip: "",
    image: sc_image_pay_summary,
    active_image: sc_image_pay_summary_active,
    icon: <TableIcon />,
    component: SchedulerPayrollSummary,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_payroll_summary",
  },
  {
    path: "/scheduler/correction_request",
    label: "Correction",
    tooltip: "",
    image: sc_image_pay_summary,
    active_image: sc_image_pay_summary_active,
    icon: <TableIcon />,
    component: SchedulerCorrectionRequest,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
  },
  // {
  //   path: "/scheduler/mycorrection_request",
  //   label: "My Correction Request",
  //   tooltip: "",
  //   image: sc_image_pay_summary,
  //   active_image: sc_image_pay_summary_active,
  //   icon: <TableIcon />,
  //   component: SchedulerMyCorrectionRequest,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute,
  // },
  // {
  //   path: "/scheduler/teams",
  //   label: "Team",
  //   image: sc_image_team,
  //   active_image: sc_image_team_active,
  //   component: SchedulerTeam,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute
  // },
  // {
  //   path: "/scheduler/timeoffhistory",
  //   label: "Time Off History",
  //   image: sc_image_timeoffrequest,
  //   active_image: sc_image_timeoffrequest_active,
  //   component: SchedulerTimeOffHistory,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute
  // },
  {
    path: "/scheduler/timecard",
    label: "Timecard Report",
    tooltip: "",
    image: sc_image_timecard,
    active_image: sc_image_timecard_active,
    component: SchedulerTimecard,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_timecard_report",
  },
  {
    path: "/scheduler/settings/time_tracking",
    label: "Settings",
    tooltip: "",
    image: sc_image_settings,
    active_image: sc_image_settings_active,
    component: SchedulerSettingsTimeTracking,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
  },
  {
    path: "/scheduler/dashboard",
    label: "Back",
    tooltip: "",
    image: sc_image_back,
    active_image: sc_image_back,
    component: SchedulerTeam,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
  },
  {
    path: "/scheduler/demo",
    label: "Demo",
    tooltip: "",
    image: sc_image_team,
    active_image: sc_image_team_active,
    component: Demo,
    product: "Demo",
    layout: SchedulerLayout,
    access: PrivateRoute,
  },
  // {
  //   path: "/scheduler/settings/location",
  //   label: "Location Basic Info",
  //   image: sc_image_settings_location,
  //   active_image: sc_image_settings_location_active,
  //   component: SchedulerSettingsLocation,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute,
  //   permission: "scheduler_settings"
  // },
  {
    path: "/scheduler/settings/time_tracking",
    label: "Time Tracking",
    tooltip: "",
    image: sc_image_settings_timetracking,
    active_image: sc_image_settings_timetracking_active,
    component: SchedulerSettingsTimeTracking,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
  },
  {
    path: "/scheduler/settings/scheduling",
    label: "Scheduling",
    tooltip: "",
    image: sc_image_settings_scheduling,
    active_image: sc_image_settings_scheduling_active,
    component: SchedulerSettingsScheduling,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
  },
  // {
  //   path: "/scheduler/settings/payroll",
  //   label: "Payroll",
  //   image: sc_image_pay_summary,
  //   active_image: sc_image_pay_summary_active,
  //   component: SchedulerSettingsPayroll,
  //   product: "Scheduler",
  //   layout: SchedulerLayout,
  //   access: PrivateRoute,
  //   permission: "scheduler_settings"
  // },
  {
    path: "/scheduler/settings/payrollNew",
    label: "Payroll ",
    tooltip: "",
    image: sc_image_pay_summary,
    active_image: sc_image_pay_summary_active,
    component: SchedulerSettingsPayrollNew,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
    count: "schedular_noPayrollUsers",
  },
  {
    path: "/scheduler/settings/timeoff_pto",
    label: "Time Off & PTO",
    tooltip: "",
    image: sc_image_settings_timeofpto,
    active_image: sc_image_settings_timeofpto_active,
    component: SchedulerTimeOffPTO,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
  },
  {
    path: "/scheduler/settings/roles",
    // label: "Roles",
    label: "Department",
    tooltip: "",
    image: sc_image_roles,
    active_image: sc_image_roles_active,
    component: SchedulerRoles,
    product: "Scheduler",
    layout: SchedulerLayout,
    access: PrivateRoute,
    permission: "scheduler_settings",
  },
  {
    path: "/newsandupdate/dashboard",
    label: "Dashboard",
    tooltip: "",
    icon: <FeedIcon />,
    component: NewsDashboard,
    product: "News",
    layout: NewsLayout,
    access: PrivateRoute,
  },
  {
    path: "/newsandupdate/mynews",
    label: "My News",
    tooltip: "",
    icon: <NewspaperIcon />,
    component: MyNews,
    product: "News",
    layout: NewsLayout,
    access: PrivateRoute,
  },
  {
    path: "/newsandupdate/news",
    label: "News Builder",
    tooltip: "",
    icon: <FeedIcon />,
    component: NewsUpdateNews,
    product: "News",
    layout: NewsLayout,
    access: PrivateRoute,
  },

  {
    path: "/dashboard",
    label: "Chat",
    tooltip: "",
    image: ovChat,
    active_image: ovChat,
    product: "Chat",
    component: MainDashboard,
    layout: PlainLayout,
    access: PrivateRoute,
  },
  {
    path: "/dashboard",
    label: "Forms",
    tooltip: "",
    image: ovRoutine,
    active_image: ovRoutine,
    component: MainDashboard,
    product: "Forms",
    layout: PlainLayout,
    access: PrivateRoute,
  },

  {
    path: "/ovRating/Rating",
    label: "Rating",
    tooltip: "",
    icon: <FeedIcon />,
    component: Rating,
    product: "360 Rating",
    layout: MainLayout,
    permission: "rating_form",
    access: PrivateRoute,
  },

  {
    path: "/ovRating/RateTeam",
    label: "Rate Team",
    tooltip: "",
    icon: <FeedIcon />,
    component: TeamRating,
    product: "360 Rating",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/ovRating/myRatings",
    label: "My Ratings",
    tooltip: "",
    icon: <FeedIcon />,
    component: MyRatings,
    product: "360 Rating",
    layout: MainLayout,
    access: PrivateRoute,
  },
  {
    path: "/404",
    label: "NotFound",
    tooltip: "",
    icon: <TableIcon />,
    component: NotFound,
    product: "NotFound",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/feedback/qr",
    label: "SendFeedback",
    tooltip: "",
    icon: <TableIcon />,
    component: SendFeedback,
    product: "SendFeedback",
    layout: PlainLayout,
    access: PublicRoute,
  },
  {
    path: "/hr/hierarachy_chart",
    label: "Hierarachy chart",
    tooltip: "",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
    component: HierarachyChart,
    product: "ovHR",
    layout: MainLayout,
    access: PrivateRoute,
    // permission: "feedback_dashboard"
  },
  {
    path: "/hr/roles",
    label: "Roles & Permissions",
    tooltip: "",
    image: roles,
    active_image: roles,
    component: SettingsRoles,
    product: "ovHR",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_roles",
  },
  {
    path: "/hr/users",
    label: "Users",
    tooltip: "",
    image: users,
    active_image: users,
    component: SettingsUsers,
    product: "ovHR",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_users",
  },
  {
    path: "/hr/payroll",
    label: "Payroll Management",
    tooltip: "",
    image: payrollImage,
    active_image: payrollImage,
    component: Settingspayroll,
    product: "ovHR",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_payroll",
  },
  {
    path: "/hr/holiday",
    label: "Public Holiday",
    tooltip: "",
    image: payrollImage,
    active_image: payrollImage,
    component: Settingsholiday,
    product: "ovHR",
    layout: MainLayout,
    access: PrivateRoute,
    permission: "common_payroll",
  },
  {
    path: "/forms/dashboard",
    label: "Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineDashboard,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
  },

  //////////Routine//////
  {
    path: "/routine",
    label: "Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineNewDashboard,
    product: "Routine",
    layout: RoutineLayout,
    access: PrivateRoute,
  },

  {
    path: "/routine/developer",
    label: "Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineListPageDashboard,
    product: "Routine",
    layout: RoutineLayout,
    access: PrivateRoute,
  },

  {
    path: "/routine/tasklibrary",
    label: "Task Library",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineTaskLibrary,
    product: "Routine",
    layout: RoutineLayout,
    access: PrivateRoute,
  },




  //////Routine////
  {
    path: "/forms/create-group",
    label: "Create Group",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: NewGroup,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/forms/edit-group/:id",
    label: "Edit Group",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: EditGroup,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  // {
  //   path: "/forms/groups",
  //   label: "Groups",
  //   tooltip: "",
  //   image: sc_image_dashboard,
  //   active_image: sc_image_dashboard_active,
  //   component: Groups,
  //   product: "Forms",
  //   layout: OVFormsLayout,
  //   access: PrivateRoute
  // },
  {
    path: "/forms/routine-tasks",
    label: "Routine Tasks",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: ViewForms,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
  },
  // {
  //   path: "/forms/non-routine-tasks",
  //   label: "Non Routine Tasks",
  //   tooltip: "",
  //   image: sc_image_dashboard,
  //   active_image: sc_image_dashboard_active,
  //   component: NonRoutineTasks,
  //   product: "Forms",
  //   layout: OVFormsLayout,
  //   access: PrivateRoute
  // },
  {
    path: "/forms/questionnaire",
    label: "Form Builder",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: Questionnaire,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
  },
  {
    path: "/forms/questionnaire/:id",
    label: "Edit Form",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: EditQuestionnaire,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/forms/user/dashboard",
    label: "User Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineUserDashboard,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
  },
  {
    path: "/forms/user/routine-tasks",
    label: "My Routine Tasks",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: MyRoutineTasks,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
  },
  {
    path: "/forms/user/routine-task/:location/:frequency_id",
    label: "Routine Task",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: RoutineTaskDetails,
    product: "Forms",
    layout: OVFormsLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/dashboard",
    label: "Dashboard",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: SupportDashboard,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/tickets",
    label: "Tickets",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: Tickets,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/tickets/:id",
    label: "Tickets",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserTickets,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/calls/:id",
    label: "Calls",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserCalls,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/notes/:id",
    label: "Notes",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserNotes,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/tasks/:id",
    label: "Tasks",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserTasks,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/chat/:id",
    label: "Chat",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserChat,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/emails/:id",
    label: "Emails",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserEmails,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },
  {
    path: "/support/user/timeline/:id",
    label: "Timeline",
    tooltip: "",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
    component: UserTimeline,
    product: "Support",
    layout: SupportLayout,
    access: PrivateRoute,
    invisible: 1,
  },

  // {
  //   path: "/forms/user/non-routine-tasks",
  //   label: "My Non-routine Tasks",
  //   tooltip: "",
  //   image: sc_image_dashboard,
  //   active_image: sc_image_dashboard_active,
  //   component: MyNonRoutineTasks,
  //   product: "Forms",
  //   layout: OVFormsLayout,
  //   access: PrivateRoute
  // },
];

export default Routes;
