import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MyTextfield, MySelectField, MyMultiSelect } from '../../../components'
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
}
  from '@material-ui/core';
import { schedulerColor } from '../../../config'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  }
});


class AddUser extends Component {
  render() {
    const {
      classes,
      userItem,
      errors,
      errorsHelper,
      rolesData,
      usersLocations,
      selectedLocationIds
    } = this.props;

    return (
      <Grid
        className={classes.root}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <MyTextfield
            name="user_name"
            label="Name"
            onChange={this.props.handleChangeUserItem}
            onFocus={this.props.handleFocus}
            value={userItem.user_name}
            type="text"
            error={errors.user_name}
            helperText={errors.user_name ? errorsHelper.user_name : ""}
            placeholder="Enter user name"
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_email"
            label="Email"
            onChange={this.props.handleChangeUserItem}
            onFocus={this.props.handleFocus}
            value={userItem.user_email}
            type="text"
            error={errors.user_email}
            helperText={errors.user_email ? errorsHelper.user_email : ""}
            placeholder="Enter user email"
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_address"
            label="Address"
            onChange={this.props.handleChangeUserItem}
            onFocus={this.props.handleFocus}
            type="text"
            value={userItem.user_address}
            error={errors.user_address}
            helperText={errors.user_address ? errorsHelper.user_address : ""}
            placeholder="Enter user address"
          />
        </Grid>

        <Grid item xs={12}>
          <MyTextfield
            name="user_mobile_no"
            label="Mobile no"
            onChange={this.props.handleChangeUserItem}
            onFocus={this.props.handleFocus}
            value={userItem.user_mobile_no}
            type="text"
            error={errors.user_mobile_no}
            helperText={errors.user_mobile_no ? errorsHelper.user_mobile_no : ""}
            placeholder="Enter user mobile"
          />
        </Grid>

        <Grid item xs={12}>
          <MySelectField
            label=""
            name="user_role"
            onChange={this.props.handleChangeUserItem}
            onFocus={this.props.handleFocus}
            value={userItem.user_role}
            error={errors.user_role}
            helperText={errors.user_role ? errorsHelper.user_role : ""}
            placeholder="Please Select role"
          >
            <option value={0}>
              Select role
            </option>
            {rolesData.map((item) => {
              return (
                <option key={item.rid} value={item.rid}>
                  {item.role_name}
                </option>
              );
            })}
          </MySelectField>
        </Grid>

        <Grid item xs={12}>
          <MyMultiSelect
            options={usersLocations}
            mapId="lid"
            mapName="location_name"
            selectedIds={selectedLocationIds}
            handleChange={this.props.handleUsersLocation}
          />

          {errors.locations ?
            <Typography
              style={{ color: "red" }}
              variant="body2"
            >
              {errorsHelper.locations}
            </Typography>
            : ""}
        </Grid>

        <Grid item xs={12}  >
          <FormControlLabel
            label={`Show ${userItem.user_name} in location schedule`}
            control={
              <Checkbox
                style={{ color: schedulerColor.main }}
                name="show_location_schedule"
                value={userItem.show_location_schedule}
                checked={userItem.show_location_schedule ? true : false}
                size="medium"
                onClick={(e) => this.props.toggleCheckbox(e, "show_location_schedule")}
              />
            }
          />

          <FormControlLabel
            label={`Exclude ${userItem.user_name} from location alerts`}
            control={
              <Checkbox
                style={{ color: schedulerColor.main }}
                name="exclude_location_alert"
                value={userItem.exclude_location_alert}
                checked={userItem.exclude_location_alert ? true : false}
                size="medium"
                onClick={(e) => this.props.toggleCheckbox(e, "exclude_location_alert")}
              />
            }
          />

          <FormControlLabel
            label={`Exclude ${userItem.user_name}  from timeclock errors`}
            control={
              <Checkbox
                style={{ color: schedulerColor.main }}
                name="exclude_timeclock_errors"
                value={userItem.exclude_timeclock_errors}
                checked={userItem.exclude_timeclock_errors ? true : false}
                size="medium"
                onClick={(e) => this.props.toggleCheckbox(e, "exclude_timeclock_errors")}
              />
            }
          />


          <FormControlLabel
            label={`Eligible ${userItem.user_name} for open shifts`}
            control={
              <Checkbox
                style={{ color: schedulerColor.main }}
                name="eligible_open_shifts"
                value={userItem.eligible_open_shifts}
                checked={userItem.eligible_open_shifts ? true : false}
                size="medium"
                onClick={(e) => this.props.toggleCheckbox(e, "eligible_open_shifts")}
              />
            }
          />

        </Grid>

      </Grid>
    )
  }
}

export default withStyles(styles)(AddUser);