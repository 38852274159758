import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  Grid
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export class SearchContainer extends Component {
  render() {
    return (
      <div>
        <PlacesAutocomplete
          value={this.props.address}
          onChange={this.props.handleChangePlaceAddress}
          onSelect={this.props.selectLatLong}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                style={{
                  border: "1px solid #d4d5d9",
                  height: 30,
                  borderRadius: "4px",
                  width: "98.5%",
                  padding: 8
                }}
                {...getInputProps({
                  placeholder: 'Search for area,street,name ...',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion,index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div 
                    key={index}
                    style={{
                      display: "flex",
                      border: "1px solid #d4d5d9"
                    }} alignItems="center">
                      <Grid item
                        style={{
                          padding: "10px"
                        }}
                      >
                        <LocationOnIcon />
                      </Grid>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        style={{
                          padding: "15px 0"
                        }}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>

    )
  }
}

export default SearchContainer;