//DeleteEvent
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Avatar,
  TextField
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/RateReview';

const styles = (theme) => ({
  avatar: {
    margin: "0px auto",
    height: "75px",
    width: "75px",
    backgroundColor: theme.palette.primary.main
  },
  dialogConfirm: {
    backgroundColor: "#c3cbbf87"
  },
  dialogContentConfirm: {
    "&:last-child": {
      paddingTop: '3rem'
    },
    backgroundColor: "#fcfcfc",
    padding: "3rem 2rem"
  },
});


class DeleteEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
    }
  }

  
  handleSubmit = () => {
    this.props.approveAvailability()
  }


  render() {
    const { classes } = this.props;
    return (
      <Dialog
        className={classes.dialogConfirm}
        maxWidth="xs"
        fullWidth
        onClose={this.props.notifyModalClose}
        open={this.props.notifyModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.dialogContentConfirm} dividers>

          <Grid container spacing={2}>

            <Grid
              item
              md={12}
              xs={12}
            >
              <Avatar className={classes.avatar} color="primary">
                <CheckIcon style={{ color: "white", height: "40px", width: "40px" }} />
              </Avatar>
            </Grid>


            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography
                style={{ textAlign: "center" }}
                color="textSecondary"
                variant="h4" >
                Add A Note
              </Typography>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                size='small'
                id="outlined-multiline-static"
                inputlabelprops={{ classes: { root: classes.labelColor } }}
                multiline
                fullWidth
                rows={3}
                placeholder="Anything you want to add?"
                defaultValue={this.state.manager_notes}
                variant="outlined"
                onChange={this.props.handleManagerNotes}
              />
            </Grid>

          </Grid>

          <DialogActions style={{ marginTop: "1rem", "justifyContent": "center" }}>
            <Button
              onClick={this.handleSubmit}
              variant="outlined"
              color="primary" autoFocus>
              send to manager
            </Button>
          </DialogActions>

        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DeleteEvent);
