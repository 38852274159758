import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config'
import {
  Grid,
  Typography,
  Card,
  CardContent,
}
  from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  title: {
    color: schedulerColor.main
  },
  CardContent: {
    padding: '1rem 1rem !important',
    "&:last-child": {
      padding: '0'
    },
  },
  card: {
    cursor: "pointer",
    borderRadius: '5px',
    border: '1px solid rgb(229, 230, 231)',
    boxShadow: "0px 2px 5px rgba(83, 97, 255, 0.0534986)",
    '&:hover': {
      boxShadow: "4px 8px 20px 0 rgba(0, 0, 0, 0.5), 4px 8px 20px 0 rgba(0, 0, 0, 0.5)"
    }
  },
});


class AddEditShifts extends Component {
  render() {
    const { classes, printList } = this.props;
    return (
      <Grid
        container
        spacing={3}
        className={classes.root}
      >
        <Grid
          item
          sm={12}
          xs={12}
        >
          <Typography
            className={classes.title}
            variant="h5"
            gutterBottom
          >
            How would you like to print your schedule?
          </Typography>
        </Grid>

        {printList.map((item) => {
          return (
            <Grid
              item
              sm={12}
              xs={12}
            >
              <Card
                onClick={() => { this.props.printData(item.type) }}
                className={classes.card}
              >
                <CardContent
                  className={classes.CardContent}
                >
                  <Typography
                    className={classes.title}
                    variant="h5"
                    gutterBottom
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    variant="body1"
                  >
                    {item.sub_title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        })
        }
      </Grid >
    )
  }
}

export default withStyles(styles)(AddEditShifts);