import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { schedulerColor } from '../../../config';
import { LoadingData } from '../../../components';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialog-paper': {
    minWidth: 800,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0 }} {...other} display="flex">
      {children}
      {onClose ? (
        <IconButton style={{ marginLeft: 'auto', padding: 0 }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  headCell: {
    padding: "0.5rem 0rem",
    color: "white",
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: "18px",
  },
});

export default function WarningIconModal(props) {
  const { warningModalClose, is_warning_modal, correctionWarningData, isLoading } = props;
  const classes = useStyles();
  const [headCells, setHeadCells] = React.useState([
    { id: "id", label: "ID", numeric: false },
    { id: "request", label: "Request Date", numeric: false },
    { id: "correction", label: "Correction Type", numeric: false },
    { id: "status", label: "Status", numeric: false },
    { id: "date", label: "Occurance Date", numeric: false },
  ]);

  return (
    <div>
      <BootstrapDialog
        onClose={warningModalClose}
        aria-labelledby="customized-dialog-title"
        open={is_warning_modal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={warningModalClose} display="flex">
          Correction Request
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Table className={classes.table} size="medium">
            <TableHead style={{ backgroundColor: schedulerColor.main }}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} >
                    <TableSortLabel className={classes.headCell}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}

              </TableRow>
            </TableHead>
            {/* {isLoading === true ? <LoadingData /> : */}
            <TableBody>
              {correctionWarningData && correctionWarningData.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell className={classes.TableCell}>
                      {row.id}
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {moment(row.createdAt).locale('en-US').format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      <Typography className={classes.TableCell}>
                        {row?.connection_type[0].isshift_start ? "Start Shift, " : ""}<br />
                        {row?.connection_type[0].isshift_end ? "End Shift, " : ""}
                        {row?.connection_type[0].isno_break ? "No Break, " : ""}
                        {row?.connection_type[0].is_break ? "Break" : ""}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {row.status === 0 ?
                        <div style={{ color: "#8E0C0C" }}>
                          Level 1 Pending
                        </div>
                        : row.status === 1 ?
                          <div style={{ color: "#8E0C0C" }}>
                            Level 2 Pending
                          </div>
                          : row.status === 2 ?
                            <div style={{ color: "#8E0C0C" }}>
                              Level 3 Pending
                            </div> :
                            <div style={{ color: "#0F640D" }}>
                              Approved
                            </div>
                      }
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {moment(row.occurance_date).locale('en-US').format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            {/* } */}
          </Table>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}