//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  DialogTitle: {
    padding: "0px 24px",
    backgroundImage: "linear-gradient(0deg, #AA076B, #9F0D9C)",
  },
  myTitle: {
    padding: "5px",
    lineHeight: "2.5rem",
    float: "left",
    color: "#fff",
    fontWeight: 600,
  },
  closeIcon: {
    padding: "10px 10px",
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
    borderRadius: '50%'
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class MyPlainFullModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            backgroundColor: this.props.bgColor ? this.props.bgColor : "gray",
          }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4">
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              style={{ color: "white" }}
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MyPlainFullModal);
