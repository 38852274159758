import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { LoadingData, NoPermission } from '../../../components'
// import BarDemo from '../Dashboard/BarDemo';
import { schedulerColor } from "../../../config";
import { connect } from "react-redux";
// import filterArray from '../../../utils/feedback_filter_data';
import {
    Grid, Button, Typography
}
    from '@material-ui/core';
import moment from "moment";
import SwitchSelector from "react-switch-selector";
import MyAvailability from "./MyAvailability";
import TeamAvailability from "./TeamAvailability";


const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    tabButton: {
        border: `1px solid ${schedulerColor.main}`,
        textTransform: "capitalize",
        padding: "10px",
        fontWeight: 500,
        fontSize: "14px",
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#fcfcfc'
        }
    },
    avatar: {
        height: "30px",
        width: "30px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: schedulerColor.main,
        color: "white",
    },
    schtabletrhead:{
        backgroundImage: "linear-gradient(0deg, #AA076B, #9F0D9C)",
    
      },
});

export function fetchAvailabilityData() {
    window.AvailabilityMain();
}

class AvailabilityMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addEditModal: false,
            report_name: "",
            errors: {},
            errorsHelper: {},
            selectedCategoryIds: [],
            selectedLocationIds: [],
            campaignLocations: [],
            campaignCategory: [],
            myItem: {},
            dataPass: 0,
            dataPass1: 0,
            buttonsList: [
                {
                    status: 0,
                    title: "My"
                },
                {
                    status: 1,
                    title: "Team"
                },
            ],
            option: [
                {
                  label: "My",
                  value: "my",
                  selectedBackgroundColor: schedulerColor.main,
                  innerHeight: 180
                },
                {
                  label: "Team",
                  value: "team",
                  selectedBackgroundColor: schedulerColor.main,
                  innerHeight: 180
                }
              ],
            activeIndex: "my",
            order: 'asc',
            orderBy: "_id"
        }
        document.title = "Dashboard";
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };


    setActiveIndex = (activeIndex) => {
        var data = {
            pageNumber: 1,
            nPerPage: 10,
            isrefresh: 0
        }
        this.setState({ activeIndex })
    }


    render() {
        const {
            classes,
            rowsPerPage,
            pageNo,
            timezone,
            permissionsData,
        } = this.props;


        const { buttonsList, activeIndex } = this.state;
        const initialSelectedIndex = this.state.option.findIndex(({ value }) => value === "my");

        return (
            permissionsData.elearning_dashboard ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid
                        style={{ margin: "auto", }}
                        item
                        xl={6}
                        md={6}
                        lg={8}
                        sm={8}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={0}
                        >
                            {/* {buttonsList.map((label, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xl={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                    >
                                        <Button
                                            style={{
                                                borderRadius: 28,
                                                color: activeIndex === label.status ? "#ffffff" : schedulerColor.main,
                                                backgroundColor: activeIndex === label.status ? schedulerColor.main : "#ffffff",
                                            }}
                                            className={classes.tabButton}
                                            fullWidth
                                            onClick={() => { this.setActiveIndex(label.status) }}
                                        >
                                            {label.title}
                                        </Button>
                                    </Grid>
                                )
                            })} */}
                            <p style={{ height: "40px", width: "220px", margin: "auto" }}>
                                <SwitchSelector
                                onChange={this.setActiveIndex}
                                options={this.state.option}
                                initialSelectedIndex={initialSelectedIndex}
                                backgroundColor={"white"}
                                fontColor={"gray"}
                                selectedBackgroundColor="linear-gradient(0deg, #AA076B, #9F0D9C)"
                                fontSize={16}
                                wrapperBorderRadius={0}
                                optionBorderRadius={0}
                                />
                            </p> 
                        </Grid>
                    </Grid>
                    {activeIndex === "my" ?
                    <MyAvailability
                    scheduler_lid={this.props.scheduler_lid} 
                    permissionsData={this.props.permissionsData}
                    rowsPerPage={this.props.rowsPerPage}
                    pageNo={this.props.pageNo}
                    timezone={timezone}
                    />
                        : activeIndex === "team" ? 
                            <TeamAvailability   
                            scheduler_lid={this.props.scheduler_lid} 
                            permissionsData={this.props.permissionsData}  
                            rowsPerPage={this.props.rowsPerPage}
                            pageNo={this.props.pageNo}  
                            timezone={timezone}                   
                            /> : ""
                        
                    }


                </Grid>
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoadingDashboard: state.eLearningDashboard.isLoadingDashboard,
});

const ConnectWith = connect(mapStateToProps, null)(AvailabilityMain);
export default withStyles(styles)(ConnectWith);