//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Avatar } from "@material-ui/core";

const styles = (theme) => ({
  label: {
    fontSize: "12px",
    fontWeight: 500,
  },
  input: {
    // padding: '0px 0px 0px 0px !important',
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "#f4f2f26b !important",
    borderRadius: 5,
    marginBottom: 0,
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
});

class MyImageUpload extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TextField
          size="small"
          placeholder={this.props.placeholder}
          variant="outlined"
          name={this.props.name}
          onChange={this.props.onChange}
          onClick={this.props.onFocus}
          fullWidth
          type="file"
          error={this.props.error}
          helperText={this.props.helperText}
          inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            // disableUnderline: true,
            className: classes.input,
          }}
          accept="image/*"
        />
        {this.props.src ? (
          <Avatar
            style={{ width: "100%", height: "100%" }}
            variant="square"
            src={this.props.src}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MyImageUpload);
