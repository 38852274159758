import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { notfound } from '../icons'
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: "#ababab",
    overflowX: 'hidden',
    padding: '0 2rem'
  },
  content: {
    paddingTop: 0,
    textAlign: 'center'
  },
  image: {
    marginBottom: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: '100%'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          sm={6}
          xs={12}
        >
          <div className={classes.content}>
            <img
              alt=""
              className={classes.image}
              src={notfound}
            />
            <Typography
              variant="h1"
              style={{ marginBottom: '2rem' }}
            >
              404: The page you are looking for isn’t here
            </Typography>
            <Link to="/">
              <Button
                variant="outlined"
              >
                go to dashboard
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
