import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config'
import { MyTextArea, MyTextfield } from '../../../components'
import {
  Grid
}
  from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  avatarDay: {
    padding: "2px",
    cursor: "pointer",
    float: 'left',
    textTransform: 'uppercase',
    height: '30px',
    width: '30px',
    fontWeight: 800,
    fontSize: '10px',
    color: '#ffffff'
  },
  avatarText: {
    float: 'left',
    textTransform: 'uppercase',
    height: '35px',
    width: '35px',
    fontWeight: 800,
    fontSize: '15px',
    backgroundColor: '#DFF2F7',
    color: '#8A5EA6'
  },
  userName: {
    color: schedulerColor.main,
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '3px',
    lineHeight: '2.1rem',
    display: "inline",
    float: 'left',
    cursor: 'move'
  },
});


class AddEditShifts extends Component {
  render() {
    const { classes,
      myItem,
      errors,
      errorsHelper
    } = this.props;
    return (
      <Grid
        container
        spacing={2}
        className={classes.root}
      >
        <Grid item sm={12} xs={12}>
          <MyTextfield
            name="title"
            label="Title"
            onChange={this.props.handleChangeItem}
            onFocus={this.props.handleFocus}
            value={myItem.title}
            type="text"
            error={errors.title}
            helperText={errors.title ? errorsHelper.title : ""}
            placeholder="Please enter title"
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <MyTextArea
            name="description"
            label="Description"
            onChange={this.props.handleChangeItem}
            onFocus={this.props.handleFocus}
            value={myItem.description}
            type="text"
            error={errors.description}
            helperText={errors.description ? errorsHelper.description : ""}
            placeholder="Leave a note for your employee, lie the address of a job site , and they'll see it when they clock in."
            rows={5}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AddEditShifts);