import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from 'axios';

export const payrollServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    fetchPayrollHistoryData,
    fetchPayrollAssignUsers,
    fetchNoPayrollUsers,
    togglePayrollAssignUsers
}

function fetchData(lid) {
    var data = {
        lid: lid
    }
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_template/list`, data , HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_template`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data, id) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/payroll_template/${id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(_id) {
    return axios.delete(`${MoNGO_DB_API_URL}/scheduler/payroll_template/${_id}`, HEADER_TOKEN).then(response => response)
};

function fetchPayrollHistoryData(id) {
return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_template/history/${id}`, HEADER_TOKEN).then(response => response)
};

function fetchPayrollAssignUsers(id) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_template/users/${id}`, HEADER_TOKEN).then(response => response)
};

function fetchNoPayrollUsers(lid) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_template/no_payroll_assign/${lid}`, HEADER_TOKEN).then(response => response)
};

function togglePayrollAssignUsers(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_template/users`, { data }, HEADER_TOKEN).then(response => response)
};