import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config'
import { alert } from "../../../utilities";
import moment from "moment";
import {
  Grid,
  Button,
  ButtonGroup,
  Typography,
  IconButton
}
  from '@material-ui/core';

import {
  NavigateBefore as NextIcon,
  NavigateNext as PrevIcon
} from '@material-ui/icons';
import WarningIcon from '@mui/icons-material/Warning';
import { locale } from "moment";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  btnChild: {
    fontWeight: 500,
    padding: '8px 25px',
    color: "rgb(71 85 105)",
    border: "1px solid #ccc!important",
    marginRight: "10px",
    borderRadius: "8px!important",
    boxShadow: "0 3px 20px #0000000b!important"

  }
});


class AddEditShifts extends Component {
  render() {
    const { classes, toolbarData, totalUnpublishedShifts,isConflicts } = this.props;
    return (
      <Grid
        container
        className={classes.root}
      >
        <Grid
          style={{ textAlign: "left", lineHeight: "2.5rem" }}
          item
          sm={4}
          xs={12}
        >
          <IconButton
            style={{ padding: "5px" }}
            onClick={this.props.prevClick}
          >
            <NextIcon />
          </IconButton>
          &nbsp;
          <Typography
            // onClick={this.props.todayClick}
            display="inline"
            variant="h6"
            style={{ cursor: "pointer", verticalAlign: "middle" }}
          >
            {this.props.columns.length > 0 ?
              moment(this.props.columns[0].date).locale('en-US').format('ddd DD MMM YYYY')
              : ""}
            &nbsp;-&nbsp;
            {this.props.columns.length > 0 ?
                moment(this.props.columns[6].date).locale('en-US').format('ddd DD MMM YYYY')
              : ""}
          </Typography>
          &nbsp;
          <IconButton
            style={{ padding: "5px" }}
            onClick={this.props.nextClick}
          >
            <PrevIcon />
          </IconButton>
        </Grid>

        <Grid
          item
          sm={8}
          xs={12}
        >
          <ButtonGroup
            size="medium"
            variant="outlined"
          >
            {toolbarData.map((item) => {
              return (
                <Button
                  disabled={
                    item.sortName === "publish" && totalUnpublishedShifts === 0 ? true :
                      item.permission ? false : true
                  }
                  onClick={isConflicts === true && item.sortName === "publish" ? item.conflictClick : item.onClick}
                  startIcon={isConflicts === true && item.sortName === "publish" ? <WarningIcon style={{color:"red"}}/> : <item.icon />}
                  variant="contained"
                  className={classes.btnChild}
                >
                  {item.title}&nbsp;
                  {item.sortName === "publish" ? `( ` + totalUnpublishedShifts + ` )` : ""}
                </Button>
              )
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AddEditShifts);