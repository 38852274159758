import React, { Component, lazy } from "react";
import SidebarCategoryLink from "./SidebarCategoryLink";
import {
    SupervisorAccountOutlined as ListIcon,
}
    from "@material-ui/icons";
import MainLayout from "../MainLayout";
import { PublicRoute } from "../../accesslevel";
import { FILE_URL } from "../../config";
const Lessons = lazy(() => import("../../pages/eLearning/Lessons/Lessons"));

class TodoList extends Component {
    render() {
        const { isSidebarOpen, location } = this.props;
        var categoryRoutes = []
        this.props.categoryData.map((page, index) => {
            var routeObject = {
                categoryId: page._id,
                path: `/elearning/category?_id=${page._id}`,
                label: page.category_name,
                icon: <ListIcon />,
                image: page.image,
                active_image: page.image,
                component: Lessons,
                product: "eLearning",
                layout: MainLayout,
                access: PublicRoute
            }
            categoryRoutes.push(routeObject)
        })

        return (
            categoryRoutes.map((page, key) => {
                return (
                    <SidebarCategoryLink
                        categoryId={page.categoryId}
                        key={key}
                        location={location}
                        isSidebarOpen={isSidebarOpen}
                        {...page}
                    />
                )
            })
        )
    }
}
export default TodoList;