import {
  SET_COVER_LOADING,
  SET_COVER,
  ADD_COVER,
  EDIT_COVER,
  DELETE_COVER
} from "./constants";

const initialState = {
  isLoading: true,
  mycoverData: [],
  teamCoverData:[],
  myTest : [{
    "id" : "test"
  }]
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COVER_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_COVER:
      return {
        ...state,
        isLoading: false,
        mycoverData: payload.myCover,
        teamCoverData:payload.teamCover
      };
    case ADD_COVER:
      return {
        ...state,
        isLoading: false,
        coverData: [...state.coverData, payload]
      }
    case EDIT_COVER:
      return {
        ...state,
        isLoading: false,
        teamCoverData: state.teamCoverData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    case DELETE_COVER:
      return {
        ...state,
        isLoading: false,
        teamCoverData: state.teamCoverData.filter((item) => item.id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;