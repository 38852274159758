import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import UserIcon from "@material-ui/icons/Person";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const styles = (theme) => ({
  menu: {
    minWidth: "200px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
  },
  MenuItem: {
    border: "1px solid #f2f2f2",
    "&:hover": {
      boxShadow:
        "4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  Typography: {
    width: "100%",
    fontSize: "16px"
  },
  ListItemIcon: {
    minWidth: "45px",
  },
  mYicon: {
    height: 25,
    width: 25,
  },
});

class LogOutMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutData: [
        {
          id: "profile",
          name: "Profile",
          icon: UserIcon,
        },
        {
          id: "logout",
          name: "Logout",
          icon: LogoutIcon,
        },
      ]
    };
  }

  render() {
    const { classes, logoutAnchor } = this.props;
    const { logoutData } = this.state;
    const temp_ovToken = cookies.get('temp_ovToken')
    const temp_ovUid = cookies.get('temp_ovUid')
    const temp_ovCid = cookies.get('temp_ovCid')

    return (
      <>
        <Menu
          MenuListProps={{ disablePadding: true }}
          anchorEl={logoutAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={Boolean(logoutAnchor)}
          onClose={(e) => {
            this.props.handleLogoutClose("");
          }}
          PopoverClasses={{
            paper: classes.menu,
          }}
        >
          {logoutData.map((value, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  this.props.handleLogoutClose(value.id);
                }}
                className={classes.MenuItem}
              >
                <ListItemIcon
                  className={classes.ListItemIcon}
                >
                  <IconButton
                    style={{ padding: "8px" }}
                  >
                    <value.icon
                      className={classes.mYicon}
                    />
                  </IconButton>
                </ListItemIcon>
                <Typography
                  className={classes.Typography}
                  color="textPrimary"
                  variant="inherit"
                >
                  {value.name}
                </Typography>
              </MenuItem>
            );
          })}

          {temp_ovToken && temp_ovUid && temp_ovCid ?
            <MenuItem
              onClick={() => { this.props.handleLogoutClose("exit_access"); }}
              className={classes.MenuItem}
            >
              <ListItemIcon
                className={classes.ListItemIcon}
              >
                <IconButton
                  style={{ padding: "8px" }}
                >
                  <LogoutIcon
                    className={classes.mYicon}
                  />
                </IconButton>
              </ListItemIcon>
              <Typography
                className={classes.Typography}
                color="textPrimary"
                variant="inherit"
              >
                Exit Access
              </Typography>
            </MenuItem> : ""}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(LogOutMenus);
