import { HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const todoServices = {
    fetchData,
    fetchDataById,
    fetchTodoList,
    editList,
    addData,
    addList,
    editData,
    deleteData,
    addSubtaks,
    addComment,
    deleteSubtaks,
    deleteComment,
    deleteList,
    toggleSubtaks,
    toggleTodo
}

function fetchTodoList() {
    return axios.get(`${MoNGO_DB_API_URL}/todo/list`, HEADER_TOKEN).then(response => response)
};

function fetchData(id) {
    return axios.get(`${MoNGO_DB_API_URL}/todo/dashboard/${id}`, HEADER_TOKEN).then(response => response)
};

function fetchDataById(id) {
    return axios.get(`${MoNGO_DB_API_URL}/todo/dashboard/detail/${id}`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/todo/dashboard`,  data , HEADER_TOKEN).then(response => response)
};

function addList(data) {
    return axios.post(`${MoNGO_DB_API_URL}/todo/list`,  data , HEADER_TOKEN).then(response => response)
};

function editList(data) {
    return axios.put(`${MoNGO_DB_API_URL}/todo/list/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function addSubtaks(data) {
    return axios.post(`${MoNGO_DB_API_URL}/todo/subtasks`,  data , HEADER_TOKEN).then(response => response)
};

function addComment(data) {
    return axios.post(`${MoNGO_DB_API_URL}/todo/comments`, data , HEADER_TOKEN).then(response => response)
};

function editData(myData) {
    const { created_at, totalSubtasks, compltedSubtasks, ...data } = myData;
    return axios.put(`${MoNGO_DB_API_URL}/todo/dashboard/${data._id}`, data , HEADER_TOKEN).then(response => response)
};

function toggleTodo(data) {
    return axios.put(`${MoNGO_DB_API_URL}/todo/dashboard/toggle/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function toggleSubtaks(data) {
    return axios.put(`${MoNGO_DB_API_URL}/todo/subtasks/toggle/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteList(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/todo/list/${id}`, HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/todo/dashboard/${id}`, HEADER_TOKEN).then(response => response)
};

function deleteSubtaks(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/todo/subtasks/${id}`, HEADER_TOKEN).then(response => response)
};

function deleteComment(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/todo/comments/${id}`, HEADER_TOKEN).then(response => response)
};