import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper,Button } from "@material-ui/core";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { connect } from "react-redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import  "./slickSlider.css";
const styles = (theme) => ({
    mainRoot: {
        margin:'1rem'
    },
    // paper: {
    //     boxShadow: 'none',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     overflow: "auto",
    //     width: "89%",
    // },
});

class SlickSilder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentCard: [],
            activeSlide: 0,
            speed: 300,
            SlideIndex: 0,
            active_question_index: 0,
        }
    };

    setActiveSlide = (val) => {
        this.setState({
            activeSlide: val
        })
    };
forward = ()=>{
    this.props.showNewCard("Next")
}
backward = ()=>{ 
    this.props.showNewCard("Prev")
}
    render() {
        const { classes, MonthData, getData, is_select_card } = this.props;

        return (
            <div className={classes.mainRoot}>
                <Grid container style={{marginLeft:'auto',marginRight:'auto',marginBottom:'1rem'}} spacing={4} className={classes.root}>
                    <Button style={{padding:'0',minWidth:'50px'}}
                    onClick={()=> this.backward()}
                    >

                        <ArrowLeftIcon style={{ fontSize: "50px", cursor: 'pointer' }}  />
                    </Button>
                    <Paper  className= 'paper' >
                        {MonthData.map((row) => {
                            return (
                                <Grid item>
                                <div style={{ padding: "15px", backgroundColor: "white", paddingTop: "2.3%" }}
                                    onClick={() => this.props.clickCard(row,this.props.payload_ids)}>
                                    <div
                                        style={{
                                            width: 169,
                                            height: 86,
                                            cursor: "pointer",
                                            border: "1px solid black",
                                            textAlign: "center",
                                            lineHeight: "28px",
                                            boxSizing: "border-box",
                                            visibility: "block",
                                            background: is_select_card === row.Date ? 'lightgray' : "white"
                                        }}
                                    >
                                        <div onClick={() => getData(row)}>
                                            <div style={{ fontWeight: 700 }} onClick={() => getData(row)}>{row.Month}</div>
                                            <div >{row.Date}</div>
                                            {row.Status === 0 ?
                                                <div style={{ color: 'white', background: '#b71c1c' }}>
                                                    Pending</div>
                                                :
                                                <div style={{ color: 'green', background: '#E3E0E0' }}><CheckCircleIcon
                                                    style={{ marginRight: 8 }}
                                                />Completed</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                </Grid>
                            )
                        })}
                                            </Paper>
                        <Button
                        
                       style={{padding:'0',minWidth:'50px'}}
                        onClick={() => this.forward()}
                        >

                        <ArrowRightIcon style={{ fontSize: "50px", cursor: 'pointer' }} />
                        </Button>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.eLearningMyCourses.isLoading,
});

const ConnectWith = connect(mapStateToProps, null)(SlickSilder);
export default withStyles(styles)(ConnectWith);
