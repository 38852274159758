import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
})

class MyTags extends React.Component {
        render() {
                return (
                        <>
                                <TagsInput
                                        name={this.props.name}
                                        value={this.props.tags}
                                        onChange={this.props.onChange}
                                        onFocus={this.props.onFocus}
                                        className="react-tagsinput py-0"
                                />
                                {this.props.error ?
                                        <FormHelperText
                                                style={{ color: "red" }}
                                        >
                                                {this.props.helperText}
                                        </FormHelperText> : ""}
                        </>
                );
        }
}

export default withStyles(styles)(MyTags);