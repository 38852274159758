import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import SidebarLessonLink from "./SidebarLessonLink";

class TodoList extends Component {
    render() {
        const { isSidebarOpen, location, active_lesson_details, active_course_details } = this.props;
        return (
            <>
                <Typography
                    align="center"
                    variant="body1"
                    gutterBottom
                >
                    {isSidebarOpen ? active_course_details?.course_id ? active_course_details.course_id.course_name : "" : ""}
                </Typography>

                {this.props.myLessons && this.props.myLessons?.map((page, key) => {
                    return (
                        <SidebarLessonLink
                            selectMyCourseLesson={this.props.selectMyCourseLesson}
                            active_lesson_details={active_lesson_details}
                            lesson_id={page._id}
                            lessonDetails={page}
                            key={key}
                            location={location}
                            isSidebarOpen={isSidebarOpen}
                            {...page}
                        />
                    )
                })}
            </>
        )
    }
}
export default TodoList;