import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MyDatePicker, MyLeftModal, MyMultiSelect, MySearchBar, MySelectField, MyTextfield } from "../../../components";
import { ovHrColor } from "../../../config";
import { fetchData } from '../../common/Roles/modules/actions';
import { fetchLocationData } from "./modules/action";
import { connect } from "react-redux";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SchedulerModal from './SchedulerModal';

const useStyles = makeStyles((theme) => ({
    addRoleDiv: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: '-35px'
    },
    addRoleButton: {
        backgroundColor: '#977c4f!important',
        color: 'white!important',
    },
    permissionDiv: {
        display: 'flex'
    },
    lineSection: {
        width: '100%',
        height: 20,
        borderBottom: '1px solid black',
        textAlign: 'center',
        marginBottom: 30,
    },
    titleSection: {
        fontSize: 20,
        backgroundColor: 'white',
        padding: '0px 10px',
    }
}));

const AddRolesHierarachy = (props) => {
    const classes = useStyles();
    const [openSchedulerModal, setOpenSchedulerModal] = React.useState({
        isModalOpen: false,
        isType: ""
    });
    const [addRolesModal, setAddRolesModal] = useState(false);
    const [searchLevel, setSearchLevel] = useState();
    const [searchRole, setSearchRole] = useState();
    const [inputsValue, setInputsValue] = useState({
        role_name: '',
        description: '',
        report_to: '',
        show_checkbox: 1
    });

    const [schedulerPermission, setSchedulerPermission] = useState([
        {
            title: "Scheduler Dashboard",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Scheduler",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "My Availability",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Team Availability",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Time-Off Requests",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Trade Shifts",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Cover Shifts",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Timesheets",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Payroll Template",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Payroll Summary",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Scheduler Roles",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Scheduler Settings",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Receive Manager Alerts",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
    ]);

    const [eLearningPermission, setElearningPermission] = useState([
        {
            title: "Dashboard",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "My Course",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Library",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "My Helpdesk",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Helpdesk Builder",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Assign Helpdesk",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
    ]);

    const [feedbackPermission, setFeedbackPermission] = useState([
        {
            title: "Dashboard",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Followsup",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Campaign",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Locations",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Reports",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Settings",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Team",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
    ]);

    const [commonPermission, setCommonPermission] = useState([
        {
            title: "Dashboard",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Locations",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Users",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Roles",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Payroll Management",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Ticket",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Ideas",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Billings",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Coupons",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
        {
            title: "Products",
            IsFullAccess: false,
            IsEdit: false,
            IsDelete: false,
            IsCreate: false,
            IsAssign: false
        },
    ]);

    useEffect(() => {
    }, [schedulerPermission, eLearningPermission, feedbackPermission, commonPermission]);

    const toggleCheckbox = (e, name) => {
        let checked = e.target.checked;
        setInputsValue(inputs => ({ ...inputs, [name]: checked ? 1 : 0 }))
    };

    //permission Onchange
    const onChangeFullAcces = (name, { IsFullAccess }) => {
        setSchedulerPermission((schedulerPermission) => {
            return schedulerPermission.map(item => {
                if (item.title === name) {
                    item.IsFullAccess = IsFullAccess
                    item.IsAssign = IsFullAccess
                    item.IsCreate = IsFullAccess
                    item.IsEdit = IsFullAccess
                    item.IsDelete = IsFullAccess
                }
                return item
            })
        })
        setElearningPermission((eLearningPermission) => {
            return eLearningPermission.map(item => {
                if (item.title === name) {
                    item.IsFullAccess = IsFullAccess
                    item.IsAssign = IsFullAccess
                    item.IsCreate = IsFullAccess
                    item.IsEdit = IsFullAccess
                    item.IsDelete = IsFullAccess
                }
                return item
            })
        })
        setFeedbackPermission((feedbackPermission) => {
            return feedbackPermission.map(item => {
                if (item.title === name) {
                    item.IsFullAccess = IsFullAccess
                    item.IsAssign = IsFullAccess
                    item.IsCreate = IsFullAccess
                    item.IsEdit = IsFullAccess
                    item.IsDelete = IsFullAccess
                }
                return item
            })
        })
        setCommonPermission((commonPermission) => {
            return commonPermission.map(item => {
                if (item.title === name) {
                    item.IsFullAccess = IsFullAccess
                    item.IsAssign = IsFullAccess
                    item.IsCreate = IsFullAccess
                    item.IsEdit = IsFullAccess
                    item.IsDelete = IsFullAccess
                }
                return item
            })
        })
    };

    const onChangeSchedulerIsCreate = (name, { IsCreate }) => {
        setSchedulerPermission((schedulerPermission) => {
            return schedulerPermission.map(item => {
                if (item.title === name) {
                    item.IsCreate = IsCreate
                }
                return item
            })
        })
        setElearningPermission((eLearningPermission) => {
            return eLearningPermission.map(item => {
                if (item.title === name) {
                    item.IsCreate = IsCreate
                }
                return item
            })
        })
        setFeedbackPermission((feedbackPermission) => {
            return feedbackPermission.map(item => {
                if (item.title === name) {
                    item.IsCreate = IsCreate
                }
                return item
            })
        })
        setCommonPermission((commonPermission) => {
            return commonPermission.map(item => {
                if (item.title === name) {
                    item.IsCreate = IsCreate
                }
                return item
            })
        })
    };

    const onChangeSchedulerIsEdit = (name, { IsEdit }) => {
        setSchedulerPermission((schedulerPermission) => {
            return schedulerPermission.map(item => {
                if (item.title === name) {
                    item.IsEdit = IsEdit
                }
                return item
            })
        })
        setElearningPermission((eLearningPermission) => {
            return eLearningPermission.map(item => {
                if (item.title === name) {
                    item.IsEdit = IsEdit
                }
                return item
            })
        })
        setFeedbackPermission((feedbackPermission) => {
            return feedbackPermission.map(item => {
                if (item.title === name) {
                    item.IsEdit = IsEdit
                }
                return item
            })
        })
        setCommonPermission((commonPermission) => {
            return commonPermission.map(item => {
                if (item.title === name) {
                    item.IsEdit = IsEdit
                }
                return item
            })
        })
    };

    const onChangeSchedulerIsDelete = (name, { IsDelete }) => {
        setSchedulerPermission((schedulerPermission) => {
            return schedulerPermission.map(item => {
                if (item.title === name) {
                    item.IsDelete = IsDelete
                }
                return item
            })
        })
        setElearningPermission((eLearningPermission) => {
            return eLearningPermission.map(item => {
                if (item.title === name) {
                    item.IsDelete = IsDelete
                }
                return item
            })
        })
        setFeedbackPermission((feedbackPermission) => {
            return feedbackPermission.map(item => {
                if (item.title === name) {
                    item.IsDelete = IsDelete
                }
                return item
            })
        })
        setCommonPermission((commonPermission) => {
            return commonPermission.map(item => {
                if (item.title === name) {
                    item.IsDelete = IsDelete
                }
                return item
            })
        })
    };

    const onChangeSchedulerIsAssign = (name, { IsAssign }) => {
        setSchedulerPermission((schedulerPermission) => {
            return schedulerPermission.map(item => {
                if (item.title === name) {
                    item.IsAssign = IsAssign
                }
                return item
            })
        })
        setElearningPermission((eLearningPermission) => {
            return eLearningPermission.map(item => {
                if (item.title === name) {
                    item.IsAssign = IsAssign
                }
                return item
            })
        })
        setFeedbackPermission((feedbackPermission) => {
            return feedbackPermission.map(item => {
                if (item.title === name) {
                    item.IsAssign = IsAssign
                }
                return item
            })
        })
        setCommonPermission((commonPermission) => {
            return commonPermission.map(item => {
                if (item.title === name) {
                    item.IsAssign = IsAssign
                }
                return item
            })
        })
    };

    //Scheduler Modal onclick
    const handleOpenSchedulerModal = (isType) => {
        setOpenSchedulerModal({ isModalOpen: true, isType });
    };

    const handleCloseSchedulerModal = () => {
        setOpenSchedulerModal({ isModalOpen: false, isType: "" });
    };

    //onChange Input Click
    const onChangeInput = (e) => {
        e.persist();
        setInputsValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    };

    //add roles modal handle click
    const onHandleOpenRolesModal = () => {
        setAddRolesModal(true);
    };

    const onHandleCloseRolesModal = () => {
        setAddRolesModal(false);
    };

    const clearSearch = () => {
        setSearchLevel("");
        setSearchRole("");
    };

    const handleSearchLevel = (target) => {
        setSearchLevel(target);
    };

    const handleSearchRole = (target) => {
        setSearchRole(target);
    };

    return (
        <div>
            <div style={{ marginBottom: 30 }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 30 }}>
                        <MySearchBar
                            clearSearch={clearSearch}
                            handleSearch={handleSearchLevel}
                            value={searchLevel}
                            placeholder="Search Location"
                        />
                    </div>
                    <div style={{ marginRight: 30 }}>
                        <MySearchBar
                            clearSearch={clearSearch}
                            handleSearch={handleSearchRole}
                            value={searchRole}
                            placeholder="Search Role"
                        />
                    </div>
                </div>
                <div className={classes.addRoleDiv}>
                    <Button className={classes.addRoleButton} onClick={onHandleOpenRolesModal}>
                        Add Role
                    </Button>
                </div>
            </div>

            <MyLeftModal
                bgColor={ovHrColor.main}
                title="Add Roles"
                open={addRolesModal}
                onClose={onHandleCloseRolesModal}
                handleSubmit={() => onHandleCloseRolesModal()}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MyTextfield
                            name="role_name"
                            label="Role Name"
                            onChange={onChangeInput}
                            value={inputsValue.role_name}
                            type="text"
                            placeholder="Enter role name"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MyTextfield
                            name="description"
                            label="Description"
                            onChange={onChangeInput}
                            value={inputsValue.description}
                            type="text"
                            placeholder="Enter Description"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MyTextfield
                            name="report_to"
                            label="Report To"
                            onChange={onChangeInput}
                            value={inputsValue.report_to}
                            type="text"
                            placeholder="Enter user address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Share Data with same roles" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.lineSection}>
                            <span className={classes.titleSection}>
                                Profile Permissions
                            </span>
                        </div>
                    </Grid>

                    <Grid item xs={12} className={classes.permissionDiv}>
                        <Grid item xs={6}>
                            <button className="buttonname rounded-corners" onClick={() => handleOpenSchedulerModal("Scheduler")}> Scheduler </button>
                        </Grid>

                        <Grid item xs={6}>
                            <button className="buttonname rounded-corners" onClick={() => handleOpenSchedulerModal("Elearaning")}> Elearaning </button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.permissionDiv}>
                        <Grid item xs={6}>
                            <button className="buttonname rounded-corners" onClick={() => handleOpenSchedulerModal("Feedback")}> Feedback </button>
                        </Grid>

                        <Grid item xs={6}>
                            <button className="buttonname rounded-corners" onClick={() => handleOpenSchedulerModal("Common")}> Common </button>
                        </Grid>
                    </Grid>

                    <SchedulerModal handleCloseSchedulerModal={handleCloseSchedulerModal}
                        openSchedulerModal={openSchedulerModal} permissionList={openSchedulerModal.isType == "" ? [] : openSchedulerModal.isType == "Scheduler" ? schedulerPermission :
                            openSchedulerModal.isType == "Elearaning" ? eLearningPermission : openSchedulerModal.isType == "Feedback" ? feedbackPermission : commonPermission}
                        onChangeSchedulerIsCreate={onChangeSchedulerIsCreate}
                        onChangeSchedulerIsEdit={onChangeSchedulerIsEdit}
                        onChangeSchedulerIsDelete={onChangeSchedulerIsDelete}
                        onChangeSchedulerIsAssign={onChangeSchedulerIsAssign}
                        onChangeFullAcces={onChangeFullAcces}
                    />
                </Grid>
            </MyLeftModal>
        </div>
    )
};

const mapStateToProps = (state) => ({
    locationData: state.HierarachyChart.locationData,
    rolesData: state.settingsRoles.rolesData
});

const ConnectWith = connect(mapStateToProps, { fetchData })(AddRolesHierarachy);
export default (ConnectWith);
