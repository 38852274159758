import validator from 'validator';

export const validationEmail = async (value) => {
    var isEmail = await validator.isEmail(value) ? false : true
    return isEmail;
}

export const validationIsMobilePhone = async (value) => {
    var isMobilePhone = validator.isMobilePhone(value) ? false : true
    return isMobilePhone;
}

export const validationPassword = async (value) => {
    var isStrongPassword = validator.isStrongPassword(value) ? false : true
    return isStrongPassword;
}

export const validationIsEmpty = async (value) => {
    var isEmpty = validator.isEmpty(value, { ignore_whitespace: true })
    return isEmpty;
}


export const validationCode = async (value) => {
    if (value.toString().length === 6) {
        return true;
    } else {
        return false;
    }
}