import { HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const timeOffServices = {
    fetchData,
    addData,
    editData,
    updateStatus,
    deleteData
}

function fetchData(lid) {
    return axios.get(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${lid}`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/app/scheduler/timeoff`, data , HEADER_TOKEN).then(response => response)
};

function editData(data,id) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${id}`,  data , HEADER_TOKEN).then(response => response)
};

function updateStatus(data,id) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${id}`, data , HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${id}`, HEADER_TOKEN).then(response => response)
};