import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/century-gothic/Century-Gothic.otf';
import './assets/fonts/century-gothic/Century-Gothic-Bold.otf';

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
