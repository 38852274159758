import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  DeleteModal,
  MyDatePicker,
} from "../../../components";
import {
  getScheduleData,
  updateScheduleData,
} from "../Settings/modules/actions";
import { connect } from "react-redux";
import { schedulerColor } from "../../../config";
import { alert } from "../../../utilities";
import moment, { weekdays } from "moment";
import Backdrop from "../../../components/Backdrop";
import Modal from "../../../components/Modal";
import TimeZone from "../Settings/Timezone";
import { settingsServices } from "../Settings/modules/services";
import "../TeamAvailability/setting.css";
import EditIcon from "@mui/icons-material/Edit";
import SaveSection from "../Settings/SaveSection";
import { MyAvailabilityService } from "./modules/services";
import ModalTeam from "./ModalTeam";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CardContent,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  OutlinedInput,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ViewBreakModal from "../Settings/ViewBreakModal";
import CollapsedTableMainAvailability from "./CollapsedTableMainAvailability";
import { myAvailabilityService } from "../MyAvailability/modules/services";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  HeadCell: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#232056",
    marginLeft: 10,
  },
  buttonMain: {
    textAlign: "right",
    fontWeight: 700,
  },
  button: {
    background: "#005F73",
    borderRadius: 8,
    color: "white",
    padding: 10,

    "&:hover": {
      background: "#005F73",
      borderRadius: 8,
    },
  },
  flex: {
    display: "flex",
    margin: "20px",
  },
  Heading: {
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  HeadingValue: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "36px",
  },
  card: {
    //   margin: '15px 0px 0px 0px',
    borderRadius: 8,
    background: "#FFFFFF",
    //   boxShadow: '0 0 0.5em #E2E2E2',
    boxShadow: "0 12.5214px 16.9039px rgb(0 0 0 / 5%)",
  },
  scheduleCard: {
    background: "#FFFCFC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: "16px 68px",
    display: "flex",
    justifyContent: "space-between",
  },
  divWdth: {
    background: "#fff",
    border: "1px solid rgba(161, 161, 161, 0.5)",
    width: 70,
    borderRadius: "6px",
    cursor: "pointer",
  },
  divWdths: {
    background: "#4D72F8",
    cursor: "pointer",
    color: "white",
    marginTop: 26,
    padding: 7,
    width: "122%",
    marginLeft: -8,
    height: 80,
  },
  time: {
    marginTop: 10,
    fontFamily: "Calibri",
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
    marginTop: -12,
  },
  viewSection: {
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export function fetchNewTeamAvailabilityData() {
  window.NewTeamAvailability.fetchBusinessData();
  window.NewTeamAvailability.fetchData();
  window.NewTeamAvailability.getScheduleData();
}

class TeamAvailability extends Component {
  constructor(props) {
    super(props);
    window.NewTeamAvailability = this;
    this.state = {
      displayBusinessLocations: true,
      getbusinessData: "",
      disable: true,
      isWeek: false,
      myItem: {},
      isChecked: false,
      order: "asc",
      orderBy: "id",
      searchUsers: "",
      headCells: [
        { id: "user_name", label: "User Name", numeric: false },
        { id: "currentDate", label: "Current Date", numeric: false },
        { id: "location_name", label: "Location", numeric: false },
        { id: "manager_status", label: "Status", numeric: false },
        { id: "created_at", label: "Created At", numeric: false },
      ],
      flageed: [
        { value: 1, label: "Flagged" },
        { value: 0, label: "Blocked" },
      ],
      applyToAll: 0,
      workschedule: false,
      workRestriction: false,
      Reminders: false,
      breakss: false,
      alertsAndNotification: false,
      emoji: 1,
      anchorEl: null,
      data: {},
      allBreaks: [],
      addBreaksData: [],
      location_timezone: "",
      deleterowId: "",
      getApiResponse: "",
      scheduler_lid: cookies.get("scheduler_lid"),
      break_start_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      break_end_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
      isBreakUpdate: 0,
      viewModalOpen: false,
      isMustAknowledge_comment: false,
      BlockDropdownData: [
        { value: 2, label: "Block" },
        { value: 1, label: "Flag" },
      ],
      PaidDropdownData: [
        { value: 1, label: "Paid" },
        { value: 2, label: "Unpaid" },
      ],
      MandatoryDropdownData: [
        { value: 1, label: "Mandatory" },
        { value: 2, label: "Optional" },
      ],
      ScheduleDay: [
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
        { value: 0, label: "Sunday" },
      ],
      weekDaysTimingsData: [
        {
          day: "Monday",
          schedule_start_day: 1,
          schedule_day_start_time: "",
          schedule_day_end_time: "",
        },
      ],
    };
  }

  handleClickToggle = (open, index) => {
    const newData = this.state.getApiResponse?.my_avail.map(
      (items, newIndex) => {
        const cloneItem = items;
        if (index === newIndex) {
          cloneItem["open"] = open ? false : true;
        } else {
          cloneItem["open"] = false;
        }

        return cloneItem;
      }
    );

    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        my_avail: newData,
      },
    }));
  };
  handleClickToggleInside = (open, index) => {
    const newData = this.state.getApiResponse?.my_avail.map(
      (items, newIndex) => {
        const cloneItem = items;
        if (index === newIndex) {
          cloneItem["openInside"] = open ? false : true;
        } else {
          cloneItem["openInside"] = false;
        }

        return cloneItem;
      }
    );
    this.setState((prevState) => ({
      getApiResponse: {
        ...prevState.getApiResponse,
        my_avail: newData,
      },
    }));
  };

  componentDidMount = () => {
    this.fetchBusinessData();
    this.fetchData();
    this.getScheduleData();
  };

  fetchData = () => {
    var data = {
      lid: this.props.scheduler_lid,
    };
    var date = moment(this.state.effective_date).format("YYYY-MM-DD");
    this.setState({ isLoading: true });
    MyAvailabilityService.fetchTeamAvaData(data.lid).then((response) => {
      if (response.data.success === 2) {
      } else {
        this.setState({
          getApiResponse: response.data,
          isLoading: false,
        });
      }
    });
  };
  fetchBusinessData = () => {
    var data = this.props.scheduler_lid;

    settingsServices.getScheduleData(data).then((res) => {
      if (res.data.success === 2) {
      } else {
        this.setState({
          getbusinessData: res.data,
        });
      }
    });
  };
  onChangeSetAsAllWeeks = (e) => {
    this.setState({ isWeek: !this.state.isWeek });
    if (e.target.checked) {
      var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
        const openValue =
          this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
        const closeValue =
          this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
        var cloneItem = item;
        cloneItem["schedule_day_start_time"] = openValue;
        cloneItem["schedule_day_end_time"] = closeValue;
        return cloneItem;
      });
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    } else {
      var newRoutes = [...this.state.weekDaysTimingsData].map(
        (item, myIndex) => {
          const openValue =
            this.state.weekDaysTimingsData[0]["schedule_day_start_time"];
          const closeValue =
            this.state.weekDaysTimingsData[0]["schedule_day_end_time"];
          var cloneItem = item;
          cloneItem["schedule_day_start_time"] = "";
          cloneItem["schedule_day_end_time"] = "";
          if (myIndex === 0) {
            cloneItem["schedule_day_start_time"] = openValue;
            cloneItem["schedule_day_end_time"] = closeValue;
          }
          return cloneItem;
        }
      );
      this.setState({
        weekDaysTimingsData: newRoutes,
      });
    }
  };

  handleChangeWeekTiming = (e, isOpen, index) => {
    var newRoutes = this.state.weekDaysTimingsData.map((item, myIndex) => {
      var cloneItem = item;
      if (index === myIndex) {
        cloneItem[isOpen] = e.value;
      }
      return cloneItem;
    });
    this.setState({
      weekDaysTimingsData: newRoutes,
    });
  };

  getScheduleData = () => {
    this.setState({
      isLoading: true,
    });
    var lid = this.props.scheduler_lid;
    this.props.getScheduleData(lid);
  };

  componentDidUpdate(prevProps) {
    if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
      if (this.props.LocationDetaildata.WorkScheduleData) {
        const newWeekDaysTimingsData = [
          ...this.props.LocationDetaildata.WorkScheduleData,
        ].map((item) => {
          var cloneItem = item;
          var schedule_day_start_time = moment(item.schedule_day_start_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          var schedule_day_end_time = moment(item.schedule_day_end_time)
            .tz(item.schedule_time_zone)
            .format("HH:mm");
          cloneItem["schedule_day_start_time"] = schedule_day_start_time;
          cloneItem["schedule_day_end_time"] = schedule_day_end_time;
          return cloneItem;
        });
        this.setState({
          weekDaysTimingsData: newWeekDaysTimingsData,
        });
      }
      this.setState({
        isLoading: false,
        location_timezone:
          this.props.LocationDetaildata.locationDetail.location_timezone,
        data: this.props.LocationDetaildata.locationDetail,
      });
    }
  }

  rangeCalculater = (date, index) => {
    if (date === undefined || date === null) {
      return false;
    }

    var formatStart = moment(
      date.schedule_day_start_time,
      "YYYY-MM-DD HH:mm"
    ).format("HH");
    var formatEnd = moment(
      date.schedule_day_end_time,
      "YYYY-MM-DD HH:mm"
    ).format("HH");
    var formatStartNew = moment(
      date.schedule_day_start_time,
      "YYYY-MM-DD HH:mm"
    ).format("HH:mm");
    var formatEndNew = moment(
      date.schedule_day_end_time,
      "YYYY-MM-DD HH:mm"
    ).format("HH:mm");
    const startRange =
      formatStart !== "" ? Number(formatStart.split(":")[0]) : null;
    const endRange = formatEnd !== "" ? Number(formatEnd.split(":")[0]) : null;

    if (startRange === null || endRange === null) {
      return false;
    }
    const inbtwnRange = index >= startRange && index <= endRange ? true : false;

    return {
      inbtwnRange: inbtwnRange,
      startIndex: {
        index: startRange,
        value: formatStartNew,
      },
      endIndex: {
        index: endRange,
        value: formatEndNew,
      },
    };
  };

  handlePopupModal = () => {
    if (this.state.workschedule === true) {
      this.setState({
        openModal: !this.state.openModal,
      });
    }
  };

  closeEditModal = (value) => {
    this.setState({
      workschedule: value === "workSchedule" ? false : this.state.workschedule,
      workRestriction:
        value === "workRestrictions" ? false : this.state.workRestriction,
      Reminders: value === "Reminders" ? false : this.state.Reminders,
      breakss: value === "Breaks" ? false : this.state.breakss,
      alertsAndNotification:
        value === "alertsAndNotification"
          ? false
          : this.state.alertsAndNotification,
    });
  };

  handleSubmit = async () => {
    var tempArray = this.state.weekDaysTimingsData.map((item, index) => {
      var timeZoneSplit = this.state.location_timezone.split("|").pop();
      let initialdate = moment().format("YYYY-MM-DD");
      let start_time =
        initialdate +
        "T" +
        item.schedule_day_start_time +
        ":00" +
        timeZoneSplit;
      let datetimeA = start_time;
      let initialdateEnd = moment().format("YYYY-MM-DD");
      let end_time =
        initialdateEnd +
        "T" +
        item.schedule_day_end_time +
        ":00" +
        timeZoneSplit;
      let datetimeB = end_time;

      // var splitdata = timeZoneSplit[0];
      // var timezone = moment(datetimeA).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var timezone_end = moment(datetimeB).tz(splitdata).format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc = moment(timezone).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      // var utc_end = moment(timezone_end).utc().format("YYYY-MM-DDTHH:mm:ssZ");
      var newObj = {
        schedule_start_day: index,
        schedule_day_start_time: datetimeA,
        schedule_day_end_time: datetimeB,
        schedule_time_zone: this.state.location_timezone,
      };
      return newObj;
    });

    var data = {
      lid: this.props.scheduler_lid,
      same_for_all_days: this.state.isWeek,
      DaysArr: tempArray,
    };

    settingsServices.addWorkScheduler(data).then((response) => {
      if (response.data.success === 2) {
        alert.error(response.data.message);
      } else {
        // this.setState({
        if (this.state.workschedule === true) {
          this.setState({
            workschedule: false,
          });
        }
        // })
        // this.props.getScheduleData(this.props.scheduler_lid,data)
        alert.success(response.data.message);
      }
    });
  };

  approveRejectStatus = (uid, lid, status) => {
    var data = {
      uid: uid,
      lid: lid,
      status: status,
    };
    myAvailabilityService.approveRejectStatus(data).then((response) => {
      if (status === 1 || status === 2) {
        //   // invalidToken(response.data.message)
        // } else {
        alert.success(response.data.message);

        const newData = this.state.getApiResponse?.my_avail.map((items) => {
          const cloneItem = items;
          if (items.av_id === response.data?.post?._id?.toString()) {
            cloneItem["status"] = response.data?.post?.status;
          }
          return cloneItem;
        });

        this.setState((prevState) => ({
          getApiResponse: {
            ...prevState.getApiResponse,
            my_avail: newData,
          },
        }));
        this.fetchData();
      }
    });

    // this.setState({
    //   isLoading: false
    // })
  };

  handleEffectiveDate = (date, e) => {
    this.setState({
      effective_date: date,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      isChecked: e.target.checked ? 1 : 0,
    });
  };

  newAvailability = () => {
    var effective_date = moment(this.state.effective_date).format("YYYY-MM-DD");
    var data = {
      effective_date: effective_date,
      user_notes: "",
      status: 3,
      lid: this.state.curLID,
      uid: this.state.userValue.uid,
    };

    MyAvailabilityService.addMyAvailability(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        alert.success(response.data.message);
        this.newModalClose();
        this.fetchData();
      }
    });
  };

  newModalOpen = (userValue, lid) => {
    this.setState({
      newModal: true,
      isChecked: false,
      userValue: userValue,
      curLID: lid,
      effective_date: moment().format("YYYY-MM-DD"),
    });
  };

  newModalClose = () => {
    this.setState({
      newModal: false,
      userValue: "",
    });
  };

  render() {
    const cardBg = "#faf9f9";
    const cardRadius = "12px";
    const { permissionsData, classes, LocationDetaildata, isLoading } =
      this.props;

    if (this.state.isLoading) return <LoadingData />;
    if (isLoading) return <LoadingData />;

    return permissionsData.scheduler_settings ? (
      <Grid container spacing={2} className={classes.root}>
        {this.state.openModal && (
          <div>
            <Backdrop handlePopupModal={this.handlePopupModal} />
            <Modal title="TimeZone" handlePopupModal={this.handlePopupModal}>
              <div style={{ width: 850 }}>
                <TimeZone
                  isWeek={this.state.isWeek}
                  onChangeSetAsAllWeeks={this.onChangeSetAsAllWeeks}
                  handlePopupModal={this.handlePopupModal}
                  daysData={this.state.weekDaysTimingsData}
                  handleChangeWeekTiming={this.handleChangeWeekTiming}
                  handleSubmit={this.handleSubmit}
                />
              </div>
            </Modal>
          </div>
        )}

        <Grid item md={12}>
          {/* <Card
            style={{
              backgroundColor: cardBg,
              borderRadius: cardRadius,
              boxShadow: this.state.workschedule ? "-4px -4px 16px #999" : "",
            }}
          >
            <CardContent> */}
          <Grid
            style={{
              marginBottom: "1.7rem",
            }}
            container
            spacing={2}
          >
            <Grid
              item
              md={12}
              onClick={() => {
                this.setState({
                  displayBusinessLocations: this.state.displayBusinessLocations
                    ? false
                    : true,
                });
              }}
              style={{
                // borderRadius: "5px",
                padding: "10px",
                backgroundImage: schedulerColor.main,
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                Business Hours
              </Typography>
            </Grid>

            <Grid item md={12} className="d-flex">
              {this.state.displayBusinessLocations && (
                <Grid item md={12}>
                  <div>
                    <Card className={classes.scheduleCard}>
                      {this.state.getbusinessData.WorkScheduleData &&
                        this.state.getbusinessData.WorkScheduleData.map(
                          (weekDay, index) => (
                            <div
                              onClick={(e) => this.handleClickTwo(e, index)}
                              key={index}
                            >
                              <Typography
                                style={{ marginBottom: "0.4rem" }}
                                align="center"
                                variant="h6"
                              >
                                {weekDay.schedule_start_day === 0
                                  ? "Monday"
                                  : weekDay.schedule_start_day === 1
                                  ? "Tuesday"
                                  : weekDay.schedule_start_day === 2
                                  ? "Wednesday"
                                  : weekDay.schedule_start_day === 3
                                  ? "Thursday"
                                  : weekDay.schedule_start_day === 4
                                  ? "Friday"
                                  : weekDay.schedule_start_day === 5
                                  ? "Saturday"
                                  : weekDay.schedule_start_day === 6
                                  ? "Sunday"
                                  : ""}
                              </Typography>
                              {/* <Typography align="center" variant="h6">{weekDay.label}</Typography> */}
                              <div className={classes.divWdth}>
                                {new Array(24).fill("").map((item, i) => {
                                  const inbtwn = this.rangeCalculater(
                                    this.state.getbusinessData.WorkScheduleData[
                                      index
                                    ],
                                    i
                                  );

                                  const cls =
                                    "hour" +
                                    " " +
                                    `${
                                      inbtwn.inbtwnRange
                                        ? `active ${i === 0 && "start_ele"} ${
                                            i === 23 && "end_ele"
                                          }`
                                        : ""
                                    }`;

                                  return (
                                    <>
                                      <div
                                        className={cls}
                                        style={{
                                          borderTopLeftRadius:
                                            i === 0 ? "10px" : "0",
                                          borderTopRightRadius:
                                            i === 0 ? "10px" : "0",
                                          borderBottomLeftRadius:
                                            i === 23 ? "10px" : "0",
                                          borderBottomRightRadius:
                                            i === 23 ? "10px" : "0",
                                          margin: 0,
                                          height: 5,
                                          // backgroundColor: "#4d72f8"
                                          backgroundColor: inbtwn.inbtwnRange
                                            ? "#9CC3F1"
                                            : "#FFFFFF",
                                        }}
                                      >
                                        {inbtwn?.startIndex?.index === i && (
                                          <span
                                            style={{
                                              color: "#000",
                                              fontSize: 8,
                                              display: "grid",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {inbtwn?.startIndex.value}
                                            {i <= 12 ? " AM" : " PM"}
                                          </span>
                                        )}
                                        {inbtwn?.endIndex?.index === i && (
                                          <span
                                            style={{
                                              color: "#000",
                                              fontSize: 8,
                                              display: "grid",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {inbtwn?.endIndex.value}
                                            {i > 12 ? " PM" : " AM"}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          )
                        )}
                    </Card>
                  </div>
                </Grid>
              )}

              {this.state.workschedule && (
                <SaveSection
                  handleSubmit={this.handleSubmit}
                  handleCancel={() => {
                    this.closeEditModal("workSchedule");
                  }}
                />
              )}
            </Grid>
          </Grid>
          {/* </CardContent>
          </Card> */}
        </Grid>

        <Grid item md={12}>
          <CollapsedTableMainAvailability
            handleClickToggle={this.handleClickToggle}
            handleClickToggleInside={this.handleClickToggleInside}
            scheduler_lid={this.state.scheduler_lid}
            getApiResponse={this.state.getApiResponse}
            approveRejectStatus={this.approveRejectStatus}
            newModalOpen={this.newModalOpen}
          />
        </Grid>

        <ModalTeam
          isChecked={this.state.isChecked}
          handleEffectiveDate={this.handleEffectiveDate}
          effective_date={this.state.effective_date}
          handleCheckBox={this.handleCheckBox}
          dropdownData={this.state.dropdownData}
          newAvailability={this.newAvailability}
          newModal={this.state.newModal}
          newModalClose={this.newModalClose}
        />
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerSettingsLocationdata.isLoading,
  LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
  WorkScheduleData: state.schedulerSettingsLocationdata.WorkScheduleData,
  WorkSchedule: state.schedulerSettingsLocationdata.WorkSchedule,
});

const ConnectWith = connect(mapStateToProps, {
  getScheduleData,
  updateScheduleData,
})(TeamAvailability);
export default withStyles(styles)(ConnectWith);
