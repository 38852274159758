import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { eLearningColor } from '../../config'
import { FILE_URL } from "../../config";

const useStyles = makeStyles(theme => ({
  link: {
    padding: '8px 10px',
    borderRadius: '5px',
    textDecoration: "none",
    marginBottom: '.25rem',
    boxShadow: "0.5px 0.5ppx 0.5ppx rgba(0, 0, 0, 0.25)",
    '&:hover': {
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: theme.palette.text.secondary
      },
      "& $linkIcon": {
        color: theme.palette.text.secondary
      },
      "& $linkTextCount": {
        color: theme.palette.text.secondary
      }
    }
  },
  linkActive: {
    backgroundColor: eLearningColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)"
  },
  eLearningActive: {
    backgroundColor: eLearningColor.main
  },
  avatar: {
    height: 25,
    width: 25,
    marginRight: '1rem'
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color")
  },
  linkIconActive: {
    color: "#ffffff"
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextCount: {
    float: 'right',
    color: "#000000",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 12
  },
  linkTextActive: {
    fontWeight: 600,
    color: "#ffffff"
  },
  linkTextHidden: {
    opacity: 0,
  }
}));


export default function SidebarLink({ active_lesson_details, selectMyCourseLesson, path, lesson_image, lesson_name, location, isSidebarOpen, activeProduct, lesson_id, lessonDetails }) {
  var classes = useStyles();
  var isLinkActive = path && (location.pathname === path);
  var newId = active_lesson_details ? active_lesson_details._id : 0
  var categoryLinkActive = lesson_id === newId ? true : false;
  var [toolTip, setToolTip] = useState(false);

  const openTooltip = () => {
    if (!isSidebarOpen) {
      setToolTip(true)
    }
  }

  const closeTooltip = () => {
    setToolTip(false)
  }

  return (
    <Tooltip
      arrow
      placement="right"
      open={toolTip}
      onClose={closeTooltip}
      onOpen={openTooltip}
      title={<p className={classes.popoverTitle}>{lesson_name}</p>}
    >
      <ListItem
        onClick={() => { selectMyCourseLesson(lessonDetails) }}
        button
        component={path && Link}
        to={path}
        classes={{
          root: classnames(classes.link, {
            [classes.linkActive]: lesson_id ? categoryLinkActive : isLinkActive,
            [classes.eLearningActive]: activeProduct === "eLearning" && isLinkActive ? true : false
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: lesson_id ? categoryLinkActive : isLinkActive
          })}
        >
          <Avatar
            variant="square"
            className={classes.avatar}
            src={lesson_image}
          />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: lesson_id ? categoryLinkActive : isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpen,
            }),
          }}
          primary={lesson_name}
        />

      </ListItem>
    </Tooltip>
  );
}
