import {
  SET_PAGE,
  SET_ROWS,
  SET_SIDEBAR
} from "./constants";

export function onChangePage(event, newPage) {
  return {
    type: SET_PAGE,
    payload: newPage ? newPage : 0
  };
}

export function onChangeRowsPerPage(event) {
  return {
    type: SET_ROWS,
    payload: parseInt(event.target.value, 10)
  };
}

export function onChangeSidebar(value) {
  return {
    type: SET_SIDEBAR,
    payload: value ? true : false
  };
}