import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableRecordNotFound, LoadingData } from '../../../components'
import {
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Grid
}
    from '@material-ui/core';
import { schedulerColor, FILE_URL } from "../../../config";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: schedulerColor.main,
        color: "white",
    },
    user_name: {
        maxWidth: "175px",
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: schedulerColor.main,
        fontSize: '16px',
        fontWeight: 600,
        float: 'left',
        lineHeight: 2.5
    },
    buttonSignup: {
        boxShadow: 'none',
        fontWeight: 400,
        color: '#ffffff',
        backgroundColor: schedulerColor.main,
        borderRadius: 0,
        border: '1px solid green',
        padding: '5px 22px'
    },
    buttonLogin: {
        boxShadow: 'none',
        fontWeight: 400,
        color: '#ffffff',
        backgroundColor: "black",
        borderRadius: 0,
        border: '1px solid red',
        padding: '5px 22px'
    },
});


class Company extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            myItem: {},
            order: 'asc',
            orderBy: "uid",
            headCells: [
                { id: 'user_name', label: "Name", numeric: false },
                { id: 'total_timecard', label: "Total Timecard", numeric: false },
                { id: 'payroll_rule', label: "Payroll Rule", numeric: false },
                { id: 'total_scheduled_hrs', label: "Total Scheduled", numeric: false },
                { id: 'total_actual_hrs', label: "Total Actual", numeric: false },
                { id: 'total_sch_vs_paid_hrs', label: "Total Sch vs Paid", numeric: false },
                { id: 'total_sch_vs_actual_hrs', label: "Total Sch vs Actual", numeric: false },
                { id: 'total_paid_hrs', label: "Total Paid", numeric: false },
                { id: 'total_break_hrs', label: "Total Breaks", numeric: false },
                { id: 'total_issues', label: "Total Issues", numeric: false }
            ],
            errors: {},
            errorsHelper: {},
        }
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };


    render() {
        const { usersData, isLoading, classes } = this.props;
        if (isLoading) return <LoadingData />
        return (
            <Grid
                container
                spacing={4}
                className={classes.root}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Paper>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size='medium'
                            >
                                <TableHead style={{ backgroundImage: schedulerColor.main }}>
                                    <TableRow>
                                        {this.state.headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                            >
                                                <TableSortLabel
                                                    className={classes.headCell}
                                                    active={this.state.orderBy === headCell.id}
                                                    direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                    onClick={this.createSortHandler(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {this.state.orderBy === headCell.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {usersData.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.uid}
                                            >
                                                <TableCell className={classes.TableCell}>
                                                    {row.user_image ?
                                                        <Avatar
                                                            src={FILE_URL + row.user_image}
                                                            className={classes.avatar}
                                                        />
                                                        :
                                                        <Avatar className={classes.avatar} >
                                                            {row.user_name.charAt(0)}
                                                        </Avatar>
                                                    }
                                                    <Typography className={classes.user_name} >
                                                        {row.user_name}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell className={classes.TableCell}>{row.total_timecard}&nbsp;{row.total_timecard > 1 ? "Timecards" : "Timecard"}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.payrollrule}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_scheduled_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_actual_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_sch_vs_paid_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_sch_vs_actual_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_paid_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_break_hrs}</TableCell>
                                                <TableCell className={classes.TableCell}>{row.total_issues}&nbsp;{row.total_issues > 1 ? "Issues" : "Issue"}</TableCell>

                                            </TableRow>
                                        );
                                    })}

                                    {usersData && usersData.length < 1 ?
                                        <TableRecordNotFound
                                            colSpan={8}
                                            label="No Data Found.."
                                        />
                                        : ""
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* <TableMyPagination
                            count={usersData ? usersData.length : 0}
                        /> */}
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Company);