//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Modal, FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { schedulerColor } from '../config';

const styles = (theme) => ({
  dialog: {
    margin: '0px 0px 0px auto',
    right: '0px',
    left: "auto",
    [theme.breakpoints.up('md')]: {
      width: "25%"
    }
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    lineHeight: '2.5rem',
    float: "left",
    color: "#ffffff",
    fontWeight: 600
  },
  dialogcontent: {
    backgroundColor: '#fcfcfc',
    padding: '1rem 1.5rem'
  },
  saveButton: {
    color: '#ffffff',
    padding: '10px 22px'
  },
  closeButton: {
    backgroundColor: 'black',
    color: '#ffffff',
    padding: '10px 22px'
  }
});

class MyRightModal extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.open || false}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{ backgroundColor: 'white' }}
          className={classes.addModal}>
          <Typography variant="h2" style={{ textAlign: 'center' }}>Add To Time Card</Typography>
          <InputLabel id="demo-simple-select-helper-label" style={{ padding: '10px 5px' }}>Employee</InputLabel>
          <FormControl style={{ minWidth: '550px' }}>
            <Select
              variant="outlined"
              name={this.props.name}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              value={this.props.value}
              type={this.props.type}
              error={this.props.error}
              helperText={this.props.helperText}
              placeholder={this.props.placeholder}
            >
              {/* {usersData.map((data, index) => {
                        return (
                            <MenuItem value={data.uid}>
                                {data.user_name}
                            </MenuItem>
                        )
                    })} */}
            </Select>
          </FormControl>
          <InputLabel id="demo-simple-select-helper-label" style={{ padding: '5px 5px' }}>Date</InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              style={{ minWidth: '550px' }}
              inputVariant="outlined"
              name={this.props.name}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              value={this.props.value}
              type={this.props.type}
              error={this.props.error}
              helperText={this.props.helperText}
              placeholder={this.props.placeholder}
              selected={this.props.selected}
              onSelect={this.onSelect}
            />
          </MuiPickersUtilsProvider>
          <InputLabel id="demo-simple-select-helper-label" style={{ padding: '5px 5px' }}>Role</InputLabel>
          <FormControl style={{ minWidth: '550px' }}>
            <Select
              variant="outlined"
              name={this.props.name}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              value={this.props.value}
              type={this.props.type}
              error={this.props.error}
              helperText={this.props.helperText}
              placeholder={this.props.placeholder}
            >
              {/* {rolesData.map((role, index) => {
                        return (
                            <MenuItem value={role.role_id}>
                                {role.role_name}
                            </MenuItem>
                        )
                    })} */}
            </Select>
          </FormControl>
          <div style={{ float: 'right', margin: '10px 10px' }}>
            <Button style={{ border: '1px solid', marginRight: '1rem', padding: '4px 10px' }} onClick={this.props.onClose}>Close</Button>
            <Button style={{ backgroundColor: schedulerColor.main, color: 'white', border: '1px solid' }}
              onClick={this.handleOpenDetail}>Add Time Card</Button>
          </div>
        </Box>
      </Modal>
    )
  }
}

export default withStyles(styles)(MyRightModal);
