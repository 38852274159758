import { MoNGO_DB_API_URL, HEADER_TOKEN, API_URL } from "../../../../../config";
import axios from 'axios';

export const QuessionaireApi = {
    saveForm: function (body) {
        return axios
            .post(API_URL + "/onevision/forms/form/create", body, HEADER_TOKEN)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error;
            })
    },
    editForm: function (id, body) {
        return axios
            .post(API_URL + "/onevision/forms/form/edit/" + id, body, HEADER_TOKEN)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error;
            })
    }
}
