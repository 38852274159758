import {
    SET_OV_RATING_LOADING,
    SET_OV_GET_LOCATIONS,
    SET_OV_GET_ROLES,
    SET_ADD_RATING,
    SET_RATING_DATA,
    SET_EDIT_RATING,
    SET_DELETE_RATING,
    SET_OV_GET_QUESTION,
    SET_OV_RATING_QUESTION_LOADING,
    SET_TEAM_RATING,
    SET_TEAM_RATING_LOADING,
    SET_USER_TEAM_RATING,
    SET_MY_TEAM_RATING,
    SET_MY_TEAM_RATING_LOADING
  } from "./constants";
  
  const initialState = {
    isLoading: true,
    isQuestionLoading:true,
    locationDetails: [],
    roleDetails:[],
    ratingData:[],
    questionData:[],
    isTeamLoading:true,
    teamRatingData:[],
    userteamRatingData:[],
    myTeamLoader:true,
    myTeamData:[]
  }
  
  const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_OV_RATING_LOADING:
        return {
          ...state,
          isLoading: payload
        };
        case SET_TEAM_RATING_LOADING:
          return {
            ...state,
            isTeamLoading: payload
          };
          case SET_MY_TEAM_RATING_LOADING:
            return {
              ...state,
              myTeamLoader: payload
            };
        case SET_OV_RATING_QUESTION_LOADING:
          return {
            ...state,
            isQuestionLoading: payload
          };
      case SET_OV_GET_LOCATIONS:
        return {
          ...state,
          isLoading: false,
          locationDetails: payload
        }
        case SET_OV_GET_ROLES:
            return {
              ...state,
              isLoading: false,
              roleDetails: payload
            }
        case SET_RATING_DATA:
            return{
                ...state,
              isLoading: false,
              ratingData: payload 
            }
        case SET_OV_GET_QUESTION:
              return{
                  ...state,
                  isQuestionLoading: false,
                questionData: payload 
              }
        case SET_ADD_RATING:
              return {
              ...state,
              isLoading: false,
              ratingData: [...state.ratingData, payload]
          }
        case SET_EDIT_RATING:
              return {
              ...state,
              isLoading: false,
              ratingData: state.ratingData.map((item) => item._id === payload._id ? { ...payload } : item)
          }
        case SET_DELETE_RATING:
            return {
              ...state,
              isLoading: false,
              ratingData: state.ratingData.filter((item) => item._id !== payload)
        }
        case SET_TEAM_RATING:
            return {
              ...state,
              isTeamLoading: false,
              teamRatingData: payload
        }
        case SET_USER_TEAM_RATING:
          return {
            ...state,
            isTeamLoading: false,
            userteamRatingData: payload
      }
      case SET_MY_TEAM_RATING:
        return {
          ...state,
          myTeamLoader: false,
          myTeamData: payload
    }
      default:
        return state;
    }
  };
  
  export default Reducer;