//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider,KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import CalendarIcon from "../icons";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "#f4f2f26b !important",
    borderRadius: 5,
    marginBottom: 0,
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
});

class MyNewDatePicker extends Component {
  render() {
    const { classes } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          keyboardIcon={<CalendarMonthIcon style={{color: "red !important"}}/>}
          style={{ backgroundColor: "red !important" }}
          fullWidth
          disablePast={
            this.props.disablePast === undefined
              ? false
              : this.props.disablePast
          }
          disableFuture={
            this.props.disableFuture === undefined
              ? false
              : this.props.disableFuture
          }
          label={this.props.label}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value}
          variant="inline"
          size="small"
          inputVariant="outlined"
          format={"dd MMM yyyy"}
          inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            className: classes.input,
          }}
        
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(MyNewDatePicker);
