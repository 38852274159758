import Cookies from "universal-cookie";
import { store } from "../store"
const cookies = new Cookies();
const myStore = store.getState()

export const COOKIE_MAX_AGE = 300 * 60; //minite * seconds
export const WEB_URL = 'https://ordering.onevision.io/nodejs';
// export const WEB_URL = 'http://chatapi.onevision.io/nodejs';
export const SLIDER_URL = 'https://ordering.onevision.io/elearning/mycourses/slider';
export const API_URL = `https://ordering.onevision.io/nodejs`;
// export const API_URL = `http://localhost:8008`;
// export const API_URL = `http://chatapi.onevision.io/nodejs`;
export const MoNGO_DB_API_URL = `https://ordering.onevision.io/nodejs/onevision`;
// export const MoNGO_DB_API_URL = `https://chatapi.onevision.io/nodejs/onevision`;
// export const MoNGO_DB_API_URL = `http://localhost:8008/onevision`;
export const GOOGLE_API_KEY = 'AIzaSyDjaOhHjUWJGXFbgFvIRkkairVvCFoYlIk';
export const FILE_URL = 'https://onevision-space.nyc3.digitaloceanspaces.com';
export const PHP_FILE_URL = 'https://dashboard.onevision.io/php/uploadFile.php'
export const PERSIST_KEY = 'onevision'
export const CRYPTOJS_SECRATE_KEY = 'onevision'
export const MY_TOKEN = cookies.get('ovToken')

export const HEADER_TOKEN = {
  headers: {
    Authorization: MY_TOKEN
  }
}
// productsColorsData
const data_local = localStorage.getItem("productsColorsData")
var productsColorsData = data_local ? JSON.parse(data_local) : []

const myColor = (myProduct) => {
  if (productsColorsData.length) {
    // console.log("productsColorsData : " , productsColorsData)
    const data = productsColorsData?.find((item) => item.product_name.toLowerCase() === myProduct.toLowerCase())
    return data?.color_code
  }
  return null
}

export const feedbackColor = {
  main: myColor("feedback") || "red"
};
export const eLearningColor = {
  main: myColor("eLearning") || "#135098"
};
export const todoColor = {
  main: myColor("todo") || "#735D78"
};
export const schedulerColor = {
  main: "linear-gradient(45deg, #AA076B, #9F0D9C)"

  // main: myColor("scheduler") || "#005F73"
};
export const settingColor = {
  main: "rgba(170, 7, 107, 0.125)"
};
export const newsandupdate = {
  main: myColor("newsandupdate") || "#8E0C0C"
};
export const ratingColor = {
  main: myColor("rating") || "darkseagreen"
};
export const ovChatColor = {
  main: myColor("ovChat") || "#28a745"
}

export const ovFormsColor = {
  main: myColor("ovForms") || "#20c997"
}

export const RoutineColor = {
  main: myColor("ovRoutine") || "#295CDC"
}


export const ovHrColor = {
  main: myColor("ovHr") || "rgb(151 124 79)"
}

export const ovSupportColor = {
  main: myColor("ovSupport") || "#635BFF"
}

export const getRandomColors = (length) => {
  let randomColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#ffebee",
    "#fce4ec",
    "#f3e5f5",
    "#ef5350",
    "#880e4f",
    "#4a148c",
    "#ff5252",
    "#ff4081",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
  ]
  const colorsData = []
  const randomIndex = Math.floor(Math.random() * randomColors.length);
  for (let i = 0; i < length; i++) {
    colorsData.push(randomColors[randomIndex])
  }
  return colorsData;
}