import React, { Component } from 'react'
import {
        TableCell,
        TableRow
}
        from '@material-ui/core';
import { data_not_found } from "../icons";

export default class TableRecordNotFound extends Component {
        render() {
                return (
                        <TableRow>
                                <TableCell
                                        colSpan={this.props.colSpan}
                                        style={{ height: 100, textAlign: "center" }}
                                >
                                        <img
                                                alt='dsa'
                                                style={{ padding: "3rem", height: "300px" }}
                                                src={data_not_found}
                                        />
                                </TableCell>
                        </TableRow>
                )
        }
}
