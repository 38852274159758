import { configOptions } from "final-form";
import moment from "moment";
import {
  SET_USERS_NOTIFICATIONS_LOADING,
  SET_HOLIDAY_LOADING,
  SET_HOLIDAY,
  ADD_USERS,
  EDIT_USERS,
  DELETE_USERS,
  SET_OV_GET_LOCATIONS,
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
  notificationLoading: true,
  countData: {},
  notificationsData: [],
  holidayData: [],
  locationDetails: [],
  currentUser: {},
  isLoggedIn: false
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HOLIDAY_LOADING:
      return {
        ...state,
        isLoading: payload
      };
      case SET_HOLIDAY:
        return {
          ...state,
          isLoading: false,
          holidayData: payload.HolidayData
        };
    case ADD_USERS:
      var myPayload = payload
      return {
        ...state,
        isLoading: false,
        holidayData: [...state.holidayData, myPayload]
      }
      case SET_OV_GET_LOCATIONS:
        return {
          ...state,
          isLoading: false,
          locationDetails: payload
        }
    case EDIT_USERS:
      return {
        ...state,
        isLoading: false,
        holidayData: state.holidayData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_USERS:
      return {
        ...state,
        isLoading: false,
        holidayData: state.holidayData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;