import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0px 0px 5px 0px',
    height: '100%',
    width: "100%"
  },
  content: {
    backgroundColor: '#ffffff',
  }
}));

const PlainLayout = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.container}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PlainLayout;
