//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Avatar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
        media: {
                height: 30,
                width: 30
        },
        textField: {
                backgroundColor: '#F3F3F3 !important',
                width: '100%',
        },
        input: {
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: 5,
        },
        label: {
                color: "red",
                fontSize: '16px',
                fontWeight: 600
        },
        labelColor: {
                color: 'red !important'
        }
});

class MyTextField extends Component {
        render() {
                const { classes } = this.props;

                return (
                        <div>
                                <FormControl
                                        className={classes.textField}
                                        variant="outlined"
                                >
                                        <InputLabel
                                                htmlFor="outlined-adornment-password"
                                        >
                                                {this.props.label}
                                        </InputLabel>
                                        <OutlinedInput
                                                label={this.props.label}
                                                id="outlined-adornment-password"
                                                disabled={this.props.disabled === undefined ? false : this.props.disabled}
                                                autoFocus={true}
                                                placeholder={this.props.placeholder}
                                                type='text'
                                                name={this.props.name}
                                                onChange={this.props.onChange}
                                                onFocus={this.props.onFocus}
                                                value={this.props.value}
                                                inputlabelprops={{ classes: { root: classes.labelColor } }}
                                                inputProps={{
                                                        className: classes.input
                                                }}
                                                endAdornment={
                                                        <InputAdornment position="end">

                                                                <Avatar
                                                                        variant="square"
                                                                        className={classes.media}
                                                                        src={this.props.image}
                                                                />
                                                        </InputAdornment>
                                                }
                                        />
                                        {this.props.error ?
                                                <FormHelperText
                                                        style={{ color: "red" }}
                                                >
                                                        {this.props.helperText}
                                                </FormHelperText> : ""}
                                </FormControl>
                        </div >
                )
        }
}

export default withStyles(styles)(MyTextField);