import {
  SET_TIMECODE_LOADING,
  SET_TIMECODE,
  ADD_TIMECODE,
  EDIT_TIMECODE,
  DEFAULT_TIMECODE,
  DELETE_TIMECODE
} from "./constants";

const initialState = {
  isLoading: true,
  timecodeData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMECODE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMECODE:
      return {
        ...state,
        isLoading: false,
        timecodeData: payload.TimecodeData
      };
    case ADD_TIMECODE:
      return {
        ...state,
        isLoading: false,
        timecodeData: [...state.timecodeData, payload]
      }
    case DEFAULT_TIMECODE:
      var newTimecode = state.timecodeData.map((item) => {
        var cloneItem = item
        var is_default = 0
        if (item._id === payload.id) {
          is_default = 1
        }
        cloneItem["is_default"] = is_default
        return cloneItem
      })
      return {
        ...state,
        timecodeData: newTimecode
      }
    case EDIT_TIMECODE:
      return {
        ...state,
        isLoading: false,
        timecodeData: state.timecodeData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_TIMECODE:
      return {
        ...state,
        isLoading: false,
        timecodeData: state.timecodeData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;