import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { settingColor } from '../config';

const useStyles = makeStyles(theme => ({
  Card: {
    margin: '10px',
    borderRadius: '16px',
    backgroundColor: "#ffffff",
    boxShadow: "0px 1px 2px rgba(44, 39, 56, 0.1), 0px 1px 2px rgba(44, 39, 56, 0.1)",
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  },
  CardContent: {
    padding: "3rem 2rem"
  },
  startButton: {
    textTransform: "capitalize",
    padding: "10px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    border: "1px solid #CCCCCC",
    boxSizing: "borderBox",
    boxShadow: "0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08)",
    borderRadius: "6px",
    '&:hover': {
      backgroundColor: '#fcfcfc'
    }
  },
  price: {
    color: "#2C2738",
    fontSize: "30px"
  },
  plan: {
    margin: '15px 0 10px 0',
    fontSize: "22px"
  },
  description: {
    marginBottom: '10px',
    color: "#92969D",
    fontWeight: 400,
    fontSize: "15px"
  },
  courseTitle: {
    paddingLeft: '0.5rem',
    color: "#7C7C7C",
    fontSize: "16px",
    lineHeight: "25px"
  }
}));

const MyCourseCard = props => {
  const { data, index } = props;
  const classes = useStyles();

  return (
    <Card className={classes.Card}>
      <CardContent className={classes.CardContent} >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Typography
            display="inline"
              className={classes.price}
            >
              {data.price}
            </Typography>
            <Typography
            display="inline"
              variant="body2"
            >
              / month
            </Typography>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Typography
            style={{
              color: index === 0 ? "#F3B10E" : index === 1 ? "#1DABF2" : "#46D68C"
            }}
              className={classes.plan}
            >
              {data.plan}
            </Typography>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Typography
              className={classes.description}
            >
              {data.description}
            </Typography>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Grid container>
              {data.benefits.map((benefit) => {
                return (
                  <Grid item sm={12} xs={12}>
                    <CheckIcon
                      style={{ float: "left" }}
                      className={classes.CheckIcon}
                    />

                    <Typography
                      style={{ float: "left" }}
                      display="inline"
                      className={classes.courseTitle}
                      component="p"
                      gutterBottom
                    >
                      {benefit}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>

          <Grid
            item
            sm={12} xs={12}>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              style={{ margin: '1rem 0' }}
            >
              1 - 5 Locations
            </Typography>
          </Grid>

          <Grid style={{ margin: 'auto' }} item sm={10} xs={10}>
            <Button
            onClick={props.setActiveSteps}
              style={{
                color: index === 0 ? "#798289" : index === 1 ? "#798289" : "#FFFFFF",
                backgroundColor: index === 0 ? "#EBECED" : index === 1 ? "#FFFFFF" : settingColor.main
              }}
              fullWidth
              variant="contained"
              className={classes.startButton}
            >
              {data.buttonLabel}
            </Button>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

export default MyCourseCard;