import {
  SET_FEEDBACK_DASHBOARD_LOADING,
  SET_FEEDBACK_DASHBOARD,
  SET_FEEDBACK_FILTER_CAMPAIGN,
} from "./constants";

const initialState = {
  isLoading: true,
  campaignData: [],
  categoryData: [],
  averageData: [],
  followupsData: [],
  rankingData: [],
  devicesData: [],
  locationData: [],
  location_data: [],
  category_data: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_FILTER_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignData: payload.campaignDropDown
          ? payload.campaignDropDown
          : payload.campaignData,
        locationData: payload.locationData,
        categoryData: payload.categoryData,
        followupsData: payload.followupsPercentage,
      };
    case SET_FEEDBACK_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        categoryData: payload.categoryData,
        averageData: payload.averageData,
        followupsData: payload.followupsPercentage,
        rankingData: payload.rankingData,
        devicesData: payload.devicesData,
      };
    default:
      return state;
  }
};

export default Reducer;
