import {
  SET_SC_ROLES_LOADING,
  SET_SC_ROLES,
  ADD_SC_ROLES,
  EDIT_SC_ROLES,
  DELETE_SC_ROLES
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { scrolesServices } from './services'

export function fetchData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_SC_ROLES_LOADING,
        payload: true
      });
    }

    scrolesServices.fetchData().then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_SC_ROLES,
          payload: response.data
        });
      }
    });
  };
}


export function deleteData(role_id) {
  return function (dispatch) {
    scrolesServices.deleteData(role_id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_SC_ROLES,
          payload: role_id
        });
      }
    });
  };
}


export function addData(data) {
  return function (dispatch) {
    scrolesServices.addData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_SC_ROLES,
          payload: response.data.post
        });
      }
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    scrolesServices.editData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_SC_ROLES,
          payload: response.data.post
        });
      }
    });
  };
}