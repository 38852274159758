import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MyPlainFullModal, MyNewSearchBar, LoadingData } from '../../../components'
import { settingColor } from '../../../config'
import {
    ViewAgenda as ViewIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons';
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { TableRecordNotFound, TableMyPagination } from '../../../components'
import { invalidToken } from '../../../utilities'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Button,
    Paper,
    Grid
}
    from '@material-ui/core';
// import ViewDetails from "./ViewDetails";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    categoryName: {
        maxWidth: "200px",
        lineHeight: 3,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        color: settingColor.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.primary.main
        }
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
    },
    Buttons: {
        float: 'right',
        backgroundColor: settingColor.main,
        fontWeight: "500",
        color: "white",
        marginRight: 10,
        border: "3px",
        padding: "10px",
        height: 40,
        "&:hover": {
            border: '1px solid darkseagreen',
            backgroundColor: settingColor.main,
            color: 'white'
        }
    },
    viewButtons: {
        float: 'right',
        backgroundColor: settingColor.main,
        fontWeight: "500",
        color: "white",
        marginRight: 10,
        border: "3px",
        padding: "10px",
        height: 40,
        borderRadius: 20,
        "&:hover": {
            border: '1px solid darkseagreen',
            backgroundColor: settingColor.main,
            color: 'white'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});


class HolidayLinked extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDialog: false,
            moreData: [
                {
                    icon: ViewIcon,
                    title: "View Report",
                    sortName: "view",
                    onClick: this.modifyData
                },
                {
                    icon: EditIcon,
                    title: "Edit Report",
                    sortName: "edit",
                    onClick: this.modifyData
                },
                {
                    icon: DeleteIcon,
                    title: "Delete Report",
                    sortName: "delete",
                    onClick: this.modifyData
                }
            ],
            myItem: {},
            order: 'asc',
            orderBy: "_id",
            headCells: [
                {
                    id: 'date',
                    label: "Date",
                    align: "left",
                    numeric: false
                },
                {
                    id: 'name',
                    label: "Name",
                    align: "left",
                    numeric: false
                },
            ],
            page: 1,
            errors: {},
            assignLocationData: [],
            assignLocationDataTemp: [],
            errorsHelper: {},
            searchUsers: ""
        }
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };


    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.assignLocationData) !== JSON.stringify(this.props.assignLocationData)) {
            this.setState({
                searchUsers: "",
                assignLocationData: this.props.assignLocationData,
                assignLocationDataTemp: this.props.assignLocationData
            })
        }
    }


    handleSearch = (target) => {
        var updatedList = this.state.assignLocationDataTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                return (
                    item.name.toLowerCase().search(target.toLowerCase()) !== -1
                );
            });
        }
        var assignLocationData = updatedList.filter((item) => item.name)
        this.setState({
            searchUsers: target,
            assignLocationData,
        });

    };

    clearSearch = () => {
        var assignLocationData = this.state.assignLocationDataTemp.filter((item) => item.name)
        this.setState({
            searchUsers: "",
            assignLocationData,
        });
    }


    render() {
        const { classes, pageNo, rowsPerPage, locationLoader, assignUserName, assignLocationData } = this.props;
        // const { assignLocationData } = this.state;
        if (locationLoader) return <LoadingData />
        return (
            <Grid
                container
                spacing={4}
                className={classes.root}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Paper>
                        <Toolbar variant="regular" className={classes.rootToolbar}>
                            <Typography
                                display="inline"
                                className={classes.title}
                                variant="h6" >
                                Holiday
                            </Typography>

                            {/* <Grid style={{ marginBottom: 6 }}>
                                <MyNewSearchBar
                                    clearSearch={this.clearSearch}
                                    handleSearch={this.handleSearch}
                                    value={this.state.searchUsers}
                                    placeholder="Search Location"
                                    style={{ marginTop: 8, height: 40 }}
                                />
                            </Grid> */}

                        </Toolbar>

                        <TableContainer className={{ root: classes.customTableContainer }}>
                            <Table
                                stickyHeader
                                className={classes.table}
                                size='medium'
                            >
                                <TableHead style={{ backgroundColor: settingColor.main }}>
                                    <TableRow>
                                        {this.state.headCells.map((headCell) => (
                                            <TableCell
                                                style={{ backgroundColor: settingColor.main }}
                                                align={headCell.align}
                                                key={headCell.id}
                                                sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                            >
                                                <TableSortLabel
                                                    className={classes.headCell}
                                                    active={this.state.orderBy === headCell.id}
                                                    direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                    onClick={this.createSortHandler(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {this.state.orderBy === headCell.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(assignLocationData, getComparator(this.state.order, this.state.orderBy))
                                        .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage).map((row, index) => {
                                        
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row._id}
                                                >
                                                    <TableCell
                                                        align="left"
                                                    // className={classes.TableCell}
                                                    >
                                                        {moment(row.holiday_date).locale('en-US').format("DD-MM-YYYY")}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                    // className={classes.TableCell}
                                                    >
                                                        {row.holiday_name}
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}

                                    {assignLocationData && assignLocationData.length < 1 ?
                                        <TableRecordNotFound
                                            colSpan={8}
                                            label="No Data Found.."
                                        />
                                        : ""
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <TableMyPagination
                                count={assignLocationData ? assignLocationData.length : 0}
                                /> */}
                    </Paper>


                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(HolidayLinked);