import React, { useRef } from 'react';
import JoditEditor from "jodit-pro-react";

export default function App(props) {
    const {handleEditor } = props;
    const editor = useRef(null)
    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        filebrowser: {
            ajax: {
                url: 'https://xdsoft.net/jodit/finder/'
            }
        },
        license: '1216B-0PK50-AJJPL-SBBQZ',
        readonly: props.readonly ? props.readonly : false,
        width: '100%',
        maxWidth: '100%',
        minHeight: 400,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: 'insert_clear_html',
        placeholder: 'Write something awesome ...',
        beautyHTML: true,
        toolbarButtonSize: "large",
        controls: {
            font: {
                command: "fontname",
                data: {
                    cssRule: "font-family",
                    //normalize: "function(e){return e.toLowerCase().replace(/['\"]+/g,\"\").replace(/[^a-z0-9]+/g,\",\")}"
                },
                list: {
                    "": "Default",
                    "Quicksand,sans-serif": "Quicksand",
                    "Helvetica,sans-serif": "Helvetica",
                    "Arial,Helvetica,sans-serif": "Arial",
                    "Georgia,serif": "Georgia",
                    "Impact,Charcoal,sans-serif": "Impact",
                    "Tahoma,Geneva,sans-serif": "Tahoma",
                    "'Times New Roman',Times,serif": "Times New Roman",
                    "Verdana,Geneva,sans-serif": "Verdana",
                    "Lucida Console": "Lucida Console",
                    "Lucida Handwriting": "Lucida Handwriting",
                    "Papyrus": "Papyrus",
                    "Monaco": "Monaco",
                    "Roboto": "Roboto",
                    "Brush Script MT": "Brush Script MT",
                    "Courier New": "Courier New",
                    "monospace": "monospace"
                },
                exec: "function(e,t,n){var r=n.control;return a.memorizeExec(e,t,{control:r},(function(t){var n;return\"fontsize\"===(null===(n=r.command)||void 0===n?void 0:n.toLowerCase())?\"\"+t+e.o.defaultFontSizePoints:t}))}",
                childTemplate: "function(e,t,n){return'<span style=\"font-family: '+t+'!important;\">'+n+\"</span>\"}",
                tooltip: "Font family",
                isChildActive: "function(e,t){var n,r,o=e.s.current(),s=(null===(n=t.data)||void 0===n?void 0:n.cssRule)||\"font-size\",l=(null===(r=t.data)||void 0===r?void 0:r.normalize)||function(t){return/pt$/i.test(t)&&\"pt\"===e.o.defaultFontSizePoints?t.replace(/pt$/i,\"\"):t};if(o){var u=i.Dom.closest(o,(function(t){return i.Dom.isBlock(t,e.ew)||t&&i.Dom.isElement(t)}),e.editor)||e.editor,c=a.css(u,s);return Boolean(c&&t.args&&l(t.args[0].toString())===l(c.toString()))}return!1}"
            },
        },
    }

    return (
        <JoditEditor
            ref={editor}
            value={props.value}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => handleEditor(newContent)}
        />
    );
}