import {
  GET_LOCATION_DETAIL_DATA,
  GET_STATE_DATA,
  GET_CITY_DATA,
  GET_SCHEDULE_SETTING_DATA,
  UPDATE_SCHEDULE_SETTING_DATA,
  UPDATE_LOCATION_DETAIL_DATA,
  GET_ROLE_DATA,
  SCHEDULER_SETTINGS_LOADING,
  GET_BREAK_DATA,
  DELETE_BREAK_DATA,
  ADD_WORK_SCEDULER,
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
  stateData: [],
  cityData: [],
  updateLocationdata: [],
  LocationDetaildata: [],
  updatedLocationDetaildata: [],
  BreakData: [],
  roleDetails: [],
  DeleteBreakData: [],
  WorkScedulerData: [],
  WorkSchedule: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SCHEDULER_SETTINGS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case GET_LOCATION_DETAIL_DATA:
      return {
        ...state,
        isLoading: false,
        locationData: payload,
      };
    case GET_STATE_DATA:
      return {
        ...state,
        isLoading: false,
        stateData: payload,
      };
    case GET_CITY_DATA:
      return {
        ...state,
        isLoading: false,
        cityData: payload,
      };
    case UPDATE_LOCATION_DETAIL_DATA:
      return {
        ...state,
        isLoading: false,
        updateLocationdata: payload,
      };
    case GET_SCHEDULE_SETTING_DATA:
      return {
        ...state,
        isLoading: false,
        LocationDetaildata: payload,
        WorkSchedule: payload.WorkScheduleData,
      };
    case UPDATE_SCHEDULE_SETTING_DATA:
      return {
        ...state,
        isLoading: false,
        updatedLocationDetaildata: payload,
      };
    case GET_BREAK_DATA:
      return {
        ...state,
        isLoading: false,
        BreakData: payload,
      };
    case DELETE_BREAK_DATA:
      return {
        ...state,
        isLoading: false,
        BreakData: state.BreakData.filter((item) => item._id !== payload),
      };
    case GET_ROLE_DATA:
      return {
        ...state,
        isLoading: false,
        roleDetails: payload,
      };
    case ADD_WORK_SCEDULER:
      var myPayload = payload;
      return {
        ...state,
        isLoading: false,
        WorkScedulerData: state.WorkScedulerData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
