import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { eLearningColor } from '../../config'
const useStyles = makeStyles(theme => ({
  link: {
    padding: '0px 15px',
    borderRadius: '5px',
    textDecoration: "none",
    marginBottom: '.25rem',
    boxShadow: "0.5px 0.5ppx 0.5ppx rgba(0, 0, 0, 0.25)",
    '&:hover': {
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: theme.palette.text.secondary
      },
      "& $linkIcon": {
        color: theme.palette.text.secondary
      },
      "& $linkTextCount": {
        color: theme.palette.text.secondary
      }
    }
  },
  borderDiv: {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    height: '45px',
    marginLeft: "-30px",
    marginRight: "25px"
  },
  linkActive: {
    backgroundColor: eLearningColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)"
  },
  eLearningActive: {
    backgroundColor: eLearningColor.main
  },
  avatar: {
    height: 25,
    width: 25,
    marginRight: '1rem'
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color")
  },
  linkIconActive: {
    color: "#ffffff"
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    textDecoration:'none',
  },
  linkTextCount: {
    float: 'right',
    color: "#000000",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 12
  },
  linkTextActive: {
    fontWeight: 600,
    color: "#ffffff"
  },
  linkTextHidden: {
    opacity: 0,
  }
}));


export default function SidebarLink({ clickLink, path, icon, image, active_image, label, location, isSidebarOpen, activeProduct, categoryId }) {
  var classes = useStyles();
  var isLinkActive = path && (location.pathname === path);
  const query = new URLSearchParams(location.search);
  var newId = query.get('_id')
  var categoryLinkActive = categoryId.toString() === newId.toString() ? true : false;
  var [toolTip, setToolTip] = useState(false);

  const openTooltip = () => {
    if (!isSidebarOpen) {
      setToolTip(true)
    }
  }

  const closeTooltip = () => {
    setToolTip(false)
  }

  return (
    <Tooltip
      arrow
      placement="right"
      open={toolTip}
      onClose={closeTooltip}
      onOpen={openTooltip}
      title={<p className={classes.popoverTitle}>{label}</p>}
    >
      <ListItem
        onClick={clickLink}
        button
        component={path && Link}
        to={path}
        classes={{
          root: classnames(classes.link, {
            [classes.linkActive]: categoryId ? categoryLinkActive : isLinkActive,
            [classes.eLearningActive]: activeProduct === "eLearning" && isLinkActive ? true : false
          }),
        }}
        disableRipple
      >
        <div
          className={classes.borderDiv}
          style={{
            borderLeft:
              categoryLinkActive ?
                `5px solid ${eLearningColor.main}`
                : `5px solid #ffffff`,
          }}
        />
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: categoryId ? categoryLinkActive : isLinkActive
          })}
        >
          {image ?
            <Avatar
              variant="square"
              className={classes.avatar}
              src={isLinkActive ? active_image : image} />
            : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: categoryId ? categoryLinkActive : isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpen,
            }),
          }}
          primary={label}
        />

      </ListItem>
    </Tooltip>
  );
}
