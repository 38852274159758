import axios from 'axios';
import { PHP_FILE_URL } from '../config'
export const fileUpload = async (file, product_name) => {
    let result = null;
    const formData = new FormData();
    formData.append("myfile", file, file.name);
    formData.append("product_name", product_name);
    await axios.post(PHP_FILE_URL, formData).then(response => {
        if (response.data.success) {
            // console.log("hhhhhhresponse.data : " ,JSON.stringify(response.data))
            result = response.data.location
        }
    }).then();
    return result;
}


