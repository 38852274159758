//MyCenterModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const styles = (theme) => ({
  dialog: {
    borderRadius: 0,
  },
  buttonSignup: {
    boxShadow: 'none',
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: 'green',
    borderRadius: 0,
    border: '1px solid green',
    padding: '5px 22px'
  },
  buttonLogin: {
    boxShadow: 'none',
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    border: '1px solid red',
    padding: '5px 22px'
  },
  DialogTitle: {
    padding: "10px",
  },
  myTitle: {
    lineHeight: '2.5rem',
    float: "left",
    color: "#ffffff",
    fontWeight: 600
  },
  closeIcon: {
    color: "#ffffff",
    cursor: 'pointer',
    float: 'right'
  },
  dialogcontent: {
    backgroundColor: '#fcfcfc',
    padding: '1rem 1.5rem'
  }
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MyCenterModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{ backgroundColor: this.props.bgColor ? this.props.bgColor : "gray" }}
          className={classes.DialogTitle}
        >
          <Typography className={classes.myTitle} variant="h4" >
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Close">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent
          className={classes.dialogcontent}
          dividers>
          {children}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(MyCenterModal);