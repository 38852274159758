export function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    var myA = a[orderBy] > -1 ? a[orderBy] : a[orderBy]
    var myB = b[orderBy] > -1 ? b[orderBy] : b[orderBy]
    if (myB < myA) { return -1; }
    if (myB > myA) { return 1; }
    return 0;
}

export function stableSort(array, comparator) {
    array = array && array.length > 0 ? array : []
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}