import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, TableMyPagination, TableRecordNotFound, LoadingData, DeleteModal,MyLeftSchedulerModal,MySearchBar } from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { fetchData, addData, editData, deleteData } from './modules/actions';
import { connect } from "react-redux";
import { FILE_URL, schedulerColor } from '../../../config';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { utcToLocal,invalidToken,alert } from '../../../utilities';
import {
    Delete as DeleteIcon
} from '@material-ui/icons';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    Avatar,
    Button,
}
    from '@material-ui/core';
import { coverServices } from "./modules/services";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    TableCell: {
        padding: '25px'
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontSize: '16px',
        lineHeight: '27px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
        req_user_name: {
        fontSize: "15px",
        maxWidth: "50px",
        lineHeight: '4.5',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    title: {
        flex: '1 1 100%',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#1E2134'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    userName: {
        maxWidth: "250px",
        // lineHeight: 3,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        lineHeight: '22.88px',
        fontWeight: 500,
        color: '#1E2134',
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "35px",
        width: "35px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    button: {
        color: '#ffffff',
        padding: '8px 22px',
        width: "150px",
        fontWeight: '400'
    },
});

export function fetchCoverShiftsData() {
    window.CoverShift.fetchData(true);
}

class TeamCoverShift extends Component {
    constructor(props) {
        super(props)
        window.CoverShift = this;
        this.state = {
            myItem: {},
            detailModal:false,
            order: 'asc',
            orderBy: "id",
            headCells: [
                { id: 'reqBy', label: "User Name", numeric: false },
                { id: 'reqTo', label: "Created At", numeric: false },
                { id: 'reqDate', label: "Location name", numeric: false },
                { id: 'status', label: "Approved At", numeric: false },
            ],
        }
    }

    componentDidMount = () => {
        var isReload = this.props.teamCoverData.length > 0 ? false : true
        this.fetchData(isReload)
    }

componentDidUpdate(prevProps, prevState) {
if (JSON.stringify(prevProps.teamCoverData) !== JSON.stringify(this.props.teamCoverData)) {
    this.setState({
        searchUsers: "",
        teamCoverData:this.props.teamCoverData,
        teamCoverDataTemp:this.props.teamCoverData
    })
}
}

    fetchData = (isReload) => {
        var lid = this.props.scheduler_lid
        this.props.fetchData(lid, isReload)

        this.setState({
            teamCoverData:this.props.teamCoverData,
            teamCoverDataTemp:this.props.teamCoverData
        })
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    deleteData = () => {
        this.setState({ deleteModal: false })
        this.props.deleteData(this.state.myItem.id)
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    modifyData = (value, item) => {
        this.setState({
            myItem: item,
            deleteModal: value === "delete" ? true : false,
            detailModal:false
        })
    }

    openDetailModal = (row) => {
        this.setState({
            detailModal:true,
            UserData:row,
            isUserLoading:true
        })
        coverServices.fetchTeamDetailsById(row.id).then(response => {
            if (response.data.success === 2) {
                invalidToken(response.data.message)
              } else {
                this.setState({
                    coverUserData:response.data.tradeUsers ? response.data.tradeUsers[0] : "",
                    isUserLoading:false
                })
              }
         })
    }

    closeDetalModal= () => {
        this.setState({
            detailModal:false
        })
    }

    handleApprove = (row) => {
        var data ={
            status:1,
            job_id:this.state.UserData.job_id
        }
        coverServices.approveRejectTeamCover(this.state.UserData.id,data).then(response => {
            if (response.data.success === 2) {
                invalidToken(response.data.message)
              } else {
                alert.success(response.data.message)
                this.closeDetalModal()
              }

         })
    }

    handleReject = (row) => {
        var data ={
            status:2,
            job_id:this.state.UserData.job_id
        }
        coverServices.approveRejectTeamCover(this.state.UserData.id,data).then(response => {
            if (response.data.success === 2) {
                invalidToken(response.data.message)
              } else {
                alert.success(response.data.message)
                this.closeDetalModal()
              }
         })
    }


    handleSearch = (target) => {
        var updatedList = this.state.teamCoverDataTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                return (
                    item.user_name.toLowerCase().search(target.toLowerCase()) !== -1
                );
            });
        }
            var teamCoverData = updatedList.filter((item) => item.user_name)
            this.setState({
                searchUsers: target,
                teamCoverData,
            });
        
    };

    clearSearch = () => {
        var teamCoverData = this.state.teamCoverDataTemp.filter((item) => item.user_name)
        this.setState({
            searchUsers: "",
            teamCoverData,
        });
    }


    render() {
        const { classes, isLoading, pageNo, rowsPerPage, permissionsData } = this.props;
        const {teamCoverData} = this.state;
        if (isLoading) return <LoadingData />


        return (
            permissionsData.scheduler_cover_shift ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Paper>
                            <Toolbar variant="regular" className={classes.rootToolbar}>
                                <Typography
                                    display="inline"
                                    className={classes.title}
                                    variant="h6" >
                                    Team Cover
                                </Typography>
                                <MySearchBar
                                    clearSearch={this.clearSearch}
                                    handleSearch={this.handleSearch}
                                    value={this.state.searchUsers}
                                    placeholder="Search user"
                                />
                            </Toolbar>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    size='medium'
                                >
                                    <TableHead style={{ backgroundImage: schedulerColor.main }}>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                >
                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                style={{ paddingRight: '20px' }}
                                                className={classes.headCell}
                                                align="right"
                                            // colSpan={2}
                                            >Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {stableSort(teamCoverData, getComparator(this.state.order, this.state.orderBy))
                                            .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        style={{cursor:"pointer"}}
                                                  
                                                    >
                                                        <TableCell
                                                            style={{ verticalAlign: "middle" }}
                                                            onClick={() => this.openDetailModal(row)}
                                                            className={classes.TableCell}>
                                                            {row.user_image ?
                                                                <Avatar
                                                                    src={FILE_URL + row.user_image}
                                                                    className={classes.avatar}
                                                                />
                                                                :
                                                                <Avatar className={classes.avatar} >
                                                                    {row.user_name.charAt(0)}
                                                                </Avatar>
                                                            }
                                                            <Typography
                                                                className={classes.userName}
                                                                onClick={() => this.openDetailModal(row)}
                                                                style={{ marginTop: '5px' }}
                                                            >
                                                                {row.user_name}
                                                            </Typography>
                                                        </TableCell>



                                                        <TableCell
                                                              onClick={() => this.openDetailModal(row)}
                                                            className={classes.TableCell}>
                                                            <Typography
                                                                className={classes.userName}
                                                            >
                                                                {row.created_at ? moment(utcToLocal(row.created_at)).locale('en-US').format("DD MMM YYYY") : ""}
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell
                                                              onClick={() => this.openDetailModal(row)}
                                                            className={classes.TableCell}>
                                                             <Typography
                                                                className={classes.userName}
                                                                style={{ marginTop: '5px' }}
                                                            >
                                                                {row.location_name}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell
                                                              onClick={() => this.openDetailModal(row)}
                                                            className={classes.TableCell}>
                                                            <Typography
                                                                className={classes.userName}
                                                            >
                                                                {row.user_approved_date ? moment(utcToLocal(row.user_approved_date)).locale('en-US').format("DD MMM YYYY") : ""}
                                                            </Typography>
                                                        </TableCell>


                                                        <TableCell
                                                            align="right"
                                                            className={classes.TableCell}>
                                                            <IconButton
                                                                //disabled={this.props.ovUid === row.id && permissionsData.scheduler_covershifts_delete ? false : true}
                                                                size="small"
                                                                onClick={() => { this.modifyData("delete", row) }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}

                                        {teamCoverData && teamCoverData.length < 1 ?
                                            <TableRecordNotFound
                                                colSpan={8}
                                                label="No Data Found.."
                                            />
                                            : ""
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableMyPagination
                                count={teamCoverData ? teamCoverData.length : 0}
                            />
                        </Paper>

                        <DeleteModal
                            bgColor={schedulerColor.main}
                            desc="Are you sure you want to delete?"
                            open={this.state.deleteModal}
                            onClose={this.deleteModalClose}
                            handleDelete={this.deleteData}
                        />

                    <MyLeftSchedulerModal
                                bgColor={schedulerColor.main}
                                title={"Cover Shift"}
                                open={this.state.detailModal}
                                onClose={this.closeDetalModal}
                                handleApprove={this.handleApprove}
                                handleReject={this.handleReject}
                                >
                                {this.state.isUserLoading ?
                                    <LoadingData/>
                                :
                                <Grid container spacing={1}>
                                   <Grid>
                                    <Card >
                                    <CardContent>
                                     <Grid container spacing={2} style={{display:"flex"}}>
                                        <Grid item md ={4} style={{backgroundColor:schedulerColor.main,textAlign:"center"}}>
                                            <div style={{marginTop:"50%"}}>
                                            {this.state.coverUserData?.created_at ? moment(utcToLocal(this.state.coverUserData?.created_at)).locale('en-US').format("DD") : ""}
                                            </div>
                                            <div >
                                            {this.state.coverUserData?.created_at ? moment(utcToLocal(this.state.coverUserData?.created_at)).locale('en-US').format("MMM") : ""}
                                            </div>
                                            <div>
                                            {this.state.coverUserData?.created_at ? moment(utcToLocal(this.state.coverUserData?.created_at)).locale('en-US').format("YYYY") : ""}
                                            </div>
                                        </Grid>
                                        <Grid item md ={8}>
                                            <div>
                                            { moment(utcToLocal(this.state.coverUserData?.startTime)).locale('en-US').format("hh:mm A") } - {moment(utcToLocal(this.state.coverUserData?.endTime)).locale('en-US').format("hh:mm A") }
                                            </div>
                                            <div>
                                              {this.state.coverUserData?.total_scheduled_hrs}hour
                                            </div>
                                            
                                            <div>
                                                {this.state.coverUserData?.location_name}
                                            </div>
                                            <div>
                                                Requested on {this.state.coverUserData?.created_at ? moment(utcToLocal(this.state.coverUserData?.created_at)).locale('en-US').format("DD MMM YYYY") : ""}
                                            </div>
                                            <div>
                                                {this.state.coverUserData?.user_name}  wants to cover shift
                                            </div>
                                            </Grid>
                                     </Grid>
                                    </CardContent>
                                    </Card>
                                   </Grid>
                                </Grid>}
                                </MyLeftSchedulerModal>
                    </Grid>
                </Grid >
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerCoverShift.isLoading,
    teamCoverData: state.schedulerCoverShift.teamCoverData
});

const ConnectWith = connect(mapStateToProps, { fetchData, addData, editData, deleteData })(TeamCoverShift);
export default withStyles(styles)(ConnectWith);
