import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, MyLeftModalWithUser, LoadingData, DeleteModal } from '../../../components'
import { schedulerColor } from '../../../config';
import { teamAvailabilityService } from './modules/services';
import { utcTimeToLocal, localTimeToUtc, validationIsEmpty } from '../../../utilities'
import moment from "moment";
import {
    Paper,
    Grid
}
    from '@material-ui/core';
import { alert } from '../../../utilities';
import AddEdit from './AddEdit';
import TeamAvailabilityScheduler from './TeamAvailabilityScheduler';
import {utcToTimeZone} from "../../../utilities/utcToTimeZone"
import NotifyManager from './NotifyManager';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    btnChild: {
        fontWeight: 500,
        padding: '8px 25px',
        color: schedulerColor.main
    },
});

export function fetchTeamAvailabilityData() {
    window.TeamAvailability.fetchData();
}

class TeamAvailability extends Component {
    constructor(props) {
        super(props)
        window.TeamAvailability = this;
        this.state = {
            start_day: 1,
            enforce_hours_start: "",
            enforce_hours_end: "",
            isLoading: true,
            myItem: {},
            errors: {},
            errorsHelper: {},
            timeArray: [],
            allChilds: [],
            resources: [],
            eventsData: [],
        }
    }

    componentDidMount = () => {
        this.fetchData()
        var timeArray = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                var id = moment({ hour, minute }).format();
                id = new Date(id)
                id = id.toLocaleString('en-US', { timeZone:this.props.timezone[0].location_timezone  });
                var name = moment(id).format("hh:mm A");
                timeArray.push({ id, name });
            }
        }
        this.setState({ timeArray })
    }

    fetchData = () => {
        var data = {
            lid: this.props.scheduler_lid
        }

        this.setState({ isLoading: true })
        teamAvailabilityService.fetchData(data).then(response => {
            if (response.data.success === 2) {
            } else {
                var days = [];
                var start_day = response.data.start_day
                var currentDate = moment();
                var currentDateIndex = currentDate.day()
                var weekStart1 = currentDate.clone().startOf('week');
                var weekStart = ""
                if (start_day > currentDateIndex) {
                    var diff = 7 - start_day
                    weekStart = moment(weekStart1).subtract(diff, 'days')
                } else {
                    weekStart = moment(weekStart1).add(start_day, 'days')
                }

                for (var i = 0; i <= 6; i++) {
                    var date = moment(weekStart).add(i, 'days')
                    var object1 = date.format("YYYY-MM-DD")
                    var object2 = new Date(date).getDay()
                    var object = { "date": object1, "dayId": object2 }
                    days.push(object);
                }

                var eventsData = response.data.eventsData.map((items) => {
                    var cloneItem = items
                    //var start_time = moment(utcTimeToLocal(items.start_time)).format("HH:mm:ss")
                    //var end_time = moment(utcTimeToLocal(items.end_time)).format("HH:mm:ss")
                    var startTiime = new Date(items.start_time)
                    var start_time = startTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                    //var end_time = moment(utcTimeToLocal(items.end_time)).format("HH:mm:ss")
                    var endTiime = new Date(items.end_time)
                    var end_time = endTiime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                    days.map(dayValue => {
                        if (dayValue.dayId === items.day_id) {
                            cloneItem["title"] = ""

                            if (items.is_wholeday) {
                                cloneItem["start"] = dayValue.date + " " + "00:00:00"
                                cloneItem["end"] = dayValue.date + " " + "23:45:00"
                            } else {
                                cloneItem["start"] =  start_time
                                cloneItem["end"] = end_time
                            }
                        }
                    })
                    return cloneItem
                })

                this.setState({
                    isLoading: false,
                    start_day: response.data.start_day,
                    enforce_hours_start: response.data.enforce_hours_start,
                    enforce_hours_end: response.data.enforce_hours_end,
                    allChilds: response.data.allChilds,
                    eventsData: eventsData,
                    resources: response.data.usersData
                })
            }
        });
    }

    handleSubmit = async () => {
        var myItem = this.state.myItem;
        var valid_start_time = await validationIsEmpty(myItem.start_time);
        var valid_end_time = await validationIsEmpty(myItem.end_time);
        if (myItem.is_wholeday === false) {
            if (valid_start_time) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        start_time: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        start_time: "Please select start time"
                    }
                }))
            }

            if (valid_end_time) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        end_time: true
                    },
                    errorsHelper: {
                        ...prevState.errorsHelper,
                        end_time: "Please select end time"
                    }
                }))
            }

            if (valid_end_time || valid_start_time) {
                return;
            }
        }
        this.setState({ addEditModal: false })
        if (this.state.isEdit) {
            this.editAvailability()
        } else {
            this.addAvailability()
        }
    };

    deleteAvailability = () => {
        var id = this.state.id
        teamAvailabilityService.deleteAvailability(id).then(response => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.deleteModalClose()
                window.location.reload()
            }
        });
    };

    deleteModalOpen = (id) => {
        this.setState({
            id: id,
            deleteModal: true
        })
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };

    editModalOpen = (event, dayName) => {
        var start_time = moment(event.start).format("HH:mm:ss")
        var end_time = moment(event.end).format("HH:mm:ss")
        var myItem = {
            id: event.id,
            lid: event.lid,
            day_id: event.day_id,
            is_prefered: event.is_prefered,
            is_wholeday: event.is_wholeday,
            dayName: dayName,
            start_time: start_time,
            end_time: end_time
        }

        this.setState({
            isEdit: 1,
            addEditModal: true,
            myItem
        }, () => {
            this.setSelectData()
        })
    }

    addEditModalOpen = (av_id, day_id, dayName) => {
        var start_time = this.state.enforce_hours_start
        var end_time = this.state.enforce_hours_end
        var myItem = {
            lid: this.props.scheduler_lid,
            day_id: day_id,
            is_prefered: 1,
            is_wholeday: 0,
            dayName: dayName,
            start_time: "",
            end_time: ""
        }

        this.setState({
            av_id,
            isEdit: 0,
            addEditModal: true,
            myItem
        }, () => {
            this.setSelectData()
        })
    };


    setSelectData = () => {
        var myItem = this.state.myItem
        var startTimeSelected = this.state.timeArray.filter(function (time) {
            return time.id === myItem.start_time;
        });

        var endTimeSelected = this.state.timeArray.filter(function (time) {
            return time.id === myItem.end_time;
        });

        this.setState({
            errors: {},
            errorsHelper: {},
            startTimeSelected: startTimeSelected.length > 0 ? startTimeSelected[0] : {},
            endTimeSelected: endTimeSelected.length > 0 ? endTimeSelected[0] : {}
        })
    }

    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }))
    }

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }

    handleChangeWholeDay = () => {
        var is_wholeday = this.state.myItem.is_wholeday
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                is_wholeday: is_wholeday ? 0 : 1
            }
        }))
    }

    handleChangePrefered = (is_prefered) => {
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                is_prefered
            }
        }))
    }

    handleChangeStartTime = (newValue) => {
        var startTime = newValue ? newValue.id : ""
        var startTimeSelected = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                start_time: startTime
            },
            startTimeSelected
        }))
    }

    handleChangeEndTime = (newValue) => {
        var endTime = newValue ? newValue.id : ""
        var endTimeSelected = newValue ? newValue : null
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                end_time: endTime
            },
            endTimeSelected
        }))
    }


    editAvailability = () => {
        var data = this.state.myItem
        data.start_time = moment(data.start_time).utc().format("YYYY-MM-DD HH:mm:ssZ")
        data.end_time = moment(data.end_time).utc().format("YYYY-MM-DD HH:mm:ssZ")
        data.av_id = this.state.av_id
        teamAvailabilityService.editAvailability(data).then(response => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.addEditModalClose()
                // window.location.reload()
            }
        });
    }

    addAvailability = () => {
        var data = this.state.myItem
        data.start_time = moment(data.start_time).utc().format('YYYY-MM-DDTHH:mm:ssZ')
        data.end_time = moment(data.end_time).utc().format('YYYY-MM-DDTHH:mm:ssZ')
        // data.end_time = moment(localTimeToUtc(data.end_time)).format("HH:mm:ss")
        data.av_id = this.state.av_id ? this.state.av_id : 0
        teamAvailabilityService.addAvailability(data).then(response => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.addEditModalClose()
                // window.location.reload()
            }
        });
    }

    notifyModalOpen = (av_id, status) => {
        this.setState({
            av_id,
            status,
            notifyModal: true
        })
    }

    notifyModalClose = () => {
        this.setState({
            notifyModal: false,
        });
    }

    approveAvailability = () => {
        var data = {
            id: this.state.av_id,
            manager_notes: this.state.manager_notes,
            status: this.state.status
        }

        teamAvailabilityService.approveAvailability(data).then(response => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.notifyModalClose()
                window.location.reload()
            }
        });
    }

    handleManagerNotes = (e) => {
        this.setState({
            manager_notes: e.target.value
        });
    }

    render() {
        const { classes, locationData, permissionsData ,timezone} = this.props;
        const {
            isLoading,
            eventsData,
            timeArray,
            errors,
            errorsHelper,
            startTimeSelected,
            endTimeSelected,
            myItem
        } = this.state;

        if (isLoading) return <LoadingData />
        return (
            permissionsData.scheduler_team_availability ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Paper>
                            <TeamAvailabilityScheduler
                                start_day={this.state.start_day || 0}
                                permissionsData={permissionsData}
                                notifyModalOpen={this.notifyModalOpen}
                                allChilds={this.state.allChilds}
                                timeArray={timeArray}
                                timezone={timezone}
                                locationData={locationData}
                                resources={this.state.resources}
                                events={eventsData}
                                addEditModalOpen={this.addEditModalOpen}
                                editModalOpen={this.editModalOpen}
                                deleteModalOpen={this.deleteModalOpen}
                        />
                        </Paper>


                        <MyLeftModalWithUser
                            bgColor={schedulerColor.main}
                            userName={this.props.ovUserName}
                            userImage={this.props.ovUserImage}
                            title={this.state.isEdit ? "Edit Shift" : "Add Shift"}
                            open={this.state.addEditModal}
                            onClose={this.addEditModalClose}
                            handleSubmit={this.handleSubmit}
                        >
                            <AddEdit
                                timeArray={timeArray}
                                errors={errors}
                                errorsHelper={errorsHelper}
                                startTimeSelected={startTimeSelected}
                                endTimeSelected={endTimeSelected}
                                myItem={myItem}
                                locationData={locationData}
                                handleChangePrefered={this.handleChangePrefered}
                                handleChangeWholeDay={this.handleChangeWholeDay}
                                handleChange={this.handleChange}
                                handleFocus={this.handleFocus}
                                handleChangeStartTime={this.handleChangeStartTime}
                                handleChangeEndTime={this.handleChangeEndTime}
                            />
                        </MyLeftModalWithUser>

                        <NotifyManager
                            manager_notes={this.state.manager_notes}
                            handleManagerNotes={this.handleManagerNotes}
                            approveAvailability={this.approveAvailability}
                            notifyModal={this.state.notifyModal}
                            notifyModalClose={this.notifyModalClose}
                        />

                        <DeleteModal
                            bgColor={schedulerColor.main}
                            desc="Are you sure you want to delete?"
                            open={this.state.deleteModal}
                            onClose={this.deleteModalClose}
                            handleDelete={this.deleteAvailability}
                        />
                    </Grid>
                    </Grid>

:
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

export default withStyles(styles)(TeamAvailability);