import {MoNGO_DB_API_URL, API_URL, HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import axios from 'axios';

export const categoryServices = {
    fetchData,
    addData,
    editData,
    deleteData
}

function fetchData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/elearning/category/v3/library`,{data}, HEADER_TOKEN).then(response => response)
};


function addData(myData) {
    const data = new FormData();
    data.append("category_name", myData.category_name);
    data.append("file", myData.image, myData.image.name);
    var config = {
        method: 'post',
        url: `${MoNGO_DB_API_URL}/elearning/category`,
        headers: {
            'Authorization': MY_TOKEN
        },
        data: data
    };
    return axios(config).then(response => response)
};

function editData(myData) {
    const data = new FormData();
    data.append("_id", myData._id);
    data.append("category_name", myData.category_name);
    data.append("category_lesson", myData.category_lesson);
    if (myData.changeFile) {
        data.append("file", myData.image, myData.image.name);
    }
    var config = {
        method: 'post',
        url: `${MoNGO_DB_API_URL}/elearning/category/edit`,
        headers: {
            'Authorization': MY_TOKEN
        },
        data: data
    };
    return axios(config).then(response => response)
};

function deleteData(_id) {
    return axios.delete(`${MoNGO_DB_API_URL}/elearning/category/library/${_id}`, HEADER_TOKEN).then(response => response)
};