export const SET_PAYROLL_LOADING = 'SET_PAYROLL_LOADING'
export const SET_PAYROLL = 'SET_PAYROLL'
export const ADD_PAYROLL = 'ADD_PAYROLL'
export const EDIT_PAYROLL = 'EDIT_PAYROLL'
export const DELETE_PAYROLL= 'DELETE_PAYROLL'
//////////////PE//////////////////////////
export const SET_PE_LOADING = 'SET_PE_LOADING'
export const SET_PE = 'SET_PE'
export const ADD_PE = 'ADD_PE'
export const EDIT_PE = 'EDIT_PE'
export const DELETE_PE= 'DELETE_PE'
export const SET_TIMECODESSS = 'SET_TIMECODESSS'
export const SET_TIMECODE_LOADING = 'SET_TIMECODE_LOADING'
export const SET_USERS = 'SET_USERS'
export const SET_USERS_LOADING = 'SET_USERS_LOADING'
export const EDIT_CODE = 'EDIT_CODE'

/////////////Export header //////////////

export const SET_HEADER_LOADING = 'SET_HEADER_LOADING'
export const SET_HEADER = 'SET_HEADER'
export const ADD_HEADER = 'ADD_HEADER'
export const EDIT_HEADER = 'EDIT_HEADER'
export const DELETE_HEADER = 'DELETE_HEADER'
export const ADDDELETEROW = 'ADDDELETEROW'

////////////////Export Timecode /////////////////
export const SET_CODEUSERS = 'SET_CODEUSERS'
export const SET_CODEUSERS_LOADING = 'SET_CODEUSERS_LOADING'
export const EDIT_CUSTOMCODE = 'EDIT_CUSTOMCODE'
export const MODAL_OPEN = 'MODAL_OPEN'
